import React, {useRef, useState} from "react"
import {Route, Switch} from "react-router-native"
import styled from "@emotion/native"
import {useLocation} from "react-router"
import {T, useTranslator} from "../../translations/translate"
import {useCampaign} from "../../GlobalContexts"

import {TeamsRanking} from "./TeamsRanking"
import {PlayersRanking} from "./PlayersRanking"

import {colors} from "@civitime/library/storybook/configs/colors"
import {useScreenDimensions} from "@civitime/library/storybook/hooks/useScreenDimensions"
import {MOBILE_HEADER_SIZE}             from "@civitime/library/storybook/configs/sizes"
import {TabsButton}                     from "@civitime/library/storybook/stories/Button"
import {
  IndividualRankIcon,
  TeamRankIcon,
}                                       from "@civitime/library/storybook/stories/Icons"
import {MediumText, Title1}             from "@civitime/library/storybook/stories/Texts"
import InfoButton                       from "@civitime/library/assets/info-button-i.svg"
import {TouchableWithoutFeedback, View} from "react-native"
import {TooltipTuto}                    from "../../Phases/Phase 2/Components/Tuto/TooltipTuto"
import {useProfile}                     from "../../Auth/useProfile"
import { PWA }                          from '../../../clientId'

export const RankingTabs = ({phase}) => {
  const location = useLocation()
  const root = useRef(null)
  const {mobile} = useScreenDimensions(root)
  const {t} = useTranslator()
  const {height, width} = useScreenDimensions(root)
  const {campaignNavigate, campaignSettings} = useCampaign()
  const {basePath, campaignEnded} = useCampaign()
  const {userState} = useProfile()
  const teamRankLocation = location.pathname.match("/leaderboard/team")

  const [displayedModalInfos, setDisplayedModalInfos] = useState('')
  const [activeTab, setActiveTab] = useState('team')
  return (
    <Screen
      ref={root}
      color={colors.text}
      title={t("app.team.yourTeam")}
      onPress={() => campaignNavigate("")}
      height={height}
      width={width}
      withShadow
      mobile={mobile}
    >
      <TabsButtonWrapper {...{mobile}}>
        {!PWA && <TabsButtons
          {...{ mobile, width }}
          color={colors.lightText}
          backgroundColor={colors.primary}
          activeTab={() => setActiveTab('team')}
          last={mobile}
          first={true}
          icon={
            <TeamRankIcon
              color={teamRankLocation ? '#F2F2F2' : '#4A4A4A'}
              width={35}
              height={35}
            />
          }
          onPress={() => campaignNavigate('leaderboard/team')}
          selected={teamRankLocation}
        >
          {
            (phase === 2) &&
            <WrapperInfosButton>
              <TouchableWithoutFeedback onPress={() => setDisplayedModalInfos('team')}>
                <View>
                  <InfoButton width={25} height={25} currentColor={activeTab === 'team' ? colors.white : '#1B39E2'}/>
                </View>
              </TouchableWithoutFeedback>
            </WrapperInfosButton>
          }
          {
            displayedModalInfos === 'team' &&
            <TooltipTuto
              color={colors.white}
              backgroundColor={colors?.text}
              style={{
                position: 'absolute',
                top: 65,
                left: 3,
                zIndex: 2,
                transform: [{ scale: 0.8 }],
                transformOrigin: 'top left'
              }}
              cornerLeft={15}
              cornerTop={-15}
              infos={{
                title: t('phase_2.tutorial.tooltips.ranking.rankingTabs.teams.title'),
                text: (campaignEnded && !userState?.teamId) ? t('phase_2.tutorial.tooltips.ranking.rankingTabs.teams.textUnBoarding') : t('phase_2.tutorial.tooltips.ranking.rankingTabs.teams.text'),
                onClose: () => setDisplayedModalInfos(false)
              }}/>
          }
          <T path="app.leaderboards.teamLeaderboard.title"/>
        </TabsButtons>}
        <TabsButtons
          {...{mobile, width}}
          color={colors.lightText}
          backgroundColor={colors.primary}
          last={true}
          first={mobile}
          activeTab={() => setActiveTab('player')}
          icon={
            <IndividualRankIcon
              color={!teamRankLocation ? "#F2F2F2" : "#4A4A4A"}
              width={35}
              height={35}
            />
          }
          style={{
            zIndex: displayedModalInfos === 'team' ? -1 : 'unset'
          }}
          onPress={() => campaignNavigate("leaderboard/player")}
          selected={!teamRankLocation}
        >
          {
            (phase === 2) &&
            <WrapperInfosButton>
              <TouchableWithoutFeedback onPress={() => setDisplayedModalInfos('player')}>
                <View>
                  <InfoButton width={25} height={25} currentColor={activeTab === 'player' ? colors.white : '#1B39E2'}/>
                </View>
              </TouchableWithoutFeedback>
            </WrapperInfosButton>
          }
          {
            displayedModalInfos === 'player' &&
            <TooltipTuto
              color={colors.white}
              backgroundColor={colors?.text}
              style={{
                position: 'absolute',
                top: 65,
                left: 3,
                zIndex: 2,
                transform: [{scale: 0.8}],
                transformOrigin: 'top left'
              }}
              cornerLeft={15}
              cornerTop={-15}
              infos={{
                title: t("phase_2.tutorial.tooltips.ranking.rankingTabs.player.title"),
                text: t("phase_2.tutorial.tooltips.ranking.rankingTabs.player.text"),
                onClose: () => setDisplayedModalInfos(false)
              }}/>
          }
          <T path="app.leaderboards.playerLeaderboard.title"/>
        </TabsButtons>
      </TabsButtonWrapper>
      {
        campaignEnded && !PWA &&
        <WrapperCampaignEnded mobile={mobile} width={width}>
          <TitleCampaignEnded>
            <T path="phase_1.campaignEnded.ranking.title" data={{date: convertDate(campaignSettings?.endAt)}}/>
          </TitleCampaignEnded>
          <TextCampaignEnded>
            <T path="phase_1.campaignEnded.ranking.text"/>
          </TextCampaignEnded>
        </WrapperCampaignEnded>
      }
      <Switch>
        <Route path={`${basePath}/leaderboard/player`}>
          <PlayersRanking phase={phase}/>
        </Route>
        <Route path={`${basePath}/leaderboard/team`}>
          <TeamsRanking phase={phase}/>
        </Route>
      </Switch>
    </Screen>
  )
}

const WrapperInfosButton = styled.View({
  position: 'absolute',
  top: '50%',
  left: 10,
  transform: [{translateY: '-50%'}]
})

const TitleCampaignEnded = styled(Title1)({
  marginBottom: 15,
  color: colors.primary
})

const TextCampaignEnded = styled(MediumText)({})

const WrapperCampaignEnded = styled.View(({mobile, width}) => ({
  padding: 20,
  backgroundColor: colors.violetLight,
  maxWidth: mobile ? "none" : (width / 3) * 2,
  marginLeft: mobile ? "auto" : '50%',
  transform: mobile ? 'none' : [{translateX: '-50%'}],
  marginRight: mobile ? "auto" : 0,
  width: mobile ? "95%" : "none",
}))

const Screen = styled.View(({width, height, mobile}) => ({
  width: width,
  minHeight: height,
  paddingTop: mobile ? MOBILE_HEADER_SIZE : MOBILE_HEADER_SIZE + 40,
}))

const TabsButtonWrapper = styled.View(({width, mobile}) => ({
  display: "flex",
  flexDirection: mobile ? "column" : "row",
  justifyContent: "center",
  width: width * 0.8,
  zIndex: 10
}))

const TabsButtons = styled(TabsButton)(({width, mobile}) => ({
  position: 'relative',
  maxWidth: mobile ? "none" : width / 3,
  marginLeft: mobile ? "auto" : 0,
  marginRight: mobile ? "auto" : 0,
  width: mobile ? "95%" : "25%",
}))

export const convertDate = (inputFormat) => {
  function pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  const d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
}
