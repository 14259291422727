import React, {useEffect} from "react"
import {useProfile} from "../../../../Auth/useProfile"
import {BuildingTutorialCustomComponent} from "./BuildingTutorialCustomComponent"
import {useTranslator} from "../../../../translations/translate"
import {useCampaign} from "../../../../GlobalContexts"

export const TutoBuildings = ({onOpen, onClose, linkModal, building}) => {
  const {emitCommand, userState} = useProfile()
  const {campaignEnded} = useCampaign()
  const {t} = useTranslator()

  useEffect(() => {
    if (onOpen) {
      linkModal({
        type: 'basic',
        infos: {
          title: t(`phase_2.modals.buildingModal.buildings.${building?.type}.name`),
          customComponent: BuildingTutorialCustomComponent,
          textIntro: t("phase_2.tutorial.buildings.textIntro"),
          textFlouz: (campaignEnded && !userState?.teamId) ? t("phase_2.tutorial.buildings.textFlouzUnBoarding") : t("phase_2.tutorial.buildings.textFlouz"),
          textPoints: t("phase_2.tutorial.buildings.textPoints"),
          buttonText: t("phase_2.tutorial.buildings.buttonText"),
          action: async () => {
            await emitCommand({
              type: "Phase2UpdateSeeTutoStep",
              payload: {
                step: "buildingFirstClicked"
              }
            })
            onClose()
            linkModal({type: "construction", infos: building})
          },
          noCloseButton: true
        }
      })
    }
  }, [onOpen])

  return <></>
}
