import React, { useEffect } from 'react'
import styled               from '@emotion/native'
import { useProfile }       from './useProfile'
import { useCampaign }      from '../GlobalContexts'
import { colors }           from '@civitime/library/storybook/configs/colors'
import { useLocation }      from 'react-router'
import { AppLoader }        from '../Phases/Phase1/Components/AppLoader'

export const AuthFilter = ({ children, firestoreInitiated }) => {
  const { loggedIn } = useProfile()
  const { campaignNavigate } = useCampaign()
  const location = useLocation()
  useEffect(() => {
    if (!loggedIn) return
    loggedIn && !location.pathname.includes('challenges') && campaignNavigate('')
  }, [loggedIn])
  return (loggedIn && firestoreInitiated) ? (
    <>{children}</>
  ) : (
    <AppLoader/>
  )
}

export const Background = styled.ImageBackground(({ screenWidth, screenHeight, mobile }) => ({
  flex: 1,
  flexDirection: 'row',
  width: screenWidth,
  height: screenHeight,
  alignItems: 'center',
  justifyContent: mobile ? 'center' : 'unset',
  backgroundColor: colors.lightBackground,
  overflow: 'hidden',
  position: "absolute",
}))


