import React, { useEffect, useRef, useState }   from 'react'
import { _focusOnElement, MapPhase2 }           from '../../Map/MapPhase2'
import { useProfile }                           from '../../../../Auth/useProfile'
import { useCampaign }                          from '../../../../GlobalContexts'
import { MapPhase2Content }                     from '../../Map/MapPhase2Content'
import { _wonderSimulateOnPress, MapBuildings } from '../../Map/MapBuildings'
import { ModalOverlay, ModalSelector }          from '../Modals/ModalSelector'
import { firebaseAccessor }                     from '../../../../firebase'
import { clientId }                             from '../../../../../clientId'
import { useTutorialStatus }                    from '../../Hooks/useTutorialStatus'
import { useScreenDimensions }                  from '@civitime/library/storybook/hooks/useScreenDimensions'
import { useTranslator }                        from '../../../../translations/translate'
import styled                                   from '@emotion/native'
import { DefaultModal }                         from '@civitime/library/storybook/stories/Modals'

export const Phase2AppSkeleton = ({
  returnToMap,
  updateHeaderDisplayed,
  headerDisplayed,
  setPhaseProps,
  openHelper,
  screenWidth,
  screenHeight,
  globalModalOpened,
  isOffline,
  ...props
}) => {
  const { userState, userSession, publicProfile } = useProfile()
  const { campaignSettings, campaignId } = useCampaign()
  const dateNow = new Date(Date.now()).toISOString()
  const [zoomScaleValue, setZoomScaleValue] = useState(0)
  const [displayedModal, setDisplayedModal] = useState(null)
  const { hallTownFirstClicked } = useTutorialStatus()
  const { mobile } = useScreenDimensions()
  const { t } = useTranslator()
  const checkOnceWonderState = useRef(false)
  
  useEffect(() => {
    setPhaseProps({
      linkModal: (e) => setDisplayedModal(e),
      updateHeaderDisplayed: updateHeaderDisplayed
    })
  }, [])
  
  useEffect(() => {
    if (headerDisplayed && !hallTownFirstClicked) {
      updateHeaderDisplayed(false)
    }
  }, [headerDisplayed, hallTownFirstClicked])
  
  useEffect(() => {
    if (!campaignId || !userState || !userSession || checkOnceWonderState.current) return
    if (!checkOnceWonderState.current) {
      ;(async () => {
        const wonderStep = await firebaseAccessor
          .get(`clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}/wonderState/step`)
        const userLastStepSeen = userSession?.progressionSeen?.wonder?.step
        if (wonderStep && !userLastStepSeen || wonderStep > userLastStepSeen) {
          setDisplayedModal({
            type: 'basic', infos: {
              title: t('phase_2.modals.awaitingWonderEvolution.title'),
              text: t(`phase_2.modals.awaitingWonderEvolution.text`),
              buttonText: t('phase_2.modals.awaitingWonderEvolution.buttonText'),
              asset: `https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/buildings/merveille/bonus/merveille.svg`,
              action: () => {
                _focusOnElement.next({
                  x: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === 'merveille')?.[0]?.positions?.x,
                  y: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === 'merveille')?.[0]?.positions?.y,
                  size: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === 'merveille')?.[0]?.size
                })
                _wonderSimulateOnPress.next(true)
                setDisplayedModal(null)
              },
              onClose: () => {
                _focusOnElement.next({
                  x: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === 'merveille')?.[0]?.positions?.x,
                  y: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === 'merveille')?.[0]?.positions?.y,
                  size: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === 'merveille')?.[0]?.size
                })
                _wonderSimulateOnPress.next(true)
                setDisplayedModal(null)
              }
            }
          })
        }
        //TODO: 1. Focus on wonder
        checkOnceWonderState.current = true
      })()
    }
  }, [campaignId, userState, userSession])
  
  useEffect(() => {
    if (!displayedModal) return updateHeaderDisplayed(true)
    updateHeaderDisplayed(false)
  }, [displayedModal])
  
  useEffect(() => {
    const lastBuildingType = campaignSettings?.buildings?.[userSession?.buildingProgression]?.name
    setTimeout(() =>
      _focusOnElement.next({
        x: Object.values(campaignSettings?.buildings || {}).filter(v => (v.type === lastBuildingType || 'merveille'))?.[0]?.positions?.x,
        y: Object.values(campaignSettings?.buildings || {}).filter(v => (v.type === lastBuildingType || 'merveille'))?.[0]?.positions?.y,
        size: Object.values(campaignSettings?.buildings || {}).filter(v => (v.type === lastBuildingType || 'merveille'))?.[0]?.size
      }), 1000)
  }, [])
  
  const moduleLaunched = location.pathname.match('/module')

  return <>
    {
      publicProfile.avatar && (!campaignSettings?.endAt < dateNow ? userState?.teamId : true) && (
        <MapPhase2 screenWidth={screenWidth}
                   screenHeight={screenHeight}
                   zoomScaleValue={zoomScaleValue}
                   hide={returnToMap || mobile && moduleLaunched}
                   overlay={headerDisplayed &&
                   <MapPhase2Content updateZoomScale={setZoomScaleValue} linkModal={setDisplayedModal}
                                     zoomScaleValue={zoomScaleValue}/>}
                   buildings={<MapBuildings linkModal={setDisplayedModal} globalModalOpened={globalModalOpened}/>}
        />
      )
    }
    {
      displayedModal && <ModalSelector type={displayedModal?.type}
                                       infosOrigin={displayedModal?.infos}
                                       linkModal={(e) => setDisplayedModal(e)}
                                       onClose={() => setDisplayedModal(null)}/>
    }
    {
      globalModalOpened &&
      <ModalOverlay/>
    }
  </>
}

const EndingModalWrapper = styled(DefaultModal)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  zIndex: 1000,
  right: 0,
})

const TextWrapper = styled.View({
  marginTop: 50,
  marginLeft: 20,
  marginRight: 20
})

const ActionButtonWrapper = styled.View({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 40,
  marginBottom: 40,
})