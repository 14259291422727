import React, { useRef }    from 'react'
import styled               from '@emotion/native'
import { T, useTranslator } from '../../translations/translate'
import Collapsible          from 'react-native-collapsible'
import { MembersList }      from './MembersList'
import { useCampaign }      from '../../GlobalContexts'

import { useScreenDimensions }           from '@civitime/library/storybook/hooks/useScreenDimensions'
import { colors }                        from '@civitime/library/storybook/configs/colors'
import { BoldText, SmallText }           from '@civitime/library/storybook/stories/Texts'
import { Blazon }                        from '@civitime/library/storybook/stories/Blazon'
import { LittleTextButton, TextButton, } from '@civitime/library/storybook/stories/Button'

export const TeamListItem = ({
  team,
  noBorder,
  onPress,
  collapsed,
  onCollapsed,
  phase
}) => {
  const root = useRef(null)
  const { t } = useTranslator()
  const { mobile, orientation } = useScreenDimensions(root)
  const { campaignSettings } = useCampaign()
  const teamMax = campaignSettings?.teamMax

  const maxMemberReached = team?.members?.length >= teamMax

  return (
    <>
      <WrapperTeamListItem
        ref={root}
        {...{ noBorder, maxMemberReached, mobile, orientation }}
      >
        <WrapperBlazon {...{ mobile, orientation }}>
          <Blazon
            phase={phase}
            size={40}
            blazon={team.logo.shape}
            color={team.logo.color}
            text={team.logo.text}
          />
        </WrapperBlazon>
        <WrapperTeamInfos {...{ mobile, orientation }}>
          <WrapperTeamName>
            <TeamName {...{ mobile, orientation }}>{team.name}</TeamName>
          </WrapperTeamName>
          <WrapperMemberButton>
            <LittleTextButton
              {...{ mobile, orientation }}
              onPress={() => onCollapsed()}
              disabled={team?.members?.length === 0}
            >
              <BoldText>{team?.members?.length}</BoldText>
              <SmallText>
                /{teamMax}
                {team?.members?.length > 1
                  ? " " + t("app.team.members")
                  : " " + t("app.team.member")}
              </SmallText>
            </LittleTextButton>
          </WrapperMemberButton>
        </WrapperTeamInfos>
        <WrapperCTA {...{ mobile, orientation }}>
          {!maxMemberReached ? (
            <TextButton {...{ onPress }}>
              <T path="app.team.join" />
            </TextButton>
          ) : (
            <TextButton disabled backgroundColor={colors.defaultBackground}>
              <T path="app.team.teamFull" />
            </TextButton>
          )}
        </WrapperCTA>
      </WrapperTeamListItem>
      <Collapsible collapsed={collapsed}>
        <WrapperMembersListCollapse {...{ noBorder }}>
          <MembersList members={team.members} />
        </WrapperMembersListCollapse>
      </Collapsible>
    </>
  )
}

const WrapperTeamListItem = styled.View(
  {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.defaultBackground,
  },
  ({ noBorder, mobile, orientation }) => ({
    paddingVertical: mobile ? (orientation === "portrait" ? 5 : 10) : 15,
    paddingHorizontal: mobile ? (orientation === "portrait" ? 10 : 25) : 55,
    borderBottomColor: colors.text,
    borderBottomWidth: noBorder ? 0 : 1,
    justifyContent:
      mobile && orientation === "portrait" ? "space-between" : "center",
  }),
  ({ maxMemberReached }) =>
    maxMemberReached && {
      backgroundColor: colors.darkLightGrey,
    }
)

const WrapperBlazon = styled.View(
  ({ mobile, orientation }) =>
    !(mobile && orientation === "portrait") && {
      flex: 0.1,
    }
)

const WrapperTeamInfos = styled.View(
  {
    alignItems: "center",
  },
  ({ mobile, orientation }) => ({
    flexDirection: mobile && orientation === "portrait" ? "column" : "row",
    ...(!(mobile && orientation === "portrait") && {
      flex: 0.65,
    }),
  })
)

const WrapperCTA = styled.View(
  ({ mobile, orientation }) =>
    !(mobile && orientation === "portrait") && {
      flex: 0.25,
    }
)

const WrapperTeamName = styled.View({
  flex: 1,
  alignItems: "flex-start",
})

const TeamName = styled(BoldText)(
  {
    textTransform: "uppercase",
  },
  ({ mobile, orientation }) => ({
    marginLeft: mobile && orientation === "portrait" ? 0 : 25,
  })
)

const WrapperMemberButton = styled.View({
  flex: 1,
  alignItems: "center",
})

const WrapperMembersListCollapse = styled.View(
  {
    justifyContent: "center",
    alignItems: "center",
  },
  ({ noBorder }) => ({
    borderBottomColor: colors.text,
    borderBottomWidth: noBorder ? 0 : 1,
  })
)
