import { campaignGet, publicProfileGet, sendEvent, stateGet } from '@civitime/game-server/lib/saga-tools/Data.effects'
import { createFunctionalError }                              from '@civitime/game-server/lib/saga-tools/Errors'
import { profileErrors }                            from '@civitime/game-server/lib/Domain/Profile/Profile.errors'

export function * initiateOfflinePublicProfile(command) {
  const userId = command.emitter
  const userPublicProfile = yield publicProfileGet(userId)
  if (!userPublicProfile) {
    yield sendEvent("OfflinePublicProfileInitiated", { id: userId })
  }
}

export function * setOfflinePublicProfile(command) {
  const previousPublicProfile = yield publicProfileGet(command.emitter)
  yield sendEvent("OfflinePublicProfileUpdated", { name: command.pseudo ?? previousPublicProfile?.name, avatar: command.avatar ?? previousPublicProfile?.avatar })
}

export function* deleteUserInfos(command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  if(!command.teamId) console.log('no teamId found')
  const teamContributionPoints = yield campaignGet(`teams/${command.teamId}/contributions/${command.emitter}`) || 0
  yield sendEvent("UserInfosDeleted", {
    teamId: command.teamId,
    pointsContribution: teamContributionPoints,
    phase: command?.phase
  })
}

export function* updateNameProfile(command){
  yield sendEvent("NameProfileUpdated", { profile: command.name })
}

export function* updateLang(command){
  yield sendEvent("LangUpdated", { lang: command.lang })
}

export function* joinCampaign (command) {
  const user = command?.emitter
  const phase = command?.phase
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userPublicProfile = yield publicProfileGet(user.id)
  const userState = yield stateGet(user.id)
  if (!userPublicProfile) {
    yield sendEvent("PublicProfileInitiated", { user })
  }
  if (!userState){
    const campaignLangs = (yield campaignGet(`settings/langs`)) || []
    const mapProperties = (yield campaignGet(`settings/map`))
    const worldsProperties = (yield campaignGet(`worlds`))
    const availableUserLang = campaignLangs && campaignLangs.some(campaignLang => command.lang === campaignLang.shortLang)
    
    switch (phase) {
      case 1:
        yield sendEvent("PlayerJoined", {
          lang: availableUserLang ? command?.lang : "fr",
          mapDefaultPosition: mapProperties?.mapDefaultPosition,
          fogMapDefault: JSON.parse(mapProperties?.fogMapDefault),
          phase: phase
        })
        break
      case 1.3:
        const campaignSettings = (yield campaignGet(`settings`))
        yield sendEvent("PlayerJoined", {
          lang: availableUserLang ? command?.lang : "fr",
          phase: phase,
          campaignSettings: campaignSettings,
          worldsProperties: worldsProperties
        })
        break
      case 2:
        const lockBuildingsFromOrder = yield campaignGet(`settings/lockBuildingsFromOrder`)
        yield sendEvent("PlayerJoined", {
          lang: availableUserLang ? command?.lang : "fr",
          phase: phase,
          lockBuildingsFromOrder: !!lockBuildingsFromOrder
        })
        break
      default:
        return
    }
  }
}
