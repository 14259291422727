import React, {useEffect, useState} from "react"
import ScoreIcon from '../Modals/Assets/score.svg'
import PAIcon from '../Modals/Assets/points.svg'
import FlouzIcon from '../Modals/Assets/flouz.svg'
import styled                        from "@emotion/native"
import {colors}                      from "@civitime/library/storybook/configs/colors"
import {MediumText}                  from "@civitime/library/storybook/stories/Texts"
import {useProfile}                  from "../../../../Auth/useProfile"
import {useCampaign}                 from "../../../../GlobalContexts"
import {TouchableWithoutFeedback}    from "react-native"
import {useTutorialStatus}           from "../../Hooks/useTutorialStatus"
import {TooltipTuto}                 from "../Tuto/TooltipTuto"
import {AvatarComponent}             from "@civitime/library/storybook/stories/Avatar"
import {useTranslator}               from "../../../../translations/translate"
import { PWA }                       from '../../../../../clientId'

export const Phase2WrapperLeftContent = ({mobile, noAvatarCanPress, noAvatar, noMargin, ...props}) => {
  const {userState, emitCommand, publicProfile} = useProfile()
  const {campaignNavigate} = useCampaign()
  const {flouzFirstClicked, pointsFirstClicked, PAFirstClicked, avatarFirstClicked} = useTutorialStatus()
  const [openTutoToolTip, setOpenTutoToolTip] = useState(null)
  const {t} = useTranslator()

  const actionPoints = null

  useEffect(() => {
    if (mobile && openTutoToolTip) {
      switch (openTutoToolTip) {
        case "avatar":
          props?.linkModal({
            type: 'basic',
            infos: {
              title: t("phase_2.tutorial.tooltips.avatar.avatarHomeButton.title"),
              text: t("phase_2.tutorial.tooltips.avatar.avatarHomeButton.text"),
              buttonText: t("phase_2.tutorial.tooltips.avatar.avatarHomeButton.buttonText"),
              noCloseButton: true,
              action: async () => {
                await emitCommand({
                  type: "Phase2UpdateSeeTutoStep",
                  payload: {
                    step: "avatarFirstClicked"
                  }
                }).then(() => {
                  campaignNavigate("avatar")
                })
                setOpenTutoToolTip(null)
                props?.linkModal(null)
              },
            }
          })
          return
        case "points":
          props?.linkModal({
            type: 'basic',
            infos: {
              title: t("phase_2.tutorial.tooltips.points.score.title"),
              text: t("phase_2.tutorial.tooltips.points.score.text"),
              onClose: async () => {
                await emitCommand({
                  type: "Phase2UpdateSeeTutoStep",
                  payload: {
                    step: "pointsFirstClicked"
                  }
                })
                setOpenTutoToolTip(null)
                props?.linkModal(null)
              },
              noButton: true
            }
          })
          return
        case "pa":
          props?.linkModal({
            type: 'basic',
            infos: {
              title: t("phase_2.tutorial.tooltips.points.PA.title"),
              text: t("phase_2.tutorial.tooltips.points.PA.text"),
              onClose: async () => {
                await emitCommand({
                  type: "Phase2UpdateSeeTutoStep",
                  payload: {
                    step: "PAFirstClicked"
                  }
                })
                setOpenTutoToolTip(null)
                props?.linkModal(null)
              },
              noButton: true
            }
          })
          return
        case "flouz":
          props?.linkModal({
            type: 'basic',
            infos: {
              title: t("phase_2.tutorial.tooltips.points.credits.title"),
              text: t("phase_2.tutorial.tooltips.points.credits.text"),
              onClose: async () => {
                await emitCommand({
                  type: "Phase2UpdateSeeTutoStep",
                  payload: {
                    step: "flouzFirstClicked"
                  }
                })
                setOpenTutoToolTip(null)
                props?.linkModal(null)
              },
              noButton: true
            }
          })
          return
      }
    }
  }, [mobile, openTutoToolTip])

  return <>
    {
      (openTutoToolTip === 'avatar' && !mobile) &&
      <WrapperToolip>
        <TooltipTuto
          minWidth={450}
          cornerLeft={-15}
          style={{
            transformOrigin: 'top left',
            transform: [{scale: 0.7}],
            left: 45
          }}
          infos={{
            title: t("phase_2.tutorial.tooltips.avatar.avatarHomeButton.title"),
            text: t("phase_2.tutorial.tooltips.avatar.avatarHomeButton.text"),
            buttonText: t("phase_2.tutorial.tooltips.avatar.avatarHomeButton.buttonText"),
            noCloseButton: true,
            action: async () => {
              await emitCommand({
                type: "Phase2UpdateSeeTutoStep",
                payload: {
                  step: "avatarFirstClicked"
                }
              }).then(() => {
                campaignNavigate("avatar")
                setOpenTutoToolTip(null)
              })
            }
          }}/>
      </WrapperToolip>
    }
    {
      !noAvatar &&
      <HomeAvatarButton
        playerName={!mobile && publicProfile.name}
        circleAvatarConfig={{
          size: mobile ? 50 : 60,
          withBorder: true,
          backgroundColor: colors.extraLightBackground,
        }}
        cartridgeConfig={{
          width: 128,
          height: 20,
          backgroundColor: colors.text,
          color: colors.lightText,
        }}
        onPress={() => {
          if (noAvatarCanPress) return
          if (!avatarFirstClicked) {
            setOpenTutoToolTip('avatar')
            return
          }
          campaignNavigate("avatar")
        }}
        avatar={publicProfile?.avatar}
      />
    }
    <TouchableWithoutFeedback
      onPress={() => {
        if (pointsFirstClicked) return
        setOpenTutoToolTip('points')
      }}
      disabled={pointsFirstClicked}>
      <WrapperScoreIcon>
        {
          (openTutoToolTip === 'points' && !mobile) &&
          <WrapperToolip>
            <TooltipTuto
              minWidth={450}
              cornerLeft={-15}
              style={{
                transformOrigin: 'top left',
                transform: [{scale: 0.7}, {translateY: -45}],
                left: 45
              }}
              infos={{
                title: t("phase_2.tutorial.tooltips.points.score.title"),
                text: t("phase_2.tutorial.tooltips.points.score.text"),
                onClose: async () => {
                  setOpenTutoToolTip(null)
                }
              }}/>
          </WrapperToolip>
        }
        <ScoreIcon width={30} height={30} style={{position: 'absolute', left: 0, top: 0}}/>
        <ScoreValue>
          {userState?.points || 0}
        </ScoreValue>
      </WrapperScoreIcon>
    </TouchableWithoutFeedback>
    <TouchableWithoutFeedback
      onPress={() => {
        if (flouzFirstClicked) return
        setOpenTutoToolTip('flouz')
      }}
      disabled={flouzFirstClicked}>
      <WrapperFlouz noMargin={noMargin}>
        {
          (openTutoToolTip === 'flouz' && !mobile) &&
          <WrapperToolip>
            <TooltipTuto
              minWidth={450}
              cornerLeft={-15}
              style={{
                transformOrigin: 'top left',
                transform: [{scale: 0.7}, {translateY: -45}],
                left: 45
              }}
              infos={{
                title: t("phase_2.tutorial.tooltips.points.credits.title"),
                text: t("phase_2.tutorial.tooltips.points.credits.text"),
                onClose: async () => {
                  setOpenTutoToolTip(null)
                }
              }}/>
          </WrapperToolip>
        }
        <FlouzIcon width={33} height={33} style={{position: 'absolute', left: -2, top: -1}}/>
        <ScoreValue>
          {userState?.flouz || 0}
        </ScoreValue>
      </WrapperFlouz>
    </TouchableWithoutFeedback>
    {!PWA && <TouchableWithoutFeedback
      onPress={() => {
        if (PAFirstClicked) return
        setOpenTutoToolTip('pa')
      }}
      disabled={PAFirstClicked}>
      <WrapperActionPoints noMargin={noMargin}>
        {
          (openTutoToolTip === 'pa' && !mobile) &&
          <WrapperToolip>
            <TooltipTuto
              minWidth={450}
              cornerLeft={-15}
              style={{
                transformOrigin: 'top left',
                transform: [{ scale: 0.7 }, { translateY: -45 }],
                left: 45
              }}
              infos={{
                title: t('phase_2.tutorial.tooltips.points.PA.title'),
                text: t('phase_2.tutorial.tooltips.points.PA.text'),
                onClose: async () => {
                  setOpenTutoToolTip(null)
                }
              }}/>
          </WrapperToolip>
        }
        <PAIcon width={33} height={33} style={{ position: 'absolute', left: -2, top: -1 }}/>
        <ScoreValue>
          {`${actionPoints}`}
        </ScoreValue>
      </WrapperActionPoints>
    </TouchableWithoutFeedback>}
  </>
}

const HomeAvatarButton = styled(AvatarComponent)({
  marginBottom: 20,
})

export const WrapperScoreIcon = styled.View({
  position: 'relative',
  width: 110,
  height: 34,
  backgroundColor: colors.white,
  borderWidth: 2,
  borderColor: colors.credit,
  borderRadius: 18,
  justifyContent: 'center'
})

export const ScoreValue = styled(MediumText)({
  marginLeft: 20,
  fontWeight: 'bold'
})

const WrapperActionPoints = styled(WrapperScoreIcon)(({noMargin}) => ({
  width: 110,
  height: 35,
  borderRadius: 20,
  borderColor: colors.text,
  marginTop: noMargin ? 0 : 25,
}))

const WrapperFlouz = styled(WrapperActionPoints)({})

export const WrapperToolip = styled.View({
  position: 'absolute',
  right: 0
})
