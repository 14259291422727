import React, {useMemo} from "react"
import {Phase2Init} from "../Phases/Phase 2/Components/Router/Phase2Init"
import styled                   from '@emotion/native'

const phasesHomeElements = {
  2: Phase2Init,
}

export const Home = ({phase}) => {
  const HomeRender = useMemo(() => {
    if (!phase) return null
    return phasesHomeElements?.[phase]
  }, [phase])
  return HomeRender ? (
    <HomeRender/>
  ) : null
}


export const MenuIcon = styled.Image({
  position: 'fixed',
  right: '9%',
  top: '4%',
  width: 30,
  height: 30,
  zIndex: 3,
  cursor: 'pointer',
})

const Background = styled.ImageBackground({
  opacity: .2,
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0
})

