import { firebaseAccessor } from '../../../../firebase'
import { shuffle }          from '@civitime/game-server/lib/Domain/Content/Content.helpers'

export const getRandomQuestion = (array, questionsNb) => {
  const questions = []
  const questionArray = [...array]

  for (let i = 0; i < questionsNb; i++) {
    const questionRandomIndex = Math.floor(Math.random() * questionArray.length)
    const question = questionArray[questionRandomIndex]
    questions.push(question)
    questionArray.splice(questionRandomIndex, 1)
  }
  return shuffleQuestions(questions).map((question) => {
    return {
      ...question,
      answers: shuffleQuestions(question?.answers)
    }
  })
}

const shuffleQuestions = (array) => {
  const data = [ ...array]
  for (let a = 0; a < data.length; a++) {
    const x = data[a];
    const y = Math.floor(Math.random() * (a + 1));
    data[a] = data[y];
    data[y] = x;
  }
  return data
}

export const getMemoryContent = async (clientId, campaignId, step, theme, memory) => {
  return await Promise.all(memory.map(async item => {
    return ({
      ...item,
      image: await fetch(
        `https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/content/memory/${step}/${theme}/${item.image}`)
        .then(res => res.blob())
        .then(blob => (URL.createObjectURL(blob)))
        .catch(err => console.error(err))
    })
  }))
}

export const getPreferencesContent = async (clientId, campaignId, moduleStep, moduleTheme, teamId, userId) => {
  const preferencesData = await firebaseAccessor
    .get(`clients/${clientId}/campaigns/${campaignId}/content/modules/preferences/${moduleStep}/${moduleTheme}`)

  if (!Object.entries(preferencesData || {}).length) return {}

  const teamMembersIds = Object.keys(preferencesData?.[teamId] || {})

  const usersProfiles = await Promise.all(
    teamMembersIds.map(async userId => {
      return await firebaseAccessor
        .get(`clients/${clientId}/profiles/${userId}`)
    })
  )

  const globalAnswers = Object.values(preferencesData || {})

  const globalAnswersFormat = globalAnswers.reduce((acc, users) => {
    Object.entries(users).forEach(([id, userContent]) => {
      Object.values(userContent || {}).forEach((answer) => {
        if (id === userId) return
        acc[answer] = acc?.[answer] + 1 || 1
      })
    })
    return acc
  }, {})

  const usersCount = globalAnswers.reduce((acc, users) => {
    Object.entries(users).forEach(([id, _userContent]) => {
      if (id === userId) return
      acc++
    })
    return acc
  }, 0)

  return {
    teamMembers: usersProfiles.filter(Boolean).filter(v => v.id !== userId).map((profile) => {
      return {
        result: preferencesData?.[teamId]?.[profile?.id],
        userProfile: profile,
        id: profile?.id
      }
    }),
    globalUsersStats: {
      globalAnswers: globalAnswersFormat,
      usersCount: usersCount
    }
  }
}

export const getGoldenCompanyContent = async (clientId, campaignId, moduleStep, moduleTheme, teamId, userId) => {
  const goldenCompanyData = await firebaseAccessor
    .get(`clients/${clientId}/campaigns/${campaignId}/content/modules/goldenCompany/${moduleStep}/${moduleTheme}`)

  const affinityWord = Object.entries(goldenCompanyData?.wrongAnswers || {})
    .sort((a, b) => {
      return b[1] - a[1]
    })

  const rawTeamUsersData = Object.entries(goldenCompanyData?.[teamId] || {})
    .filter(p => p?.id !== userId)

  const usersProfiles = await Promise.all(
    rawTeamUsersData.map(async ([userId, _firstChoice]) => {
      return await firebaseAccessor
        .get(`clients/${clientId}/profiles/${userId}`)
    })
  )

  const choicesUsersProfiles = rawTeamUsersData.reduce((acc, [userIdRaw, firstChoice]) => {
    if (userIdRaw === userId) return acc
    const userProfile = usersProfiles.filter(p => p?.id === userIdRaw)?.[0]
    if (!acc?.[firstChoice]) {
      acc[firstChoice] = []
    }
    acc[firstChoice].push(userProfile)
    return acc
  }, {})

  return {
    affinity: null,
    choicesUsersProfiles: choicesUsersProfiles
  }
}

export const getPictionaryContent = async (clientId, campaignId, moduleStep, moduleTheme, teamId, userId) => {
  const pictionaryData = await firebaseAccessor
    .get(`clients/${clientId}/campaigns/${campaignId}/content/modules/pictionary/${moduleStep}/${moduleTheme}`)
  const pictionaryRawArray = Object.entries(pictionaryData || {})
  shuffle(pictionaryRawArray)
  const [keyDrawElement, drawElement] = pictionaryRawArray.pop()
  const [keyAnswerElement, answerElement] = pictionaryRawArray.shift()
  const drawsOpponents = Object.entries(answerElement?.draws || {}).filter(([vId, _Data]) => vId !== userId)
  const opponentsArray = drawsOpponents
    .filter(([_vId, Data]) => {
      if (campaignId === 'bddf-2' || campaignId === 'gse-test'){
        return Data.isDefault
      }
      if(drawsOpponents?.length > 1){
        return !Data?.isDefault
      }
      return true
    })
  shuffle(opponentsArray)
  const [opponentId, opponentData] = opponentsArray?.shift()
  const opponentProfile = await firebaseAccessor
    .get(`clients/${clientId}/profiles/${opponentId}`)

  const rawTeamUsersData = Object.entries(answerElement?.teams?.[teamId]?.[opponentId] || {})
    .filter(p => p?.id !== userId)

  const usersProfiles = await Promise.all(
    rawTeamUsersData?.map(async ([userId, data]) => {
      return {
        profile: await firebaseAccessor
          .get(`clients/${clientId}/profiles/${userId}`),
        data: data
      }
    })
  )

  return {
    elementToDraw: {
      id: drawElement?.id,
      originalName: keyDrawElement
    },
    elementToGuess: {
      id: answerElement.id,
      originalName: keyAnswerElement,
      drawerProfile: opponentProfile,
      url: opponentData?.url,
      team: usersProfiles
    }
  }
}

export const getMastermindTranslations = async (lang) => await fetch(`https://ct-campaigns.civitimeapp.com/texts/modules/mastermind/${lang}.json`)
  .then(r => {
    if (r.status === 404) {
      return null
    }
    return r.json()
  })