import React, {useEffect, useRef, useState} from "react"
import styled from "@emotion/native"
import {clientId} from "../../../clientId"
import {useCampaign} from "../../GlobalContexts"
import {useRealTimeData} from "../../Utils/dataClient"

import {Line} from "../components/Containers"
import {TeamDetails} from "../components/TeamDetails"
import {TopTeamRanking} from "../components/TopTeamRanking"
import {TeamRankingList} from "../components/TeamRankingList"

import {MOBILE_HEADER_SIZE} from "@civitime/library/storybook/configs/sizes"
import {useScreenDimensions} from "@civitime/library/storybook/hooks/useScreenDimensions"

export const TeamsRanking = ({phase}) => {
  const root = useRef(null)
  const {mobile, orientation, height} = useScreenDimensions(root)
  const [selected, setSelected] = useState()
  const [teamRanking, setTeamRanking] = useState([])
  const {campaignId, campaignSettings, campaignEnded} = useCampaign()
  const teamMax = campaignSettings?.teamMax
  const [top, setTop] = useState(0)
  const [teamPressed, setTeamPressed] = useState(null)
  const [visible, setVisible] = useState(false)

  const teamLeaderboard = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/leaderboards/teamLeaderboard`
  )

  const pressTeam = (teamId) => {
    setTeamPressed(teamId)
    setVisible(true)
  }

  useEffect(() => {
    if (!teamLeaderboard) return
    delete teamLeaderboard.loaded
    const sortedRank = Object.entries({...teamLeaderboard})
      .sort(([_idA, scoreA], [_idB, scoreB]) => (scoreB || 0) - (scoreA || 0))
      .map(([id, score]) => ({teamId: id, score: score && score > 0 ? score : 0}))
    setTeamRanking(sortedRank)
  }, [teamLeaderboard])

  return (
    <>
      <TeamsRankingWrapper ref={root} {...{mobile}} mobile={mobile} heightScreen={height} campaignEnded={campaignEnded}>
        <Wrapper
          onLayout={(event) =>
            setTop(
              MOBILE_HEADER_SIZE + 100 + event?.nativeEvent?.layout?.height
            )
          }
        >
          <TopTeamRanking
            phase={phase}
            select={(e) =>
              selected === e ? setSelected(null) : setSelected(e)
            }
            data={teamRanking ? teamRanking.slice(0, 3) : []}
            {...{mobile, orientation, selected, teamMax, pressTeam}}
          />
          <Line {...{mobile, orientation}} />
        </Wrapper>
        <TeamRankingList
          phase={phase}
          select={(e) => (selected === e ? setSelected(null) : setSelected(e))}
          data={teamRanking ? teamRanking.slice(3) : []}
          {...{mobile, orientation, selected, teamMax, top}}
        />
      </TeamsRankingWrapper>

      {visible && (
        <TeamDetails
          mobile={mobile}
          phase={phase}
          {...{visible}}
          onRequestClose={() => {
            setVisible(false)
            setTeamPressed(null)
          }}
          teamId={teamPressed}
        />
      )}
    </>
  )
}

const TeamsRankingWrapper = styled.View(({mobile, heightScreen, campaignEnded}) => ({
  height: !mobile && !campaignEnded ? (heightScreen - 169) : !mobile ? (heightScreen - 302)
    : mobile && !campaignEnded ? (heightScreen - 172) : mobile ? (heightScreen - 345) : 'unset'
}))

const Wrapper = styled.View({})
