import React from "react"
import styled from "@emotion/native"
import {MediumText} from "@civitime/library/storybook/stories/Texts"
import FlouzIcon from "../Modals/Assets/flouz.svg"
import ScoreIcon from "../Modals/Assets/score.svg"

export const BuildingTutorialCustomComponent = ({infos}) => {

  return <WrapperBuildingTutorialCustomComponent>
    <TextBuildingTutorial style={{marginTop: 15, textAlign: 'center'}}>
      {infos?.textIntro}
    </TextBuildingTutorial>
    <WrapperLineBuildingTuto>
      <FlouzIcon style={{marginRight: 15}} width={88} height={50}/>
      <TextBuildingTutorial>
        {infos?.textFlouz}
      </TextBuildingTutorial>
    </WrapperLineBuildingTuto>
    <WrapperLineBuildingTuto>
      <ScoreIcon style={{marginRight: 15}} width={50} height={50}/>
      <TextBuildingTutorial>
        {infos?.textPoints}
      </TextBuildingTutorial>
    </WrapperLineBuildingTuto>
  </WrapperBuildingTutorialCustomComponent>
}

const WrapperBuildingTutorialCustomComponent = styled.View({
  alignItems: 'center'
})

const TextBuildingTutorial = styled(MediumText)({
  textAlign: 'left',
  width: '100%'
})

const WrapperLineBuildingTuto = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 15,
  marginBottom: 15,
  width: '100%'
})
