import React, { useMemo }      from 'react'
import PNJ1                    from './Assets/biblio.svg'
import PNJ3                    from './Assets/campus.svg'
import PNJ4                    from './Assets/arbitre.svg'
import PNJ5                    from './Assets/mayor.svg'
import PNJ6                    from './Assets/jardin.svg'
import PNJ7                    from './Assets/guichet.svg'
import PNJ8                    from './Assets/logistique-leclerc.svg'
import styled                  from '@emotion/native'
import { colors }              from '@civitime/library/storybook/configs/colors'
import { useScreenDimensions } from '@civitime/library/storybook/hooks/useScreenDimensions'
import { ModulesModal }        from '@civitime/library/storybook/stories/Modals'
import { MediumText }          from '@civitime/library/storybook/stories/Texts'
import FlouzIcon               from './Assets/white-flouz.svg'
import PointsIcon              from './Assets/yellow-points.svg'
import { T }                   from '../../../../translations/translate'

const PNJs = {
  'library': PNJ1,
  'engineer': PNJ8,
  'campus': PNJ3,
  'arbitrator': PNJ4,
  'referee': PNJ4,
  'mayor': PNJ5,
  'gardener': PNJ6,
  "counter": PNJ7
}

export const PNJModal = ({infos, onClose}) => {
  const PNJ = PNJs?.[infos?.asset] || null
  const {mobileTablet, width} = useScreenDimensions()
  const CustomComponent = useMemo(() => {
    if (!infos?.customComponent) return null
    return infos?.customComponent
  }, [infos?.customComponent])
  return <WrapperPNJModal>
    <PNJModuleModal onPress={infos?.noCloseButton ? null : (infos?.onClose || onClose)}
                    color={colors.secondary}
                    mobileTablet={mobileTablet}
                    buttonText={infos?.buttonText}
                    actionButton={infos?.action}
                    pnjMod
                    secondActionButton={!!infos.secondAction}
                    secondButtonText={infos.secondActionButtonText}
                    secondAction={infos.secondAction}
                    phase2
                    padding={mobileTablet && 20}>
      {
        CustomComponent && <CustomComponent infos={infos}/>
      }
      <TitleModal>{infos?.title}</TitleModal>
      {
        infos?.textContent &&
        <ModalContent>
          {infos?.textContent}
        </ModalContent>
      }
      {
        infos?.reward &&
        <WrapperReward>{
          !!infos.reward.flouz ?
          <WrapperModalReward>
            <T path="phase_2.modals.buildingModal.global.youWin" data={{ 0: infos?.reward?.flouz ?? 0 }}/>
            <FlouzIcon width={25} height={25}/> et {infos?.reward?.points ?? 0}
            <PointsIcon width={25} height={25}/>
          </WrapperModalReward>
            : null
        }
        </WrapperReward>
      }
      {
        infos?.asset &&
        <PNJ width={'100%'}
             height={"50%"}
             style={{position: 'fixed', bottom: -10, left: -(width / 6), zIndex: 2}}/>
      }
    </PNJModuleModal>
  </WrapperPNJModal>
}

export const WrapperPNJModal = styled.View({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: colors.mediumShadow
})

const PNJModuleModal = styled(ModulesModal)(({mobileTablet}) => ({
  position: 'absolute',
  top: mobileTablet ? 10 : 120,
  left: mobileTablet ? 10 : '45%',
  right: mobileTablet ? 10 : 'unset'
}))

const TitleModal = styled(MediumText)({
  fontWeight: 'bold'
})

const ModalContent = styled(MediumText)({
  marginTop: 20,
  marginBottom: 20
})


const WrapperModalReward = styled.Text({
  backgroundColor: colors.text,
  color: colors.white,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 15,
  paddingRight: 15,
  borderRadius: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold'
})

const WrapperReward = styled.View({
  flexDirection: 'row',
  justifyContent: 'center'
})
