import React, {useEffect, useState} from 'react'
import styled from '@emotion/native'
import {useScreenDimensions} from '@civitime/library/storybook/hooks/useScreenDimensions'
import {getBrowserInfos} from '../Utils/browserDetector'
import {Title2} from '@civitime/library/storybook/stories/Texts'
import {Background} from '../Auth/AuthFilter'
import {colors} from '@civitime/library/storybook/configs/colors'

const backgroundImg = require('../../assets/Auth/fd-mobil-ok.png')

export const InstallPwaScreen = () => {
  const {width, height} = useScreenDimensions()
  const [{os, browser}, setDevice] = useState({os: null, browser: null})

  useEffect(() => {
    const browserInfo = getBrowserInfos()
    setDevice({
      os: detectOs(browserInfo),
      browser: detectBrowser(browserInfo)
    })
  }, [])

  return <>
    <PWABackground PWABackgroundround source={backgroundImg} resizeMode="center" screenWidth={width}
                   screenHeight={height}/>
    <WrapperInstallPwaScreen width={width}
                             height={height}>
      <WrapperLogo>
        <LogosWrapper>
          <ContainerLogo width={150} height={35}>
            <Logo source={require('../../assets/leclerc-logo.png')} alt="leclerc-logo" resizeMode="contain"/>
          </ContainerLogo>
          <ContainerLogo width={140} height={65}>
            <Logo source={require('../../assets/logo.png')} alt="civitime-logo" resizeMode="contain"/>
          </ContainerLogo>
        </LogosWrapper>
      </WrapperLogo>
      <WrapperDownloadPwa>
        {
          os === 'android' ?
            <AndroidPwa browser={browser}/>
            : os === 'ios' ?
              <IOSPwa browser={browser}/>
              : <DesktopPwa/>
        }
      </WrapperDownloadPwa>
    </WrapperInstallPwaScreen>
  </>
}

const AndroidPwa = ({browser}) => {

  return <WrapperDevice width="100%">
    <TextDevice>Pour accéder au jeu, nous vous invitons à installer l’application CiviTime.</TextDevice>
    <BackgroundTextWrapper>
      <ConstrainedText color={colors.white}>Si votre appareil vous le propose</ConstrainedText>
    </BackgroundTextWrapper>
    <ConstrainedText size={13} weight={700}>Vous pouvez directement cliquer sur la bannière en bas de votre
      écran</ConstrainedText>
    <WrapperImages width={'90%'} heigth={80}>
      <WrapperImageDevice width={'30%'}>
        <ImageDevice source={require('../../assets/pwa/phone-1.png')} alt="pwa" resizeMode="contain"/>
      </WrapperImageDevice>
      <WrapperImageDevice width={'70%'}>
        <ImageDevice source={require('../../assets/pwa/android_baner.png')} alt="pwa" resizeMode="contain"/>
      </WrapperImageDevice>
    </WrapperImages>
    <BackgroundTextWrapper>
      <ConstrainedText color={colors.white} width="90%">Si ce n'est pas le cas</ConstrainedText>
    </BackgroundTextWrapper>
    <StepWrapper>
      <NumberWrapper><Logo source={require('../../assets/pwa/one.png')}/></NumberWrapper>
      <TextDevice marginBottom={5} size={13} weight={"700"}>Veuillez cliquer sur les trois petits points (paramétres) de
        votre navigateur</TextDevice>
    </StepWrapper>
    <WrapperImages width={'50%'} heigth={80}>
      <WrapperImageDevice width={'50%'}>
        <ImageDevice source={require('../../assets/pwa/phone-2.png')} alt="pwa" resizeMode="contain"/>
      </WrapperImageDevice>
      <WrapperImageDevice width={40} height={40}>
        <ImageDevice source={require('../../assets/pwa/android_option.png')} alt="pwa" resizeMode="contain"/>
      </WrapperImageDevice>
    </WrapperImages>
    <StepWrapper style={{marginTop: 15}}>
      <NumberWrapper><Logo source={require('../../assets/pwa/two.png')}/></NumberWrapper>
      <TextDevice marginBottom={5} size={13} weight={"700"}>Puis sur l'option installer l'application</TextDevice>
    </StepWrapper>
    <WrapperImageDevice width="80%">
      <ImageDevice source={
        browser === 'firefox' ? require('../../assets/pwa/firefox-android.png')
          : require('../../assets/pwa/install-android.png')} alt="pwa" resizeMode="contain"/>
    </WrapperImageDevice>
    <StepWrapper style={{marginTop: 15}}>
      <NumberWrapper><Logo source={require('../../assets/pwa/three.png')}/></NumberWrapper>
      <TextDevice marginBottom={5} size={13} weight={"700"}>Et enfin le bouton installer</TextDevice>
    </StepWrapper>
    <WrapperImageDevice height={70} width="80%" style={{marginBottom: 20}}>
      <ImageDevice source={
        browser === 'firefox' ?
          require('../../assets/pwa/firefox-android-2.png')
          : require('../../assets/pwa/install.png')} alt="pwa" resizeMode="contain"/>
    </WrapperImageDevice>
  </WrapperDevice>
}

const IOSPwa = ({browser}) => {
  return <WrapperDevice>
    <TextDevice>Pour accéder au jeu, nous vous invitons à installer l’application CiviTime.</TextDevice>
    {
      browser !== 'safari' ?
        <WrapperIsntSafari>
          <TextDevice size={15}>Attention l'application peut être installée uniquement à partir de Safari</TextDevice>
          <Safari source={require('../../assets/pwa/safari.png')} alt='safari'/>
        </WrapperIsntSafari> : <>
          <StepWrapper marginTop={5}>
            <NumberWrapper><Logo source={require('../../assets/pwa/one.png')}/></NumberWrapper>
            <TextDevice size={13} weight={"700"}>Cliquez sur l'icône suivant de votre navigateur</TextDevice>
            <WrapperImages>
              <WrapperImageDevice>
                <ImageDevice source={require('../../assets/pwa/phone-1.png')} alt="pwa" resizeMode="contain" width={67}
                             height={45}/>
              </WrapperImageDevice>
              <WrapperImageDevice backgroundColor="rgba(74,74,74,.1)">
                <ImageDevice source={require('../../assets/pwa/download-icon-1.png')} alt="pwa" resizeMode="contain"
                             width={46}
                             height={46}/>
              </WrapperImageDevice>
            </WrapperImages>
          </StepWrapper>
          <StepWrapper marginTop={5}>
            <NumberWrapper><Logo source={require('../../assets/pwa/two.png')}/></NumberWrapper>
            <TextDevice size={13} weight={"700"}>Puis ajouter l'application à l'écran d'accueil</TextDevice>
            <WrapperImages>
              <WrapperImageDevice>
                <ImageDevice source={require('../../assets/pwa/phone-2.png')} alt="pwa" resizeMode="auto" width={67}
                             height={45}/>
              </WrapperImageDevice>
              <WrapperImageDevice backgroundColor="rgba(74,74,74,.1)">
                <ImageDevice source={require('../../assets/pwa/ios_plus_home.png')} alt="pwa" resizeMode="auto" width={46}
                             height={46}/>
              </WrapperImageDevice>
            </WrapperImages>
          </StepWrapper>
        </>
    }
  </WrapperDevice>
}

const DesktopPwa = () => {
  return <WrapperDevice>
    <TextDevice>Nous vous invitons à installer l'application Civitime, vous pourrez ensuite accéder au jeu</TextDevice>
    <TextDevice>Mac & PC available to install</TextDevice>
  </WrapperDevice>
}

const TextDevice = styled(Title2)(({marginBottom, size, weight}) => ({
  fontFamily: 'Raleway_500Medium',
  fontSize: size ?? 18,
  fontWeight: weight ?? '500',
  marginBottom: marginBottom ?? 0
}))

const ConstrainedText = styled(TextDevice)({
  width: '90%',
  margin: 'auto',
  marginTop: 0
})

const WrapperImageDevice = styled.View(({backgroundColor, width, height}) => ({
  height: height ?? 50,
  width: width ?? '100%',
  backgroundColor: backgroundColor
}))

const ImageDevice = styled.Image(({width, height}) => ({
  height: height ?? '100%',
  width: width ?? '100%',
  margin: 'auto'
}))

const WrapperDevice = styled.View(({width}) => ({
  height: '100%',
  width: width ?? '90%',
  justifyContent: 'space-around',
  alignItems: 'center'
}))

const WrapperInstallPwaScreen = styled.ScrollView(({width, height}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: width,
  height: height,
  zIndex: 2
}))

const WrapperLogo = styled.View({
  width: '100%',
  height: 65,
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 10
})

const ContainerLogo = styled.View(({width, height}) => ({
  height: height ?? '85%',
  width: width ?? '45%'
}))

const Logo = styled.Image({
  height: '100%',
  width: '100%'
})

const WrapperIsntSafari = styled.View({
  alignItems: 'center'
})

const Safari = styled.Image({
  height: 50,
  width: 50,
  marginTop: 30
})

const WrapperDownloadPwa = styled.View({
  width: '100%',
  height: '90%',
  justifyContent: 'center',
  alignItems: 'center',
})

const LogosWrapper = styled.View({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  justifyContent: 'space-around',
  alignItems: 'center'
})

const NumberWrapper = styled.View({
  width: 30,
  height: 30,
  marginBottom: 8
})

const StepWrapper = styled.View(({marginTop}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'auto',
  marginTop: marginTop
}))

const WrapperImages = styled.View(({width, height}) => ({
  marginLeft: 80,
  marginRight: 80,
  marginTop: 10,
  height: height ?? 50,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: width,
}))

const PWABackground = styled(Background)({
  resizeMode: 'cover',
  backgroundColor: colors.white,
  opacity: .3
})

const BackgroundTextWrapper = styled.View({
  backgroundColor: colors.violet,
  width: '100%',
  paddingTop: 3,
  paddingBottom: 3,
  marginBottom: 10,
  marginTop: 15
})

const detectOs = (detectDevice) => {
  const osArray = ['android', 'ios']
  const osName = detectDevice?.os?.toLowerCase()
  return osArray.filter(os => osName?.includes(os))?.[0] || 'desktop'
}

const detectBrowser = (detectDevice) => {
  const browserArray = ['chrome', 'firefox', 'safari']
  const browserName = detectDevice?.browser?.toLowerCase()
  return browserArray.filter(browser => browserName?.includes(browser))?.[0] || 'chrome'
}
