import React from "react"
import styled from "@emotion/native"
import LottieView from "lottie-react-native"

export const ArrowFirstClick = () => {

  return <WrapperArrowFirstClick>
      <LottieView
        source={require('./animations/Arrow/data').default}
        style={{width: 50, height: 50, transform: [{translateX: -45}, {translateY: -45}]}}
        autoPlay
        loop
      />
  </WrapperArrowFirstClick>
}

const WrapperArrowFirstClick = styled.View({
  position: 'absolute',
  top: '50%',
  left: '50%',
  willChange: 'transform',
  transformStyle: 'preserve-3d',
  transform: [{translateX: '-50%'}, {translateY: '-50%'}]
})
