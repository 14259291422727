import { takeWhile }          from 'rxjs/operators'
import { REDUCER_ENDED }      from '@civitime/game-server/lib/saga-tools/ReducerRunner'
import { ReplaySubject }      from 'rxjs'
import { createMainReducers } from './Domain/mainReducers'

export async function handleLocalEvent(event) {
  const reducerRunner = createMainReducers()
  
  const source$ = new ReplaySubject()
  const end$ = source$
    .pipe(takeWhile(event => event.type !== REDUCER_ENDED))
  
  reducerRunner.stream$
    .subscribe(v => {
      source$.next(v)
    }, (e) => {
      console.error("error", e)
      source$.error(e)
    })
  
  reducerRunner.emit(event)
  
  await end$
    .toPromise()
  
  reducerRunner.close()
}
