import React, { useEffect, useState } from 'react'
import { Route, Switch }              from 'react-router-native'
import { useCampaign }                from '../GlobalContexts'
import { useProfile }                 from '../Auth/useProfile'
import { AppSkeleton }                from './AppSkeleton'
import { Settings }                   from '../Settings/Settings'
import { Avatar }                     from '../Avatar/Avatar'
import { AvatarHomeSelection }        from '../Avatar/AvatarHomeSelection'
import { RankingTabs }                from '../Leaderboards/pages/RankingTabs'
import styled                         from '@emotion/native'
import { LangSelectorModal }          from '../translations/LangSelectorModal'
import { useTranslator }              from '../translations/translate'
import { Home }            from './Home'
import { history }         from '../Router/history'
import { Phase2Router }    from '../Phases/Phase 2/Components/Router/Phase2Router'
import { colors }          from '@civitime/library/storybook/configs/colors'
import { Platform }        from 'react-native'
import { getBrowserInfos } from '../Utils/browserDetector'
import { clientId, PWA }   from '../../clientId'
import {useLocation}       from 'react-router'
import { useIsOffline }    from '../Hooks/isOffline'


export const AppRouter = () => {
  const {basePath, campaignSettings, campaignId} = useCampaign()
  const {lang} = useTranslator()
  const {emitCommand, userState, loading, publicProfile, emitClientCommand} = useProfile()
  const [serveDimensions, setServeDimensions] = useState({})
  const [langSelectorOpened, setLangSelectorOpened] = useState(false)
  const [phaseProps, setPhaseProps] = useState({})
  const isOffline = useIsOffline()
  
  useEffect(() => {
    if(!userState?.id || !campaignId || !clientId) return
    const browserInfos = getBrowserInfos() || {}

    if (Platform.OS === 'web') {
      emitCommand({
        type: "UpdateBrowserInfos",
        payload: {
          browserInfos: browserInfos
        }
      })
    }
  }, [userState?.id, campaignId, clientId])

  useEffect(() => {
    if (!campaignSettings?.phase) return
    if (!loading && !userState && campaignId) {
      emitCommand({
        type: "JoinCampaign",
        payload: {
          lang: lang,
          phase: campaignSettings?.phase
        },
      }, campaignId)
    }
  }, [userState, loading, campaignId, campaignSettings?.phase])

  useEffect(() => {
    if (!loading && !publicProfile) {
      if (PWA){
        return emitClientCommand({
          type: "InitiateOfflineProfile",
          payload: {
            lang: lang,
          },
        })
      }
      emitClientCommand({
        type: "InitiatePublicProfile",
        payload: {
          lang: lang,
        },
      })
    }
  }, [userState, loading, campaignId])

  useEffect(() => {
    if (!campaignId) return
    history.push(campaignId)
  }, [campaignId])

  useEffect(() => {
    campaignId && emitCommand({type: "OpenApp"}, campaignId)
  }, [campaignId])
  

  const { pathname } = useLocation()
  return publicProfile ? (
    <AppSkeleton
      phase={campaignSettings?.phase}
      openLangSelector={() => setLangSelectorOpened(true)}
      isOffline={isOffline}
      serveDimensions={(d) => {
        setServeDimensions((e) => ({...e, ...d}))
      }}
      updatePhaseProps={(e) => setPhaseProps(prev => ({
        ...prev,
        ...e
      }))}
      isAdmin={false}
      {...phaseProps}
    >
      {
        langSelectorOpened && campaignSettings?.phase ?
        <LangSelectorModal closeSelector={() => setLangSelectorOpened(false)}/>
        : langSelectorOpened && !campaignSettings?.phase ?
          <WrapperLangSelector>
            <LangSelectorModal closeSelector={() => setLangSelectorOpened(false)}/>
          </WrapperLangSelector>
          : null
      }
      <HomeWrapper hub={!campaignSettings?.phase} avatar={pathname.includes('/avatar')} width={serveDimensions?.width}>
        <Switch>
          <Route path={`${basePath}/settings`}>
            <Settings phase={campaignSettings?.phase}/>
          </Route>
          <Route path={`${basePath}/avatar/update`}>
            <AvatarHomeSelection hub={!campaignSettings?.phase}/>
          </Route>
          <Route path={`${basePath}/avatar`}>
            <Avatar {...phaseProps} phase={campaignSettings?.phase} hub={!campaignSettings?.phase}/>
          </Route>
          <Route path={`${basePath}/leaderboard`}>
            <RankingTabs phase={campaignSettings?.phase}/>
          </Route>
          <Route path={`${basePath}`}>
            <Home phase={campaignSettings?.phase} serveDimensions={serveDimensions}/>
          </Route>
          <Route
            path={`${basePath}/wait`}
            component={() => (
              <WaitingScreen
                screenHeight={serveDimensions.width}
                screenWidth={serveDimensions.height}
              />
            )}
          />
        </Switch>
        {
          campaignSettings?.phase === 2 &&
          <Phase2Router basePath={basePath}
                        phase={campaignSettings?.phase}
                        serveDimensions={serveDimensions}
                        phaseProps={phaseProps}
                        setPhaseProps={(e) => setPhaseProps(prev => ({
                          ...prev,
                          ...e
                        }))}/>
        }
      </HomeWrapper>
    </AppSkeleton>
  ) : null
}

const WrapperLangSelector = styled.View({
  position: 'fixed',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10,
  backgroundColor: colors.mediumShadow
})

const WaitingScreen = styled.View(({screenHeight, screenWidth}) => ({
  width: screenWidth,
  height: screenHeight,
  zIndex: 300,
}))

const HomeWrapper = styled.View(({hub, width, avatar}) => ({
  zIndex: 3,
  alignItems: "center",
  backgroundColor: hub && colors.lightText,
  width: width,
  paddingBottom: (hub && !avatar) && 130,
  margin: hub && avatar && 'auto'
}))

