import React, { useRef, useState } from 'react'
import styled                      from '@emotion/native'
import { useTranslator }           from '../translations/translate'
import { useProfile }              from '../Auth/useProfile'
import { useCampaign }             from '../GlobalContexts'
import { usePreCachedData }        from '../Utils/dataClient'
import { clientId }                from '../../clientId'

import { TeamList } from './components/TeamList'

import { colors }                       from '@civitime/library/storybook/configs/colors'
import { MOBILE_HEADER_SIZE }           from '@civitime/library/storybook/configs/sizes'
import { useScreenDimensions }          from '@civitime/library/storybook/hooks/useScreenDimensions'
import { DefaultModal }                 from '@civitime/library/storybook/stories/Modals'
import { UseMultiTextesBarSelection }   from '@civitime/library/storybook/stories/SelectionBar'
import { InputCard, InputCardWrapper, } from '@civitime/library/storybook/stories/Inputs'
import { JoinTeamAlert }                from './components/JoinTeamAlert'

export const TeamSearch = ({phase}) => {
  const root = useRef(null)
  const { t } = useTranslator()
  const { mobile, height, orientation } = useScreenDimensions(root)
  const { emitCommand } = useProfile()
  const { campaignNavigate, campaignId } = useCampaign()
  const [searchInTeam, setSearchInTeam] = useState("")
  const [alertJoinTeam, setAlertJoinTeam] = useState(false)
  const [joinTeamId, setJoinTeamId] = useState(null)

  const teams = usePreCachedData(
    `clients/${clientId}/campaigns/${campaignId}/teams`
  )

  const handleChange = (search) => {
    setSearchInTeam(search)
  }

  const joinTeam = (id) => {
    emitCommand({
      type: "JoinTeam",
      payload: {
        teamId: id,
      },
    }).then(
      (value) => value[0].type === "TeamJoined" && campaignNavigate("team")
    )
  }

  return (
    <>
      <DefaultModal
        backgroundColor={colors.lightBlue}
        color={colors.text}
        title={t("app.team.chooseTeam")}
        onPress={() => campaignNavigate("team")}
        maxHeight={(height - MOBILE_HEADER_SIZE) * 0.99}
        // fixedHeight
        withShadow
      >
        <WrapperSearch ref={root} {...{ mobile, orientation }}>
          <SearchInput>
            <InputCard
              withIcon
              placeholder={t("app.team.searchTeamName")}
              onChangeText={handleChange}
            />
          </SearchInput>
          <TeamList
            phase={phase}
            teams={
              teams
                ? teams.filter((team) =>
                    team.name.toLowerCase().includes(searchInTeam.toLowerCase())
                  )
                : null
            }
            onPress={(teamId) => {
              setAlertJoinTeam(true)
              setJoinTeamId(teamId)
            }}
          />
        </WrapperSearch>
        <BackButton
          texts={[t("app.team.backTeamChoice")]}
          onPressArray={[() => campaignNavigate("team")]}
          withoutActive
        />
      </DefaultModal>
      <JoinTeamAlert
        visible={alertJoinTeam}
        onRequestClose={() => setAlertJoinTeam(false)}
        navToTeam={() => joinTeam(joinTeamId)}
      />
    </>
  )
}

const WrapperSearch = styled.View(({ mobile, orientation }) => ({
  padding: mobile ? (orientation === "portrait" ? 5 : 10) : 20,
  paddingHorizontal: mobile ? (orientation === "portrait" ? 5 : 10) : 55,
  maxHeight: 330,
  overflowY: 'scroll'
}))

const SearchInput = styled(InputCardWrapper)({
  marginBottom: 10,
})

const BackButton = styled(UseMultiTextesBarSelection)({
  marginBottom: 10,
})
