import React, { useEffect, useRef }   from 'react'
import Mairie                         from './Assets/buildings/mairie.svg'
import Construction                   from './Assets/buildings/construction.svg'
import Merveille                      from './Assets/buildings/merveille.svg'
import Ended                          from './Assets/buildings/ended.svg'
import styled                         from '@emotion/native'
import { colors }                     from '@civitime/library/storybook/configs/colors'
import { Animated, Easing }           from 'react-native'
import { MediumText }                 from '@civitime/library/storybook/stories/Texts'
import { CiviflouzIcon, PadlockIcon } from '@civitime/library/storybook/stories/Icons'
import { T }                          from '../../../translations/translate'
import { useProfile }                 from '../../../Auth/useProfile'
import { useCampaign }                from '../../../GlobalContexts'

export const BuildingIcon = ({
  buildingType,
  buildingProgress,
  infos,
  infosEvol,
  hover,
  displayedMiniIcon,
  ended,
  onPress,
  evolutionReadyToPlay,
  children,
  type,
  lockBuildingsFromOrder,
  buildingProgression,
  orderLocked,
  ...props
}) => {
  const BuildingIconAsset = (ended && buildingType === 'merveille') ? buildingIcons?.wonderEnded : ended ? buildingIcons?.ended : buildingIcons?.[buildingType]
  const { userSession } = useProfile()
  const { campaignSettings } = useCampaign()
  const progressBarAnimation = useRef(new Animated.Value(0)).current
  const iconBounceScale = useRef(new Animated.Value(1)).current
  const launchIconBounce = useRef(false)
  const locked = !!(type && userSession?.buildings?.[type]?.locked) && !evolutionReadyToPlay
  useEffect(() => {
    launchIconBounce.current = evolutionReadyToPlay
  }, [evolutionReadyToPlay])

  useEffect(() => {
    setTimeout(() => Animated.timing(progressBarAnimation, {
      toValue: buildingProgress ?? 0,
      duration: 500,
      useNativeDriver: true,
    }).start(), 300)
  }, [buildingProgress])

  const iconBounceAnimationLoop = () => {
    Animated.sequence([
      Animated.timing(iconBounceScale, {
        toValue: 1.25,
        duration: 200,
        easing: Easing.ease,
        useNativeDriver: true
      }),
      Animated.timing(iconBounceScale, {
        toValue: 1,
        duration: 300,
        easing: Easing.ease,
        useNativeDriver: true
      }),
      Animated.timing(iconBounceScale, {
        toValue: 1.125,
        duration: 300,
        easing: Easing.ease,
        useNativeDriver: true
      }),
      Animated.timing(iconBounceScale, {
        toValue: 1,
        duration: 300,
        easing: Easing.ease,
        useNativeDriver: true
      }),
    ]).start(() => launchIconBounce.current && iconBounceAnimationLoop())
  }

  useEffect(() => {
    if (!launchIconBounce.current) return
    iconBounceAnimationLoop()
  }, [launchIconBounce.current])
  const evolution = evolutionReadyToPlay && buildingType !== 'mairie' && buildingType !== 'merveille'
  return <WrapperBuildingIcon hallTown={buildingType === 'mairie'} {...props}>
    <ContainerBuildingIcon onPress={lockBuildingsFromOrder && orderLocked ? null : onPress}
                           locked={lockBuildingsFromOrder && orderLocked}>
      <WrapperClickableIcon hallTown={buildingType === 'mairie'}>
        {children}
        {
          (lockBuildingsFromOrder ? orderLocked && hover
            : (buildingType !== 'merveille' && hover) || evolution || locked && hover) &&
          <WrapperInfoIcon evolution={evolution} locked={locked} orderLocked={orderLocked && lockBuildingsFromOrder}>
            <InfoIconContainer evolution={evolution} locked={locked}
                               orderLocked={orderLocked && lockBuildingsFromOrder}>
              <InfoIcon>
                {lockBuildingsFromOrder && orderLocked ? <T
                    path="phase_2.modals.buildingModal.buildingCards.buildingOrderLocked"/>
                  : evolution ? infosEvol
                    : locked ? <T path="phase_2.modals.buildingModal.buildingCards.buildingLocked"
                                  data={{ 0: Object.keys(campaignSettings?.buildings)?.length >= 6 ? 4 : 2 }}/>
                      : infos}
              </InfoIcon>
              {
                displayedMiniIcon && !ended && !evolution && !locked && !orderLocked &&
                <BuildingIconAsset width={15} height={15}/>
              }
              <InfoCorner evolution={evolution} locked={locked}/>
            </InfoIconContainer>
          </WrapperInfoIcon>
        }
        {
          buildingType !== 'mairie' && buildingType !== 'merveille' && !ended && !(lockBuildingsFromOrder && orderLocked) &&
          <BuildingIconProgressBar>
            <WrapperProgressBar>
              <ProgressBar style={{
                position: 'absolute',
                left: '-100%',
                width: '100%',
                height: '100%',
                backgroundColor: colors.module,
                transform: [{ translateX: progressBarAnimation ?? 0 }],
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 4,
                },
                shadowOpacity: 0.32,
                shadowRadius: 5.46,
                elevation: 9,
              }}/>
              {!lockBuildingsFromOrder && locked && <LockedBar progress={progressBarAnimation}/>}
            </WrapperProgressBar>
          </BuildingIconProgressBar>
        }
        <WrapperIconAnimated bounceScale={lockBuildingsFromOrder && orderLocked ? 1 : iconBounceScale} ended={ended}
                             width={(buildingType !== 'merveille') ? ended ? 45 : 35 : null}
                             locked={lockBuildingsFromOrder && orderLocked}
                             height={(buildingType !== 'merveille') ? ended ? 45 : 35 : null}>
          {lockBuildingsFromOrder && orderLocked ?
            <EmptyIcon/>
            : <BuildingIconAsset width={ended ? 45 : 35}
                                 height={ended ? 45 : 35}
                                 style={{
                                   zIndex: 2, position: 'absolute',
                                   left: 0,
                                   right: 0,
                                   bottom: 0,
                                   top: 0
                                 }}
            />}
          {
            lockBuildingsFromOrder ? orderLocked && <GreyWrapper ended={ended} opacity={1}>
              <PadlockIcon height={18.5} width={14} color={colors.white}/>
            </GreyWrapper>
              : locked && <GreyWrapper ended={ended} opacity={.5}/>
          }
        </WrapperIconAnimated>
      </WrapperClickableIcon>
    </ContainerBuildingIcon>
  </WrapperBuildingIcon>
}

const WonderButtonWithIcon = () => {
  return <WonderWrapper>
    <WonderIconWrapper>
      <Merveille width={35} height={35}/>
    </WonderIconWrapper>
    <WonderButtonWrapper>
      <TextWrapper>
        <ButtonText color={colors?.white}><T path="phase_2.icons.wonder.buttonText"/></ButtonText>
        <CiviflouzIcon width={15} height={15} color={colors.white}/>
      </TextWrapper>
    </WonderButtonWrapper>
  </WonderWrapper>
}

const buildingIcons = {
  'mairie': Mairie,
  'construction': Construction,
  'merveille': WonderButtonWithIcon,
  'ended': Ended,
  'wonderEnded': WonderButtonWithIcon
}

const WrapperIconAnimated = styled(Animated.View)(({ bounceScale, width, height, locked }) => ({
  transform: [{ scale: bounceScale }],
  willChange: 'transform',
  transformStyle: 'preserve-3d',
  width: width,
  height: height,
  cursor: locked ? 'initial' : 'pointer'
}))

const WrapperBuildingIcon = styled.View(({ hallTown }) => ({
  top: 0,
  left: 0,
  width: hallTown ? '100%' : 'unset',
  height: hallTown ? '100%' : 'unset',
  alignItems: hallTown ? 'center' : 'unset',
  justifyContent: hallTown ? 'center' : 'unset',
  position: 'absolute',
  zIndex: 2,
  shadowColor: hallTown ? 'transparent' : '#000',
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.32,
  shadowRadius: 5.46,
  elevation: 9,
  borderRadius: 50,
}))

const ContainerBuildingIcon = styled.TouchableWithoutFeedback(({ locked }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  cursor: locked ? 'initial' : 'pointer',
  backgroundColor: locked ? 'red' : 'blue'
}))

const WrapperClickableIcon = styled.View(({ hallTown }) => ({
  width: hallTown ? '100%' : 'auto',
  height: hallTown ? '100%' : 'auto',
  alignItems: hallTown ? 'center' : 'unset',
  justifyContent: hallTown ? 'center' : 'unset',
  borderRadius: hallTown ? 50 : 0,
}))

const BuildingIconProgressBar = styled.View({
  position: 'absolute',
  top: '50%',
  right: -97,
  height: 12,
  width: 100,
  backgroundColor: colors.extraLightBackground,
  borderTopRightRadius: 9,
  borderBottomRightRadius: 9,
  transform: [{ translateY: '-50%' }],
  overflow: 'hidden',
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.32,
  shadowRadius: 5.46,
  elevation: 9,
})

const WrapperProgressBar = styled.View({
  position: 'relative',
  width: '100%',
  height: '100%',
})

const ProgressBar = styled(Animated.View)({})

const LockedBar = styled(ProgressBar)({
  backgroundColor: 'rgba(74, 74, 74, 0.5)',
  zIndex: 2
})

export const WrapperInfoIcon = styled.View(({ evolution, locked, orderLocked }) => ({
  position: 'absolute',
  top: evolution ? -85
    : orderLocked ? -63
      : locked ? -140
        : -50,
  left: evolution || locked ? -69
    : orderLocked ? -70
      : -30
}))

export const InfoIcon = styled(MediumText)({
  fontSize: 11,
  zIndex: 2,
  marginRight: 5
})

export const InfoIconContainer = styled.View(({ evolution, locked, orderLocked }) => ({
  position: 'relative',
  borderRadius: 5,
  backgroundColor: colors.defaultBackground,
  paddingRight: 10,
  paddingLeft: 10,
  paddingTop: 5,
  paddingBottom: 5,
  minWidth: (evolution || orderLocked) ? 175
    : locked ? 190
      : 90,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const InfoCorner = styled.View(({ evolution, locked }) => ({
  borderRadius: 5,
  backgroundColor: colors.defaultBackground,
  width: 30,
  height: 30,
  position: 'absolute',
  left: '50%',
  top: evolution ? '90%'
    : locked ? '96%'
      : '70%',
  transform: [{ translateX: '-50%' }, { translateY: '-50%' }, { rotate: '45deg' }]
}))

const WonderWrapper = styled.View({
  position: 'relative'
})

const WonderButtonWrapper = styled.View({
  borderRadius: 18,
  backgroundColor: colors.secondary,
  height: 22,
  width: '100%',
  padding: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 20,
  paddingRight: 10
})

const TextWrapper = styled.View({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
})

const ButtonText = styled(MediumText)({
  textTransform: 'uppercase',
  paddingRight: 5,
  fontSize: 12,
  fontWeight: "700"
})

const WonderIconWrapper = styled.View({
  position: 'absolute',
  transform: 'translateX(-60%) translateY(-50%)',
  zIndex: 2,
  top: '50%'
})

const GreyWrapper = styled.View(({ ended, opacity }) => ({
  width: ended ? 45 : 35,
  height: ended ? 45 : 35,
  zIndex: 3,
  opacity: opacity,
  backgroundColor: 'rgb(74, 74, 74)',
  borderRadius: '50%',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const EmptyIcon = styled.View({
  width: 35,
  height: 35,
  backgroundColor: colors.module,
  borderWidth: 1,
  borderColor: colors.lightBackground,
  borderRadius: '50%',
})