import React from "react"
import styled from "@emotion/native"
import {colors} from "@civitime/library/storybook/configs/colors"

export const ZoomButtons = ({
                              mobile,
                              zoomButtonHoveredLeft,
                              setZoomButtonHoveredLeft,
                              updateZoom,
                              zoomLevel,
                              zoomButtonHoveredRight,
                              setZoomButtonHoveredRight
                            }) => {

  return <WrapperZoomButtons mobile={mobile}>
    <ButtonZoom leftButton
                zoomButtonHovered={zoomButtonHoveredLeft}
                onMouseEnter={() => setZoomButtonHoveredLeft(true)}
                onMouseLeave={() => setZoomButtonHoveredLeft(null)}
                onPress={() => updateZoom('downScale')}
                disabled={zoomLevel <= 0}
                mobile={mobile}>
      <ZoomText mobile={mobile} disabled={zoomLevel <= 0} hover={zoomButtonHoveredLeft}>-</ZoomText>
    </ButtonZoom>
    <TextZoom mobile={mobile}>Zoom</TextZoom>
    <ButtonZoom onPress={() => updateZoom('upScale')}
                zoomButtonHovered={zoomButtonHoveredRight}
                onMouseEnter={() => setZoomButtonHoveredRight(true)}
                onMouseLeave={() => setZoomButtonHoveredRight(null)}
                disabled={zoomLevel >= 2}
                mobile={mobile}>
      <ZoomText mobile={mobile} disabled={zoomLevel >= 2} hover={zoomButtonHoveredRight}>+</ZoomText>
    </ButtonZoom>
  </WrapperZoomButtons>
}


export const WrapperZoomButtons = styled.View(({mobile}) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: mobile ? 0 : 30,
  marginBottom: mobile ? 20 : 0,
  position: mobile ? 'fixed' : 'relative',
  bottom: mobile ? '2%' : 'unset',
  left: mobile ? '50%'  : 'unset',
  transform: mobile ? [{translateX: '-50%'}] : [],
}))

export const TextZoom = styled.Text(({mobile}) => ({
  textTransform: 'uppercase',
  padding: mobile ? 5 : 6,
  fontSize: mobile ? 10 : 13,
  alignSelf: 'center',
  color: colors.dropShadow,
  fontWeight: 'bold',
  backgroundColor: colors.defaultBackground
}))

export const ButtonZoom = styled.TouchableOpacity(({leftButton, mobile, zoomButtonHovered, disabled}) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: mobile ? 25 : 31,
  height: mobile ? 25 : 31,
  borderTopLeftRadius: leftButton ? 15 : 0,
  borderBottomLeftRadius: leftButton ? 15 : 0,
  borderTopRightRadius: !leftButton ? 15 : 0,
  borderBottomRightRadius: !leftButton ? 15 : 0,
  borderColor: disabled ? colors.defaultBackground : colors.module,
  borderWidth: 2,
  backgroundColor: disabled ? colors.defaultBackground : zoomButtonHovered ? colors.module : colors.white,
}))

export const ZoomText = styled.Text(({mobile, hover, disabled}) => ({
  color: disabled ? colors.white : hover ? colors.white : colors.module,
  fontSize: mobile ? 20 : 30,
  fontWeight: 'bold',
  transform: [{translateY: -3}],
  lineHeight: 0,
}))
