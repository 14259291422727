export default {
  'v': '5.7.1',
  'fr': 25,
  'ip': 0,
  'op': 150,
  'w': 10635,
  'h': 8745,
  'nm': 'nvlle merveille anim',
  'ddd': 0,
  'assets': [{ 'id': 'image_0', 'w': 299, 'h': 220, 'u': '', 'p': require('./images/img_0.png'), 'e': 0 }, {
    'id': 'image_1',
    'w': 223,
    'h': 165,
    'u': '',
    'p': require('./images/img_1.png'),
    'e': 0
  }, { 'id': 'image_2', 'w': 360, 'h': 279, 'u': '', 'p': require('./images/img_2.png'), 'e': 0 }, {
    'id': 'image_3',
    'w': 164,
    'h': 86,
    'u': '',
    'p': require('./images/img_3.png'),
    'e': 0
  }, { 'id': 'image_4', 'w': 199, 'h': 174, 'u': '', 'p': require('./images/img_4.png'), 'e': 0 }, {
    'id': 'image_5',
    'w': 270,
    'h': 213,
    'u': '',
    'p': require('./images/img_5.png'),
    'e': 0
  }, { 'id': 'image_6', 'w': 400, 'h': 292, 'u': '', 'p': require('./images/img_6.png'), 'e': 0 }, {
    'id': 'image_7',
    'w': 129,
    'h': 62,
    'u': '',
    'p': require('./images/img_7.png'),
    'e': 0
  }, { 'id': 'image_8', 'w': 35, 'h': 53, 'u': '', 'p': require('./images/img_8.png'), 'e': 0 }, {
    'id': 'image_9',
    'w': 45,
    'h': 23,
    'u': '',
    'p': require('./images/img_9.png'),
    'e': 0
  }, { 'id': 'image_10', 'w': 39, 'h': 94, 'u': '', 'p': require('./images/img_10.png'), 'e': 0 }, {
    'id': 'image_11',
    'w': 36,
    'h': 40,
    'u': '',
    'p': require('./images/img_11.png'),
    'e': 0
  }, { 'id': 'image_12', 'w': 29, 'h': 58, 'u': '', 'p': require('./images/img_12.png'), 'e': 0 }, {
    'id': 'image_13',
    'w': 27,
    'h': 65,
    'u': '',
    'p': require('./images/img_13.png'),
    'e': 0
  }, {
    'id': 'comp_0',
    'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 2,
      'nm': 'herbe1',
      'refId': 'image_0',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 63,
            's': [100]
          }, { 't': 87, 's': [0] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': {
          'a': 1,
          'k': [{
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 63,
            's': [5030.5, 7032, 0],
            'to': [0, 5.833, 0],
            'ti': [0, -5.833, 0]
          }, { 't': 87, 's': [5030.5, 7067, 0] }],
          'ix': 2
        },
        'a': { 'a': 0, 'k': [149.5, 110, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 300,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 2,
      'ty': 2,
      'nm': 'feuillage2',
      'refId': 'image_1',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5226.5, 6844.5, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [-4.5, 92.5, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 38,
            's': [100, 100, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 51,
            's': [120, 120, 100]
          }, { 't': 63, 's': [0, 0, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 2,
      'nm': 'feuillage1',
      'refId': 'image_2',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5285, 6708.5, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [174, 223.5, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 25,
            's': [100, 100, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 38,
            's': [120, 120, 100]
          }, { 't': 50, 's': [0, 0, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 2,
      'nm': 'ombre tronc',
      'refId': 'image_3',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 25,
            's': [65.882]
          }, { 't': 50, 's': [0] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5213, 6746, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [82, 43, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 5,
      'ty': 2,
      'nm': 'lum tronc',
      'refId': 'image_4',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 50.196, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5010, 6952, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [90, 174, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 63,
            's': [100, 100, 100]
          }, { 't': 87, 's': [10, 10, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 6
    }, {
      'ddd': 0,
      'ind': 6,
      'ty': 2,
      'nm': 'lum tronc',
      'refId': 'image_5',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 36.863, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5010, 6952, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [77, 200, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 63,
            's': [100, 100, 100]
          }, { 't': 87, 's': [10, 10, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 6
    }, {
      'ddd': 0,
      'ind': 7,
      'ty': 2,
      'nm': 'tronc',
      'refId': 'image_6',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5010, 6953, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [115, 250, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 63,
            's': [100, 100, 100]
          }, { 't': 87, 's': [10, 10, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 8,
      'ty': 2,
      'nm': 'pt branche',
      'refId': 'image_7',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5147.5, 6840, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [15.5, 33, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 62,
            's': [100, 100, 100]
          }, { 't': 75, 's': [0, 0, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }]
  }, {
    'id': 'comp_1', 'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 4,
      'nm': 'Calque de forme 4',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 5,
            's': [100]
          }, { 't': 20, 's': [0] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': {
          'a': 1,
          'k': [{
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 5,
            's': [599, 600, 0],
            'to': [-34.083, 76.167, 0],
            'ti': [34.083, -76.167, 0]
          }, { 't': 20, 's': [394.5, 1057, 0] }],
          'ix': 2
        },
        'a': { 'a': 0, 'k': [-104, -90, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 5,
            's': [81, 81, 100]
          }, { 't': 20, 's': [490, 490, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ty': 'sr',
          'sy': 1,
          'd': 1,
          'pt': { 'a': 0, 'k': 5, 'ix': 3 },
          'p': { 'a': 0, 'k': [0, 0], 'ix': 4 },
          'r': { 'a': 0, 'k': 0, 'ix': 5 },
          'ir': { 'a': 0, 'k': 9.749, 'ix': 6 },
          'is': { 'a': 0, 'k': 0, 'ix': 8 },
          'or': { 'a': 0, 'k': 19.498, 'ix': 7 },
          'os': { 'a': 0, 'k': 0, 'ix': 9 },
          'ix': 1,
          'nm': 'Tracé polyétoile 1',
          'mn': 'ADBE Vector Shape - Star',
          'hd': false
        }, {
          'ty': 'fl',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 4 },
          'o': { 'a': 0, 'k': 100, 'ix': 5 },
          'r': 1,
          'bm': 0,
          'nm': 'Fond 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [-105, -90], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': 0, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Polyétoile 1',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 35,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 2,
      'ty': 4,
      'nm': 'Calque de forme 3',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 5,
            's': [100]
          }, { 't': 20, 's': [0] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': {
          'a': 1,
          'k': [{
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 5,
            's': [599, 600, 0],
            'to': [-34.417, -81.833, 0],
            'ti': [34.417, 81.833, 0]
          }, { 't': 20, 's': [392.5, 109, 0] }],
          'ix': 2
        },
        'a': { 'a': 0, 'k': [-104, -90, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 5,
            's': [81, 81, 100]
          }, { 't': 20, 's': [490, 490, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ty': 'sr',
          'sy': 1,
          'd': 1,
          'pt': { 'a': 0, 'k': 5, 'ix': 3 },
          'p': { 'a': 0, 'k': [0, 0], 'ix': 4 },
          'r': { 'a': 0, 'k': 0, 'ix': 5 },
          'ir': { 'a': 0, 'k': 9.749, 'ix': 6 },
          'is': { 'a': 0, 'k': 0, 'ix': 8 },
          'or': { 'a': 0, 'k': 19.498, 'ix': 7 },
          'os': { 'a': 0, 'k': 0, 'ix': 9 },
          'ix': 1,
          'nm': 'Tracé polyétoile 1',
          'mn': 'ADBE Vector Shape - Star',
          'hd': false
        }, {
          'ty': 'fl',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 4 },
          'o': { 'a': 0, 'k': 100, 'ix': 5 },
          'r': 1,
          'bm': 0,
          'nm': 'Fond 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [-105, -90], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': 0, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Polyétoile 1',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 35,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 4,
      'nm': 'Calque de forme 2',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 5,
            's': [100]
          }, { 't': 15, 's': [0] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': {
          'a': 1,
          'k': [{
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 5,
            's': [599, 600, 0],
            'to': [81.583, 35.167, 0],
            'ti': [-81.583, -35.167, 0]
          }, { 't': 15, 's': [1088.5, 811, 0] }],
          'ix': 2
        },
        'a': { 'a': 0, 'k': [-104, -90, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 5,
            's': [81, 81, 100]
          }, { 't': 15, 's': [490, 490, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ty': 'sr',
          'sy': 1,
          'd': 1,
          'pt': { 'a': 0, 'k': 5, 'ix': 3 },
          'p': { 'a': 0, 'k': [0, 0], 'ix': 4 },
          'r': { 'a': 0, 'k': 0, 'ix': 5 },
          'ir': { 'a': 0, 'k': 9.749, 'ix': 6 },
          'is': { 'a': 0, 'k': 0, 'ix': 8 },
          'or': { 'a': 0, 'k': 19.498, 'ix': 7 },
          'os': { 'a': 0, 'k': 0, 'ix': 9 },
          'ix': 1,
          'nm': 'Tracé polyétoile 1',
          'mn': 'ADBE Vector Shape - Star',
          'hd': false
        }, {
          'ty': 'fl',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 4 },
          'o': { 'a': 0, 'k': 100, 'ix': 5 },
          'r': 1,
          'bm': 0,
          'nm': 'Fond 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [-105, -90], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': 0, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Polyétoile 1',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 35,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 4,
      'nm': 'Calque de forme 5',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 30,
            's': [100]
          }, { 't': 35, 's': [0] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': -23, 'ix': 10 },
        'p': { 'a': 0, 'k': [600, 600, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [0, -20, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': { 'i': [[0, 0], [0, 0]], 'o': [[0, 0], [0, 0]], 'v': [[0, -20], [552, -20]], 'c': false },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [0]
            }, { 't': 35, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [1]
            }, { 't': 35, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 20, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': 0, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 1',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': { 'i': [[0, 0], [0, 0]], 'o': [[0, 0], [0, 0]], 'v': [[20, -0.5], [572, -0.5]], 'c': false },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [0]
            }, { 't': 35, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [1]
            }, { 't': 35, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 20, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -90, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 2',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 2,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[67.97, 0], [0, -67.97], [-67.97, 0], [0, 67.97]],
              'o': [[-67.97, 0], [0, 67.97], [67.97, 0], [0, -67.97]],
              'v': [[0, -123.07], [-123.07, 0], [0, 123.07], [123.07, 0]],
              'c': true
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'fl',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 4 },
          'o': { 'a': 0, 'k': 100, 'ix': 5 },
          'r': 1,
          'bm': 0,
          'nm': 'Fond 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [-2.368, -25.257], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833, 0.833], 'y': [0.833, 0.833] },
              'o': { 'x': [0.167, 0.167], 'y': [0.167, 0.167] },
              't': 10,
              's': [0, 0]
            }, { 't': 25, 's': [482, 482] }],
            'ix': 3
          },
          'r': { 'a': 0, 'k': 0, 'ix': 6 },
          'o': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [100]
            }, { 't': 25, 's': [0] }],
            'ix': 7
          },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Ellipse 1',
        'np': 3,
        'cix': 2,
        'bm': 3,
        'ix': 3,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': { 'i': [[0, 0], [0, 0]], 'o': [[0, 0], [0, 0]], 'v': [[0.5, 19.75], [552.5, 19.75]], 'c': false },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [0]
            }, { 't': 35, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [1]
            }, { 't': 35, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 20, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -180, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 3',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 4,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': { 'i': [[0, 0], [0, 0]], 'o': [[0, 0], [0, 0]], 'v': [[-19.75, 0.25], [532.25, 0.25]], 'c': false },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [0]
            }, { 't': 35, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 10,
              's': [1]
            }, { 't': 35, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 20, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -270, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 4',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 5,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0]],
              'o': [[0, 0], [0, 0]],
              'v': [[13.535, -14.402], [565.535, -14.402]],
              'c': false
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 15,
              's': [0]
            }, { 't': 35, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 15,
              's': [1]
            }, { 't': 35, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 10, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -44, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 5',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 6,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0]],
              'o': [[0, 0], [0, 0]],
              'v': [[14.675, 13.472], [566.675, 13.472]],
              'c': false
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 15,
              's': [0]
            }, { 't': 35, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 15,
              's': [1]
            }, { 't': 35, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 10, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -134, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 6',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 7,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0]],
              'o': [[0, 0], [0, 0]],
              'v': [[-13.472, 14.425], [538.528, 14.425]],
              'c': false
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 15,
              's': [0]
            }, { 't': 35, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 15,
              's': [1]
            }, { 't': 35, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 10, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -224, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 7',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 8,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0]],
              'o': [[0, 0], [0, 0]],
              'v': [[-14.421, -13.545], [537.578, -13.545]],
              'c': false
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 15,
              's': [0]
            }, { 't': 35, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 15,
              's': [1]
            }, { 't': 35, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 10, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -314, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 8',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 9,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 10,
      'op': 45,
      'st': 10,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 5,
      'ty': 4,
      'nm': 'Calque de forme 1',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 20,
            's': [100]
          }, { 't': 25, 's': [0] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [600, 600, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [0, -20, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': { 'i': [[0, 0], [0, 0]], 'o': [[0, 0], [0, 0]], 'v': [[0, -20], [552, -20]], 'c': false },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 0,
              's': [0]
            }, { 't': 25, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 0,
              's': [1]
            }, { 't': 25, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 20, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': 0, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 1',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': { 'i': [[0, 0], [0, 0]], 'o': [[0, 0], [0, 0]], 'v': [[20, -0.5], [572, -0.5]], 'c': false },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 0,
              's': [0]
            }, { 't': 25, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 0,
              's': [1]
            }, { 't': 25, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 20, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -90, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 2',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 2,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': { 'i': [[0, 0], [0, 0]], 'o': [[0, 0], [0, 0]], 'v': [[0.5, 19.75], [552.5, 19.75]], 'c': false },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 0,
              's': [0]
            }, { 't': 25, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 0,
              's': [1]
            }, { 't': 25, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 20, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -180, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 3',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 4,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': { 'i': [[0, 0], [0, 0]], 'o': [[0, 0], [0, 0]], 'v': [[-19.75, 0.25], [532.25, 0.25]], 'c': false },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 0,
              's': [0]
            }, { 't': 25, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 0,
              's': [1]
            }, { 't': 25, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 20, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -270, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 4',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 5,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0]],
              'o': [[0, 0], [0, 0]],
              'v': [[13.535, -14.402], [565.535, -14.402]],
              'c': false
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 5,
              's': [0]
            }, { 't': 25, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 5,
              's': [1]
            }, { 't': 25, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 10, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -44, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 5',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 6,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0]],
              'o': [[0, 0], [0, 0]],
              'v': [[14.675, 13.472], [566.675, 13.472]],
              'c': false
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 5,
              's': [0]
            }, { 't': 25, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 5,
              's': [1]
            }, { 't': 25, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 10, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -134, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 6',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 7,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0]],
              'o': [[0, 0], [0, 0]],
              'v': [[-13.472, 14.425], [538.528, 14.425]],
              'c': false
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 5,
              's': [0]
            }, { 't': 25, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 5,
              's': [1]
            }, { 't': 25, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 10, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -224, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 7',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 8,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0]],
              'o': [[0, 0], [0, 0]],
              'v': [[-14.421, -13.545], [537.578, -13.545]],
              'c': false
            },
            'ix': 2
          },
          'nm': 'Tracé 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'tm',
          's': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 5,
              's': [0]
            }, { 't': 25, 's': [93] }],
            'ix': 1
          },
          'e': {
            'a': 1,
            'k': [{
              'i': { 'x': [0.833], 'y': [0.833] },
              'o': { 'x': [0.167], 'y': [0.167] },
              't': 5,
              's': [1]
            }, { 't': 25, 's': [100] }],
            'ix': 2
          },
          'o': { 'a': 0, 'k': 0, 'ix': 3 },
          'm': 1,
          'ix': 2,
          'nm': 'Réduire les tracés 1',
          'mn': 'ADBE Vector Filter - Trim',
          'hd': false
        }, {
          'ty': 'st',
          'c': { 'a': 0, 'k': [0.105882352941, 0.223529411765, 0.886274509804, 1], 'ix': 3 },
          'o': { 'a': 0, 'k': 100, 'ix': 4 },
          'w': { 'a': 0, 'k': 10, 'ix': 5 },
          'lc': 2,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': 'Contour 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'tr',
          'p': { 'a': 0, 'k': [0, 0], 'ix': 2 },
          'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
          's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
          'r': { 'a': 0, 'k': -314, 'ix': 6 },
          'o': { 'a': 0, 'k': 100, 'ix': 7 },
          'sk': { 'a': 0, 'k': 0, 'ix': 4 },
          'sa': { 'a': 0, 'k': 0, 'ix': 5 },
          'nm': 'Transformer '
        }],
        'nm': 'Forme 8',
        'np': 4,
        'cix': 2,
        'bm': 0,
        'ix': 9,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 35,
      'st': 0,
      'bm': 0
    }]
  }, {
    'id': 'comp_2',
    'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 2,
      'nm': 'herbe1',
      'refId': 'image_0',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 75,
            's': [0]
          }, { 't': 90, 's': [100] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': {
          'a': 1,
          'k': [{
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 75,
            's': [5030.5, 7079, 0],
            'to': [0, -7.833, 0],
            'ti': [0, 7.833, 0]
          }, { 't': 90, 's': [5030.5, 7032, 0] }],
          'ix': 2
        },
        'a': { 'a': 0, 'k': [149.5, 110, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 300,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 2,
      'ty': 2,
      'nm': 'feuillage2',
      'refId': 'image_1',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5226.5, 6844.5, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [-4.5, 92.5, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 100,
            's': [0, 0, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 110,
            's': [110, 110, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 115,
            's': [100.333, 100.333, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 130,
            's': [105, 105, 100]
          }, { 't': 150, 's': [100, 100, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 2,
      'nm': 'feuillage1',
      'refId': 'image_2',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5285, 6708.5, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [174, 223.5, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 100,
            's': [0, 0, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 110,
            's': [110, 110, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 115,
            's': [100, 100, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 130,
            's': [105, 105, 100]
          }, { 't': 145, 's': [100, 100, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 2,
      'nm': 'ombre tronc',
      'refId': 'image_3',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 100,
            's': [0]
          }, { 't': 105, 's': [65.882] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5213, 6746, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [82, 43, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 5,
      'ty': 2,
      'nm': 'lum tronc',
      'refId': 'image_4',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 105,
            's': [0]
          }, {
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 110,
            's': [50.196]
          }, {
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 120,
            's': [25]
          }, { 't': 135, 's': [50.196] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5010, 6952, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [90, 174, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 6
    }, {
      'ddd': 0,
      'ind': 6,
      'ty': 2,
      'nm': 'lum tronc',
      'refId': 'image_5',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 95,
            's': [0]
          }, {
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 100,
            's': [36.863]
          }, {
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 110,
            's': [15]
          }, {
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 120,
            's': [36.863]
          }, {
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 135,
            's': [15]
          }, { 't': 150, 's': [36.863] }],
          'ix': 11
        },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5010, 6952, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [77, 200, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 6
    }, {
      'ddd': 0,
      'ind': 7,
      'ty': 2,
      'nm': 'tronc',
      'refId': 'image_6',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5010, 6953, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [115, 250, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 65,
            's': [0, 0, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 75,
            's': [33.333, 33.333, 100]
          }, { 't': 95, 's': [100, 100, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 8,
      'ty': 2,
      'nm': 'pt branche',
      'refId': 'image_7',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5147.5, 6840, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [15.5, 33, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 90,
            's': [0, 0, 100]
          }, { 't': 100, 's': [100, 100, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 150,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 9,
      'ty': 0,
      'nm': 'pousse',
      'refId': 'comp_3',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [5012, 6958.5, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [4992, 6963.5, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 50,
            's': [100, 100, 100]
          }, {
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 60,
            's': [110, 110, 100]
          }, { 't': 65, 's': [0, 0, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'w': 10635,
      'h': 8745,
      'ip': 0,
      'op': 1525,
      'st': 0,
      'bm': 0
    }]
  }, {
    'id': 'comp_3',
    'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 2,
      'nm': 'lum tronc',
      'refId': 'image_8',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 50.196, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [4989.5, 6926.5, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [17.5, 26.5, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 1525,
      'st': 0,
      'bm': 6
    }, {
      'ddd': 0,
      'ind': 2,
      'ty': 2,
      'nm': 'Calque 3',
      'refId': 'image_9',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [4992.5, 6959.5, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [22.5, 11.5, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 1525,
      'st': 0,
      'bm': 1
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 2,
      'nm': 'tronc',
      'refId': 'image_10',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [4992.5, 6925, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [19.5, 47, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 1525,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 2,
      'nm': 'Calque 5 copie',
      'refId': 'image_11',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 50,
            's': [100]
          }, { 't': 62, 's': [0] }],
          'ix': 11
        },
        'r': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 32,
            's': [0]
          }, {
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 38,
            's': [-14]
          }, { 't': 50, 's': [72] }],
          'ix': 10
        },
        'p': {
          'a': 1,
          'k': [{
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 32,
            's': [4970, 6878, 0],
            'to': [0, 0, 0],
            'ti': [0, 0, 0]
          }, {
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 38,
            's': [4970, 6878, 0],
            'to': [17, 18.333, 0],
            'ti': [5, -49.333, 0]
          }, { 't': 50, 's': [5013, 7003, 0] }],
          'ix': 2
        },
        'a': { 'a': 0, 'k': [0, 40, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 1525,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 5,
      'ty': 2,
      'nm': 'Calque 5',
      'refId': 'image_12',
      'sr': 1,
      'ks': {
        'o': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 50,
            's': [100]
          }, { 't': 62, 's': [0] }],
          'ix': 11
        },
        'r': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833], 'y': [0.833] },
            'o': { 'x': [0.167], 'y': [0.167] },
            't': 25,
            's': [0]
          }, { 'i': { 'x': [0.833], 'y': [0.833] }, 'o': { 'x': [0.167], 'y': [0.167] }, 't': 32, 's': [9] }, {
            't': 44,
            's': [-93]
          }],
          'ix': 10
        },
        'p': {
          'a': 1,
          'k': [{
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 25,
            's': [4973.5, 6874, 0],
            'to': [0, 0, 0],
            'ti': [0, 0, 0]
          }, {
            'i': { 'x': 0.833, 'y': 0.833 },
            'o': { 'x': 0.167, 'y': 0.167 },
            't': 32,
            's': [4973.5, 6874, 0],
            'to': [-7.833, 17.5, 0],
            'ti': [-3.167, -34.5, 0]
          }, { 't': 44, 's': [4957.5, 6981, 0] }],
          'ix': 2
        },
        'a': { 'a': 0, 'k': [30.5, 61, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
      },
      'ao': 0,
      'ip': 0,
      'op': 1525,
      'st': 0,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 6,
      'ty': 2,
      'nm': 'Calque 4',
      'refId': 'image_13',
      'sr': 1,
      'ks': {
        'o': { 'a': 0, 'k': 100, 'ix': 11 },
        'r': { 'a': 0, 'k': 0, 'ix': 10 },
        'p': { 'a': 0, 'k': [4985.5, 6907.5, 0], 'ix': 2 },
        'a': { 'a': 0, 'k': [22.5, 43.5, 0], 'ix': 1 },
        's': {
          'a': 1,
          'k': [{
            'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
            'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
            't': 38,
            's': [100, 100, 100]
          }, { 't': 50, 's': [0, 0, 100] }],
          'ix': 6
        }
      },
      'ao': 0,
      'ip': 0,
      'op': 1525,
      'st': 0,
      'bm': 0
    }]
  }],
  'layers': [{
    'ddd': 0,
    'ind': 1,
    'ty': 4,
    'nm': 'Calque de forme 1',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5317.5, 4372.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [0, 0, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'shapes': [{
      'ty': 'gr',
      'it': [{
        'ty': 'rc',
        'd': 1,
        's': { 'a': 0, 'k': [10635, 8745], 'ix': 2 },
        'p': { 'a': 0, 'k': [0, 0], 'ix': 3 },
        'r': { 'a': 0, 'k': 0, 'ix': 4 },
        'nm': 'Tracé rectangulaire 1',
        'mn': 'ADBE Vector Shape - Rect',
        'hd': false
      }, {
        'ty': 'st',
        'c': { 'a': 0, 'k': [1, 1, 1, 1], 'ix': 3 },
        'o': { 'a': 0, 'k': 5, 'ix': 4 },
        'w': { 'a': 0, 'k': 1, 'ix': 5 },
        'lc': 1,
        'lj': 1,
        'ml': 4,
        'bm': 0,
        'nm': 'Contour 1',
        'mn': 'ADBE Vector Graphic - Stroke',
        'hd': false
      }, {
        'ty': 'fl',
        'c': { 'a': 0, 'k': [1, 1, 1, 1], 'ix': 4 },
        'o': { 'a': 0, 'k': 0, 'ix': 5 },
        'r': 1,
        'bm': 0,
        'nm': 'Fond 1',
        'mn': 'ADBE Vector Graphic - Fill',
        'hd': false
      }, {
        'ty': 'tr',
        'p': { 'a': 0, 'k': [-2.509, -4.051], 'ix': 2 },
        'a': { 'a': 0, 'k': [0, 0], 'ix': 1 },
        's': { 'a': 0, 'k': [100, 100], 'ix': 3 },
        'r': { 'a': 0, 'k': 0, 'ix': 6 },
        'o': { 'a': 0, 'k': 100, 'ix': 7 },
        'sk': { 'a': 0, 'k': 0, 'ix': 4 },
        'sa': { 'a': 0, 'k': 0, 'ix': 5 },
        'nm': 'Transformer '
      }],
      'nm': 'Rectangle 1',
      'np': 3,
      'cix': 2,
      'bm': 0,
      'ix': 1,
      'mn': 'ADBE Vector Group',
      'hd': false
    }],
    'ip': 0,
    'op': 150,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 2,
    'ty': 0,
    'nm': '1',
    'refId': 'comp_0',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5297.5, 4377.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [5317.5, 4372.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'w': 10635,
    'h': 8745,
    'ip': 149,
    'op': 299,
    'st': 149,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 3,
    'ty': 0,
    'nm': 'Composition 2',
    'refId': 'comp_1',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5257.5, 6721.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [600, 600, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'w': 1200,
    'h': 1200,
    'ip': 95,
    'op': 130,
    'st': 95,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 4,
    'ty': 0,
    'nm': '0',
    'refId': 'comp_2',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5297.5, 4377.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [5317.5, 4372.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'w': 10635,
    'h': 8745,
    'ip': 0,
    'op': 150,
    'st': 0,
    'bm': 0
  }],
  'markers': []
}