import React                             from 'react'
import styled                            from '@emotion/native'
import { ChallengeButton, HelperButton } from '@civitime/library/storybook/stories/Button'
import { Notification }                  from '../Challenges/pages/ChallengesHistory'

export const Challenge = ({notification, onPress}) => {
  return (
    <ChallengeButtonWrapper>
      <HomeChallengesButton
        onPress={onPress}
        size={30}
        iconSize={20}
      />
      {notification > 0 && <HomeNotification>{notification}</HomeNotification>}
    </ChallengeButtonWrapper>
  )
}

export const HomeHelper = styled(HelperButton)({
  position: 'fixed',
  bottom: '5%',
  right: '5%',
})

const HomeNotification = styled(Notification)({
  position: 'absolute',
  bottom: '70%',
  left: 15,
  zIndex: 5,
})

const ChallengeButtonWrapper = styled.View({ position: 'relative' })

const HomeChallengesButton = styled(ChallengeButton)({})


