import React from "react"
import {useProfile} from "../Auth/useProfile"

import {ChooseYourTeam} from "./ChooseYourTeam"
import {MyTeam} from "./MyTeam"

export const Team = ({
                       mobile,
                       orientation,
                       height,
                       openHelper,
                       openedHelper,
                       phase
                     }) => {
  const {userState, loading} = useProfile()
  return !loading && userState ? (
    userState.teamId ? (
      <MyTeam teamId={userState.teamId} openedHelper={openedHelper} mobile={mobile} height={height}
              phase={phase}
              orientation={orientation}/>
    ) : (
      <ChooseYourTeam
        phase={phase}
        mobile={mobile}
        orientation={orientation}
        height={height}
        openHelper={openHelper}
      />
    )
  ) : null
}
