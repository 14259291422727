export default {
  'v': '5.7.1',
  'fr': 25,
  'ip': 0,
  'op': 300,
  'w': 10635,
  'h': 8745,
  'nm': '6',
  'ddd': 0,
  'assets': [{ 'id': 'image_0', 'w': 506, 'h': 372, 'u': '', 'p': require('./images/img_0.png'), 'e': 0 }, {
    'id': 'image_1',
    'w': 335,
    'h': 513,
    'u': '',
    'p': require('./images/img_1.png'),
    'e': 0
  }, { 'id': 'image_2', 'w': 648, 'h': 819, 'u': '', 'p': require('./images/img_2.png'), 'e': 0 }, {
    'id': 'image_3',
    'w': 84,
    'h': 229,
    'u': '',
    'p': require('./images/img_3.png'),
    'e': 0
  }, { 'id': 'image_4', 'w': 5886, 'h': 1735, 'u': '', 'p': require('./images/img_4.png'), 'e': 0 }, {
    'id': 'image_5',
    'w': 2090,
    'h': 2372,
    'u': '',
    'p': require('./images/img_5.png'),
    'e': 0
  }, { 'id': 'image_6', 'w': 3725, 'h': 3347, 'u': '', 'p': require('./images/img_6.png'), 'e': 0 }, {
    'id': 'image_7',
    'w': 842,
    'h': 692,
    'u': '',
    'p': require('./images/img_7.png'),
    'e': 0
  }, { 'id': 'image_8', 'w': 922, 'h': 771, 'u': '', 'p': require('./images/img_8.png'), 'e': 0 }, {
    'id': 'image_9',
    'w': 829,
    'h': 673,
    'u': '',
    'p': require('./images/img_9.png'),
    'e': 0
  }, { 'id': 'image_10', 'w': 1182, 'h': 1011, 'u': '', 'p': require('./images/img_10.png'), 'e': 0 }, {
    'id': 'image_11',
    'w': 1793,
    'h': 1549,
    'u': '',
    'p': require('./images/img_11.png'),
    'e': 0
  }, { 'id': 'image_12', 'w': 1644, 'h': 1276, 'u': '', 'p': require('./images/img_12.png'), 'e': 0 }, {
    'id': 'image_13',
    'w': 3538,
    'h': 2772,
    'u': '',
    'p': require('./images/img_13.png'),
    'e': 0
  }, { 'id': 'image_14', 'w': 3170, 'h': 2499, 'u': '', 'p': require('./images/img_14.png'), 'e': 0 }, {
    'id': 'image_15',
    'w': 870,
    'h': 735,
    'u': '',
    'p': require('./images/img_15.png'),
    'e': 0
  }, { 'id': 'image_16', 'w': 1946, 'h': 1481, 'u': '', 'p': require('./images/img_16.png'), 'e': 0 }, {
    'id': 'image_17',
    'w': 2516,
    'h': 2171,
    'u': '',
    'p': require('./images/img_17.png'),
    'e': 0
  }, { 'id': 'image_18', 'w': 3951, 'h': 3391, 'u': '', 'p': require('./images/img_18.png'), 'e': 0 }, {
    'id': 'image_19',
    'w': 2154,
    'h': 1849,
    'u': '',
    'p': require('./images/img_19.png'),
    'e': 0
  }, { 'id': 'image_20', 'w': 3728, 'h': 2131, 'u': '', 'p': require('./images/img_20.png'), 'e': 0 }, {
    'id': 'image_21',
    'w': 2820,
    'h': 3338,
    'u': '',
    'p': require('./images/img_21.png'),
    'e': 0
  }, { 'id': 'image_22', 'w': 6436, 'h': 4798, 'u': '', 'p': require('./images/img_22.png'), 'e': 0 }, {
    'id': 'image_23',
    'w': 1656,
    'h': 948,
    'u': '',
    'p': require('./images/img_23.png'),
    'e': 0
  }, { 'id': 'image_24', 'w': 884, 'h': 374, 'u': '', 'p': require('./images/img_24.png'), 'e': 0 }, {
    'id': 'image_25',
    'w': 1192,
    'h': 627,
    'u': '',
    'p': require('./images/img_25.png'),
    'e': 0
  }, { 'id': 'image_26', 'w': 915, 'h': 787, 'u': '', 'p': require('./images/img_26.png'), 'e': 0 }, {
    'id': 'image_27',
    'w': 1434,
    'h': 2392,
    'u': '',
    'p': require('./images/img_27.png'),
    'e': 0
  }, { 'id': 'image_28', 'w': 2540, 'h': 4095, 'u': '', 'p': require('./images/img_28.png'), 'e': 0 }, {
    'id': 'image_29',
    'w': 3476,
    'h': 2812,
    'u': '',
    'p': require('./images/img_29.png'),
    'e': 0
  }, { 'id': 'image_30', 'w': 3677, 'h': 4798, 'u': '', 'p': require('./images/img_30.png'), 'e': 0 }, {
    'id': 'image_31',
    'w': 3697,
    'h': 4798,
    'u': '',
    'p': require('./images/img_31.png'),
    'e': 0
  }, { 'id': 'image_32', 'w': 1236, 'h': 1397, 'u': '', 'p': require('./images/img_32.png'), 'e': 0 }, {
    'id': 'image_33',
    'w': 1722,
    'h': 1971,
    'u': '',
    'p': require('./images/img_33.png'),
    'e': 0
  }, { 'id': 'image_34', 'w': 1436, 'h': 2284, 'u': '', 'p': require('./images/img_34.png'), 'e': 0 }, {
    'id': 'image_35',
    'w': 2127,
    'h': 2625,
    'u': '',
    'p': require('./images/img_35.png'),
    'e': 0
  }, { 'id': 'image_36', 'w': 1161, 'h': 1334, 'u': '', 'p': require('./images/img_36.png'), 'e': 0 }, {
    'id': 'image_37',
    'w': 1281,
    'h': 1702,
    'u': '',
    'p': require('./images/img_37.png'),
    'e': 0
  }, { 'id': 'image_38', 'w': 1281, 'h': 1702, 'u': '', 'p': require('./images/img_38.png'), 'e': 0 }, {
    'id': 'image_39',
    'w': 344,
    'h': 439,
    'u': '',
    'p': require('./images/img_39.png'),
    'e': 0
  }, { 'id': 'image_40', 'w': 1104, 'h': 630, 'u': '', 'p': require('./images/img_40.png'), 'e': 0 }, {
    'id': 'image_41',
    'w': 1790,
    'h': 1438,
    'u': '',
    'p': require('./images/img_41.png'),
    'e': 0
  }, { 'id': 'image_42', 'w': 447, 'h': 553, 'u': '', 'p': require('./images/img_42.png'), 'e': 0 }, {
    'id': 'image_43',
    'w': 2254,
    'h': 1568,
    'u': '',
    'p': require('./images/img_43.png'),
    'e': 0
  }, { 'id': 'image_44', 'w': 298, 'h': 292, 'u': '', 'p': require('./images/img_44.png'), 'e': 0 }, {
    'id': 'image_45',
    'w': 404,
    'h': 444,
    'u': '',
    'p': require('./images/img_45.png'),
    'e': 0
  }, { 'id': 'image_46', 'w': 418, 'h': 310, 'u': '', 'p': require('./images/img_46.png'), 'e': 0 }, {
    'id': 'image_47',
    'w': 915,
    'h': 485,
    'u': '',
    'p': require('./images/img_47.png'),
    'e': 0
  }, { 'id': 'image_48', 'w': 1238, 'h': 519, 'u': '', 'p': require('./images/img_48.png'), 'e': 0 }, {
    'id': 'image_49',
    'w': 963,
    'h': 420,
    'u': '',
    'p': require('./images/img_49.png'),
    'e': 0
  }, { 'id': 'image_50', 'w': 1419, 'h': 600, 'u': '', 'p': require('./images/img_50.png'), 'e': 0 }, {
    'id': 'image_51',
    'w': 461,
    'h': 128,
    'u': '',
    'p': require('./images/img_51.png'),
    'e': 0
  }, { 'id': 'image_52', 'w': 2358, 'h': 1542, 'u': '', 'p': require('./images/img_52.png'), 'e': 0 }, {
    'id': 'image_53',
    'w': 884,
    'h': 480,
    'u': '',
    'p': require('./images/img_53.png'),
    'e': 0
  }, { 'id': 'image_54', 'w': 1105, 'h': 760, 'u': '', 'p': require('./images/img_54.png'), 'e': 0 }, {
    'id': 'image_55',
    'w': 1493,
    'h': 1380,
    'u': '',
    'p': require('./images/img_55.png'),
    'e': 0
  }, { 'id': 'image_56', 'w': 1941, 'h': 1699, 'u': '', 'p': require('./images/img_56.png'), 'e': 0 }, {
    'id': 'image_57',
    'w': 1614,
    'h': 1016,
    'u': '',
    'p': require('./images/img_57.png'),
    'e': 0
  }, { 'id': 'image_58', 'w': 1700, 'h': 1512, 'u': '', 'p': require('./images/img_58.png'), 'e': 0 }, {
    'id': 'image_59',
    'w': 2027,
    'h': 1950,
    'u': '',
    'p': require('./images/img_59.png'),
    'e': 0
  }, { 'id': 'image_60', 'w': 529, 'h': 422, 'u': '', 'p': require('./images/img_60.png'), 'e': 0 }, {
    'id': 'image_61',
    'w': 668,
    'h': 774,
    'u': '',
    'p': require('./images/img_61.png'),
    'e': 0
  }, { 'id': 'image_62', 'w': 649, 'h': 642, 'u': '', 'p': require('./images/img_62.png'), 'e': 0 }, {
    'id': 'image_63',
    'w': 1136,
    'h': 737,
    'u': '',
    'p': require('./images/img_63.png'),
    'e': 0
  }],
  'layers': [{
    'ddd': 0,
    'ind': 1,
    'ty': 2,
    'nm': 'herbedevant6',
    'refId': 'image_0',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 50,
          's': [0]
        }, { 't': 75, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5695, 7944.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [260.638, 345.023, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 2,
    'ty': 2,
    'nm': 'racine devant lum',
    'refId': 'image_1',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 70,
          's': [0]
        }, { 't': 75, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5568.5, 7361.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [167.5, 256.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 70,
    'op': 300,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 3,
    'ty': 2,
    'nm': 'racine devant',
    'refId': 'image_2',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 50,
          's': [0]
        }, { 't': 60, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5507, 7222.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [216, 137.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 50,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 4,
    'ty': 2,
    'nm': 'pt branche racine',
    'refId': 'image_3',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [7482, 6278.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [30, 22.5, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [0, 0, 100]
        }, { 't': 200, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 5,
    'ty': 2,
    'nm': 'herbe6',
    'refId': 'image_4',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 25,
          's': [0]
        }, { 't': 50, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5177, 7943, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [2927, 1702.208, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 6,
    'ty': 2,
    'nm': 'lum tronc',
    'refId': 'image_5',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 30,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 40,
          's': [50.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 75,
          's': [25]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 125,
          's': [50.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 160,
          's': [25]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 200,
          's': [50.196]
        }, { 't': 235, 's': [25] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4896, 6123, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1045, 1186, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 7,
    'ty': 2,
    'nm': 'lum tronc',
    'refId': 'image_6',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 105,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 120,
          's': [70.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 140,
          's': [35]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 160,
          's': [70.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 180,
          's': [35]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 200,
          's': [70.196]
        }, { 't': 220, 's': [35] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5545.5, 5960.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1862.5, 1673.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 8,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_7',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [8666, 4107, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [421, 346, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 110,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 120,
          's': [110, 110, 100]
        }, { 't': 125, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 9,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_8',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [7440, 4792, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [348, 502, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 115,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [110, 110, 100]
        }, { 't': 130, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 110,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 10,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_9',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [3488, 5800, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [484, 512, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 90,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [110, 110, 100]
        }, { 't': 105, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0, 'ind': 11, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_10', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [3824, 5496, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [443, 588, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 110,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 115,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 142,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 168,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 195,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 219,
          's': [100, 100, 100]
        }, { 't': 246, 's': [105, 105, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 0, 'op': 300, 'st': 0, 'bm': 0
  }, {
    'ddd': 0, 'ind': 12, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_11', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5781.5, 4356.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [896.5, 774.5, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 85,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 95,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 150,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 200,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 225,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 250,
          's': [100, 100, 100]
        }, { 't': 275, 's': [105, 105, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 0, 'op': 300, 'st': 0, 'bm': 0
  }, {
    'ddd': 0,
    'ind': 13,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_12',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6903, 3553, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [822, 638, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 65,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [110, 110, 100]
        }, { 't': 80, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0, 'ind': 14, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_13', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [7912, 3632, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1501, 1951, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 115,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 130,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 150,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 200,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 225,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 250,
          's': [105, 105, 100]
        }, { 't': 275, 's': [100, 100, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 115, 'op': 300, 'st': 0, 'bm': 0
  }, {
    'ddd': 0, 'ind': 15, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_14', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4784, 3704, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1760, 1439, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 45,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 55,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 60,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 150,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 200,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 225,
          's': [105, 105, 100]
        }, { 't': 250, 's': [100, 100, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 0, 'op': 300, 'st': 0, 'bm': 0
  }, {
    'ddd': 0,
    'ind': 16,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_15',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [2359, 3323.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [435, 367.5, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 50,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 57,
          's': [110, 110, 100]
        }, { 't': 60, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 17,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_16',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [2472, 2912, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1786, 736, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 50,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 60,
          's': [110, 110, 100]
        }, { 't': 65, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0, 'ind': 18, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_17', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [2829, 3212.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1058, 1805.5, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 65,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 80,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 150,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 200,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 225,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 250,
          's': [105, 105, 100]
        }, { 't': 275, 's': [100, 100, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 0, 'op': 300, 'st': 0, 'bm': 0
  }, {
    'ddd': 0, 'ind': 19, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_18', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5949.5, 3344, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [2007.5, 2573, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 84,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 94,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 99,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 150,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 176,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 200,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 226,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 250,
          's': [100, 100, 100]
        }, { 't': 276, 's': [105, 105, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 0, 'op': 300, 'st': 0, 'bm': 0
  }, {
    'ddd': 0,
    'ind': 20,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_19',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [3794, 1806.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1077, 924.5, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 90,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [110, 110, 100]
        }, { 't': 105, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 21,
    'ty': 2,
    'nm': 'ombre',
    'refId': 'image_20',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 100,
          's': [0]
        }, { 't': 110, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4374, 4083.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1864, 1065.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 22,
    'ty': 2,
    'nm': 'ombre tronc',
    'refId': 'image_21',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 35,
          's': [0]
        }, { 't': 45, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5264, 5716, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1410, 1669, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 23,
    'ty': 2,
    'nm': 'teinte',
    'refId': 'image_22',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 120,
          's': [0]
        }, { 't': 125, 's': [52.941] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5630, 5279, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [3218, 2399, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 14
  }, {
    'ddd': 0,
    'ind': 24,
    'ty': 2,
    'nm': 'racine devant',
    'refId': 'image_23',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 100,
          's': [0]
        }, { 't': 112, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [3754, 7129, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1452, 202, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 100,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 25,
    'ty': 2,
    'nm': 'pt branche devant gauche ombre',
    'refId': 'image_24',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 100,
          's': [0]
        }, { 't': 105, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [3861, 5750, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [442, 187, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 26,
    'ty': 2,
    'nm': 'pt branche devant gauche',
    'refId': 'image_25',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4536, 6024, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1117, 513, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [0, 0, 100]
        }, { 't': 90, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 27,
    'ty': 2,
    'nm': 'pt branche devant gauche',
    'refId': 'image_26',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4536, 6024, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [768, 673, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [0, 0, 100]
        }, { 't': 90, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 28,
    'ty': 2,
    'nm': 'tronc milieu',
    'refId': 'image_27',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 34.902, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1152, 1816, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 29,
    'ty': 2,
    'nm': 'tronc milieu',
    'refId': 'image_28',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 36.863, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1940, 3356, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 30,
    'ty': 2,
    'nm': 'ombre tronc',
    'refId': 'image_29',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1747, 1998, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 31,
    'ty': 2,
    'nm': 'tronc milieu',
    'refId': 'image_30',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [2928, 3984, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 32,
    'ty': 2,
    'nm': 'tronc milieu',
    'refId': 'image_31',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [2948, 3984, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 33,
    'ty': 2,
    'nm': 'tronc cote droit',
    'refId': 'image_32',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 34.902, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [-433, 1677, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 34,
    'ty': 2,
    'nm': 'tronc cote droit',
    'refId': 'image_33',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 40, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [60, 2040, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 35,
    'ty': 2,
    'nm': 'tronc cote droit',
    'refId': 'image_34',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [-293, 2238, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 36,
    'ty': 2,
    'nm': 'tronc cote droit',
    'refId': 'image_35',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [398, 2579, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 37,
    'ty': 2,
    'nm': 'tronc cote gauche',
    'refId': 'image_36',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 34.902, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1721, 1316, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 38,
    'ty': 2,
    'nm': 'tronc cote gauche',
    'refId': 'image_37',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1747, 1331, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 39,
    'ty': 2,
    'nm': 'tronc cote gauche',
    'refId': 'image_38',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5360, 6864, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1747, 1331, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 35, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 40,
    'ty': 2,
    'nm': 'pt tronc cote gauche',
    'refId': 'image_39',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [3801, 3647.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [188, 419.5, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 40,
          's': [0, 0, 100]
        }, { 't': 45, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 41,
    'ty': 2,
    'nm': 'branche milieu lum',
    'refId': 'image_40',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 60,
          's': [0]
        }, { 't': 70, 's': [61.176] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6302, 3798, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [552, 315, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 42,
    'ty': 2,
    'nm': 'branche milieu ombre',
    'refId': 'image_41',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 65,
          's': [0]
        }, { 't': 75, 's': [63.922] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6261, 3912, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [895, 719, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 43,
    'ty': 2,
    'nm': 'branche milieu ombre',
    'refId': 'image_42',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 50,
          's': [0]
        }, { 't': 60, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5177.5, 4110.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [223.5, 276.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 44,
    'ty': 2,
    'nm': 'branche milieu',
    'refId': 'image_43',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5744, 4504, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [790, 1313, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 50,
          's': [0, 0, 100]
        }, { 't': 60, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 45,
    'ty': 2,
    'nm': 'pt branche milieu ombre',
    'refId': 'image_44',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 65,
          's': [0]
        }, { 't': 70, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6607, 3699, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [13, 410, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 46,
    'ty': 2,
    'nm': 'pt branche milieu',
    'refId': 'image_45',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6594, 3687, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [106, 398, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 60,
          's': [0, 0, 100]
        }, { 't': 65, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 47,
    'ty': 2,
    'nm': 'herbederriere6',
    'refId': 'image_46',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 125,
          's': [0]
        }, { 't': 150, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': {
        'a': 1,
        'k': [{
          'i': { 'x': 0.833, 'y': 0.833 },
          'o': { 'x': 0.167, 'y': 0.167 },
          't': 125,
          's': [4119, 6180.5, 0],
          'to': [0, -4.167, 0],
          'ti': [0, 4.167, 0]
        }, { 't': 150, 's': [4119, 6155.5, 0] }],
        'ix': 2
      },
      'a': { 'a': 0, 'k': [209, 155, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 48,
    'ty': 2,
    'nm': 'racine derriere',
    'refId': 'image_47',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 125,
          's': [0]
        }, { 't': 150, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4480.5, 6169.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [881.5, 410.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 124,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 49,
    'ty': 2,
    'nm': 'racine droite ombre',
    'refId': 'image_48',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 70,
          's': [0]
        }, { 't': 95, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6948, 6580, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [111, 338, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 70,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 50,
    'ty': 2,
    'nm': 'racine droite decor',
    'refId': 'image_49',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 70,
          's': [0]
        }, { 't': 95, 's': [32.941] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6948, 6580, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1, 419, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 70,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 51,
    'ty': 2,
    'nm': 'racine droite',
    'refId': 'image_50',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 70,
          's': [0]
        }, { 't': 95, 's': [100] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6947, 6581, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [291, 420, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 70,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 52,
    'ty': 2,
    'nm': 'pt branche droite ombre 2',
    'refId': 'image_51',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 115,
          's': [0]
        }, { 't': 118, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [7240.5, 4830, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [230.5, 64, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 115,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 53,
    'ty': 2,
    'nm': 'pt branche droite',
    'refId': 'image_52',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6483, 4989, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [3, 1483, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 110,
          's': [0, 0, 100]
        }, { 't': 115, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 54,
    'ty': 2,
    'nm': 'pt branche droite ombre 3',
    'refId': 'image_53',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 120,
          's': [0]
        }, { 't': 125, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [7816, 4120, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [-3, 406, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 120,
    'op': 305,
    'st': 5,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 55,
    'ty': 2,
    'nm': 'pt branche droite',
    'refId': 'image_54',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [7816, 4120, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [73, 614, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 110,
          's': [0, 0, 100]
        }, { 't': 120, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 56,
    'ty': 2,
    'nm': 'branche droite decor',
    'refId': 'image_55',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6160, 5312, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [-188, 1227, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 95,
          's': [0, 0, 100]
        }, { 't': 110, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 57,
    'ty': 2,
    'nm': 'branche droite ombre',
    'refId': 'image_56',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 115,
          's': [0]
        }, { 't': 125, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6160, 5312, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [202, 1470, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 58,
    'ty': 2,
    'nm': 'branche droite decor',
    'refId': 'image_57',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 115,
          's': [0]
        }, { 't': 130, 's': [40] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6160, 5312, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [159, 1109, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 59,
    'ty': 2,
    'nm': 'branche droite decor',
    'refId': 'image_58',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 40, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6160, 5312, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [2, 1505, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 95,
          's': [0, 0, 100]
        }, { 't': 110, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 60,
    'ty': 2,
    'nm': 'branche droite',
    'refId': 'image_59',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6160, 5312, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [202, 1721, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 95,
          's': [0, 0, 100]
        }, { 't': 110, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 61,
    'ty': 2,
    'nm': 'ombre',
    'refId': 'image_60',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 120,
          's': [0]
        }, { 't': 125, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [7218.5, 4059, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [264.5, 211, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 62,
    'ty': 2,
    'nm': 'pt branche droite',
    'refId': 'image_61',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [7199, 4336, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [270, 731, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 115,
          's': [0, 0, 100]
        }, { 't': 120, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 63,
    'ty': 2,
    'nm': 'branche arriere ombre',
    'refId': 'image_62',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 45,
          's': [0]
        }, { 't': 50, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4809.5, 4043, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [324.5, 321, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 64,
    'ty': 2,
    'nm': 'branche arriere',
    'refId': 'image_63',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5062, 4504, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [616, 835, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 35,
          's': [0, 0, 100]
        }, { 't': 45, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }],
  'markers': []
}