import React, {useState}   from "react"
import {Route, Switch}     from "react-router-native"
import {NewTeam}           from "../../../../Teams/NewTeam"
import {Team}              from "../../../../Teams/Team"
import {TeamSearch}        from "../../../../Teams/TeamSearch"
import {TeamInfos}         from "../../../../Teams/TeamInfos"
import {ModulesManagement} from "../Modules/ModulesManagement"
import {ChallengesHistory} from "../../../Phase1/Components/Challenges/pages/ChallengesHistory"
import { QRModal }         from '../QRCode/pages/QRModal'

export const Phase2Router = ({basePath, serveDimensions, setPhaseProps, phaseProps, phase}) => {

  const [displayResult, setDisplayResult] = useState(null)
  return <Switch>
    <Route path={`${basePath}/qrcode/:buildingType/:moduleStep/:moduleType/:moduleTheme`}>
      <QRModal phase={phase} linkModal={phaseProps?.linkModal} mobile={serveDimensions?.mobile}/>
    </Route>
    <Route path={`${basePath}/team/new`}>
      <NewTeam
        height={serveDimensions?.height}
        mobile={serveDimensions?.mobile}
        phase={phase}
      />
    </Route>
    <Route path={`${basePath}/team/search`}>
      <TeamSearch phase={phase}/>
    </Route>
    <Route path={`${basePath}/team/:teamId`}>
      <TeamInfos phase={phase}/>
    </Route>
    <Route path={`${basePath}/team`}>
      <Team
        height={serveDimensions?.height}
        mobile={serveDimensions?.mobile}
        orientation={serveDimensions?.orientation}
        phase={phase}
      />
    </Route>
    <Route path={`${basePath}/module/:buildingType/:moduleStep/:moduleType/:moduleTheme/:moduleId/:source`}>
      <ModulesManagement linkModal={phaseProps?.linkModal} phase={phase} updateHeaderDisplayed={phaseProps?.updateHeaderDisplayed}/>
    </Route>
    <Route
      path={`${basePath}/history`}
      component={() => (
        <ChallengesHistory
          displayedResult={displayResult}
          displayResult={(v) => setDisplayResult(v)}
          height={serveDimensions?.height}
          mobile={serveDimensions?.mobile}
          orientation={serveDimensions?.orientation}
          phase={phase}
        />
      )}
    />
  </Switch>
}
