import React from "react"

import { useProfile } from "../Auth/useProfile"

import { AvatarHomeSelection } from "./AvatarHomeSelection"
import { AvatarProfile } from "./AvatarProfile"

export const Avatar = ({phase, hub, ...props}) => {
  const { publicProfile } = useProfile()
  return publicProfile ? (
    publicProfile?.avatar ? (
      <AvatarProfile phase={phase} hub={hub}/>
    ) : (
      <AvatarHomeSelection openHelper={props?.openHelper} hub={hub}/>
    )
  ) : null
}
