import React, { useEffect, useRef, useState } from 'react'
import { Animated }                           from 'react-native'
import styled                                 from '@emotion/native'
import { colors }                             from '@civitime/library/storybook/configs/colors'
import { MediumText }                         from '@civitime/library/storybook/stories/Texts'
import { useScreenDimensions }                from '@civitime/library/storybook/hooks/useScreenDimensions'
import LottieView                             from 'lottie-react-native'
import { useProfile }                         from '../../../../Auth/useProfile'
import { TextButton }                         from '@civitime/library/storybook/stories/Button'
import { getBrowserInfos }                    from '../../../../Utils/browserDetector'

export const EvolutionModal = ({ infos }) => {
  const { mobile } = useScreenDimensions()
  const { emitCommand } = useProfile()
  const scaleAnimation = useRef(new Animated.Value(0)).current
  const launchScaleAnimation = () => {
    Animated.timing(scaleAnimation, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true
    }).start()
  }
  
  const buildingManager = {
    merveille: {
      ref: useRef(null),
      animations: {
        2: require('../../Map/Assets/animations/merveille/modal/niv2/data.js'),
        3: require('../../Map/Assets/animations/merveille/modal/niv3/data.js'),
        4: require('../../Map/Assets/animations/merveille/modal/niv4/data.js'),
        5: require('../../Map/Assets/animations/merveille/modal/niv5/data.js'),
        6: require('../../Map/Assets/animations/merveille/modal/niv6/data.js'),
        7: require('../../Map/Assets/animations/merveille/modal/niv7/data.js'),
      },
    },
  }
  
  const [device, setDevice] = useState(null)
  
  useEffect(() => {
    const detectDevice = getBrowserInfos() || {}
    const launchDetector = (os) => detectDevice.os.toLowerCase().includes(os)
    if (launchDetector('android')) {
      setDevice('android')
    } else if (launchDetector('ios')) {
      setDevice('ios')
      launchScaleAnimation()
    } else if (launchDetector('mac')) {
      setDevice('mac')
    } else {
      setDevice('else')
    }
  }, [])

  useEffect(() => {
    if (!buildingManager?.[infos?.type]?.ref.current) return
    buildingManager?.[infos?.type]?.ref.current.play()
    emitCommand({
      type: 'Phase2UpdateBuildingSeen',
      payload: {
        step: infos?.step,
        buildingType: infos?.type,
      }
    })
  }, [buildingManager?.[infos?.type]?.ref?.current])
  
  
  return <WrapperEvolutionModal>
    <ContainerEvolutionModal mobile={mobile}>
      <ModalText>{infos?.title}</ModalText>
      <WrapperModalAsset mobile={mobile} resizeMode={infos?.buildingType}>
        {device && device !== 'ios' && <ScaleWrapper scale={scaleConfig?.[infos.step]}>
          <BuildingAnimation
            source={buildingManager?.[infos?.type]?.animations?.[infos?.step]?.default}
            width={'100%'}
            height={'100%'}
            ref={buildingManager?.[infos?.type]?.ref}
          />
        </ScaleWrapper>}
        {
          device && device === 'ios' && infos?.type === 'merveille' && infos?.asset &&
          <ScaleImage scaleAnimation={scaleAnimation}>
            <Asset source={infos?.asset}/>
          </ScaleImage>
        }
      </WrapperModalAsset>
      <ModalText>{infos?.text}</ModalText>
      <WrapperButtons mobile={mobile}>
        <WrapperButton>
          <TextButton onPress={infos?.homeAction} color={colors.secondary} backgroundColor={colors.white}>
            Retour eva Home
          </TextButton>
        </WrapperButton>
      </WrapperButtons>
    </ContainerEvolutionModal>
  </WrapperEvolutionModal>
}

const BuildingAnimation = styled(LottieView)({})

const WrapperEvolutionModal = styled.View({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: colors.overlayShadow,
  justifyContent: 'center',
  alignItems: 'center'
})

const ScaleImage = styled(Animated.View)(({ scaleAnimation }) => ({
  transform: [{ scale: scaleAnimation }],
  height: "100%",
}))

const ContainerEvolutionModal = styled.View(({ mobile }) => ({
  alignItems: 'center',
  paddingRight: mobile ? 15 : 0,
  paddingLeft: mobile ? 15 : 0,
}))

const ModalAsset = styled.Image({
  width: 180,
  height: 50,
})

const Asset = styled.Image({
  width: '100%',
  height: '100%',
})

const WrapperModalAsset = styled.View(({ mobile, resizeMode }) => ({
  width: mobile ? (resizeMode ? 290 : 140) : (resizeMode ? 480 : 180),
  height: mobile ? (resizeMode ? 180 : 230) : 300,
  marginBottom: 30,
  overflow: 'hidden',
  position: 'relative'
}))

const ScaleWrapper = styled.View(({ scale }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: scale
}))

const ModalText = styled(MediumText)({
  color: colors.white,
  fontSize: 18,
  lineHeight: 30,
  marginBottom: 30
})

const WrapperButtons = styled.View(({ mobile }) => ({
  display: 'flex',
  flexDirection: mobile ? 'column-reverse' : 'row',
  justifyContent: 'space-around',
  width: '80%',
  height: 'fit-content'
}))

const WrapperButton = styled.View({
  margin: 'auto'
})

const scaleConfig = {
  2: [{ translateX: 0 }, { translateY: '-409%' }, { scale: 14 }],
  3: [{ translateX: 0 }, { translateY: '-409%' }, { scale: 14 }],
  4: [{ translateX: '-20%' }, { translateY: '-339%' }, { scale: 9 }],
  5: [{ translateX: '-20%' }, { translateY: '-165%' }, { scale: 7 }],
  6: [{ translateX: '-50%' }, { translateY: '-50%' }, { scale: 3 }],
  7: [{ translateX: '-50%' }, { translateY: '-50%' }, { scale: 2 }],
}