import React, {useRef, useState, useEffect} from "react"
import {Animated} from "react-native"
import styled from "@emotion/native"
import {T, useTranslator} from "../../../../../translations/translate"

import { env }           from '../../../../../configs/env'
import { clientId, PWA } from '../../../../../../clientId'
import { useProfile }    from '../../../../../Auth/useProfile'
import { useCampaign }           from '../../../../../GlobalContexts'
import { Cards }                 from '../components/Cards'
import { ChallengeLaunch }       from './ChallengeLaunch'
import { MobileChallengeLaunch } from './MobileChallengeLaunch'

import {colors} from '@civitime/library/storybook/configs/colors'
import {BoldText} from '@civitime/library/storybook/stories/Texts'
import {DefaultModal} from '@civitime/library/storybook/stories/Modals'
import {ReloadButton} from '@civitime/library/storybook/stories/Button'
import {MOBILE_HEADER_SIZE} from '@civitime/library/storybook/configs/sizes'
import {useScreenDimensions} from '@civitime/library/storybook/hooks/useScreenDimensions'
import {useLocation} from 'react-router'

const getRandom = (arr, n) => {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len)

  if (n > len)
    throw new RangeError('getRandom: more elements taken than available')

  while (n--) {
    var x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

export const Challenges = ({match, enterModule, phase, updateHeaderDisplayed}) => {
  const root = useRef(null)
  const module = match?.params?.module
  const location = useLocation()
  // const theme = location?.state
  const level = match?.params?.level
  const theme = match?.params?.theme
  const {width, height, mobile, orientation} = useScreenDimensions(root)
  const {t} = useTranslator()
  const {token, emitCommand} = useProfile()
  const {campaignNavigate, campaignId, campaignEnded} = useCampaign()
  const chooseOpacity = new Animated.Value(1)
  const [mixPlayer, setMixPlayer] = useState(false)
  const [players, setPlayers] = useState(null)
  const [randomPlayers, setRandomPlayers] = useState([false, false, false])
  const [chosenPlayer, setChosenPlayer] = useState(false)
  const [loadingOpponent, setLoadingOpponent] = useState(false)
  useEffect(() => {
    updateHeaderDisplayed(false)
    if (players && players.length > 0) return
    if (!loadingOpponent) {
      setLoadingOpponent(true)
      getRandomOpponents()
    }
  }, [clientId, campaignId])

  const getRandomOpponents = () => {
    if (PWA) {
      emitCommand({
        type: "GetBotsToChallenge",
        payload: {
          theme: theme,
          module: module,
          level: level
        }
      })
        .then(r => {
          setPlayers(r.filter(v => v.type === "BotsToChallengeGet")?.[0]?.payload?.bots)
          setLoadingOpponent(false)
        })
    } else {
      fetch(
        env.GAME_SERVER_URI + `/get-random-opponents/${clientId}/${campaignId}/${theme}/${module}/${level}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
        }
      ).then((r) => {
          return r.json().then((value) => {
            setPlayers(value.players)
            setLoadingOpponent(false)
          })
        }
      )
    }
  
  }

  useEffect(() => {
    if (!players || (randomPlayers && randomPlayers[0]) || mixPlayer) return
    if (campaignEnded) {
      const botPlayers = players.filter((player) => player.id.startsWith('bot--'))
      setRandomPlayers(botPlayers)
    } else {
      if (!players) setRandomPlayers([false, false, false])
      const randomPlayers = getRandom(players, 3)
      setRandomPlayers(randomPlayers)
    }
  }, [randomPlayers, players, mixPlayer])

  useEffect(() => {
    if (!chosenPlayer) return
    Animated.spring(chooseOpacity, {
      toValue: 0,
    }).start()
  }, [chosenPlayer])

  const challengePlayer = async (playerChallenged) => {
    setChosenPlayer(playerChallenged)
  }

  const launchModuleAfterAnimation = () => {
    (() => {
      if (phase === 1 || phase === 1.3) {
        campaignNavigate(`challenges/${module}/${level}/${chosenPlayer.id}`, chosenPlayer)
        enterModule()
      } else if (phase === 2) {
        enterModule(chosenPlayer.id?.startsWith("bot--") ?
          {...chosenPlayer, bot: true}
          : {id: chosenPlayer.id})
      }
    })()
  }
  return (
    <>
      <Animated.View ref={root} style={{opacity: chooseOpacity}}>
        <DefaultModal
          backgroundColor={colors.lightBlue}
          color={colors.text}
          closeButtonColor={colors.secondary}
          title={t('app.challenges.randomOpponent')}
          onPress={() => campaignNavigate('')}
          maxHeight={
            mobile && orientation === 'landscape'
              ? height * 0.9
              : (height - MOBILE_HEADER_SIZE) * 0.99
          }
          withShadow
        >
          <WrapperChooseOpponentContent>
            <Cards
              players={randomPlayers}
              challengePlayer={(playerChallenged) => challengePlayer(playerChallenged)}
              {...{mobile, orientation}}
            />
            <WrapperMix mobile={mobile}>
              <MixButton
                size={mobile ? 20 : 30}
                color={colors.defaultBackground}
                iconColor={colors.secondary}
                disabled={mixPlayer || !players}
                onPress={() => {
                  setMixPlayer(true)
                  setRandomPlayers([false, false, false])
                  setTimeout(() => {
                    if (campaignEnded) {
                      const botPlayers = players.filter((player) => player.id.startsWith('bot--'))
                      setRandomPlayers(botPlayers)
                    } else {
                      setRandomPlayers(getRandom(players, 3))
                    }
                    setMixPlayer(false)
                  }, 700)
                }}
              />
              <BoldText>
                <T path="app.challenges.mix"/>
              </BoldText>
            </WrapperMix>
          </WrapperChooseOpponentContent>
        </DefaultModal>
      </Animated.View>
      {chosenPlayer &&
      (mobile && orientation === 'portrait' ? (
        <MobileChallengeLaunch
          launchModuleAfterAnimation={launchModuleAfterAnimation}
          phase={phase}
          player={chosenPlayer}
          {...{mobile, orientation}}
        />
      ) : (
        <ChallengeLaunch
          launchModuleAfterAnimation={launchModuleAfterAnimation}
          phase={phase}
          player={chosenPlayer}
          {...{width, height, mobile, orientation}}
        />
      ))}
    </>
  )
}

export const WrapperChooseOpponentContent = styled.View(({mobile}) => ({
  height: mobile ? 'unset' : 350,
}))

export const WrapperMix = styled.View(({mobile}) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: mobile ? 8 : 20,
  marginLeft: 60,
  marginTop: mobile ? 30 : 0,
}))

export const MixButton = styled(ReloadButton)({
  marginRight: 7,
})
