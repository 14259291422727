import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation }                                 from 'react-router'
import styled                                          from '@emotion/native'
import { useCampaign }                                 from '../GlobalContexts'
import { useProfile }                                  from '../Auth/useProfile'
import { colors }                                      from '@civitime/library/storybook/configs/colors'
import { useScreenDimensions }                         from '@civitime/library/storybook/hooks/useScreenDimensions'
import { brand as isMobileApp }                        from 'expo-device'
import { MobileHeader }                                from './MobileHeader'
import { Header }                                      from './Header'
import { Phase2AppSkeleton }                           from '../Phases/Phase 2/Components/AppSkeleton/Phase2AppSkeleton'

const appSkeletonElements = {
  2: Phase2AppSkeleton
}

const sendPostMessages = (userSession, technicalProfile, campaignSettings) => {
  const session = {...userSession}
  delete session?.challenges
  delete session?.id
  delete session?.loaded
  const modulesDone = Object.values(session || {}).reduce((acc, v) => {
    return acc + Object.keys(v.levelsDone || {}).length
  }, 0)
  const campaignThemes = campaignSettings?.themes
  const maxModules = Object.values(campaignThemes || {}).reduce((acc, v) => {
    return acc + Object.keys(v.levels || {})?.length
  }, 0)
  if (modulesDone > 0) {
    window.parent.postMessage(JSON.stringify({
      type: 'modulesAchieved',
      currentModules: modulesDone,
      totalModules: 20,
      success: modulesDone === maxModules
    }), '*')
  }

}

export const AppSkeleton = ({
                              children,
                              serveDimensions,
                              openLangSelector,
                              phase,
                              updatePhaseProps,
                              toggleHubMenu,
                              isAdmin,
                              isOffline,
                              ...props
                            }) => {
  const root = useRef(null)
  const {campaignNotStarted} = useCampaign()
  const {mobile, orientation, height, width, mobileTablet} = useScreenDimensions(root)
  const {publicProfile, userState, notifications, userSession, technicalProfile} = useProfile()
  const location = useLocation()
  const [headerDisplayed, setHeaderDisplayed] = useState(!phase)
  const {campaignSettings, campaignId} = useCampaign()
  const [phaseProps, setPhaseProps] = useState({})

  useEffect(() => {
    updatePhaseProps(phaseProps)
  }, [phaseProps])

  const AppSkeletonRender = useMemo(() => {
    if (!phase) return null
    return appSkeletonElements[phase]
  }, [phase])

  useEffect(() => {
    if (phase !== 1 || phase !== 1.3) {
      setHeaderDisplayed(true)
    }
  }, [phase])

  useEffect(() => {
    serveDimensions({
      mobile: mobile,
      orientation: orientation,
      height: height,
      width: width,
      mobileTablet: mobileTablet
    })
  }, [mobile, orientation, height])

  useEffect(() => {
    sendPostMessages(userSession, technicalProfile, campaignSettings)
  }, [userSession])

  const returnToMap = location.pathname.match('/leaderboard')
  const moduleLaunched = location.pathname.match('/module')
  const globalModalOpened = location.pathname.match('/settings') || location.pathname.match('/qrcode')|| location.pathname.match('/avatar') || (location.pathname.match('/team') && !location.pathname.match('leaderboard/team')) || location.pathname.match('/fragments')
  const {pathname} = useLocation()

  const backgroundImg =
    isMobileApp && orientation === 'portrait'
      ? require('../../assets/Auth/fd-mobil-ok.png')
      : require('../../assets/Auth/fd-desk-ok.png')

  useEffect(() => {
    if (globalModalOpened) {
      setHeaderDisplayed(false)
    } else {
      setHeaderDisplayed(true)
    }
  }, [globalModalOpened])

  return publicProfile ? (
    <AppBackground ref={root} hub={!campaignSettings?.phase}>
      {
        campaignNotStarted &&
        <AppBackgroundCampaignNotStarted>
          <BackgroundWaitingCampaignStart source={backgroundImg} alt={'background'}/>
        </AppBackgroundCampaignNotStarted>
      }
      {
        AppSkeletonRender && !campaignNotStarted &&
        <AppSkeletonRender returnToMap={returnToMap}
                           updateHeaderDisplayed={setHeaderDisplayed}
                           headerDisplayed={headerDisplayed}
                           setPhaseProps={setPhaseProps}
                           screenWidth={width}
                           screenHeight={height}
                           isOffline={isOffline}
                           globalModalOpened={globalModalOpened}
                           {...props}/>
      }
      {
        mobile && returnToMap && headerDisplayed ? (
          <MobileHeader
            {...{
              avatar: publicProfile?.avatar,
              returnToMap,
              publicProfile,
              userState,
            }}
            phase={phase}
            openHelper={props?.openHelper}
            openLangSelector={openLangSelector}
            {...phaseProps}
          />
        ) : (
          headerDisplayed && (
            <Header
              {...{publicProfile, returnToMap, userState, mobile}}
              notifications={notifications}
              openHelper={props?.openHelper}
              openLangSelector={openLangSelector}
              screenWidth={width}
              phase={phase}
              screenHeight={height}
              isAdmin={isAdmin}
              toggleHubMenu={toggleHubMenu}
              isOffline={isOffline}
              {...phaseProps}
            />
          )
        )}
      <ChildrenWrapper hub={!campaignSettings?.phase}
                       mobile={orientation === 'portrait' && mobile}
                       returnToMap={returnToMap}
                       moduleLaunched={moduleLaunched}
                       avatar={pathname.includes('/avatar')}
      >
        {children}
      </ChildrenWrapper>
    </AppBackground>
  ) : null
}

const BackgroundWaitingCampaignStart = styled.Image({
  width: '100%',
  height: '100%',
})

const AppBackgroundCampaignNotStarted = styled.View({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})

const AppBackground = styled.View(({hub}) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: hub ? colors.lightText : colors.defaultBackground,
  zIndex: 1,
  overflow: 'hidden',
  userSelect: 'none',
}))

const ChildrenWrapper = styled.View(({mobile, hub, returnToMap, moduleLaunched, avatar}) => ({
  position: mobile ? 'absolute' : 'relative',
  zIndex: hub ? 1 : mobile ? (returnToMap ? 0 : moduleLaunched ? 6 : 4) : moduleLaunched ? 6 : 4,
  width: hub && '100%',
  height: hub && '100%',
  top: (hub && !avatar) && 110,
  overflow: hub && 'auto',
  paddingBottom: (hub && !avatar) && 110
}))
