export default {
  'v': '5.7.1',
  'fr': 25,
  'ip': 0,
  'op': 169,
  'w': 10635,
  'h': 8745,
  'nm': '5',
  'ddd': 0,
  'assets': [{ 'id': 'image_0', 'w': 2300, 'h': 568, 'u': '', 'p': require('./images/img_0.png'), 'e': 0 }, {
    'id': 'image_1',
    'w': 491,
    'h': 410,
    'u': '',
    'p': require('./images/img_1.png'),
    'e': 0
  }, { 'id': 'image_2', 'w': 559, 'h': 454, 'u': '', 'p': require('./images/img_2.png'), 'e': 0 }, {
    'id': 'image_3',
    'w': 1786,
    'h': 1542,
    'u': '',
    'p': require('./images/img_3.png'),
    'e': 0
  }, { 'id': 'image_4', 'w': 2628, 'h': 2072, 'u': '', 'p': require('./images/img_4.png'), 'e': 0 }, {
    'id': 'image_5',
    'w': 2563,
    'h': 2212,
    'u': '',
    'p': require('./images/img_5.png'),
    'e': 0
  }, { 'id': 'image_6', 'w': 2010, 'h': 1574, 'u': '', 'p': require('./images/img_6.png'), 'e': 0 }, {
    'id': 'image_7',
    'w': 1306,
    'h': 1014,
    'u': '',
    'p': require('./images/img_7.png'),
    'e': 0
  }, { 'id': 'image_8', 'w': 3130, 'h': 1912, 'u': '', 'p': require('./images/img_8.png'), 'e': 0 }, {
    'id': 'image_9',
    'w': 488,
    'h': 534,
    'u': '',
    'p': require('./images/img_9.png'),
    'e': 0
  }, { 'id': 'image_10', 'w': 1539, 'h': 1571, 'u': '', 'p': require('./images/img_10.png'), 'e': 0 }, {
    'id': 'image_11',
    'w': 2578,
    'h': 2782,
    'u': '',
    'p': require('./images/img_11.png'),
    'e': 0
  }, { 'id': 'image_12', 'w': 985, 'h': 770, 'u': '', 'p': require('./images/img_12.png'), 'e': 0 }, {
    'id': 'image_13',
    'w': 3611,
    'h': 3320,
    'u': '',
    'p': require('./images/img_13.png'),
    'e': 0
  }, { 'id': 'image_14', 'w': 674, 'h': 346, 'u': '', 'p': require('./images/img_14.png'), 'e': 0 }, {
    'id': 'image_15',
    'w': 1171,
    'h': 1622,
    'u': '',
    'p': require('./images/img_15.png'),
    'e': 0
  }, { 'id': 'image_16', 'w': 2118, 'h': 3003, 'u': '', 'p': require('./images/img_16.png'), 'e': 0 }, {
    'id': 'image_17',
    'w': 1105,
    'h': 1628,
    'u': '',
    'p': require('./images/img_17.png'),
    'e': 0
  }, { 'id': 'image_18', 'w': 1658, 'h': 2825, 'u': '', 'p': require('./images/img_18.png'), 'e': 0 }, {
    'id': 'image_19',
    'w': 2041,
    'h': 1823,
    'u': '',
    'p': require('./images/img_19.png'),
    'e': 0
  }, { 'id': 'image_20', 'w': 2446, 'h': 3318, 'u': '', 'p': require('./images/img_20.png'), 'e': 0 }, {
    'id': 'image_21',
    'w': 2458,
    'h': 3320,
    'u': '',
    'p': require('./images/img_21.png'),
    'e': 0
  }, { 'id': 'image_22', 'w': 690, 'h': 1019, 'u': '', 'p': require('./images/img_22.png'), 'e': 0 }, {
    'id': 'image_23',
    'w': 958,
    'h': 1415,
    'u': '',
    'p': require('./images/img_23.png'),
    'e': 0
  }, { 'id': 'image_24', 'w': 818, 'h': 1524, 'u': '', 'p': require('./images/img_24.png'), 'e': 0 }, {
    'id': 'image_25',
    'w': 1250,
    'h': 1912,
    'u': '',
    'p': require('./images/img_25.png'),
    'e': 0
  }, { 'id': 'image_26', 'w': 775, 'h': 1075, 'u': '', 'p': require('./images/img_26.png'), 'e': 0 }],
  'layers': [{
    'ddd': 0,
    'ind': 1,
    'ty': 2,
    'nm': 'herbe5',
    'refId': 'image_0',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 12,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 37,
          's': [100]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 200,
          's': [100]
        }, { 't': 225, 's': [0] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5238, 7268, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1150, 500, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 12,
          's': [100, 41, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 37,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 200,
          's': [100, 100, 100]
        }, { 't': 225, 's': [100, 41, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 2,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_1',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6418.5, 5265, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [245.5, 205, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 113,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 137,
          's': [100, 100, 100]
        }, { 't': 163, 's': [105, 105, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 3,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_2',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4620.5, 5936, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [279.5, 227, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 87,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 94,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 150,
          's': [100, 100, 100]
        }, { 't': 175, 's': [105, 105, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 4,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_3',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6848, 4476, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [891, 949, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 113,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 150,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 213,
          's': [110, 110, 100]
        }, { 't': 250, 's': [0, 0, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0, 'ind': 5, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_4', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 4248, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [947, 1664, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 51,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 64,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 76,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 149,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 200,
          's': [110, 110, 100]
        }, { 't': 225, 's': [0, 0, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 0, 'op': 169, 'st': 0, 'bm': 0
  }, {
    'ddd': 0, 'ind': 6, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_5', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [3328, 3888, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [841, 1144, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 38,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 51,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 64,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 88,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 113,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 137,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 169,
          's': [100, 100, 100]
        }, { 't': 200, 's': [0, 0, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 0, 'op': 169, 'st': 0, 'bm': 0
  }, {
    'ddd': 0, 'ind': 7, 'ty': 2, 'nm': 'feuillage', 'refId': 'image_6', 'sr': 1, 'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4806, 2808, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1005, 787, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 51,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 64,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 76,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 149,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [100, 100, 100]
        }, { 't': 200, 's': [0, 0, 100] }],
        'ix': 6
      }
    }, 'ao': 0, 'ip': 0, 'op': 169, 'st': 0, 'bm': 0
  }, {
    'ddd': 0,
    'ind': 8,
    'ty': 2,
    'nm': 'feuillage',
    'refId': 'image_7',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6432, 3147, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [653, 507, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 64,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 76,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 88,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 113,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 137,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 185,
          's': [105, 105, 100]
        }, { 't': 225, 's': [0, 0, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 9,
    'ty': 2,
    'nm': 'ombre',
    'refId': 'image_8',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 112,
          's': [0]
        }, { 't': 125, 's': [65.882] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5307, 5226, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1565, 956, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 10,
    'ty': 2,
    'nm': 'pt branche devant gauche',
    'refId': 'image_9',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4931, 6122, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [476, 451, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [0, 0, 100]
        }, { 't': 88, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 11,
    'ty': 2,
    'nm': 'lum tronc',
    'refId': 'image_10',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 50,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 113,
          's': [50.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 141,
          's': [25]
        }, { 't': 168, 's': [50] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4952.5, 6235.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [769.5, 785.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 12,
    'ty': 2,
    'nm': 'lum tronc',
    'refId': 'image_11',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 88,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 125,
          's': [70.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 150,
          's': [35]
        }, { 't': 175, 's': [70] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5478.5, 5813, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1289, 1391, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 13,
    'ty': 2,
    'nm': 'lum tronc',
    'refId': 'image_12',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 100,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 125,
          's': [70.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 151,
          's': [35]
        }, { 't': 175, 's': [70] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6357.5, 5285, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [492.5, 385, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 14,
    'ty': 2,
    'nm': 'teinte',
    'refId': 'image_13',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 52.941, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [2052, 2932, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 14
  }, {
    'ddd': 0,
    'ind': 15,
    'ty': 2,
    'nm': 'pt branche droite',
    'refId': 'image_14',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [6091, 5490, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [37, 301, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [0, 0, 100]
        }, { 't': 113, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 16,
    'ty': 2,
    'nm': 'branche droite',
    'refId': 'image_15',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5821.5, 5947, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [89.5, 1483, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [0, 0, 100]
        }, { 't': 100, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 75,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 17,
    'ty': 2,
    'nm': 'ombre tronc',
    'refId': 'image_16',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1227, 2615, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 18,
    'ty': 2,
    'nm': 'tronc milieu',
    'refId': 'image_17',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 34.902, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1009, 1311, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 19,
    'ty': 2,
    'nm': 'tronc milieu',
    'refId': 'image_18',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 36.863, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1350, 2440, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 20,
    'ty': 2,
    'nm': 'ombre tronc',
    'refId': 'image_19',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [1149, 1441, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 21,
    'ty': 2,
    'nm': 'tronc milieu',
    'refId': 'image_20',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [2040, 2932, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 22,
    'ty': 2,
    'nm': 'tronc milieu',
    'refId': 'image_21',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [2052, 2932, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 23,
    'ty': 2,
    'nm': 'tronc cote droit',
    'refId': 'image_22',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 34.902, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [-193, 1149, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 24,
    'ty': 2,
    'nm': 'tronc cote droit',
    'refId': 'image_23',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 40, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [83, 1424, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 25,
    'ty': 2,
    'nm': 'tronc cote droit',
    'refId': 'image_24',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [-86, 1481, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 26,
    'ty': 2,
    'nm': 'tronc cote droit',
    'refId': 'image_25',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [346, 1869, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 27,
    'ty': 2,
    'nm': 'morceau derriere',
    'refId': 'image_26',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5344, 6816, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [481, 2615, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [0, 0, 100]
        }, { 't': 38, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 169,
    'st': 0,
    'bm': 0
  }],
  'markers': []
}