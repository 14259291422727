import React, { createContext, useContext, useEffect, useState } from 'react'
import { Auth }                                                  from './Auth/useProfile'
import { generatePath, Router }                                  from 'react-router-native'
import { history }                                               from './Router/history.web'
import { TranslationCampaignProvider }                           from './translations/translate'
import { firebaseAccessor }                                      from './firebase'
import { clientId }                                              from '../clientId'
import { clearedOffsetDate }                                     from '@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers'
import { BehaviorSubject }                                       from 'rxjs'

export const GlobalContexts = ({ children, firestoreInitiated }) => (
    <TranslationCampaignProvider firestoreInitiated={firestoreInitiated}>
      <ClientProvider firestoreInitiated={firestoreInitiated}>
        <Auth firestoreInitiated={firestoreInitiated}>
          <Router history={history}>{children}</Router>
        </Auth>
      </ClientProvider>
    </TranslationCampaignProvider>
)

const getCampaignSettings = async (clientId, campaignId) => {
  return await firebaseAccessor.get(
    `clients/${clientId}/campaigns/${campaignId}/settings`
  )
}

const getCampaignWorlds = async (clientId, campaignId) => {
  return await firebaseAccessor.get(
    `clients/${clientId}/campaigns/${campaignId}/worlds`
  )
}

export const CampaignContext = createContext({ campaignId: null })

const ClientProvider = ({ children, firestoreInitiated }) => {
  const [campaignContext, setCampaignContext] = useState({ loading: true })
  const campaignId = useCampaignId()
  
  const basePath = campaignId ? generatePath(`/:campaignId`, { campaignId: campaignId }) : ''
  const campaignNavigate = (path, state) => {
    history.push(`${basePath}/${path}`, state)
  }
  useEffect(() => {
    setCampaignContext((ctx) => ({
      ...ctx,
      basePath: basePath,
      campaignNavigate: campaignNavigate,
      campaignId: campaignId,
    }))
  }, [campaignId])
  useEffect(() => {
    ;(async () => {
      if (!campaignId || !firestoreInitiated) return
      const campaignSettings = await getCampaignSettings(clientId, campaignId)
      const campaignWorldsSettings = await getCampaignWorlds(clientId, campaignId)
      const dateNow = new Date(Date.now()).toISOString()
      setCampaignContext((ctx) => ({
        ...ctx,
        campaignWorlds: campaignWorldsSettings,
        campaignEnded: clearedOffsetDate(campaignSettings?.endAt) < clearedOffsetDate(dateNow),
        campaignNotStarted: clearedOffsetDate(dateNow) < clearedOffsetDate(campaignSettings?.startAt),
        campaignSettings: campaignSettings || {},
      }))
    })()
  }, [campaignContext?.campaignId, firestoreInitiated])
  return (
    <CampaignContext.Provider value={campaignContext}>
      {children}
    </CampaignContext.Provider>
  )
}

export const useCampaign = () => {
  return useContext(CampaignContext)
}

export const _campaignId = new BehaviorSubject()

export const useCampaignId = () => {
  const [campaignId, setCampaignId] = useState("leclerc")
  useEffect(() => {
    _campaignId.subscribe(v => {
      v && setCampaignId(v)
    })
  }, [])
  
  return campaignId
}