import {useEffect, useState} from "react"
import {useProfile}          from "../../../Auth/useProfile"
import { PWA }               from '../../../../clientId'

const tutorialSteps = [
  'tutorialIntro',
  'hallTownFirstClicked',
  'buildingFirstClicked',
  'wonderFirstClicked',
  'firstInvestClicked',
  'flouzFirstClicked',
  'pointsFirstClicked',
  'PAFirstClicked',
  'avatarFirstClicked',
  'rankingFirstClicked',
  'cityFirstClicked',
  'notificationsFirstClicked'
]

export const useTutorialStatus = () => {
  const {userState, userSession} = useProfile()
  const [tutorial, setTutorial] = useState({
    tutorialIntro: true,
    hallTownFirstClicked: true,
    buildingFirstClicked: true,
    wonderFirstClicked: true,
    firstInvestClicked: true,
    flouzFirstClicked: true,
    pointsFirstClicked: true,
    PAFirstClicked: true,
    avatarFirstClicked: true,
    rankingFirstClicked: true,
    cityFirstClicked: true,
    notificationsFirstClicked: true,
  })
  
  useEffect(() => {
    if (!userState?.teamId && !PWA || !userSession?.initialized) return
    tutorialSteps.forEach((tutorialKey) => {
      setTutorial((tutorial) => ({
        ...tutorial,
        [tutorialKey]: userSession?.progressionSeen?.tuto?.[tutorialKey] || false
      }))
    })
  }, [userState?.teamId, userSession?.progressionSeen?.tuto])
  return {
    tutorialIntro: tutorial?.tutorialIntro,
    hallTownFirstClicked: tutorial?.hallTownFirstClicked,
    buildingFirstClicked: tutorial?.buildingFirstClicked,
    wonderFirstClicked: tutorial?.wonderFirstClicked,
    firstInvestClicked: tutorial?.firstInvestClicked,
    flouzFirstClicked: tutorial?.flouzFirstClicked,
    pointsFirstClicked: tutorial?.pointsFirstClicked,
    PAFirstClicked: tutorial?.PAFirstClicked,
    avatarFirstClicked: tutorial?.avatarFirstClicked,
    rankingFirstClicked: tutorial?.rankingFirstClicked,
    cityFirstClicked: tutorial?.cityFirstClicked,
    notificationsFirstClicked: tutorial?.notificationsFirstClicked,
  }
}
