import React, {useMemo}     from "react"
import {ModulesModal}       from "@civitime/library/storybook/stories/Modals"
import {colors}             from "@civitime/library/storybook/configs/colors"
import {MediumText, Title1} from "@civitime/library/storybook/stories/Texts"
import styled               from "@emotion/native"
import { useIsOffline }     from '../../../../Hooks/isOffline'

export const BasicModal = ({
                             infos, onClose, minWidth, onMap, backgroundColor, color, noShadow, customPadding, textFont,
                             titleFont, textLineHeight, mainMarginPadding, width, ...props
                           }) => {

  const HeaderAssetRender = useMemo(() => {
    return infos?.headerAsset
  }, [infos?.headerAsset])

  const CustomComponent = useMemo(() => {
    if (!infos?.customComponent) return null
    return infos?.customComponent
  }, [infos?.customComponent])

  const CustomAssetSvg = useMemo(() => {
    if (!infos?.assetSvg) return null
    return infos?.assetSvg
  }, [infos?.assetSvg])
  
  const isOffline = useIsOffline()

  return <BasicModalContainer buttonText={isOffline && infos?.buttonTextOffline || infos?.buttonText}
                              padding={customPadding}
                              minWidth={minWidth}
                              onPress={infos?.noCloseButton ? false : (infos?.onClose || onClose)}
                              actionButton={infos?.action || onClose}
                              color={colors.secondary}
                              customPositionCross={10}
                              noButton={infos?.noButton}
                              onMap={onMap}
                              backgroundColor={backgroundColor}
                              noShadow={noShadow}
                              textFont={textFont}
                              width={width}
                              {...props}
  >
    {
      infos?.headerAsset &&
      <WrapperHeaderAsset>
        <HeaderAssetRender width={60} height={60}/>
      </WrapperHeaderAsset>
    }
    {
      infos?.title &&
      <ModalTitle titleFont={titleFont} color={color}>{infos?.title}</ModalTitle>
    }
    {
      CustomComponent && <CustomComponent infos={infos}/>
    }
    {
      (infos?.text && !Array.isArray(infos.text)) ? <ModalText textFont={textFont} color={color} textLineHeight={textLineHeight} mainMarginPadding={mainMarginPadding}>{infos?.text}</ModalText> :
        Array.isArray(infos.text) && <ModalText textFont={textFont} color={color} textLineHeight={textLineHeight} mainMarginPadding={mainMarginPadding}>{infos?.text}</ModalText>
    }
    {
      infos?.asset &&
      <WrapperModalAsset biggerAsset={!!infos?.tuto}>
        <ModalAsset source={infos?.asset} resizeMode="contain" alt="modal asset"/>
      </WrapperModalAsset>
    }
    {
      CustomAssetSvg &&
      <WrapperCustomAssetSvg>
        <CustomAssetSvg width={70} height={70}/>
      </WrapperCustomAssetSvg>
    }
  </BasicModalContainer>
}

const WrapperCustomAssetSvg = styled.View({
  width: '100%',
  alignItems: 'center'
})

const WrapperHeaderAsset = styled.View({
  width: '100%',
  alignItems: 'center'
})

const BasicModalContainer = styled(ModulesModal)(({onMap}) => ({
  marginRight: onMap ? 0 : 10,
  marginLeft: onMap ? 0 : 10,
}))

const ModalTitle = styled(Title1)(({color, titleFont}) => ({
  color: color || colors.text,
  fontSize: titleFont || 18
}))

export const ModalText = styled(MediumText)(({color, textFont, textLineHeight, mainMarginPadding}) => ({
  marginTop: mainMarginPadding || 30,
  marginBottom: mainMarginPadding || 30,
  color: color || colors.text,
  fontSize: textFont || 16,
  lineHeight: textLineHeight || 18
}))

const WrapperModalAsset = styled.View(({biggerAsset}) => ({
  width: '100%',
  height: biggerAsset ? 40 : 30,
  alignItems: 'center'
}))

const ModalAsset = styled.Image({
  width: '100%',
  height: '100%',
})
