import React, {useEffect, useMemo, useRef, useState} from "react"
import styled from "@emotion/native"
import {colors} from "@civitime/library/storybook/configs/colors"
import {ButtonText, MediumText} from "@civitime/library/storybook/stories/Texts"
import Lock from './Assets/lock.svg'
import Construction from './Assets/icone construction.svg'
import {Animated}   from "react-native"
import {AvatarComponent}  from "@civitime/library/storybook/stories/Avatar"
import {useProfile}       from "../../../../Auth/useProfile"
import Bonus2             from "./Assets/bonus2.svg"
import Bonus3             from "./Assets/bonus3.svg"
import Bonus4             from "./Assets/bonus4.svg"
import Bonus5             from "./Assets/bonus5.svg"
import Bonus6             from "./Assets/bonus6.svg"
import {TooltipTuto}      from "../Tuto/TooltipTuto"
import {T, useTranslator} from "../../../../translations/translate"
import {useCampaign} from "../../../../GlobalContexts"

const bonusIcons = {
  0: null,
  2: Bonus2,
  3: Bonus3,
  4: Bonus4,
  5: Bonus5,
  6: Bonus6,
}

export const ModalBuildingCard = ({
                                    buttonText,
                                    image,
                                    onPress,
                                    construction,
                                    lock,
                                    children,
                                    cantPlayNow,
                                    buildingLocked,
                                    alreadyPlayed,
                                    levelBonus,
                                    neverPlay,
                                    wonder=false
                                  }) => {
  const OverlayIcon = lock ? Lock : construction ? Construction : null
  const hoverAnimationOpacity = useRef(new Animated.Value(0)).current
  const {publicProfile} = useProfile()
  const {campaignSettings} = useCampaign()
  const BonusIcon = useMemo(() => bonusIcons?.[levelBonus] || null, [levelBonus])
  const [buttonHovered, setButtonHovered] = useState(null)
  const {t} = useTranslator()
  const cardHoveredEnter = (id) => {
    setButtonHovered(id)
  }

  useEffect(() => {
    if (!buttonHovered) return
    Animated.timing(hoverAnimationOpacity, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true
    }).start()
  }, [buttonHovered])

  const cardHoveredLeave = () => {
    Animated.timing(hoverAnimationOpacity, {
      toValue: 0,
      duration: 100,
      useNativeDriver: true,
    }).start(() => setButtonHovered(null))
  }

  return <WrapperModalBuildingCard neverPlay={neverPlay} wonder={wonder}>
    {
      alreadyPlayed && <WrapperAvatarIndicator>
        <AvatarComponent
          avatar={publicProfile?.avatar}
          circleAvatarConfig={{
            size: 30,
            withBorder: true,
            backgroundColor: colors.extraLightBackground,
          }}
        />
      </WrapperAvatarIndicator>
    }
    <CardImage source={lock ? '' : image} alt={"card image"} resizeMode="contain" wonder={wonder}/>
    <TopCardContainer
      wonder={wonder}
      style={{opacity: buttonHovered === image ? hoverAnimationOpacity : 0}}/>
    {
      !!(buildingLocked && buttonHovered === image && !cantPlayNow) &&
      <BuildingIsDownWrapper wonder={wonder}>
        <BuildingIsDownText>
          <T path="phase_2.modals.buildingModal.buildingCards.buildingLocked"
             data={{0: Object.keys(campaignSettings?.buildings).length >= 6 ? 4 : 2}}/>
        </BuildingIsDownText>
      </BuildingIsDownWrapper>
    }
    {
      (cantPlayNow && buttonHovered === image) &&
      <BuildingIsDownWrapper wonder={wonder}>
        <BuildingIsDownText>
          <T path="phase_2.modals.buildingModal.buildingCards.moduleLocked"/>
        </BuildingIsDownText>
      </BuildingIsDownWrapper>
    }
    <CardContent>
      {
        BonusIcon && !lock &&
        <BonusIcon width={35} height={35} style={{
          position: 'absolute',
          top: -40,
          left: 8,
        }}/>
      }
      {
        neverPlay &&
        <TooltipTuto
          mainMarginPadding={10}
          customPadding={15}
          color={colors.white}
          backgroundColor={colors?.text}
          width={200}
          style={{
            transform: [{scale: 0.7}, {translateX: '-50%'}, {translateY: '-119%'}],
            transformOrigin: 'top left',
            left: '50%',
            zIndex: 3
          }}
          cornerBottom={-25}
          infos={{
            title: t("phase_2.modals.buildingModal.global.playButton"),
            text: t("phase_2.tutorial.tooltips.winFirstPoints.text"),
            noCloseButton: true
          }}/>
      }
      {
        buttonText && !lock && !construction &&
        <CardButton onPress={!cantPlayNow && onPress}
                    alreadyPlayed={alreadyPlayed}
                    onMouseEnter={() => cardHoveredEnter(image)}
                    onMouseLeave={cardHoveredLeave}
                    borderColor={alreadyPlayed && '#6AB435'}
                    hovered={buttonHovered === image}
                    disabled={cantPlayNow}
        >
          <CardButtonText alreadyPlayed={alreadyPlayed}>
            {buttonText}
          </CardButtonText>
        </CardButton>
      }
      {children}
    </CardContent>
    {
      (lock || construction) &&
      <CardOverlay>
        <OverlayIcon width={50} height={50}/>
      </CardOverlay>
    }
  </WrapperModalBuildingCard>
}

const BuildingIsDownWrapper = styled.View(({wonder}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 175,
  height: wonder ? 150 : 175,
  backgroundColor: colors.mediumShadow,
  padding: 10,
  alignItems: 'center',
  justifyContent: 'center',
  borderTopRightRadius: 9,
  borderTopLeftRadius: 9,
}))

const BuildingIsDownText = styled.Text({
  fontSize: 13,
  color: colors.white,
  textAlign: 'center'
})

const WrapperAvatarIndicator = styled.View({
  position: 'absolute',
  top: 5,
  left: 5
})

const WrapperModalBuildingCard = styled.View(({neverPlay, wonder}) => ({
  position: 'relative',
  width: 175,
  height: wonder ? 170 : 220,
  flexDirection: 'column',
  backgroundColor: colors.white,
  marginTop: wonder ? 0 : 15,
  marginBottom: 15,
  borderRadius: 9,
  marginLeft: 10,
  marginRight: 10,
  zIndex: neverPlay ? 2 : 1
}))

const CardImage = styled.Image(({wonder}) => ({
  width: 175,
  height: wonder ? 100 : 150,
}))

const TopCardContainer = styled(Animated.View)(({wonder}) => ({
  position: 'absolute',
  width: 175,
  height: wonder ? 125 : 175,
  top: 0,
  left: 0,
  borderTopRightRadius: 9,
  borderTopLeftRadius: 9,
  borderColor: colors.secondary,
  borderWidth: 2,
}))

const CardContent = styled.View({
  position: 'relative',
  width: 175,
  height: 70,
  backgroundColor: colors.linkWater,
  borderBottomRightRadius: 9,
  borderBottomLeftRadius: 9,
  padding: 3
})

export const CardButton = styled(ButtonText)(({disabled, hovered, alreadyPlayed, borderColor}) => ({
  borderRadius: 18,
  backgroundColor: alreadyPlayed ? colors.white
    : disabled ? colors.secondaryLock
      : colors.secondary,
  filter: disabled && hovered && 'drop-shadow(1px 1px 3px rg ba(94, 104, 121, 0.945))',
  width: alreadyPlayed ? 100 : 84,
  height: 35,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: -17,
  left: '50%',
  transform: [{translateX: '-50%'}],
  zIndex: 2,
  cursor: 'pointer',
  pointerEvents: 'auto',
  borderWidth: borderColor && 2,
  borderColor: borderColor
  
}))

export const CardButtonText = styled(MediumText)(({alreadyPlayed}) => ({
  color: alreadyPlayed ? colors.secondary : colors.white,
  fontSize: 16,
  fontWeight: 'bold',
}))

const CardOverlay = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: colors.mediumShadow,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 9
})
