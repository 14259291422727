import './Utils/customPolyfills'
import 'core-js'
import 'regenerator-runtime'
import 'whatwg-fetch'
import React, { useEffect, useRef, useState }                   from 'react'
import { BackHandler, Platform, }                               from 'react-native'
import { registerRootComponent }                                from 'expo'
import * as Linking                                             from 'expo-linking'
import AppLoading                                               from 'expo-app-loading'
import { lockPlatformAsync, unlockAsync, WebOrientationLock, }  from 'expo-screen-orientation'
import {
  Raleway_100Thin,
  Raleway_100Thin_Italic,
  Raleway_200ExtraLight,
  Raleway_200ExtraLight_Italic,
  Raleway_300Light,
  Raleway_300Light_Italic,
  Raleway_400Regular,
  Raleway_400Regular_Italic,
  Raleway_500Medium,
  Raleway_500Medium_Italic,
  Raleway_600SemiBold,
  Raleway_600SemiBold_Italic,
  Raleway_700Bold,
  Raleway_700Bold_Italic,
  Raleway_800ExtraBold,
  Raleway_800ExtraBold_Italic,
  Raleway_900Black,
  Raleway_900Black_Italic,
  useFonts,
}                                                               from '@expo-google-fonts/raleway'
import { LuckiestGuy_400Regular, useFonts as useLuckiestfonts } from '@expo-google-fonts/luckiest-guy'
import './Utils/monkeyProxy'
import { _campaignId, GlobalContexts }                          from './GlobalContexts'

import { history }                     from './Router/history'
import styled                          from '@emotion/native'
import { useScreenDimensions }         from '@civitime/library/storybook/hooks/useScreenDimensions'
import { useProfile }                  from './Auth/useProfile'
import { clientId, FORCED_CAMPAIGNID } from '../clientId'
import { TranslationCampaignProvider } from './translations/translate'
import { AuthFilter }                  from './Auth/AuthFilter'
import { AppRouter }                   from './MainComponents/AppRouter'
import { InstallPwaScreen }            from './MainComponents/InstallPwaScreen'
import { PWALoader }                   from './MainComponents/PWALoader'
import { onlineStateChanged }          from './Utils/fetchAppData'
import { firebaseAccessor }            from './firebase'
import { Offline, useIsOffline }       from './Hooks/isOffline'
import { getBrowserInfos }             from '@civitime/duel-quiz/src/Game'

const App = () => {
  const [fontsLoaded] = useFonts({
    Raleway_100Thin,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light,
    Raleway_300Light_Italic,
    Raleway_400Regular,
    Raleway_400Regular_Italic,
    Raleway_500Medium,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black,
    Raleway_900Black_Italic
  })
  const [luckiestFontsLoaded] = useLuckiestfonts({ LuckiestGuy_400Regular })
  const root = useRef(null)
  const { width, height } = useScreenDimensions(root)
  const isOffline = useIsOffline()
  const [campaignId, setCampaignId] = useState(null)
  const [externalProgress, setExternalProgress] = useState(0)
  const [SWProgress, setSWProgress] = useState({ temp: 0, app: 0 })
  const [firestoreInitiated, setFirestoreInitiated] = useState(false)

  const [cacheLoading, setCacheLoading] = useState(true)
  const [pwaStatus, setPwaStatus] = useState(false)

  const forceCampaignId = () => {
    history.push(FORCED_CAMPAIGNID)
    _campaignId.next(FORCED_CAMPAIGNID)
  }

  useEffect(() => {
    forceCampaignId()
    _campaignId.subscribe(v => {
      setCampaignId(v)
    })
  }, [])

  useEffect(() => {
    if (Platform.OS === 'web') {
      lockPlatformAsync({
        screenOrientationLockWeb: WebOrientationLock.LANDSCAPE,
      }).catch((e) => console.warn('Unable to lock screen orientation', e))

      return
    } else {
      unlockAsync()
    }
    // TODO : prepared for universal deeplinks, but native configuration is missing.
    const listenToUrlChanges = ({ url }) => {
      const path = Linking.parse(url).path
      if (!path) return
      history.push(path)
    }
    Linking.getInitialURL().then((url) => {
      listenToUrlChanges({ url })
    })
    Linking.addEventListener('url', listenToUrlChanges)

    const handleBackButton = () => {
      history.goBack()
      return true
    }
    BackHandler.addEventListener('hardwareBackPress', handleBackButton)

    return () => {
      Linking.removeEventListener('url', listenToUrlChanges)
      BackHandler.removeEventListener('hardwareBackPress', handleBackButton)
    }
  }, [])

  useEffect(() => {
     handleExpoCache(setSWProgress)
  }, [])
  useEffect(() => {
    if (!(campaignId && clientId) || SWProgress.app !== 'done') return
      ;
    (async () => {
      if (isOffline) {
        await firebaseAccessor.disableNetwork()
        setFirestoreInitiated(true)
        return
      }
      await onlineStateChanged(clientId, campaignId, setExternalProgress, setFirestoreInitiated)
      return
    })()
  }, [clientId, campaignId, isOffline, SWProgress?.app])

  const {} = useProfile()
  useEffect(() => {
    if (Platform.OS === 'web') {
      function isPwa() {
        const isNavigatorStandalone = window.navigator.standalone === true;
        const isDisplayStandalone = window.matchMedia('(display-mode: standalone)').matches;
        const isScreenHeightStandalone = screen.height-document.documentElement.clientHeight <65;
        return (isNavigatorStandalone) || (isDisplayStandalone) || (isScreenHeightStandalone)
      }
      if (isPwa()) {
        setPwaStatus(true)
      }
    }
  }, [])


  if (!fontsLoaded || !luckiestFontsLoaded) {
    return <AppLoading ref={root}/>
  } else {
    return <Offline>
      {
        cacheLoading ? <PWALoader externalProgress={externalProgress}
                                  PWAProgress={SWProgress}
                                  finishedLoading={() => setCacheLoading(false)}/>
          : pwaStatus ?
            <MainAppWrapper ref={root} screenHeight={height} screenWidth={width}>
              <GlobalContexts firestoreInitiated={firestoreInitiated}>
                <TranslationCampaignProvider firestoreInitiated={firestoreInitiated}>
                  <AuthFilter firestoreInitiated={firestoreInitiated}>
                    <AppRouter/>
                  </AuthFilter>
                </TranslationCampaignProvider>
              </GlobalContexts>
            </MainAppWrapper>
            :
            <InstallPwaScreen/>
      }
    </Offline>
  }
}

registerRootComponent(App)

const MainAppWrapper = styled.View(({ screenWidth, screenHeight }) => ({
  width: screenWidth,
  height: screenHeight,
  flex: 1,
}))

const cacheLength = 199
let progress = {}

const handleExpoCache = async (setSWProgress) => {
  const interval = setInterval(async () => {
    const expoCacheLength = await checkExpoCache()
    const expoTempCacheLength = await checkExpoTempCache()
    if (expoCacheLength >= cacheLength) {
      setSWProgress({ temp: 'done', app: 'done' })
      return clearInterval(interval)
    }
    if (expoTempCacheLength >= cacheLength || progress?.temp === 'done') {
      progress = { temp: 'done', app: Math.ceil(expoCacheLength / cacheLength * 100) }
      return setSWProgress({ temp: 'done', app: Math.ceil(expoCacheLength / cacheLength * 100) })
    } else {
      progress = {
        temp: progress?.temp === 'done' || expoTempCacheLength >= cacheLength ? 'done'
          : Math.ceil(expoTempCacheLength / cacheLength * 100),
        app: expoTempCacheLength >= cacheLength ? 'done'
          : Math.ceil(expoCacheLength / cacheLength * 100)
      }
      setSWProgress({
        temp: progress?.temp === 'done' || expoTempCacheLength >= cacheLength ? 'done'
          : Math.ceil(expoTempCacheLength / cacheLength * 100),
        app: expoTempCacheLength >= cacheLength ? 'done'
          : Math.ceil(expoCacheLength / cacheLength * 100)
      })
    }
  }, 500)

}

const checkExpoCache = async () => {
  return window?.caches?.open(`workbox-precache-${location.origin}/`).then(expoCache => expoCache.keys().then((a) => a.length))
}

const checkExpoTempCache = async () => {
  return window?.caches?.open(`workbox-precache-${location.origin}/-temp`).then(expoCache => expoCache.keys().then((a) => a.length))
}
