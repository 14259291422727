import React from "react"
import {Animated} from "react-native"
import styled from "@emotion/native"

import {PlayerCard} from "./PlayerCard"
import {colors} from "@civitime/library/storybook/configs/colors"

export const CardsItem = ({
                            player,
                            mobile,
                            orientation,
                            frontAnimatedStyle,
                            frontOpacity,
                            backAnimatedStyle,
                            backOpacity,
                            challengePlayer,
                          }) => {
  return (
    <WrapperCardItem {...{mobile, orientation}}>
      <LoadingCard
        style={[
          frontAnimatedStyle,
          {
            opacity: frontOpacity,
          },
        ]}
        {...{mobile, orientation}}
      />
      {player && (
        <PlayerCard
          style={[
            backAnimatedStyle,
            {
              opacity: backOpacity,
              position: "absolute",
              top: 0,
            },
          ]}
          {...{player, mobile, orientation, challengePlayer}}
        />
      )}
    </WrapperCardItem>
  )
}

const WrapperCardItem = styled.View(({mobile, orientation}) => ({
  flex: (orientation === 'portrait' && mobile) ? '1 1 auto' : 1,
  height: mobile ? (orientation === "portrait" ? 97 : 147) : 210,
  marginRight: mobile && orientation === "portrait" ? 0 : 20,
  marginBottom: mobile && orientation === "portrait" ? 10 : 0,
}))

const LoadingCard = styled(Animated.View)({
  flex: 1,
  borderRadius: 5,
  backgroundColor: colors.challenges.loadingCard,
})
