import React, { useEffect, useRef, useState }        from 'react'
import { Text, TouchableOpacity, Linking, Platform } from 'react-native'
import { useLocation }                               from 'react-router-native'
import styled                                        from '@emotion/native'
import { T, useTranslator }                          from '../../translations/translate'
import { useCampaign }                               from '../../GlobalContexts'
import { authClient }                                from '../authClient'

import { Input, InputRow }                                      from '@civitime/library/storybook/stories/Inputs'
import { TextButton }                                           from '@civitime/library/storybook/stories/Button'
import { colors }                                               from '@civitime/library/storybook/configs/colors'
import { BaseText, BoldText, Title2 }                           from '@civitime/library/storybook/stories/Texts'
import { AuthCard, AuthContent, AuthFooter, }                   from '@civitime/library/storybook/stories/Cards'
import { ErrorAlert, ErrorText, }                               from '@civitime/library/storybook/stories/Error'
import { Checkbox }                                             from '@civitime/library/storybook/stories/Checkbox'
import {
  MailIcon,
  PasswordIcon,
  PlayerIcon,
  VisibilityOffIcon,
  VisibilityOnIcon,
}                                                               from '@civitime/library/storybook/stories/Icons'
import { brand as isMobileApp }                                 from 'expo-device'
import { AuthLangSelectorButton, GoogleChromeImage, TitleWarn } from './Auth'
import { FirefoxLink, LoaderWrapper, WrapperWarningNavFix }     from './Login'
import { firebaseAccessor }                                     from '../../firebase'
import { clientId }                                             from '../../../clientId'
import { useProfile }                                           from '../useProfile'
import LottieView                                               from 'lottie-react-native'
import Loader                                                   from '../../../assets/Auth/animations/loader/data.json'

const checkPasswordRegexp = process.env.PASSWORD_CHECK
  ? new RegExp(process.env.PASSWORD_CHECK)
  : /^.{6,}$/

export const Signup = ({ mobile, openLangSelector, campaignSettings }) => {
  const { t, lang } = useTranslator()
  const { campaignNavigate } = useCampaign()
  const { search } = useLocation()
  const [awaiting, setAwaiting] = useState(false)
  const ref = useRef(null)
  const [state, setState] = useState({
    submitted: false,
    signing: false,
    error: false,
    filterEmail: []
  })
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [acceptCGU, setAcceptCGU] = useState(false)
  const [securedTextEntry, setSecuredTextEntry] = useState(true)
  
  const { lmsData } = useProfile()
  
  const getClientSettings = async (clientId) => {
    const filterEmail = await firebaseAccessor.get(`clients/${clientId}/settings/filterEmail`)
    setState(state => ({
      ...state,
      filterEmail: filterEmail
    }))
  }
  
  useEffect(() => {
    getClientSettings(clientId)
  }, [clientId])
  
  useEffect(() => {
    if (!search) return
    const urlSearchParams = new URLSearchParams(search)
    setEmail(urlSearchParams.get('email'))
  }, [search])
  
  const canSubmit = name.length && email.length && password.length && !state.submitted
  
  async function submit (event) {
    setAwaiting(true)
    setState((state) => ({...state, error: false, filterMailError: false, passwordTooShort: false, cguError: false}))
    event.preventDefault()
    if (!acceptCGU) {
      setState(state => ({
        ...state,
        cguError: true
      }))
      return setAwaiting(false)
    }
    if (state?.filterEmail?.length && !(state?.filterEmail.some(filter => email.includes(filter)))) {
      setState((state) => ({
        ...state,
        filterMailError: true,
      }))
      return setAwaiting(false)
    }
    if (!checkPasswordRegexp.test(password)) {
      setState((state) => ({
        ...state,
        passwordTooShort: true,
      }))
      return setAwaiting(false)
    }
    await signup()
    setAwaiting(false)
  }
  
  const signup = async () => {
    const campaignId = location.pathname.split('/')[1]
    setState((state) => ({
      ...state,
      submitted: true,
      signing: true,
      error: false,
    }))
    try {
      const payload = {
        email: email,
        password: password,
        name: name,
        clientId: clientId,
        campaignId: campaignId,
        acceptCGU: acceptCGU,
      }
      if (lmsData?.lms) {
        payload.lmsData = lmsData
      }
      await authClient.signup(payload)
    } catch (e) {
      const formattedErrors = formatErrorMessage(e)
      setState((state) => ({
        ...state,
        error: formattedErrors,
      }))
    }
    setState((state) => ({
      ...state,
      signing: false,
      submitted: false
    }))
    setAwaiting(false)
  }

  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

  const seeCguContent = () => {
    if (Platform.OS == 'web') {
      window.open(`https://ct-campaigns.civitimeapp.com/cgu/${lang === 'fr' ? 'cguFR' : 'cguEN'}.pdf`, '_blank')
    } else {
      return Linking.openURL('https://ct-campaigns.civitimeapp.com/cgu/cguFR.pdf')
    }
  }
  
  return (
    <AuthCard mobile={mobile}>
      {campaignSettings?.internationalized && <AuthLangSelectorButton size={40}
                                                                      iconSize={30}
                                                                      onPress={openLangSelector}
                                                                      text={lang}/>}
      <AuthContent>
        <Title>
          <T path={'app.auth.titles.signup'}/>
        </Title>
        
        {state.error && (
          <ErrorAlert>
            <ErrorText>
              {state.error.slice(0, 3) === 'app' ? (
                <T path={state.error}/>
              ) : (
                <T path={'app.auth.errors.emailAlreadyInUse'}/>
              )}
            </ErrorText>
          </ErrorAlert>
        )}
        {state.filterMailError && (
          <ErrorAlert>
            <ErrorText>
              <T path="app.auth.errors.filterMailError" data={[6]}/>
            </ErrorText>
          </ErrorAlert>
        )}
        {state.cguError && (
          <ErrorAlert>
            <ErrorText>
              <T path="app.auth.errors.cguError"/>
            </ErrorText>
          </ErrorAlert>
        )}
        <InputRow>
          <Input
            name="email"
            icon={<MailIcon width={17} height={17} color={colors.text}/>}
            placeholder={t('app.auth.fields.email')}
            onChangeText={setEmail}
            value={email}
            keyboardType="email-address"
            autoCompleteType="email"
          />
        </InputRow>
        <InputRow>
          <Input
            name="password"
            icon={<PasswordIcon width={17} height={17} color={colors.text}/>}
            placeholder={t('app.auth.fields.password')}
            secureTextEntry={securedTextEntry}
            onChangeText={setPassword}
            value={password}
            secondIcon={securedTextEntry ? <VisibilityOnIcon width={20} height={20} style={{ cursor: 'pointer' }}
                                                             onClick={() => setSecuredTextEntry(v => !v)}/> :
              <VisibilityOffIcon width={20} height={20} style={{ cursor: 'pointer' }}
                                 onClick={() => setSecuredTextEntry(v => !v)}/>}
          />
        </InputRow>
        {state.passwordTooShort && (
          <ErrorAlert>
            <ErrorText>
              <T path="app.auth.errors.passwordTooShort" data={[6]}/>
            </ErrorText>
          </ErrorAlert>
        )}
        <InputRow>
          <Input
            name="name"
            icon={<PlayerIcon width={17} height={17} color={colors.text}/>}
            placeholder={t('app.auth.fields.name')}
            onChangeText={setName}
            value={name}
            returnKeyType="send"
            onSubmitEditing={(e) => canSubmit && submit(e)}
          />
        </InputRow>
        <InputRow>
          <CGUWrapper mobile={mobile}>
            <CheckboxWrapper>
              <CGUCheckbox value={acceptCGU} rounded onChange={v => setAcceptCGU(v)}/>
              <CguText><T path="app.auth.fields.cguConfirm"/></CguText>
              <CguText style={{ color: colors.secondary }}
                       onPress={seeCguContent}><T path="app.auth.fields.cguLink"/></CguText>
            </CheckboxWrapper>
          </CGUWrapper>
        </InputRow>
        {!isMobileApp && !isChrome && !mobile && (
          <WrapperWarningNavFix>
            <TitleWarn>
              <T path={'app.auth.warningNav'}/>
            </TitleWarn>
            <WrapperBrowsersList>
              <Text
                accessibilityRole="link"
                href="https://www.google.com/chrome/"
                target="_blank"
              >
                <GoogleChromeImage
                  source={require('../../../assets/Auth/chrome.png')}
                  resizeMode="contain"
                />
              </Text>
              <FirefoxLink
                accessibilityRole="link"
                href="https://www.mozilla.org/fr/firefox/"
                target="_blank"
              >
                <FirefoxImage
                  source={require('../../../assets/Auth/firefox-logo.png')}
                  resizeMode="contain"
                />
              </FirefoxLink>
            </WrapperBrowsersList>
          </WrapperWarningNavFix>
        )}
        
        <ActionsWrapper>
          {awaiting && state.submitted ? <LoaderWrapper>
              <LottieView
                source={Loader}
                width={'100%'}
                height={'100%'}
                ref={ref}
                autoPlay
                loop
              />
            </LoaderWrapper>
            : <TextButton
              onPress={(e) => canSubmit && submit(e)}
              disabled={!canSubmit}
            >
              <T path={'app.auth.actions.signup'}/>
            </TextButton>}
        </ActionsWrapper>
      </AuthContent>
      
      <AuthFooter>
        <TouchableOpacity onPress={() => campaignNavigate('login')}>
          <BoldText>
            <T path={'app.auth.links.signin'}/>
          </BoldText>
        </TouchableOpacity>
      </AuthFooter>
    </AuthCard>
  )
}

export const WrapperBrowsersList = styled.View({
  flexDirection: 'row'
})

const FirefoxImage = styled.Image({
  width: 80,
  height: 22,
  marginTop: 10,
  marginLeft: 5
})

export const ActionsWrapper = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-around',
  paddingTop: 60,
})

export const Title = styled(BaseText)({
  fontSize: 21,
  lineHeight: 25,
})

const CGUWrapper = styled.View({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

const CguText = styled(Title2)({
  marginRight: 0,
  fontSize: 12,
})

const CheckboxWrapper = styled.View({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 5,
  marginTop: 10,
  minWidth: 300
})

const CGUCheckbox = styled(Checkbox)({
  marginRight: 5
})

export function formatErrorMessage (e) {
  if (e?.message) {
    return e.message
  }
  return 'app.global.errors.fatal'
}
