import { firebaseAccessor } from '../firebase'

const externalUrlsLength = 23

export async function onlineStateChanged (clientId, campaignId, setProgress, setFirestoreInitiated) {
  const cache = await window?.caches?.open('remoteAssets')
  const keys = await cache.keys()
  if (keys?.length >= externalUrlsLength) {
    await fetchFireStoreData(clientId, campaignId, setProgress)
    await firebaseAccessor.disableNetwork()
    setFirestoreInitiated(true)
  } else {
    await cacheExternalUrls(clientId, campaignId, setProgress)
    await fetchFireStoreData(clientId, campaignId, setProgress)
    await firebaseAccessor.disableNetwork()
    setFirestoreInitiated(true)
  }
}

async function cacheExternalUrls (clientId, campaignId, setExternalProgress) {
  const urlsToCache = await getCacheableUrls(clientId, campaignId)
  const cache = await window?.caches?.open('remoteAssets')

  await Promise.all(urlsToCache.map(async (url, index) => {
      try {
        const response = await fetch(url);
        await cache.put(url, response.clone())
        setExternalProgress((index + 1) * 100 / urlsToCache?.length)
      } catch (e) {
        console.log(e)
      }
    })
  )
  setExternalProgress('done')
}

const getCacheableUrls = async (clientId, campaignId) => {
  const textsUrls = getTextsUrl({ clientId: clientId, campaignId: campaignId })
  const pdfUrls = [`https://ct-campaigns.civitimeapp.com/clients/${clientId}/campaigns/${campaignId}/documents/pdf/pdf-sample.pdf`]
  const mapUrl = `https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/map/map-v2.svg`
  return ([...textsUrls, ...mapData, ...pdfUrls, ...documentsData, mapUrl])
}

async function fetchFireStoreData (clientId, campaignId, setProgress) {
  const paths = [
    { campaigns: `clients/${clientId}/campaigns` },
    { clientSettings: `clients/${clientId}/settings` },
    { campaignSettings: `clients/${clientId}/campaigns/${campaignId}/settings` },
    { goldenCompany: `clients/${clientId}/campaigns/${campaignId}/content/goldenCompany` },
    { memory: `clients/${clientId}/campaigns/${campaignId}/content/memory` },
    { modules: `clients/${clientId}/campaigns/${campaignId}/content/modules` },
    { pictionary: `clients/${clientId}/campaigns/${campaignId}/content/pictionary` },
    { preferences: `clients/${clientId}/campaigns/${campaignId}/content/preferences` },
    { quiz: `clients/${clientId}/campaigns/${campaignId}/content/quiz` },
  ]
  let result = {}
  await Promise.all(paths.map((async (v, index) => {
    const key = Object.keys(v)?.[0]
    const path = Object.values(v)?.[0]
    result[key] = await firebaseAccessor.get(path)
    setProgress((index + 1) * 100 / paths?.length)
  })))
  setProgress('done')
  return (result)
}

const getTextsUrl = ({ clientId, campaignId }) => {
  return ([`https://ct-campaigns.civitimeapp.com/clients/${clientId}/campaigns/${campaignId}/fr.json`,
    `https://ct-campaigns.civitimeapp.com/texts/fr.json`,
    `https://ct-campaigns.civitimeapp.com/texts/modules/quiz/fr.json`
  ])
}

const documentsData = [
  "https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/documents/videos/Sécurité Incendie _ Protection d’un transtockeur pour la SCA Normande.mp4",
  "https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/documents/pdf/Découvrez le bilan de l'opération Nettoyons la Nature 2020 _ Le mouvement E.Leclerc.pdf",
  "https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/documents/pdf/Les alliances locales _ les centres E.Leclerc soutiennent les producteurs locaux - Le Mouvement E.Leclerc.pdf",
  "https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/documents/pdf/PDF GPTW - bat2_lv1.pdf",
  "https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/documents/pdf/PDF Vachement Normand- bat3_lv-3.pdf",
  "https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/documents/pdf/PDF Les actions de mécénat de la SCA Normande-bat3_lv-2.pdf",
  "https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/documents/pdf/PDF Lutte contre le gaspillage alimentaire - bat3_lv-1.pdf",
  "https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/documents/pdf/PDF Normandie Forêver-bat4_lv1.pdf",
]

const mapData = [
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/Colis/Colis.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/Dépalettisation/Dépalettisation.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/Expédition/Expédition.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/Palettes/Palettes.png',

  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille1-map.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille1.png',

  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille2-map.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille2.png',

  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille3-map.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille3.png',

  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille4-map.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille4.png',

  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille5-map.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille5.png',

  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille6-map.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille6.png',

  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille7-map.png',
  'https://ct-campaigns.civitimeapp.com/phase2/cttest/campaigns/leclerc/buildings/merveille/steps/merveille7.png',
]

