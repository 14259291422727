import React, { useEffect, useMemo, useState }    from 'react'
import styled                                     from '@emotion/native'
import { colors }                                 from '@civitime/library/storybook/configs/colors'
import { BoldMediumText, ButtonText, MediumText } from '@civitime/library/storybook/stories/Texts'
import { EvolutionProgressBar }                   from './BuildingModal'
import { TouchableWithoutFeedback }               from 'react-native'
import WonderFinishedIcon                         from '../../Map/Assets/buildings/ended-wonder.svg'
import { useCampaign }                            from '../../../../GlobalContexts'
import { _focusOnElement }                        from '../../Map/MapPhase2'
import { T, useTranslator }                       from '../../../../translations/translate'
import { useProfile }                             from '../../../../Auth/useProfile'
import { TooltipTuto }                            from '../Tuto/TooltipTuto'
import { CardButton, CardButtonText }             from './ModalBuildingCard'
import { clientId }                               from '../../../../../clientId'
import BuildingFinishedIcon                       from '../../Map/Assets/buildings/ended.svg'

export const BuildingInProgressCard = ({
  building,
  linkModal,
  mobile,
  teamData,
  type,
  index,
  buildingProgress,
  lockBuildingsFromOrder
}) => {
  const { campaignSettings, campaignId } = useCampaign()
  const { t } = useTranslator()
  const [buildingFinished, setBuildingFinished] = useState(false)
  const { userSession } = useProfile()
  const orderLocked = lockBuildingsFromOrder && buildingProgress && buildingProgress < building?.order
  const getRawBuildingStep = useMemo(() => {
    if (!type) return
    if (lockBuildingsFromOrder) {
      return Math.max(...(Object.entries(userSession?.buildings?.[building?.type]?.stepsPlayed || {})
        .filter(([_, v]) => v.win)
        .map(([k, _]) => parseInt(k))?.length ? Object.entries(userSession?.buildings?.[building?.type]?.stepsPlayed || {})
        .filter(([_, v]) => v.win)
        .map(([k, _]) => parseInt(k)) : [0]))
    }
    
    return type === 'merveille' ?
      (teamData?.wonderState?.step ? teamData?.wonderState?.step : (teamData?.wonderState ? 1 : null))
      : (teamData?.buildingsState?.[building?.type]?.step ? teamData?.buildingsState?.[building?.type]?.step : (teamData?.buildingsState?.[building?.type] ? 1 : 1))
  }, [type, teamData])
  
  const buildingIsFinished = useMemo(() => {
    return Object.values(campaignSettings?.buildings || {}).filter(v => v?.type === building?.type)?.[0]?.steps === Object.keys(userSession?.buildings?.[building?.type]?.stepsPlayed || {})?.length
  }, [userSession?.buildings])
  
  useEffect(() => {
    if (!userSession?.buildings?.[building?.type]?.stepsPlayed || !campaignSettings?.buildings) return
    const buildingIsFinished = Object.entries(userSession?.buildings?.[building?.type]?.stepsPlayed || {})
      .filter(([k, v]) => !!v.win).length === Object.values(campaignSettings?.buildings)
      .filter((v => v?.type === building?.type))?.[0]?.steps
    setBuildingFinished(buildingIsFinished)
  }, [userSession?.buildings?.[building?.type]?.stepsPlayed, campaignSettings?.buildings])
  
  useEffect(() => {
    if (!(building?.type === 'merveille') || !campaignSettings?.buildings) return
    const buildingIsFinished = teamData?.wonderState?.step === Object.values(campaignSettings?.buildings).filter((v => v?.type === building?.type))?.[0]?.steps
    setBuildingFinished(buildingIsFinished)
  }, [building?.type, teamData?.wonderState?.step, campaignSettings?.buildings])
  
  const locked = !!(building && userSession?.buildings?.[building?.type]?.locked)
  
  const selectBuilding = () => {
    if (locked) return
    _focusOnElement.next({
      x: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.positions?.x,
      y: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.positions?.y,
      size: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.size
    })
    linkModal({
      type: type, infos: building
    })
  }
  return <WrapperBuildingInProgressCard>
    <WrapperTitle buildingFinished={buildingFinished}>
      <BuildingName>
        <T path={`phase_2.modals.buildingModal.buildings.${building?.type}.name`}/>
      </BuildingName>
      {
        !!getRawBuildingStep &&
        <LevelsCounter>
          {getRawBuildingStep}
          /
          {building?.steps}
        </LevelsCounter>
      }
    </WrapperTitle>
    <BuildingText>
      <T path={`buildings.${building?.type}`}/>
    </BuildingText>
    <WrapperBuildingActions mobile={mobile}>
      <BuildingImageWrapper mobile={mobile}>
        <BuildingImage
          source={`https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/buildings/${building?.type}/${building?.type}.png`}
          resizeMode="contain"
          alt="building image"/>
      </BuildingImageWrapper>
      {
        buildingFinished ?
          <BuildingActions mobile={mobile} style={{ alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
            {
              building?.type === 'merveille' ?
                <>
                  <WonderFinishedIcon width={40} height={40} style={{ marginBottom: 10 }}/>
                  <BuildingActionText>
                    <T path={`phase_2.modals.buildingModal.global.maxEvolutionWonder`}/>
                  </BuildingActionText>
                </>
                :
                buildingIsFinished ? <>
                    <BuildingFinishedIcon width={40} height={40} style={{ marginBottom: 10 }}/>
                  </>
                  : <WonderFinishedIcon><TouchableWithoutFeedback disabled={locked}
                                                                  onPress={selectBuilding}>
                    <BuildButton disabled={lockBuildingsFromOrder ? orderLocked : locked}>
                      <BuildButtonText>
                        <T path={`phase_2.modals.buildingModal.global.playButton`}/>
                      </BuildButtonText>
                    </BuildButton>
                  </TouchableWithoutFeedback>
                  
                  </WonderFinishedIcon>
            }
          </BuildingActions>
          : <BuildingActions mobile={mobile}>
            <BuildingActionText>
              <T path={getRawBuildingStep > 1 ? `phase_2.modals.buildingModal.global.levelsAvailable`
                : `phase_2.modals.buildingModal.global.levelAvailable`}
              />
            </BuildingActionText>
            <ContainerProgressBar mobile={mobile}>
              <EvolutionProgressBarHallTown>
                {
                  new Array(building?.steps || 0).fill(0).map((_e, index) => {
                    const levelWidth = 200 / building?.steps
                    return <LevelProgression width={levelWidth}
                                             done={(index + 1) <= getRawBuildingStep}
                                             firstLevel={index === 0}
                                             lastLevel={index === (new Array(building?.steps || 0).fill(0).length - 1)}
                    >
                      <LevelIndicator>{index + 1}</LevelIndicator>
                    </LevelProgression>
                  })
                }
              </EvolutionProgressBarHallTown>
            </ContainerProgressBar>
            <WrapperButtonToBuild>
              {index === 0 && !userSession?.buildings && <TooltipTuto
                mainMarginPadding={10}
                customPadding={15}
                color={colors.white}
                backgroundColor={colors?.text}
                style={{
                  transform: [{ scale: 0.7 }, { translateX: '-50%' }, { translateY: '-60%' }],
                  transformOrigin: 'top left',
                  left: '50%'
                }}
                cornerBottom={-35}
                infos={{
                  title: t('phase_2.modals.buildingModal.global.playButton'),
                  text: t('phase_2.tutorial.tooltips.winFirstPoints.text'),
                  noCloseButton: true
                }}/>}
              <TouchableWithoutFeedback disabled={lockBuildingsFromOrder ? orderLocked : locked}
                                        onPress={() => {
                                          if (orderLocked || locked) return
                                          _focusOnElement.next({
                                            x: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.positions?.x,
                                            y: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.positions?.y,
                                            size: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.size
                                          })
                                          linkModal({
                                            type: type, infos: building
                                          })
                                        }
                                        }>
                <BuildButton disabled={lockBuildingsFromOrder ? orderLocked : locked}>
                  <BuildButtonText>
                    {
                      building.type === 'merveille' ?
                        <T path={`phase_2.modals.buildingModal.global.buildingButton`}/>
                        : <T path={`phase_2.modals.buildingModal.global.playButton`}/>
                    }
                  </BuildButtonText>
                </BuildButton>
              </TouchableWithoutFeedback>
            </WrapperButtonToBuild>
          </BuildingActions>
      }
    </WrapperBuildingActions>
  </WrapperBuildingInProgressCard>
}

const EvolutionProgressBarHallTown = styled(EvolutionProgressBar)(({}) => ({
  overflow: 'unset',
}))

const LevelProgression = styled.View(({ width, done, firstLevel, lastLevel }) => ({
  position: 'relative',
  width: width,
  height: '100%',
  borderWidth: 1,
  borderColor: colors.lightShadow,
  backgroundColor: done ? colors.secondary : 'transparent',
  borderTopRightRadius: lastLevel && 18,
  borderBottomRightRadius: lastLevel && 18,
  borderTopLeftRadius: firstLevel && 18,
  borderBottomLeftRadius: firstLevel && 18,
}))

const LevelIndicator = styled.Text({
  position: 'absolute',
  fontSize: 12,
  fontWeight: 'bold',
  color: colors.text,
  top: -20,
  right: -4,
})

const BuildButton = styled(ButtonText)(({ disabled }) => ({
  borderRadius: 18,
  cursor: disabled ? 'default' : 'pointer',
  backgroundColor: colors.secondary,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 15,
  paddingRight: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  opacity: disabled && .2 || 1
}))

const BuildButtonText = styled(MediumText)({
  color: colors.white,
  fontSize: 16,
  fontWeight: 'bold'
})

const WrapperButtonToBuild = styled.View({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center'
})

const ContainerProgressBar = styled.View(() => ({
  position: 'relative',
  marginTop: 30,
  marginBottom: 30,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-around',
  paddingRight: 4,
  paddingLeft: 4,
  opacity: 1
}))

const WrapperBuildingInProgressCard = styled.View({
  flexDirection: 'column',
  width: '98%',
  backgroundColor: colors.defaultBackground,
  borderRadius: 9,
  boxShadow: '-3px -3px 7px #FFFFFF, 3px 3px 5px rgba(94, 104, 121, 0.945)',
  marginBottom: 15,
  paddingBottom: 10
})

const WrapperTitle = styled.View(({ buildingFinished }) => ({
  flexDirection: 'row',
  width: '100%',
  height: 33,
  backgroundColor: buildingFinished ? colors.lightGreen : colors.darkLightGrey,
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 15,
  paddingLeft: 15,
  borderTopRightRadius: 9,
  borderTopLeftRadius: 9
}))

const BuildingName = styled.Text({
  fontSize: 15,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: colors.text
})

const LevelsCounter = styled.Text({
  fontSize: 15,
  textTransform: 'uppercase',
  color: colors.text
})

const BuildingTextWrapper = styled.View({
  width: '100%',
  paddingRight: 10,
  paddingLeft: 10,
  marginTop: 15,
  marginBottom: 15,
})

const BuildingText = styled(MediumText)({
  textAlign: 'left',
})

const ThemeTextWrapper = styled.View({
  marginBottom: 5,
  display: 'flex',
  flexDirection: 'row'
})

const ThemeText = styled(BoldMediumText)(({ uppercase }) => ({
  textAlign: 'left',
  textTransform: uppercase && 'uppercase',
}))

const WrapperBuildingActions = styled.View(({ mobile }) => ({
  width: '100%',
  flexDirection: mobile ? 'column' : 'row',
}))

const BuildingImageWrapper = styled.View(({ mobile }) => ({
  width: mobile ? '100%' : '40%',
  height: mobile ? '100px' : 'auto'
}))

const BuildingActions = styled.View(({ mobile }) => ({
  width: mobile ? '100%' : '60%',
  flexDirection: 'column'
}))

const BuildingImage = styled.Image({
  width: '100%',
  height: '100%'
})

const BuildingActionText = styled(MediumText)(() => ({
  fontWeight: 'bold',
  fontSize: 13,
  opacity: 1
}))
