import React, {useState}                from "react"
import styled                           from "@emotion/native"
import {_zoomStatusIncrement}           from "./MapPhase2"
import Helper                           from './Assets/help.svg'
import {TouchableWithoutFeedback, View} from "react-native"
import {ZoomButtons}                    from "../../Phase1/Components/ZoonButtons"
import {useScreenDimensions}            from "@civitime/library/storybook/hooks/useScreenDimensions"
import { PWA }                          from '../../../../clientId'

export const MapPhase2Content = ({updateZoomScale, linkModal, zoomScaleValue}) => {
  const {mobile} = useScreenDimensions()
  const [zoomButtonHoveredLeft, setZoomButtonHoveredLeft] = useState(null)
  const [zoomButtonHoveredRight, setZoomButtonHoveredRight] = useState(null)

  return <>
    <ZoomCommandWrapper>
      <ZoomButtons
        mobile={mobile}
        setZoomButtonHoveredLeft={setZoomButtonHoveredLeft}
        setZoomButtonHoveredRight={setZoomButtonHoveredRight}
        updateZoom={(res) => {
          if (res === 'downScale') {
            updateZoomScale((e) => {
              if (e === 0) return e
              _zoomStatusIncrement.next(false)
              return e - 1
            })
          } else {
            updateZoomScale((e) => {
              if (e === 2) return e
              _zoomStatusIncrement.next(true)
              return e + 1
            })
          }
        }}
        zoomButtonHoveredLeft={zoomButtonHoveredLeft}
        zoomButtonHoveredRight={zoomButtonHoveredRight}
        zoomLevel={zoomScaleValue}
      />
    </ZoomCommandWrapper>
    {!PWA && <WrapperHelpButton>
      <TouchableWithoutFeedback
        onPress={() => linkModal({ type: 'helper' })}
        style={{ borderRadius: 100 }}
      >
        <View>
          <Helper width={50} height={50}/>
        </View>
      </TouchableWithoutFeedback>
    </WrapperHelpButton>}
  </>
}

const ZoomCommandWrapper = styled.View({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  bottom: 20,
  left: 20
})

const WrapperHelpButton = styled.View({
  position: "absolute",
  bottom: 20,
  right: 40,
  borderRadius: 100
})
