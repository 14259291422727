import { clientId } from '../../clientId'

const clientsToProxify = [
  'sogeprom', 'bddf', 'bddf-2'
]

let oldFetch = window.fetch
let OldWS = window.WebSocket
let oldOpen = window.XMLHttpRequest && window.XMLHttpRequest.prototype.open
if (clientsToProxify.includes(clientId)) {
  if (oldOpen) {
    window.XMLHttpRequest.prototype.open = function (...args) {
      args[1] = replaceUrl(args[1])
      return oldOpen.apply(this, args)
    }
  }
  if (oldFetch) {
    window.fetch = (url, params) => oldFetch(replaceUrl(url), params)
  }
  if (OldWS) {
    window.WebSocket = class ProxyWS extends OldWS {
      constructor(url, protocol) {
        super(replaceUrl(url), protocol)
      }
    }
  }
}

function replaceUrl(url) {
  if (typeof url !== "string") return url
  const isFBIO = url.includes("firebaseio")
  const isGCPService = url.includes("googleapis")
  if (!isFBIO && !isGCPService) return url
  const nextUrl = new URL(url)
  const originalHost = nextUrl.host
  const [sub, domain] = originalHost.split('.')
  nextUrl.host = `civitime.civitimeapp.com`
  switch (domain) {
    case "firebaseio":
      nextUrl.pathname = "/db/" + sub + nextUrl.pathname
      break
    case "googleapis":
      nextUrl.pathname = "/services/" + sub + nextUrl.pathname
      break
    default:
  }

  return nextUrl.toString()
}

