import storage from "react-native-modest-storage"
import {BehaviorSubject} from "rxjs"
import * as Linking from 'expo-linking'
import {env} from "../configs/env"

const tokenKey = "authToken"
const campaignIdKey = "campaignId"
const authState$ = new BehaviorSubject(null)

export const authClient = {
  authState$: authState$,
  async login(email, password, campaignId) {
    try {
      const authState = await jsonFetch(env.AUTH_SERVER_URI + "/login", {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
      })
      await this.saveAuthState(authState, campaignId)

      return authState
    } catch (e) {
      throw e
    }
  },
  async getToken(userId) {
    try {
      const token = await jsonFetch(`${env.AUTH_SERVER_URI}/exchange-token/${userId}`, {
        method: "POST",
        body: JSON.stringify({
          userId: userId
        }),
      })
      return token
    } catch (e) {
      throw e
    }
  },
  async signup(data) {
    try {
      const authState = await jsonFetch(env.AUTH_SERVER_URI + "/signup", {
        method: "POST",
        body: JSON.stringify(data),
      })
      const campaignId = data?.campaignId || null
      await this.saveAuthState(authState, campaignId)
    } catch (e) {
      throw e
    }
  },
  async logout() {
    await this.saveAuthState(null, null)
    //TODO fix that ugly fix to prevent old account from being loaded instead of new one
    window.location.reload()
  },
  async askResetPassword(email, clientId, campaignId, lang) {
    return await jsonFetch(env.AUTH_SERVER_URI + "/ask-reset-password", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        clientId: clientId,
        campaignId: campaignId,
        lang: lang
      }),
    })
  },
  async deleteAccount() {
    await jsonFetch(env.AUTH_SERVER_URI + "/me", {
      method: "DELETE",
    }).catch((e) => console.log(e))
    await this.logout()
  },
  async resetPassword(resetToken, password, campaignId) {
    const authState = await jsonFetch(env.AUTH_SERVER_URI + "/reset-password", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${resetToken}`,
      },
      body: JSON.stringify({
        password,
      }),
    })
    await this.saveAuthState(authState, campaignId)
  },
  async exchangeToken(token) {
    //TODO: verify is the right campaignId if it used
    const campaignId = location.pathname.split('/')[1]
    const authState = await jsonFetch(env.AUTH_SERVER_URI + "/exchange-token", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    await this.saveAuthState(authState, campaignId)
  },
  async externalAuth(clientId, authModule) {
    const url = env.AUTH_SERVER_URI + `/external/${clientId}/${authModule}`
    const supported = await Linking.canOpenURL(url)
    if (!supported) throw new Error("Cannot open the link.")
    await Linking.openURL(url)
  },
  async saveAuthState(authState, campaignId) {
    authState$.next(authState)
    await storage.set(tokenKey, JSON.stringify(authState))
    await storage.set(campaignIdKey, campaignId)
  },
  async changeAcceptEmails(acceptEmails) {
    return await jsonFetch(env.AUTH_SERVER_URI + "/change-accept-emails", {
      method: "POST",
      body: JSON.stringify({
        acceptEmails: acceptEmails,
        userId: authState$.getValue()?.id,
      }),
    }).catch((e) => console.log(e))
  },
  async updateAccount(newData) {
    return await jsonFetch(env.AUTH_SERVER_URI + "/update-account", {
      method: "POST",
      body: JSON.stringify(newData),
    })
      .catch(e => {
        return e
      })
  },
  async invitePlayer({ email, campaignId, teamName, clientId, lang }) {
    return await jsonFetch(env.AUTH_SERVER_URI + "/invite-player", {
      method: "POST",
      body: JSON.stringify({
        email,
        campaignId,
        teamName,
        clientId,
        userId: authState$.getValue()?.id,
        lang
      }),
    })
  },
}

export const jsonFetch = (url, options = {}) => {
  return fetch(url, {
    ...options,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authState$.getValue()?.token}`,
      ...options.headers,
    },
  }).then(async (res) => {
    if (res.status === 400) {
      throw "app.global.errors.fatal"
    }
    if (!res.ok && res.body) {
      throw await res.clone().json()
    } else {
      return res.json()
    }
  })
}

storage
  .get(tokenKey)
  .then((token) => token && authState$.next(JSON.parse(token)))
  .catch((e) => console.error("failed to load authState from storage :", e))

export const getCampaignIdStored = async () => {
  return await storage
    .get(campaignIdKey).then((campaignId) => {
      if (!campaignId) return null
      return campaignId
    })
}

