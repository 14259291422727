import React, {useEffect, useRef, useState} from "react"
import styled from "@emotion/native"
import {colors} from "@civitime/library/storybook/configs/colors"
import {ButtonText, MediumText, SmallText, Title1} from "@civitime/library/storybook/stories/Texts"
import DoubleArrows from './Assets/double-arrows.svg'
import UpArrow from './Assets/up-arrow.svg'
import {Animated, TouchableWithoutFeedback, View} from "react-native"
import {useCampaign} from "../../../../GlobalContexts"
import {useProfile} from "../../../../Auth/useProfile"
import {useRealTimeData} from "../../../../Utils/dataClient"
import {clientId} from "../../../../../clientId"
import {WrapperVerticalScrollbar} from "./TownHallModal"
import {CustomScrollbar} from "../CustomScrollbar"
import {T, useTranslator} from "../../../../translations/translate"

export const TeamChat = ({mobile, chatInteract, chatStatus, height}) => {
  const {emitCommand, userState, publicProfile, userId} = useProfile()
  const {campaignId} = useCampaign()
  const chatRef = useRef(null)
  const [newMessages, setNewMessages] = useState(0)
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([])
  const [messageAreAllSeen, setMessageAreAllSeen] = useState(null)
  const [goToTheFirstMessageNotSeen, setGoToTheFirstMessageNotSeen] = useState(null)
  const lockMessageSubmit = useRef(false)
  const {t} = useTranslator()

  const [scrollbarPositions, setScrollbarPositions] = useState({})
  const [wrapperConversationSizes, setWrapperConversationSizes] = useState({})
  const [wrapperConversationHeaderSizes, setWrapperConversationHeaderSizes] = useState({})
  const checkMessagesOnce = useRef(true)

  const messagesData = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}`
  )

  useEffect(() => {
    if (messageAreAllSeen || !messages.length || !checkMessagesOnce.current) return
    const lastMessageSeen = userState?.lastMessageSeenChatTeam
    const lastMessageSeenIndex = messages.findIndex(v => v[0] === lastMessageSeen)
    if(!lastMessageSeen){
      setNewMessages(messages?.filter(v => v?.[1]?.emitter !== userId)?.length)
      checkMessagesOnce.current = false
      return
    }
    if(messages.every(v => v?.[1]?.emitter === userId)) {
      checkMessagesOnce.current = false
      return
    }
    const messageNotSeen = (messages?.length - 1) - lastMessageSeenIndex
    setNewMessages(messageNotSeen)
    checkMessagesOnce.current = false
  }, [messageAreAllSeen, messages, userState?.lastMessageSeenChatTeam])

  useEffect(() => {
    if (!messagesData?.chat) return
    const messagesFormat = Object.entries(messagesData?.chat).sort(([, a], [, b]) => {
      return new Date(a?.timeStamp) - new Date(b?.timeStamp)
    })
    if (messageAreAllSeen) return setMessages(messagesFormat)
    const lastMessageId = messagesFormat[messagesFormat?.length - 1]?.[0]
    if (lastMessageId === userState?.lastMessageSeenChatTeam) {
      setMessageAreAllSeen(true)
    }
    setMessages(messagesFormat)
  }, [messagesData, userState?.lastMessageSeenChatTeam])

  useEffect(() => {
    if (!chatRef.current) return
    chatRef.current.scrollToEnd({animated: true})
    const lastMessageSeenId = messages[messages?.length - 1]?.[0]
    if (!lastMessageSeenId) return
    if (messageAreAllSeen) {
      emitCommand({
        type: "SeeLastChatTeamMessage",
        payload: {
          messageId: lastMessageSeenId,
        },
      })
    }
  }, [messages, messageAreAllSeen])

  const sendMessage = async () => {
    const messageToSend = message.trim()
    if (!messageToSend.length) return
    setMessage("")
    await emitCommand({
      type: "SendTeamMessage",
      payload: {
        message: message,
        teamId: userState?.teamId,
        userName: publicProfile?.name
      },
    }).then(async () => {
      await updateMessagesSeen(true)
      setMessageAreAllSeen(true)
      setNewMessages(0)
    })
    lockMessageSubmit.current = false
  }

  useEffect(() => {
    if (!chatRef.current) return
    chatRef.current.scrollToEnd({animated: true})
  }, [chatRef, chatStatus])

  const updateMessagesSeen = async (skipAnimation) => {
    const lastMessageSeenId = messages[messages?.length - 1]?.[0]
    if (!lastMessageSeenId) return
    setNewMessages(0)
    const lastMessageSeen = userState?.lastMessageSeenChatTeam
    const lastMessageSeenIndex = messages.findIndex(v => v[0] === lastMessageSeen)
    await emitCommand({
      type: "SeeLastChatTeamMessage",
      payload: {
        messageId: lastMessageSeenId,
      },
    })
    if(skipAnimation) return
    setGoToTheFirstMessageNotSeen(lastMessageSeenIndex)
  }

  const scrollToMe = (e) => {
    const positionLastMessageNotSeen = e?.nativeEvent?.layout?.y
    chatRef.current.scrollTo({y: positionLastMessageNotSeen, animated: true})
  }

  return <WrapperTeamChat mobile={mobile}>
    <ContainerChat mobile={mobile}>
      {
        mobile && <WrapperButtonToShowTeamCHat onPress={chatInteract}>
          <ButtonToShowTeamChat chatStatus={chatStatus}>
            <UpArrow width={15} height={15}/>
          </ButtonToShowTeamChat>
        </WrapperButtonToShowTeamCHat>
      }
      {
        !!newMessages &&
        <NotificationWrapper mobile={mobile}>
          <NotificationNumber mobile={mobile}>{newMessages}</NotificationNumber>
        </NotificationWrapper>
      }
      <ChatTitle mobile={mobile}>
        <T path="phase_2.chat.chatName"/>
      </ChatTitle>
      <ConversationContainer maxHeight={mobile && height * .45}
                             mobile={mobile}
                             chatStatus={chatStatus}
                             onLayout={(e) => setWrapperConversationSizes(e?.nativeEvent?.layout)}>
        <ConversationIntro mobile={mobile} onLayout={(e) => setWrapperConversationHeaderSizes(e?.nativeEvent?.layout)}>
          <T path="phase_2.chat.welcomeMessage"/>
          {
            !!newMessages &&
            <HeaderNewMessages>
              <NewMessagesText>
                <T path="phase_2.chat.newMessages"/>
              </NewMessagesText>
              <TouchableWithoutFeedback onPress={() => updateMessagesSeen(false)}>
                <View style={{
                  position: 'absolute',
                  top: 5,
                  right: 15
                }}>
                  <DoubleArrows width={15} height={15}/>
                </View>
              </TouchableWithoutFeedback>
            </HeaderNewMessages>
          }
        </ConversationIntro>
        <WrapperVerticalScrollbar
          style={{height: (wrapperConversationSizes?.height - wrapperConversationHeaderSizes?.height) || 0}}>
          <CustomScrollbar
            heightThumb={(wrapperConversationSizes?.height - wrapperConversationHeaderSizes?.height) || 0}
            scrollbarPositions={scrollbarPositions}
            widthThumb={16}
            widthTrack={10}
            right={5}
            scrollbarRef={chatRef}
            customPositionTop={20}
            backgroundColor={colors.white}
            color={colors.defaultBackground}/>
          <Conversation ref={chatRef} showsHorizontalScrollIndicator={false}
                        vertical
                        scrollEventThrottle={80}
                        onScroll={(e) => setScrollbarPositions(e?.nativeEvent)}>
            {
              messages?.map(([_id, messageData], index) => {
                const me = messageData?.emitter === userState?.id
                return <MessageWrapper key={index + 'chat team'} me={me}
                                       onLayout={goToTheFirstMessageNotSeen === index ? scrollToMe : null}>
                  <Emitter me={me}>{messageData?.userName}</Emitter>
                  <MessageContainer>
                    <Message me={me}>{messageData?.message}</Message>
                  </MessageContainer>
                </MessageWrapper>
              })
            }
          </Conversation>
        </WrapperVerticalScrollbar>

      </ConversationContainer>
      <EditMessage placeholder={t("phase_2.chat.messagePlaceHolder")}
                   multiline
                   chatStatus={chatStatus}
                   mobile={mobile}
                   value={message}
                   numberOfLines={4}
                   onChangeText={(m) => {
                     setMessage(m)
                   }}
      />
      <TouchableWithoutFeedback onClick={() => {
        if (lockMessageSubmit.current || !message.length) return
        lockMessageSubmit.current = true
        sendMessage()
      }}
                                disabled={!message.length}>
        <SendButton mobile={mobile} chatStatus={chatStatus} isActive={message?.length}>
          <SendButtonText isActive={message?.length}>
            <T path="phase_2.chat.send"/>
          </SendButtonText>
        </SendButton>
      </TouchableWithoutFeedback>
    </ContainerChat>
  </WrapperTeamChat>
}

const WrapperButtonToShowTeamCHat = styled.TouchableWithoutFeedback({})

const ButtonToShowTeamChat = styled.View(({chatStatus}) => ({
  position: 'absolute',
  top: 15,
  right: 13,
  transform: [{rotate: chatStatus ? '180deg' : '0deg'}]
}))

const NewMessagesText = styled(SmallText)({
  color: colors.white,
  textAlign: 'left',
})

const HeaderNewMessages = styled.View({
  position: 'absolute',
  bottom: -25,
  left: 0,
  width: '100%',
  height: 25,
  backgroundColor: colors.text,
  zIndex: 2,
  justifyContent: 'center',
  paddingLeft: 15
})

const MessageWrapper = styled.View(({me}) => ({
  width: '100%',
  paddingLeft: 5,
  paddingRight: 5,
  marginBottom: 5,
  alignItems: me ? 'flex-end' : 'flex-start'
}))

const MessageContainer = styled.View({
  padding: 10,
  backgroundColor: colors.darkLightGrey,
  borderRadius: 9,
  maxWidth: '70%'
})

const Emitter = styled(SmallText)({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textAlign: 'left'
})

const Message = styled(SmallText)({
  textAlign: 'left'
})

const EditMessage = styled.TextInput(({chatStatus, mobile}) => ({
  width: '95%',
  borderRadius: 9,
  backgroundColor: colors.defaultBackground,
  marginTop: 20,
  marginBottom: 20,
  padding: 5,
  display: mobile && chatStatus ? 'flex' : mobile && !chatStatus ? 'none' : 'flex'
}))

const Conversation = styled.ScrollView({
  height: '100%'
})

const SendButton = styled(ButtonText)(({mobile, chatStatus, isActive}) => ({
  borderRadius: 18,
  cursor: 'pointer',
  backgroundColor: isActive ? colors.secondary : colors.white,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 15,
  paddingRight: 15,
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  marginBottom: mobile ? 10 : 15,
  display: mobile && chatStatus ? 'flex' : mobile && !chatStatus ? 'none' : 'flex'
}))

const SendButtonText = styled(MediumText)(({isActive}) => ({
  color: isActive ? colors.white : colors.secondary,
  fontSize: 16,
  fontWeight: 'bold'
}))

const ConversationIntro = styled(MediumText)(({mobile}) => ({
  position: 'relative',
  padding: mobile ? 8 : 15,
  minHeight: mobile ? 'unset' : 80
}))

const ConversationContainer = styled(Animated.View)(({maxHeight, chatStatus, mobile}) => ({
  width: '95%',
  height: "60%",
  backgroundColor: colors.defaultBackground,
  borderRadius: 9,
  maxHeight: maxHeight ? maxHeight : 330,
  display: mobile && chatStatus ? 'flex' : mobile && !chatStatus ? 'none' : 'flex'
}))

const WrapperTeamChat = styled.View(({mobile}) => ({
  width: '100%',
  height: '100%',
  paddingLeft: mobile ? 0 : 15,
}))

const ContainerChat = styled.View(({mobile}) => ({
  width: '100%',
  height: '100%',
  backgroundColor: colors.primary,
  borderRadius: 9,
  alignItems: 'center',
  position: 'relative',
  justifyContent: mobile ? 'space-around' : 'unset'
}))

const ChatTitle = styled(Title1)(({mobile}) => ({
  textTransform: 'uppercase',
  color: colors.white,
  marginTop: mobile ? 10 : 15,
  marginBottom: mobile ? 5 : 15,
}))

const NotificationWrapper = styled.View(({mobile}) => ({
  borderRadius: 50,
  backgroundColor: colors.red,
  position: 'absolute',
  top: 10,
  right: mobile ? 50 : 10,
  width: mobile ? 20 : 30,
  height: mobile ? 20 : 30,
  alignItems: 'center',
  justifyContent: 'center',
}))

const NotificationNumber = styled.Text(({mobile}) => ({
  color: colors.white,
  fontSize: mobile ? 12 : 15,
  fontWeight: 'bold'
}))
