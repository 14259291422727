import { createSagaRunner }                   from '@civitime/game-server/lib/saga-tools/sagas-tools'
import { firebaseAccessor }                   from '../../../firebase'

import { updateAvatar }                       from '@civitime/game-server/lib/Domain/Avatar/Avatar.sagas'
import { updateFogMap, updatePlayerPosition } from '@civitime/game-server/lib/Domain/Phases/phase1/Map/Map.sagas'
import {
  seeChallengeResult,
  updateModuleStateUserSession
}                                             from '@civitime/game-server/lib/Domain/Phases/phase1/Session/Session.sagas'
import {
  phase2SeeCampaignEndedModal,
  phase2SeeWonderNotification, phase2UpdateBuildingSeen
}                                             from '@civitime/game-server/lib/Domain/Phases/phase2/Session/Session.sagas'
import {
  discoverFinalDestination, seeFinalDestinationModal,
  updatePlayerFragments,
  updatePlayerThemeFragments
}                                             from '@civitime/game-server/lib/Domain/Phases/phase1/Fragments/Fragments.sagas'
import { challengePlayer }                    from '@civitime/game-server/lib/Domain/Phases/phase1/Challenges/Challenges.sagas'
import {
  reInitializeGameAccount,
  seeCampaignEndedModal
}                                             from '@civitime/game-server/lib/Domain/Phases/phase1/Campaign/Campaign.sagas'
import {
  lanchModule,
  modalEndedPNJDuration,
  seePNJModal
}                                             from '@civitime/game-server/lib/Domain/Phases/phase1/Dashboard/Dashboard.sagas'
import { phase2EndModule }                    from '@civitime/game-server/lib/Domain/Phases/phase2/Modules/Modules.sagas'
import {
  phase2InvestAllInBuilding,
  phase2InvestInBuilding
}                                                from '@civitime/game-server/lib/Domain/Phases/phase2/Buildings/Buildings.sagas'
import { phase2UpdateSeeTutoStep }               from '@civitime/game-server/lib/Domain/Phases/phase2/Tuto/Tuto.sagas'
import {
  deleteUserInfos,
  initiateOfflinePublicProfile, joinCampaign, setOfflinePublicProfile,
  updateLang,
  updateNameProfile
}                                                from './LocalProfile/LocalProfile.sagas'
import { getBotsToChallenge }                    from './LocalChallenge/LocalChallenge.sagas'
import { unlockQr }                              from './LocalQr/LocalQr.sagas'
import { sendFinalLeaderboard, sendLeaderboard } from './LocalLeaderboard/LocalLeaderboard.sagas'
import { synchronizeLeaderboard }                from '@civitime/game-server/lib/Domain/Phases/phase2/Points/Points.sagas'

export const sagas = {
  
  JoinCampaign: [joinCampaign],
  UpdateNameProfile: [updateNameProfile],
  
  InitiateOfflineProfile: [initiateOfflinePublicProfile],
  SetOfflinePublicProfile: [setOfflinePublicProfile],
  UpdateAvatar: [updateAvatar],
  UpdateFogMap: [updateFogMap],
  UpdatePlayerPosition: [updatePlayerPosition],
  SeeChallengeResult: [seeChallengeResult],
  UpdateModuleStateUserSession: [updateModuleStateUserSession],
  UpdatePlayerFragments: [updatePlayerFragments],
  UpdatePlayerThemeFragments: [updatePlayerThemeFragments],
  
  GetBotsToChallenge: [getBotsToChallenge],
  ChallengePlayer: [challengePlayer],
  DiscoverFinalDestination: [discoverFinalDestination],
  SeeFinalDestinationModal: [seeFinalDestinationModal],
  
  DeleteUserInfos: [deleteUserInfos],
  
  SeeCampaignEndedModal: [seeCampaignEndedModal],
  ReInitializeGameAccount: [reInitializeGameAccount],
  UpdateLang: [updateLang],
  
  SeePNJModuleModal: [seePNJModal],
  LaunchModule: [lanchModule],
  ModalEndedPNJ: [modalEndedPNJDuration],
  
  //Phase2
  Phase2EndModule: [phase2EndModule],
  Phase2InvestInBuilding: [phase2InvestInBuilding],
  Phase2InvestAllInBuilding: [phase2InvestAllInBuilding],
  Phase2UpdateBuildingSeen: [phase2UpdateBuildingSeen],
  Phase2SeeWonderNotification: [phase2SeeWonderNotification],
  Phase2UpdateSeeTutoStep: [phase2UpdateSeeTutoStep],
  Phase2SeeCampaignEndedModal: [phase2SeeCampaignEndedModal],
  UnlockQr: [unlockQr],
  SendLeaderboard: [sendLeaderboard],
  SendFinalLeaderboard: [sendFinalLeaderboard],
  SynchronizeLeaderboard: [synchronizeLeaderboard]
  
}

export const createMainSagas = () => {
  return createSagaRunner(sagas, firebaseAccessor)
}
