import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { authClient }                                                    from './authClient'
import { useCampaign }                                                   from '../GlobalContexts'
import { clientId, PWA }                                                 from '../../clientId'
import { useRealTimeData }                                               from '../Utils/dataClient'
import { canEndChallenge }                                               from '@civitime/game-server/lib/Domain/Phases/phase1/Challenges/Challenges.helpers'
import CryptoJS                                                          from 'react-native-crypto-js'
import { sendLocalCommand }                                              from '../Utils/localServer/localSagaRunner'
import { offlineAuthClient, offlineAuthState$ }                          from './offlineAuthClient'
import { fbApp }                                                         from '../firebase'

const defaultValue = {
  loggedIn: false,
  loading: true,
}
const ProfileContext = createContext(defaultValue)

export const Profile = ({ user, children, firestoreInitiated }) => {
  const [profile, setProfile] = useState(defaultValue)
  const { campaignId, campaignSettings } = useCampaign()
  const userState = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/states/${user?.userId}`, firestoreInitiated
  )
  const technicalProfile = useRealTimeData(`users/${user?.userId}`, firestoreInitiated)
  const userSession = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/sessions/${user?.userId}`, firestoreInitiated
  )
  const publicProfile = useRealTimeData(
    `clients/${clientId}/profiles/${user?.userId}`, firestoreInitiated
  )
  const notifications = filterChallenges(userSession?.challenges, campaignSettings?.challengeEndDate)

  useEffect(() => {
    if (PWA) {
      setProfile({
        ...user,
        userState: userState,
        publicProfile: publicProfile ?? { userId: 'inititiate' },
        notifications: notifications,
        userSession: userSession,
        loggedIn: !!user?.userId,
        loading: !(user?.userId),
        emitCommand: (rawCommand, param) => {
          const campaign = campaignId ? campaignId : param
          return emitCommand(rawCommand, user?.userId, campaign)
        },
        emitClientCommand: (rawCommand) => {
          return emitClientCommand(rawCommand, user?.userId)
        },
      })
    } else {
      let lms, token
      if (window.location.search.match(/\?lms=(\w+)&token=(\w+)/gmi)) {
        [lms, token] = window.location.search.split('?lms=')[1].split('&token=')
        let bytes = CryptoJS.AES.decrypt(decodeURIComponent(token), 'A779E051797812095A9255ADE46850002D15CE235BA3C30C')
        token = bytes.toString(CryptoJS.enc.Utf8)
      }
      if (!user.userId) return setProfile({ ...defaultValue, lmsData: { lms, token } })
      setProfile({
        ...user,
        userState: userState,
        technicalProfile: technicalProfile,
        publicProfile: publicProfile,
        notifications: notifications,
        userSession: userSession,
        lmsData: { lms, token },
        loading:
          !userState?.loaded &&
          !technicalProfile?.loaded &&
          !publicProfile?.loaded,
        emitCommand: (rawCommand, param) => {
          const campaign = campaignId ? campaignId : param
          return emitCommand(rawCommand, user?.userId, campaign)
        },
        emitClientCommand: (rawCommand) => {
          return emitClientCommand(rawCommand, user?.userId)
        },
      })
    }
  }, [user, user?.userId, user?.token, technicalProfile, publicProfile, userState, userSession, notifications, campaignId, firestoreInitiated])
  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  )
}
const DEFAULT_MAX_TRIES = 5
export const Auth = ({ children, firestoreInitiated }) => {
  const [user, setUser] = useState({})
  const tries = useRef(5)
  const saveAuth = async (authState) => {
    await fbApp.auth().signInWithCustomToken(authState?.firebaseToken)
      .catch(async e => {
        await resetToken(authState)
      })
    setUser({
      loggedIn: authState !== null,
      token: authState?.token,
      userId: authState?.id,
      technicalProfile: null,
      publicProfile: null,
      userState: null,
      loading: false,
    })
  }

  const resetToken = async (authState) => {
    try {
      tries.current = (tries.current + 1)
      if (tries.current > DEFAULT_MAX_TRIES) return
      const token = await authClient?.getToken(authState?.id)
      await fbApp?.auth()?.signInWithCustomToken('toto')
      authState?.authState$?.next(v => ({ ...v, firebaseToken: token?.firebaseToken }))
      setUser({
        loggedIn: true,
        token: authState?.token,
        userId: authState?.id,
        technicalProfile: null,
        publicProfile: null,
        userState: null,
        loading: false,
      })
    } catch (e) {
      await resetToken(authState)
    }
  }

  useEffect(() => {
    const sub = authClient.authState$.subscribe((authState) => {
      if (authState?.firebaseToken && !PWA) {
        saveAuth(authState)
      } else {
        offlineAuthClient()
        const sub = offlineAuthState$.subscribe((authState) => {
          setUser(authState)
        })
        return () => {
          sub.unsubscribe()
        }
      }
    })
    return () => {
      sub.unsubscribe()
    }
  }, [])

  return <Profile user={user} firestoreInitiated={firestoreInitiated}>{children}</Profile>
}

export const useProfile = () => {
  return useContext(ProfileContext)
}

export const pushCommand = async (command) => {
  return sendLocalCommand(command)
}

const emitCommand = async (rawCommand, userId, campaignId) => {
  if (!rawCommand || !userId) return
  const command = {
    ...rawCommand,
    payload: {
      ...rawCommand?.payload,
      emitter: userId,
      campaignId: campaignId,
      clientId: clientId,
    },
  }
  return await pushCommand(command)
}

const emitClientCommand = async (rawCommand, userId) => {
  if (!rawCommand || !userId) return
  const command = {
    ...rawCommand,
    payload: {
      ...rawCommand?.payload,
      emitter: userId,
      clientId: clientId,
    },
  }
  return await pushCommand(command)
}

const filterChallenges = (challenges, challengeEndDate) => {
  const challengesArray = Object.entries(challenges || []).map(([key, value]) => ({ ...value, challengeId: key }))
  const seeAbleChallenges = challengesArray.filter(
    (challenge) => (challenge.status === 'ended' &&
      (challenge?.opponentResult || challenge?.opponentResult === 0)
      || canEndChallenge(challenge.timeStamp, challengeEndDate) && challenge.status === 'ended')
  ).length
  const playableChallenges = challengesArray.filter(challenge => challenge.status === 'started').length
  return playableChallenges + seeAbleChallenges
}
