import React, { useRef } from "react"
import { ScrollView } from "react-native"
import styled from "@emotion/native"
import { T } from "../../translations/translate"
import { clientId } from "../../../clientId"
import { useCampaign } from "../../GlobalContexts"
import { useRealTimeData } from "../../Utils/dataClient"

import { MembersList } from "../../Teams/components/MembersList"

import { Title2 } from "@civitime/library/storybook/stories/Texts"
import { Blazon } from "@civitime/library/storybook/stories/Blazon"
import { Dialog } from "@civitime/library/storybook/stories/Dialog"

export const TeamDetails = ({ visible, onRequestClose, teamId, phase, mobile}) => {
  const root = useRef(null)
  const { campaignId } = useCampaign()

  const team = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${teamId}`
  )

  return team ? (
    <Dialog noBackgroundShadow closable styled={{maxWidth: mobile ? 'unset' : 500}} {...{ visible, onRequestClose }}>
      <ScrollView style={{ flex: 1 }}>
        <WrapperTeamDetails ref={root}>
          <Blazon
            phase={phase}
            blazon={team.logo.shape}
            color={team.logo.color}
            text={team.logo.text}
            size={50}
            name={team.name}
          />
          <TeamMembers>
            <T path="app.leaderboards.playerLeaderboard.membersInTeam" />
          </TeamMembers>
          <MembersList members={team.members} />
        </WrapperTeamDetails>
      </ScrollView>
    </Dialog>
  ) : null
}

const WrapperTeamDetails = styled.View({
  justifyContent: "center",
  alignItems: "center",
})

const TeamMembers = styled(Title2)({
  marginTop: 30,
  marginBottom: 15,
})
