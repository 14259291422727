import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DefaultModal }                                from '@civitime/library/storybook/stories/Modals'
import { colors }                                      from '@civitime/library/storybook/configs/colors'
import { useScreenDimensions }                         from '@civitime/library/storybook/hooks/useScreenDimensions'
import styled                                          from '@emotion/native'
import { MediumText, SmallText, Title1 }               from '@civitime/library/storybook/stories/Texts'
import { PressedInCard }                               from '@civitime/library/storybook/stories/Cards'
import { ModalBuildingCard }                           from './ModalBuildingCard'
import { Animated }                                    from 'react-native'
import FlouzIcon                                       from './Assets/flouz.svg'
import ScoreIcon                                       from './Assets/score.svg'
import { CustomScrollbar }                             from '../CustomScrollbar'
import { useCampaign }                                 from '../../../../GlobalContexts'
import { clientId, PWA }                               from '../../../../../clientId'
import { useRealTimeData }                             from '../../../../Utils/dataClient'
import { useProfile }                                  from '../../../../Auth/useProfile'
import { nanoid }                                      from 'nanoid'
import { TooltipTuto }                                 from '../Tuto/TooltipTuto'
import { _backupLastBuildingModalSeen }                from '../../Map/MapBuildings'
import { _focusOnElement }                             from '../../Map/MapPhase2'
import { HeaderModal }                                 from './HeaderModal'
import { T, useTranslator }                            from '../../../../translations/translate'

export const BuildingModal = ({ infos, onClose, linkModal }) => {
  const { height, mobile } = useScreenDimensions()
  const [buildingFinished, setBuildingFinished] = useState(false)
  const { campaignId, campaignSettings, campaignNavigate, campaignEnded } = useCampaign()
  const { userState, userSession } = useProfile()
  const [scrollbarSizes, setScrollbarSizes] = useState({})
  const [scrollbarPositions, setScrollbarPositions] = useState({})
  const buildingsStepsRef = useRef(null)
  const { t } = useTranslator()
  const [displayedModalInfos, setDisplayedModalInfos] = useState(false)
  const teamData = (campaignEnded && !userState?.teamId) ?
    userState?.onBoardingGameState
    : useRealTimeData(
      `clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}`
    )
  
  useEffect(() => {
    if (!buildingsStepsRef?.current || !userSession?.buildings?.[infos?.type]?.stepsPlayed || !campaignSettings?.buildings) return
    const currentStepProgress = Object.values(userSession?.buildings?.[infos?.type]?.stepsPlayed || {})?.length
    const buildingMaxSteps = Object.values(campaignSettings?.buildings || {}).filter(e => e?.type === infos?.type)?.[0]?.steps
    if (currentStepProgress === buildingMaxSteps) return
    const buildingCardWidth = 175
    const scrollBarElement = buildingsStepsRef?.current
    scrollBarElement.scrollTo({ x: buildingCardWidth * (currentStepProgress) })
  }, [buildingsStepsRef?.current, userSession?.progressionSeen, campaignSettings?.buildings])
  
  useEffect(() => {
    if (!teamData?.buildingsState?.[infos?.type]?.step || !campaignSettings?.buildings) return
    const buildingIsFinished = teamData?.buildingsState?.[infos?.type]?.step === Object.values(campaignSettings?.buildings).filter((v => v?.type === infos?.type))?.[0]?.steps
    
    setBuildingFinished(buildingIsFinished)
  }, [teamData?.buildingsState?.[infos?.type]?.step, campaignSettings?.buildings, infos?.type])
  
  const lockBuildingsFromOrder = campaignSettings?.lockBuildingsFromOrder
  
  const getRawBuildingStep = useMemo(() => {
    if (!infos?.type) return
    if (lockBuildingsFromOrder && infos?.type !== 'merveille') {
      return Math.max(...(Object.entries(userSession?.buildings?.[infos?.type]?.stepsPlayed || {})
        .filter(([_, v]) => v.win)
        .map(([k, _]) => parseInt(k))?.length ? Object.entries(userSession?.buildings?.[infos?.type]?.stepsPlayed || {})
        .filter(([_, v]) => v.win)
        .map(([k, _]) => parseInt(k)) : [0]))
    }
    
    return infos?.type === 'merveille' ?
      (teamData?.wonderState?.step ? teamData?.wonderState?.step : (teamData?.wonderState ? 1 : null))
      : (teamData?.buildingsState?.[infos?.type]?.step ? teamData?.buildingsState?.[infos?.type]?.step : (teamData?.buildingsState?.[infos?.type] ? 1 : 1))
  }, [infos?.type, teamData, lockBuildingsFromOrder])
  const buildingSteps = useMemo(() => {
    const buildingStepsArray = []
    for (let i = 0; i < infos.steps; i++) {
      buildingStepsArray.push(i)
    }
    return buildingStepsArray
  }, [])
  return <>
    <HeaderModal mobile={mobile}/>
    <DefaultModal title={t(`phase_2.modals.buildingModal.buildings.${infos?.type}.name`)}
                  maxHeight={height * 0.9}
                  onPress={onClose}
                  backgroundColor={colors.lightBlue}
                  color={colors.text}
                  infoButton={() => setDisplayedModalInfos((e) => !e)}
                  withShadow>
      <WrapperBuildingsModal>
        {
          displayedModalInfos &&
          <TooltipTuto
            color={colors.white}
            backgroundColor={colors?.text}
            style={{
              position: 'absolute',
              top: 20,
              left: 3,
              zIndex: 2,
              transform: [{ scale: 0.8 }],
              transformOrigin: 'top left'
            }}
            cornerLeft={15}
            cornerTop={-15}
            infos={{
              title: t(`phase_2.modals.buildingModal.buildings.${infos.type}.name`),
              text: t(`phase_2.tutorial.tooltips.buildingModal.text`),
              onClose: () => setDisplayedModalInfos(false)
            }}/>
        }
        <WrapperHorizontalScrollbar>
          <CustomScrollbar heightThumb={scrollbarSizes?.width || 0}
                           scrollbarPositions={scrollbarPositions}
                           widthThumb={16}
                           widthTrack={10}
                           customPositionLeft={20}
                           backgroundColor={colors.white}
                           color={colors.defaultBackground}
                           horizontal
          />
          <WrapperConstructionCards horizontal
                                    showsHorizontalScrollIndicator={false}
                                    ref={buildingsStepsRef}
                                    scrollEventThrottle={80}
                                    onLayout={(e) => setScrollbarSizes(e?.nativeEvent?.layout)}
                                    onScroll={(e) => setScrollbarPositions(e?.nativeEvent)}
          >
            {
              Object.values(buildingSteps || {}).map((_, index) => {
                const cantPlayNow = index > 0 ? !userSession?.buildings?.[infos?.type]?.stepsPlayed?.[index]?.win : false
                const alreadyPlayed = userSession?.buildings?.[infos?.type]?.stepsPlayed?.[index + 1]?.win
                return <ModalBuildingCard
                  buttonText={alreadyPlayed ? t('phase_2.modals.buildingModal.global.replayButton')
                    : t('phase_2.modals.buildingModal.global.playButton')}
                  disabledButtonText={t('phase_2.modals.buildingModal.global.disabledPlayButton')}
                  neverPlay={!userSession?.buildings && (index === 0)}
                  levelBonus={teamData?.bonusLevel}
                  alreadyPlayed={alreadyPlayed}
                  buildingLocked={userSession?.buildings?.[infos?.type]?.locked}
                  cantPlayNow={cantPlayNow}
                  key={index + 'building'}
                  lock={lockBuildingsFromOrder ? cantPlayNow
                    : index + 1 >= (teamData?.buildingsState?.[infos?.type]?.step + 1 || 2)}
                  image={`https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/buildings/${infos?.type}/${infos?.type}.png`}
                  onPress={() => {
                    
                    const moduleSettings = campaignSettings?.modulesSettings?.[infos?.type]?.[index + 1]
                    
                    if (userSession?.buildings?.[infos?.type]?.locked) return
                    if (campaignSettings?.qrLockedBuildings && !userSession?.buildings?.[infos?.type]?.stepsPlayed?.[index + 1]?.qrUnlocked) {
                      _backupLastBuildingModalSeen.next(infos)
                      linkModal(null)
                      campaignNavigate(`qrcode/${infos?.type}/${index + 1}/${moduleSettings?.module}/${moduleSettings?.theme}`)
                      return
                    } else if (PWA) {
                      if (!campaignSettings?.modulesSettings?.[infos?.type]?.[index + 1]) return
                      const moduleId = nanoid()
                      _backupLastBuildingModalSeen.next(infos)
                      linkModal(null)
                      campaignNavigate(`module/${infos?.type}/${index + 1}/${moduleSettings?.module}/${moduleSettings?.theme}/${moduleId}/origin`)
                      return
                    }
                    linkModal({
                      type: 'module',
                      infos: {
                        title: t(`phase_2.modals.buildingModal.buildings.${infos.type}.launchModuleModal.title`),
                        textContent: t(`phase_2.modals.buildingModal.buildings.${infos.type}.launchModuleModal.text`),
                        buttonText: t(`phase_2.modals.buildingModal.buildings.${infos.type}.launchModuleModal.buttonText`),
                        asset: t(`phase_2.modals.buildingModal.buildings.${infos.type}.launchModuleModal.asset`),
                        action: async () => {
                          if (!campaignSettings?.modulesSettings?.[infos?.type]?.[index + 1]) return
                          const moduleId = nanoid()
                          _backupLastBuildingModalSeen.next(infos)
                          linkModal(null)
                          campaignNavigate(`module/${infos?.type}/${index + 1}/${moduleSettings?.module}/${moduleSettings?.theme}/${moduleId}/origin`)
                        },
                        onClose: () => linkModal({
                          type: 'construction',
                          infos: infos
                        })
                      },
                    })
                  }}>
                  <WrapperContentCard>
                    <FirstLine>
                      <LevelText>
                        <T path="phase_2.modals.buildingModal.global.currentLevelPwa" data={{ 0: index + 1 }}/>
                      </LevelText>
                    </FirstLine>
                    <SecondLine>
                      <RewardText>
                        <T path="phase_2.modals.buildingModal.global.rewardValue"
                           data={{ 0: campaignSettings?.buildingsRules?.[index]?.reward }}/>
                      </RewardText>
                      <FlouzIcon width={20} height={20}/>
                      <RewardText>+</RewardText>
                      <ScoreIcon width={20} height={20}/>
                    </SecondLine>
                  </WrapperContentCard>
                </ModalBuildingCard>
              })
            }
          </WrapperConstructionCards>
        </WrapperHorizontalScrollbar>
        <WrapperContent mobile={mobile}>
          <BuildingDescription mobile={mobile}>
            <T path={`buildings.${infos?.type}`}/>
          </BuildingDescription>
          <WrapperBuildingEvolution noHover customPadding={20} mobile={mobile}>
            <WrapperMakingEvolution>
              {
                !buildingFinished &&
                <TextEvolution>
                  <T path={getRawBuildingStep >= infos?.steps ? `phase_2.modals.buildingModal.global.buildingFinished`
                    : getRawBuildingStep > 1 ? `phase_2.modals.buildingModal.global.levelsAvailable`
                      : `phase_2.modals.buildingModal.global.levelAvailable`}
                  />
                </TextEvolution>
              }
              <WrapperEvolutionProgress mobile={mobile}>
                <WrapperProgressBar>
                  <EvolutionProgressBar>
                    {
                      new Array(infos?.steps || 0).fill(0).map((_e, index) => {
                        const levelWidth = 200 / infos?.steps
                        return <LevelProgression width={levelWidth}
                                                 done={(index + 1) <= getRawBuildingStep}
                                                 firstLevel={index === 0}
                                                 lastLevel={index === (new Array(infos?.steps || 0).fill(0).length - 1)}
                        >
                          <LevelIndicator>{index + 1}</LevelIndicator>
                        </LevelProgression>
                      })
                    }
                  </EvolutionProgressBar>
                </WrapperProgressBar>
              </WrapperEvolutionProgress>
            
            </WrapperMakingEvolution>
            <ButtonToReturnOrigin onPress={() => {
              _focusOnElement.next({
                x: campaignSettings?.townHall?.positions?.x,
                y: campaignSettings?.townHall?.positions?.y
              })
              linkModal({ type: 'mairie' })
            }}>
              <TextReturnOrigin mobile={mobile}>
                Retour EVAHOME
              </TextReturnOrigin>
            </ButtonToReturnOrigin>
          </WrapperBuildingEvolution>
        </WrapperContent>
      </WrapperBuildingsModal>
    </DefaultModal>
  </>
}

const LevelProgression = styled.View(({ width, done, firstLevel, lastLevel }) => ({
  position: 'relative',
  width: width,
  height: '100%',
  borderWidth: 1,
  borderColor: colors.lightShadow,
  backgroundColor: done ? colors.secondary : 'transparent',
  borderTopRightRadius: lastLevel && 18,
  borderBottomRightRadius: lastLevel && 18,
  borderTopLeftRadius: firstLevel && 18,
  borderBottomLeftRadius: firstLevel && 18,
}))

const LevelIndicator = styled.Text({
  position: 'absolute',
  fontSize: 12,
  fontWeight: 'bold',
  color: colors.text,
  top: -20,
  right: -4,
})

export const NoFlouzTextMessage = styled(SmallText)({
  position: 'absolute',
  left: '50%',
  bottom: -15,
  transform: [{ translateX: '-50%' }],
  fontSize: 8,
  width: '100%',
  fontWeight: 'bold'
})

export const WrapperHorizontalScrollbar = styled.View({
  position: 'relative',
  paddingBottom: 20
})

const WrapperBuildingsModal = styled.View({
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
  paddingTop: 10,
  paddingRight: 10,
  paddingBottom: 40,
  paddingLeft: 10
})

const WrapperConstructionCards = styled.ScrollView({
  width: '100%',
  overflow: 'hidden'
})

const WrapperContent = styled.View(({ mobile }) => ({
  width: '100%',
  flexDirection: mobile ? 'column' : 'row',
}))

const BuildingDescription = styled(MediumText)(({ mobile }) => ({
  textAlign: 'left',
  alignSelf: 'center',
  width: mobile ? '100%' : '50%',
  padding: 15
}))

const WrapperBuildingEvolution = styled(PressedInCard)(({ mobile }) => ({
  width: mobile ? '100%' : '50%',
  marginTop: 20,
}))

const WrapperContentCard = styled.View({
  flexDirection: 'column',
})

const FirstLine = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  height: 35,
  paddingRight: 5,
  paddingLeft: 5,
})

const SecondLine = styled(FirstLine)({
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingRight: 5,
  paddingLeft: 5,
})

const LevelText = styled(MediumText)({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  whiteSpace: 'noWrap',
  fontSize: 13,
})

const RewardText = styled(MediumText)({
  marginRight: 5,
  marginLeft: 5,
  color: colors.text,
  fontSize: 15,
  fontWeight: 'bold',
  whiteSpace: 'noWrap'
})

const WrapperMakingEvolution = styled.View({
  flexDirection: 'column',
  width: '100%'
})

const TextEvolution = styled(Title1)({
  fontSize: 15
})

const WrapperEvolutionProgress = styled.View(({ mobile }) => ({
  flexDirection: mobile ? 'column' : 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  marginTop: 30,
}))

export const EvolutionProgressBar = styled.View(({ merveille }) => ({
  position: 'relative',
  width: 200,
  height: 15,
  borderRadius: 18,
  boxShadow: 'inset 1px 1px 3px rgba(94, 104, 121, 0.692), inset -1px -1px 4px #FFFFFF',
  flexDirection: 'row',
  overflow: merveille && 'hidden'
}))

export const ProgressBar = styled(Animated.View)(({ progress }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: '-100%',
  willChange: 'transform',
  backgroundColor: colors.secondary,
  transform: [{ translateX: progress }]
}))

export const WrapperProgressBar = styled.View({
  position: 'relative'
})

export const TextProgressBar = styled(MediumText)({
  position: 'absolute',
  right: 0,
  top: -25
})

const ButtonToReturnOrigin = styled.TouchableWithoutFeedback({})

const TextReturnOrigin = styled(MediumText)(({ mobile }) => ({
  position: 'absolute',
  fontWeight: 'bold',
  bottom: -30,
  left: '50%',
  transform: [{ translateX: '-50%' }],
  width: mobile ? '100%' : 'auto'
}))