import React from "react"
import styled from "@emotion/native"
import {Phase2WrapperLeftContent} from "../AppSkeleton/Phase2WrapperLeftContent"

export const HeaderModal = ({mobile}) => {

  return <>
    {
      mobile ?
        <WrapperHeaderModalMobile>
          <Phase2WrapperLeftContent noAvatarCanPress noAvatar noMargin/>
        </WrapperHeaderModalMobile>
        : <WrapperHeaderModal>
          <Phase2WrapperLeftContent noAvatarCanPress/>
        </WrapperHeaderModal>
    }
  </>
}

const WrapperHeaderModalMobile = styled.View({
  top: -10,
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-around',
})

const WrapperHeaderModal = styled.View({
  position: 'absolute',
  top: '2.5%',
  left: '2.5%',
})
