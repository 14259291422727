import {useCampaign} from "../../../../GlobalContexts"
import {useProfile}  from "../../../../Auth/useProfile"
import {useEffect}   from "react"
import { PWA }       from '../../../../../clientId'

export const Phase2Init = () => {
  const {campaignNavigate, campaignSettings, campaignEnded, campaignNotStarted} = useCampaign()
  const {userState, loading, publicProfile} = useProfile()
  useEffect(() => {
    if (!loading) {
      if (publicProfile?.avatar) {
        return campaignNavigate("");
      }
      if (!publicProfile?.avatar) {
        return campaignNavigate("avatar");
      }
    }

    if (!userState?.teamId && !campaignEnded && !PWA) {
      return campaignNavigate("team");
    }

    if (campaignNotStarted && !PWA) {
      campaignNavigate("team");
    }
  }, [userState, publicProfile?.avatar, publicProfile, campaignSettings?.endAt, campaignNotStarted])

  return null
}


