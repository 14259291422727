import React, { useRef, useState, useEffect } from "react"
import { useParams, useLocation } from "react-router-native"
import styled from "@emotion/native"
import { T, useTranslator } from "../translations/translate"
import { useProfile } from "../Auth/useProfile"
import { useCampaign } from "../GlobalContexts"
import { MembersList } from "./components/MembersList"
import { JoinTeamAlert } from "./components/JoinTeamAlert"

import { colors } from "@civitime/library/storybook/configs/colors"
import { MOBILE_HEADER_SIZE } from "@civitime/library/storybook/configs/sizes"
import { useScreenDimensions } from "@civitime/library/storybook/hooks/useScreenDimensions"
import { DefaultModal } from "@civitime/library/storybook/stories/Modals"
import {
  Title2,
  BoldText,
  MediumText,
} from "@civitime/library/storybook/stories/Texts"
import { ActionButton } from "@civitime/library/storybook/stories/Button"
import { Blazon } from "@civitime/library/storybook/stories/Blazon"

export const TeamInfos = ({phase}) => {
  const root = useRef(null)
  const { t } = useTranslator()
  const { height } = useScreenDimensions(root)
  const { campaignNavigate, campaignSettings } = useCampaign()
  const teamMax = campaignSettings?.teamMax
  const { emitCommand } = useProfile()
  const { teamId } = useParams()
  const { state: team } = useLocation()
  const [alertJoinTeam, setAlertJoinTeam] = useState(false)
  const [joinTeamId, setJoinTeamId] = useState(null)

  const joinTeam = (id) => {
    emitCommand({
      type: "JoinTeam",
      payload: {
        teamId: id,
      },
    }).then(
      (value) => value[0].type === "TeamJoined" && campaignNavigate("team")
    )
  }

  return team ? (
    <>
      <DefaultModal
        backgroundColor={colors.lightBlue}
        color={colors.text}
        title={teamId + t("app.team.teamPlayerList")}
        onPress={() => campaignNavigate("team")}
        maxHeight={(height - MOBILE_HEADER_SIZE) * 0.99}
        withShadow
      >
        <WrapperTeamInfos ref={root}>
          <Blazon
            phase={phase}
            blazon={team.logo.shape}
            color={team.logo.color}
            text={team.logo.text}
            size={50}
            name={teamId}
          />
          <WelcomeTitle>
            <Title2>{team.members?.length}</Title2>/{teamMax}
            {team?.members?.length > 1
              ? " " + t("app.team.members")
              : " " + t("app.team.member")}
          </WelcomeTitle>
          <MembersList members={team.members} />
          <JoinButton
            onPress={() => {
              setAlertJoinTeam(true)
              setJoinTeamId(team.id)
            }}
          >
            <T path="app.team.join" />
          </JoinButton>
          <BackButton onPress={() => campaignNavigate("team")}>
            <T path="app.team.backTeamChoice" />
          </BackButton>
        </WrapperTeamInfos>
      </DefaultModal>
      <JoinTeamAlert
        visible={alertJoinTeam}
        onRequestClose={() => {
          setAlertJoinTeam(false)
          setJoinTeamId(null)
        }}
        navToTeam={() => joinTeam(joinTeamId)}
      />
    </>
  ) : (
    <>{campaignNavigate("team")}</>
  )
}

const WrapperTeamInfos = styled.View({
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 25,
})

const WelcomeTitle = styled(MediumText)({
  marginVertical: 15,
})

const JoinButton = styled(ActionButton)({
  marginTop: 20,
})

const BackButton = styled(BoldText)({
  margin: 15,
})
