import { Text }             from 'react-native-web'
import { useProfile }       from '../../../../../Auth/useProfile'
import { AvatarComponent }  from '@civitime/library/storybook/stories/Avatar'
import { colors }           from '@civitime/library/storybook/configs/colors'
import { ChallengeIcon }    from '@civitime/library/storybook/stories/Icons'
import React                from 'react'
import { BoldText, Title2 } from '@civitime/library/storybook/stories/Texts'
import styled               from '@emotion/native'
import { useDataLoading }   from '../../../../../Utils/dataClient'
import { clientId }         from '../../../../../../clientId'
import { useCampaign }      from '../../../../../GlobalContexts'
import { useHovered }       from '@civitime/library/storybook/hooks/useHovered'
import { T, useTranslator } from '../../../../../translations/translate'

export const ChallengeDisplay = ({ challenge, type, seeResult, mobile, phase }) => {
  const { publicProfile } = useProfile()
  const opponentProfile = useDataLoading(`clients/${clientId}/profiles/${challenge.opponentUid}`)
  const { campaignNavigate, campaignSettings } = useCampaign()
  const {t} = useTranslator()
  const opponentResult = challenge?.opponentUid?.includes('-bot') ? (campaignSettings?.botResult || 2) : challenge?.opponentResult
  return <ChallengeWrapper>
    <WrapperMembersList>
      <PlayerWrapper mobile={mobile} left>
        <AvatarComponent avatar={publicProfile?.avatar} circleAvatarConfig={{
          size: 60,
          withBorder: true,
          backgroundColor: colors.extraLightBackground,
        }}/>
        <PlayerName>{publicProfile?.name}</PlayerName>
      </PlayerWrapper>
      <ScoreWrapper>
        <Score>{challenge?.result || challenge?.result === 0 ? challenge?.result : '-'}</Score>
        <WrapperChallengeIcon height={33} width={15} color={colors.secondary}/>
        <Score>{typeof opponentResult === 'number' ? opponentResult : '-'}</Score>
      </ScoreWrapper>
      <PlayerWrapper mobile={mobile} reverse right>
        <PlayerName>{opponentProfile?.data?.name}</PlayerName>
        <AvatarComponent avatar={opponentProfile?.data?.avatar} circleAvatarConfig={{
          size: 60,
          withBorder: true,
          backgroundColor: colors.extraLightBackground,
        }}/>
      </PlayerWrapper>
    </WrapperMembersList>
    {
      type === 'play' && <ButtonWrapper>
        <ChallengeButton challengeId={challenge.id}
                         onPress={() => {
                           if(phase === 1) {
                             campaignNavigate(`challenges/${challenge?.type}/${challenge?.level}/${challenge?.challengeId}`)
                           } else if (phase === 2) {
                             campaignNavigate(`module/${challenge?.moduleName}/${challenge?.level}/${challenge?.type}/${challenge?.theme}/${challenge?.challengeId}/notOrigin`)
                           }
                         }}
                         title={t('modules.challengesHistory.buttons.play')}/>
      </ButtonWrapper>
    }
    {
      type === 'seeResult' && <ButtonWrapper>
        <ChallengeButton onPress={() => seeResult(challenge)} title={t('modules.challengesHistory.buttons.seeResult')}/>
      </ButtonWrapper>
    }
    {
      type === 'awaiting' && <ButtonWrapper>
        <ChallengeButton onPress={() => campaignNavigate(`challenges/${challenge?.challengeId}`)} title={t('modules.challengesHistory.buttons.awaiting')}
                         disabled/>
      </ButtonWrapper>
    }
  </ChallengeWrapper>
}

export const ChallengeButton = ({ onPress, title, disabled = false }) => {
  const [hovered, hoverProps] = useHovered()
  return <ChallengeButtonWrapper onPress={onPress} disabled={disabled}
                                 {...hoverProps} hover={hovered}>
    <ChallengeButtonText hover={hovered} disabled={disabled}>{title}</ChallengeButtonText>
  </ChallengeButtonWrapper>
}

export const NoChallengesToDisplay = () => <NoChallengesToDisplayWrapper>
  <Text>
    <T path="modules.challengesHistory.empty"/>
  </Text>
</NoChallengesToDisplayWrapper>


const NoChallengesToDisplayWrapper = styled.View({
  height: '100%',
  marginTop: 20,
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center'
})

export const ScoreWrapper = styled.View({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: [{translateX: '-50%'}, {translateY: '-50%'}],
})

const ButtonWrapper = styled.View({
  display: 'flex',
  alignItems: 'center'
})

const ChallengeWrapper = styled.View({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const ChallengeButtonText = styled(BoldText)(({ hover, disabled }) => ({
  textTransform: 'uppercase',
  color: hover && !disabled ? colors.white : colors.secondary
}))

export const PlayerWrapper = styled.View(({ mobile, reverse, left, right }) => ({
  paddingHorizontal: 20,
  paddingLeft: mobile && left && 0,
  paddingRight: mobile && right && 0,
  flexDirection: mobile && reverse ? 'column-reverse' : mobile ? 'column' : reverse ? 'column-reverse' : 'column',
  justifyContent: reverse ? "flex-end": "flex-start",
  alignItems: 'center',
}))

const PlayerName = styled(Title2)({
  textTransform: 'uppercase',
  margin: 10
})

const Score = styled(PlayerName)({
  flex: 0.4,
  margin: 10
})

const WrapperChallengeIcon = styled(ChallengeIcon)({
  alignItems: 'center'
})

const ChallengeButtonWrapper = styled.TouchableOpacity(({ hover, disabled }) => ({
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  margin: 5,
  opacity: disabled ? .3 : 1,
  borderRadius: 17.5,
  width: 'fit-content',
  backgroundColor: !disabled && hover ? colors.secondary : colors.defaultBackground,
  borderColor: colors.secondary,
  borderWidth: 2
}))

export const WrapperMembersList = styled.View({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  marginTop: 5,
  marginBottom: 5,
  width: '100%',
})
