import { isDev } from './isDev'

export const PWA = true
export const FORCE_PHASE = 2
export const FORCED_CAMPAIGNID = 'leclerc'

export const clientId = extractClientId()

function extractClientId() {
  if (isDev) return 'cttest'
  const parts = window.location.hostname.split('.')
  return parts[0]
}
