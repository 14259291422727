import React, {useRef, useState} from 'react'
import styled from '@emotion/native'
import {T, useTranslator} from '../translations/translate'
import {useCampaign} from '../GlobalContexts'
import {useDataLoading} from '../Utils/dataClient'
import {clientId} from '../../clientId'

import {MyTeamMembersList} from './components/MyTeamMembersList'

import {colors} from '@civitime/library/storybook/configs/colors'
import {MOBILE_HEADER_SIZE} from '@civitime/library/storybook/configs/sizes'
import {DefaultModal} from '@civitime/library/storybook/stories/Modals'
import {Blazon} from '@civitime/library/storybook/stories/Blazon'
import {Dialog} from '@civitime/library/storybook/stories/Dialog'
import {BoldText, MediumText, SmallText, Title1, Title2,} from '@civitime/library/storybook/stories/Texts'
import {ActionButton, LittleTextButton, TextButton,} from '@civitime/library/storybook/stories/Button'
import {InputCard, InputCardWrapper,} from '@civitime/library/storybook/stories/Inputs'
import {authClient} from '../Auth/authClient'
import {useProfile} from '../Auth/useProfile'
import {ErrorAlert, ErrorText} from '@civitime/library/storybook/stories/Error'
import {convertDate} from "../Leaderboards/pages/RankingTabs"
import {TooltipTuto} from "../Phases/Phase 2/Components/Tuto/TooltipTuto"

export const MyTeam = ({teamId, openedHelper, mobile, height, orientation, phase}) => {
  const {t} = useTranslator()
  const {campaignNavigate, campaignId, campaignSettings, campaignNotStarted} = useCampaign()
  const teamMax = campaignSettings?.teamMax

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [alertCampaignNotStarted, setAlertCampaignNotStarted] = useState(true)

  const [displayedModalInfos, setDisplayedModalInfos] = useState(false)

  const {data: team, loaded} = useDataLoading(
    `clients/${clientId}/campaigns/${campaignId}/teams/${teamId}`,
    [],
    false
  )

  return loaded ? (
    <>
      <DefaultModal
        backgroundColor={colors.lightBlue}
        color={colors.text}
        title={t('app.team.yourTeam')}
        onPress={(campaignNotStarted && alertCampaignNotStarted) ? false : () => {
          if (campaignNotStarted) {
            setAlertCampaignNotStarted(true)
          } else {
            campaignNavigate('')
          }
        }}
        maxHeight={(height - MOBILE_HEADER_SIZE) * 0.99}
        withShadow
        infoButton={() => {
          if(phase !== 2) return false
          setDisplayedModalInfos((e) => !e)
        }}
      >
        {
          displayedModalInfos &&
          <TooltipTuto
            color={colors.white}
            backgroundColor={colors?.text}
            style={{
              position: 'absolute',
              top: 20,
              left: 3,
              zIndex: 2,
              transform: [{scale: 0.8}],
              transformOrigin: 'top left'
            }}
            cornerLeft={15}
            cornerTop={-15}
            infos={{
              title: t('app.team.tooltip.title'),
              text: t("app.team.tooltip.text"),
              onClose: () => setDisplayedModalInfos(false)
            }}/>
        }
        {
          campaignNotStarted ? alertCampaignNotStarted ? <WrapperAlertCampaignNotStarted mobile={mobile}>
              <MediumText style={{lineHeight: !mobile ? 30 : 'unset'}}>
                <T path="app.team.campaignNotStarted.alertCampaignNotStartedText" data={{
                  date: convertDate(campaignSettings.startAt)
                }}/>
              </MediumText>
              <PlayButton onPress={() => setAlertCampaignNotStarted(false)}>
                <T path="app.team.campaignNotStarted.showMyTeam"/>
              </PlayButton>
            </WrapperAlertCampaignNotStarted> :
            <>
              <IntroText>
                <T path="app.team.youAreInTeam"/>
              </IntroText>
              <WrapperTeam {...{mobile, orientation}}>
                <WrapperTeamInfos>
                  <Blazon
                    phase={phase}
                    size={50}
                    blazon={team.logo.shape}
                    color={team.logo.color}
                    text={team.logo.text}
                  />
                  <TeamName>{team.name}</TeamName>
                </WrapperTeamInfos>
                <LittleTextButton noHover>
                  <BoldText>{team?.members?.length}</BoldText>
                  <SmallText>
                    /{teamMax}
                    {team?.members?.length > 1
                      ? ' ' + t('app.team.members')
                      : ' ' + t('app.team.member')}
                  </SmallText>
                </LittleTextButton>
              </WrapperTeam>
              {team?.members?.length < teamMax && (
                <WrapperInvitation mobile={mobile}>
                  <WrapperInvitText>
                    <InvitText>
                      <T path="app.team.campaignNotStarted.inviteMessage" data={{
                        0: "X"
                      }}/>
                    </InvitText>
                  </WrapperInvitText>
                  <WrapperInvitButton>
                    <TextButton
                      backgroundColor={colors.defaultBackground}
                      onPress={() => setInviteDialogOpen(true)}
                    >
                      <T path="app.team.invite"/>
                    </TextButton>
                  </WrapperInvitButton>
                </WrapperInvitation>
              )}
              <WrapperTeamDetails mobile={mobile}>
                <TeamDetailsIntro>
                  <T path="app.team.teamMemberList"/>
                </TeamDetailsIntro>
                <MyTeamMembersList members={team.members}/>
              </WrapperTeamDetails>
            </>
            : <>
              <IntroText>
                <T path="app.team.youAreInTeam"/>
              </IntroText>
              <WrapperTeam ref={root} {...{mobile, orientation}}>
                <WrapperTeamInfos>
                  <Blazon
                    phase={phase}
                    size={50}
                    blazon={team.logo.shape}
                    color={team.logo.color}
                    text={team.logo.text}
                  />
                  <TeamName>{team.name}</TeamName>
                </WrapperTeamInfos>
                <LittleTextButton noHover>
                  <BoldText>{team?.members?.length}</BoldText>
                  <SmallText>
                    /{teamMax}
                    {team?.members?.length > 1
                      ? ' ' + t('app.team.members')
                      : ' ' + t('app.team.member')}
                  </SmallText>
                </LittleTextButton>
              </WrapperTeam>
              {team?.members?.length < teamMax && (
                <WrapperInvitation mobile={mobile}>
                  <WrapperInvitText>
                    <InvitText>
                      <T path="app.team.invitationText"/>
                    </InvitText>
                  </WrapperInvitText>
                  <WrapperInvitButton>
                    <TextButton
                      backgroundColor={colors.defaultBackground}
                      onPress={() => setInviteDialogOpen(true)}
                    >
                      <T path="app.team.invite"/>
                    </TextButton>
                  </WrapperInvitButton>
                </WrapperInvitation>
              )}
              <WrapperTeamDetails mobile={mobile}>
                <TeamDetailsIntro>
                  <T path="app.team.teamMemberList"/>
                </TeamDetailsIntro>
                <MyTeamMembersList members={team.members}/>
              </WrapperTeamDetails>
              <PlayButton onPress={() => campaignNavigate('')}>
                {openedHelper ? (
                  <T path="app.team.play"/>
                ) : (
                  <T path="app.team.close"/>
                )}
              </PlayButton>
            </>
        }
      </DefaultModal>
      <InvitePlayerAlert
        onRequestClose={() => setInviteDialogOpen(false)}
        visible={inviteDialogOpen}
        {...{team}}
      />
    </>
  ) : null
}

const InvitePlayerAlert = ({onRequestClose, visible, team}) => {
  const {t} = useTranslator()
  const {campaignId} = useCampaign()
  const {emitCommand, userState} = useProfile()
  const [invitePlayerMail, setInvitePlayerMail] = useState(null)
  const [inviteSuccess, setInviteSuccess] = useState(false)
  const [inviteError, setInviteError] = useState(false)
  const [notAnEmailError, setNotAnEmailError] = useState(false)
  const lang = userState.lang
  const isAnEmail = /^.+@.+$/

  const sendInvitation = async () => {
    const res = await emitCommand({
      type: 'CreateInviteTeam',
      payload: {email: invitePlayerMail, teamId: team.id},
    }).then((value) => {
      if (!invitePlayerMail.match(isAnEmail)) return setNotAnEmailError(true)
      setNotAnEmailError(false)
      if (value[0].type === 'InviteTeamCreated') {
        authClient.invitePlayer({
          email: invitePlayerMail,
          campaignId,
          clientId,
          teamName: team.name,
          lang: lang
        })
        setInviteSuccess(true)
      }
      if (value[0].type === 'InviteErrors_PlayerAlreadyExists') {
        authClient.invitePlayer({
          email: invitePlayerMail,
          campaignId,
          clientId,
          teamName: team.name,
          lang: lang
        })
        setInviteError(true)
      }
    })
  }

  const closeAlert = () => {
    setInvitePlayerMail(null)
    setInviteSuccess(false)
    onRequestClose()
  }
  return (
    <Dialog {...{visible}} onRequestClose={closeAlert} closable>
      <WrapperOpacitySuccess visible={inviteSuccess} disabled>
        <WarningTitle>
          <T path="app.team.invitePlayer"/>
        </WarningTitle>
        <MediumText>
          <T path="app.team.inviteAddMail"/>
        </MediumText>
        {inviteError && (
          <ErrorAlert>
            <ErrorText>
              <T path={'app.team.errors.playerAlreadyJoined'}/>
            </ErrorText>
          </ErrorAlert>
        )}
        {
          notAnEmailError && (
            <ErrorAlert>
              <ErrorText>
                <T path={'app.team.errors.notAnEmail'}/>
              </ErrorText>
            </ErrorAlert>
          )
        }
        <ModalActionButtonWrapper>
          <InputMailInvit>
            <InputCard
              name="email"
              autofocus={true}
              //TODO: placeholder wont work on IE
              // placeholder={t("app.team.playerMail")}
              onChangeText={(e) => {
                setInviteError(false)
                setInvitePlayerMail(e)
              }}
              keyboardType="email-address"
              autoCompleteType="email"
              returnKeyType="send"
              onSubmitEditing={sendInvitation}
              disabled={inviteSuccess}
            />
          </InputMailInvit>
          <ActionButton
            onPress={sendInvitation}
            disabled={inviteSuccess || !invitePlayerMail}
          >
            <T path="app.team.invite"/>
          </ActionButton>
        </ModalActionButtonWrapper>
      </WrapperOpacitySuccess>
      {inviteSuccess && (
        <WrapperInvitSuccess>
          <InvitSuccess>
            <T path="app.team.inviteSuccess"/>
          </InvitSuccess>
          <ActionButton onPress={closeAlert}>
            <T path="app.global.close"/>
          </ActionButton>
        </WrapperInvitSuccess>
      )}
    </Dialog>
  )
}

const WrapperAlertCampaignNotStarted = styled.View(({mobile}) => ({
  marginTop: 30,
  paddingLeft: mobile ? 10 : 0,
  paddingRight: mobile ? 10 : 0,
}))

const IntroText = styled(Title2)({
  marginVertical: 15,
})

const WrapperTeam = styled.View(
  {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ({mobile, orientation}) => ({
    marginHorizontal: mobile && orientation === 'portrait' ? 0 : 150,
  })
)

const WrapperTeamInfos = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

const TeamName = styled(BoldText)({
  textTransform: 'uppercase',
  marginLeft: 20,
})

const WrapperInvitation = styled.View(({mobile}) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: mobile ? 10 : 20,
  paddingHorizontal: mobile ? 20 : 30,
  paddingVertical: 10,
  backgroundColor: colors.secondaryLight,
}))

const InvitText = styled(MediumText)({
  textAlign: 'left',
})

const WrapperInvitText = styled.View({
  flex: 0.7,
})

const WrapperInvitButton = styled.View({
  flex: 0.3,
  alignItems: 'center',
})

const WrapperTeamDetails = styled.View(({mobile}) => ({
  alignItems: 'center',
  height: mobile ? 220 : 250,
  overflowY: 'scroll'
}))

const TeamDetailsIntro = styled(Title2)({
  marginVertical: 15,
})

const PlayButton = styled(ActionButton)({
  alignSelf: 'center',
  marginTop: 20,
  marginBottom: 30,
})

const WrapperOpacitySuccess = styled.View(
  ({visible}) =>
    visible && {
      opacity: 0.5,
    }
)

const WarningTitle = styled(Title1)({
  marginBottom: 13,
  textTransform: 'uppercase',
})

const ModalActionButtonWrapper = styled.View({
  marginTop: 30,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const InputMailInvit = styled(InputCardWrapper)({
  flex: 1,
  marginRight: 30,
})

const WrapperInvitSuccess = styled.View({
  alignItems: 'center',
  marginTop: 35,
})

const InvitSuccess = styled(Title1)({
  marginBottom: 15,
})
