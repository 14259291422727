import React, {useEffect, useMemo, useRef, useState} from "react"
import {useCampaign} from "../GlobalContexts"
import {T, useTranslator} from "../translations/translate"
import {calculateActualActionPoints} from "@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers"
import {colors} from "@civitime/library/storybook/configs/colors"
import {ActionPointsCounter, PointsCounter} from "@civitime/library/storybook/stories/Counters"
import styled from "@emotion/native"
import {
  LangSelectorButton,
  LeaderboardButton,
  ReturnToMapButton,
  SettingsButton
} from "@civitime/library/storybook/stories/Button"
import {AvatarComponent} from "@civitime/library/storybook/stories/Avatar"
import {Phase2HomeButtons, Phase2WrapperRightContent} from "../Phases/Phase 2/Components/AppSkeleton/Phase2Header"
import {Phase2WrapperLeftContent} from "../Phases/Phase 2/Components/AppSkeleton/Phase2WrapperLeftContent"
import {clientId} from '../../clientId'
import {MenuIcon} from './Home'

const HeaderComponentsElements = {
  2: {
    HeaderButtons: Phase2HomeButtons,
    HeaderLeftContent: Phase2WrapperLeftContent,
    HeaderRightContent: Phase2WrapperRightContent
  }
}

export const Header = ({
                         publicProfile,
                         returnToMap,
                         userState,
                         mobile,
                         openHelper,
                         notifications,
                         screenWidth,
                         screenHeight,
                         openLangSelector,
                         phase,
                         toggleHubMenu,
                         isAdmin,
                         isOffline,
                         ...props
                       }) => {
  if (!publicProfile?.avatar || (!props?.onMap && phase === 1)) return null

  const {campaignNavigate, campaignSettings} = useCampaign()
  const [showToolTip, setShowToolTip] = useState(null)
  const cancelTimeout = useRef(null)
  const {t, lang} = useTranslator()

  const HeaderRender = useMemo(() => {
    if (!phase) return null
    return HeaderComponentsElements[phase]
  }, [phase])

  useEffect(() => {
    clearTimeout(cancelTimeout.current)
    if (mobile && showToolTip) {
      cancelTimeout.current = setTimeout(() => setShowToolTip(null), 6000)
    }
  }, [showToolTip])

  const actionPoints = ((phase === 1 || phase === 1.3) && userState) ? calculateActualActionPoints(
    campaignSettings?.actionPointsPerDay,
    campaignSettings?.maxActionPoints,
    userState?.actionPoints,
    new Date().toISOString(),
    campaignSettings?.startAt
  ) : 0

  return (
    <HeaderWrapper hub={!phase}>
      <WrapperLeftContent>
        {
          !phase &&
          <HomeAvatarButton
            playerName={!mobile && publicProfile.name}
            circleAvatarConfig={{
              size: mobile ? 50 : 60,
              withBorder: true,
              backgroundColor: colors.extraLightBackground,
            }}
            cartridgeConfig={{
              width: 128,
              height: 20,
              backgroundColor: colors.text,
              color: colors.lightText,
            }}
            onPress={() => {
              campaignNavigate("avatar")
            }}
            avatar={publicProfile?.avatar}
          />
        }
        {
          (phase === 1 || phase === 1.3) && <>
            <HomeAvatarButton
              playerName={!mobile && publicProfile.name}
              circleAvatarConfig={{
                size: mobile ? 50 : 60,
                withBorder: true,
                backgroundColor: colors.extraLightBackground,
              }}
              cartridgeConfig={{
                width: 128,
                height: 20,
                backgroundColor: colors.text,
                color: colors.lightText,
              }}
              onPress={() => {
                campaignNavigate("avatar")
              }}
              avatar={publicProfile?.avatar}
            />
            {
              !!userState?.actionPoints && <ActionPointsCounter
                actionsPoints={actionPoints}
                startAt={campaignSettings?.startAt}
                hovered={showToolTip?.pa}
                mobile={mobile}
                toolTip={<T path="app.toolTips.pa"/>}
                onMouseEnter={() => setShowToolTip({pa: true})}
                onMouseLeave={() => setShowToolTip(null)}
              />
            }
            {phase && <HomePoints points={userState?.points || 0}
                                  hovered={showToolTip?.points}
                                  toolTip={<T path="app.toolTips.points"/>}
                                  mobile={mobile}
                                  onMouseEnter={() => setShowToolTip({points: true})}
                                  onMouseLeave={() => setShowToolTip(null)}/>}
          </>
        }
        {
          HeaderRender?.HeaderLeftContent &&
          <HeaderRender.HeaderLeftContent showToolTip={showToolTip}
                                          updateShowTooTips={setShowToolTip}
                                          mobile={mobile} {...props}/>
        }
      </WrapperLeftContent>
      {
        HeaderRender?.HeaderButtons &&
        <HeaderRender.HeaderButtons returnToMap={returnToMap} width={screenWidth} height={screenHeight}
                                    mobile={mobile} openHelper={openHelper} phase={phase} {...props}/>
      }
      {
        !phase && <>
          <CiviTimeHubLogo source={require('../../assets/Hub/logo-commityourpeople.png')}/>
          <ClientHubLogo
            source={`https://ct-campaigns.civitimeapp.com/${clientId}/logos/civi-logo.png`}/>
          {isAdmin && <MenuIcon source={require('../../assets/Hub/menu-icon.png')} onClick={toggleHubMenu}/>}
        </>
      }
      <IconsWrapper>
        {
          campaignSettings?.internationalizedContent && phase === 1 && <HomeLangSelectorButton
            size={30}
            iconSize={20}
            onPress={openLangSelector}
            text={lang}
          />
        }
        {
          !phase && <WrapperLangButton>
            <HomeSettingsButton
              size={30}
              iconSize={20}
              onPress={() => campaignNavigate("settings")}
            />
            <HomeLangSelectorButton
              size={30}
              iconSize={20}
              onPress={openLangSelector}
              style={{marginLeft: 10}}
              text={lang}
            />
          </WrapperLangButton>
        }
        {
          (phase === 1 || phase === 1.3) && <>
            <HomeSettingsButton
              size={30}
              iconSize={20}
              onPress={() => campaignNavigate("settings")}
            />
            <HomeLeaderboardButton
              size={30}
              iconSize={20}
              onPress={() => campaignNavigate("leaderboard/team")}
            />
          </>
        }
        {
          HeaderRender?.HeaderRightContent &&
          <HeaderRender.HeaderRightContent notifications={notifications} openLangSelector={openLangSelector} isOffline={isOffline}
                                           lang={lang} {...props}/>
        }
      </IconsWrapper>
      {returnToMap && (
        <AppReturnToMap
          text={t("app.header.returnToMap")}
          onPress={() => campaignNavigate("")}
        />
      )}
    </HeaderWrapper>
  )
}

const WrapperLangButton = styled.View({
  position: 'absolute',
  top: 40,
  right: 0,
  transform: [{translateY: '-50%'}],
  flexDirection: 'row'
})

export const HomeSettingsButton = styled(SettingsButton)({
  position: 'relative',
})

export const HomeLeaderboardButton = styled(LeaderboardButton)({
  position: 'relative',
})

const HomeAvatarButton = styled(AvatarComponent)({
  marginBottom: 20,
})

const WrapperLeftContent = styled.View({
  alignItems: 'center',
  position: 'fixed',
  top: '2.5%',
  left: '2.5%',
})
const HeaderWrapper = styled.View(({hub}) => ({
  alignItems: 'center',
  zIndex: 5,
  // zIndex: 4,
  backgroundColor: hub && '#FFFFFF',
  boxShadow: hub && '0px 4px 4px rgba(0, 0, 0, 0.25)',
  width: hub && '100%',
  height: hub && 110,
  position: hub && 'fixed',
  top: hub && 0,
  left: hub && 0
}))

const AppReturnToMap = styled(ReturnToMapButton)({
  position: 'fixed',
  top: '6%',
  zIndex: 2,
  right: '17%',
})
const HomePoints = styled(PointsCounter)({
  marginVertical: 10,
})

const IconsWrapper = styled.View({
  position: 'fixed',
  top: '2%',
  right: '5%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: 140,
})

export const HomeLangSelectorButton = styled(LangSelectorButton)({
  position: 'relative'
})

const CiviTimeHubLogo = styled.Image({
  position: 'fixed',
  top: '3%',
  left: '20%',
  width: 130,
  height: 60,
})

const ClientHubLogo = styled.Image({
  position: 'fixed',
  top: '3%',
  right: '20%',
  width: 60,
  height: 60,
})
