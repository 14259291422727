import React from "react"
import {useRealTimeData} from "../../Utils/dataClient"
import {clientId} from "../../../clientId"
import {useProfile} from "../../Auth/useProfile"
import {useCampaign} from "../../GlobalContexts"

import {TopRanking} from "./TopRanking"
import {TopRankingWrapper} from "./Containers"

export const TopTeamRanking = ({
                                 select,
                                 selected,
                                 data,
                                 teamMax,
                                 mobile,
                                 orientation,
                                 pressTeam,
                                 phase,
                                 ...props
                               }) => {
  const {campaignId} = useCampaign()
  const {userState} = useProfile()

  const firstTeam = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${data[0]?.teamId}`
  )
  const secondTeam = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${data[1]?.teamId}`
  )
  const thirdTeam = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${data[2]?.teamId}`
  )

  return (
    <TopRankingWrapper {...{mobile, orientation}} {...props}>
      {secondTeam && secondTeam?.logo?.shape && (
        <TopRanking
          phase={phase}
          type="team"
          data={{...secondTeam, points: data[1]?.score}}
          rank={2}
          me={userState.teamId === secondTeam.id}
          onPress={() => {
            select(data?.[1]?.teamId)
            pressTeam(secondTeam.id)
          }}
          {...{teamMax, mobile, orientation}}
        />
      )}
      {firstTeam && firstTeam?.logo?.shape && (
        <TopRanking
          style={{
            marginLeft: !mobile && '5%',
            marginRight: !mobile && '5%'
          }}
          phase={phase}
          type="team"
          data={{...firstTeam, points: data[0]?.score}}
          rank={1}
          me={userState.teamId === firstTeam.id}
          onPress={() => {
            select(data?.[0]?.teamId)
            pressTeam(firstTeam.id)
          }}
          {...{teamMax, mobile, orientation}}
        />
      )}
      {thirdTeam && thirdTeam?.logo?.shape && (
        <TopRanking
          phase={phase}
          type="team"
          data={{...thirdTeam, points: data[2]?.score}}
          rank={3}
          me={userState.teamId === thirdTeam.id}
          onPress={() => {
            select(data?.[2]?.teamId)
            pressTeam(thirdTeam.id)
          }}
          {...{teamMax, mobile, orientation}}
        />
      )}
    </TopRankingWrapper>
  )
}
