import React, { useState } from "react"
import { Animated } from "react-native"
import styled from "@emotion/native"
import { T } from "../../../../../translations/translate"
import { colors } from "@civitime/library/storybook/configs/colors"
import { PressedInCard } from "@civitime/library/storybook/stories/Cards"
import { AvatarComponent } from "@civitime/library/storybook/stories/Avatar"
import { Title2 } from "@civitime/library/storybook/stories/Texts"
import { TextButton } from "@civitime/library/storybook/stories/Button"

export const PlayerCard = ({
  player,
  mobile,
  orientation,
  challengePlayer,
  ...props
}) => {
  const [hovered, setHovered] = useState(false)
  return (
    <WrapperPlayerCard
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      <WrapperPressInPlayer
        noCursor
        customPadding={mobile ? 7 : 13.5}
        {...{ mobile, orientation }}
      >
        <WrapperPlayerInfos {...{ mobile, orientation }}>
          <AvatarComponent
            circleAvatarConfig={{
              size: mobile ? 50 : 100,
              withBorder: true,
              backgroundColor: hovered
                ? colors.challenges.avatarHovered
                : colors.extraLightBackground,
            }}
            avatar={player.avatar}
          />
          <PlayerName {...{ mobile, orientation }}>{player.name}</PlayerName>
        </WrapperPlayerInfos>
        <WrapperChoiceButton {...{ mobile, orientation }}>
          <ChoiceButton
            color={colors.secondary}
            onPress={() => challengePlayer(player)}
          >
            <T path="app.challenges.choose" />
          </ChoiceButton>
        </WrapperChoiceButton>
      </WrapperPressInPlayer>
    </WrapperPlayerCard>
  )
}

const WrapperPlayerCard = styled(Animated.View)({
  width: "100%",
  height: "100%",
})

const WrapperPlayerInfos = styled.View(
  {
    flexDirection: "column",
  },
  ({ mobile, orientation }) =>
    mobile &&
    orientation === "portrait" && {
      flex: 1,
    }
)

const WrapperPressInPlayer = styled(PressedInCard)(
  ({ mobile, orientation }) => ({
    flexDirection: mobile && orientation === "portrait" ? "row" : "column",
  })
)

const PlayerName = styled(Title2)(({ mobile, orientation }) => ({
  textTransform: "uppercase",
  marginTop: 15,
  marginBottom: mobile && orientation === "portrait" ? 0 : 15,
}))

const WrapperChoiceButton = styled.View(
  ({ mobile, orientation }) =>
    mobile &&
    orientation === "portrait" && {
      flex: 1,
      alignItems: "center",
    }
)

const ChoiceButton = styled(TextButton)({
  margin: 0,
})
