import React, { useEffect, useRef, useState } from "react";
import { Camera } from "expo-camera";
import * as Permissions from "expo-permissions";
import jsQR from "jsqr";
import { QRHeader } from "../components/Header";
import { colors } from "@civitime/library/storybook/configs/colors";
import styled from "@emotion/native";
import { Title1 } from "@civitime/library/storybook/stories/Texts";
import { QRContact } from "../../Modals/HelperModal";
import { BackUpQR } from "../components/BackupQR";
import { QRCameraSquare } from "../components/QRCameraSquare";
import { useParams } from "react-router-native";
import { useCampaign } from "../../../../../GlobalContexts";
import { nanoid } from "nanoid";
import { _backupLastBuildingModalSeen } from "../../../Map/MapBuildings";
import { useProfile } from "../../../../../Auth/useProfile";
import { QrErrorModal } from "../components/QrErrorModal";
import { T } from "../../../../../translations/translate";
import { FirefoxWebCam } from "../components/FirefoxWebcam";

export const QRModal = ({ mobile, linkModal }) => {
  const { campaignSettings, campaignNavigate } = useCampaign();
  const { emitCommand } = useProfile();
  const { moduleType, moduleTheme, moduleStep, buildingType } = useParams();
  const [data, setData] = useState(null);
  const [codeError, setCodeError] = useState("");
  const [screenDimensions, setScreenDimensions] = useState(null);
  const qrCode =
    campaignSettings?.modulesSettings?.[buildingType]?.[moduleStep]?.qrCode;
  const [_hasPermission, setHasPermission] = useState(null);
  const [type] = useState(Camera.Constants.Type.back);
  const cameraRef = useRef(null);
  const [decodedQR, setDecodedQR] = useState(null);
  const ref = useRef(null);
  const [input, setInput] = useState([]);
  const isIOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
        "MacIntel",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  useEffect(() => {
    setData({
      qrCode: qrCode,
      moduleType: moduleType,
      moduleTheme: moduleTheme,
      moduleStep: moduleStep,
      buildingType: buildingType,
    });
  }, [qrCode, moduleType, moduleTheme, moduleStep, buildingType]);

  const getScreenDimensions = (event) => {
    const { width, height } = event.nativeEvent.layout;
    if (
      !screenDimensions?.initial?.width &&
      !screenDimensions?.initial?.height
    ) {
      setScreenDimensions((state) => ({
        ...state,
        initial: { width: width, height: height },
      }));
    }
    setScreenDimensions((state) => ({
      ...state,
      current: { width: width, height: height },
    }));
  };

  const keyboardOpened =
    screenDimensions?.initial?.height !== screenDimensions?.current?.height ||
    screenDimensions?.initial?.width !== screenDimensions?.current?.width;
  const isFirefox = typeof InstallTrigger !== "undefined";
  useEffect(() => {
    (async () => {
      if (isFirefox) return;
      const { status } = await Permissions?.askAsync(Permissions.CAMERA);
      setHasPermission(status === "granted");
    })();
  }, []);

  useEffect(() => {
    if (decodedQR) {
      if (decodedQR === qrCode) {
        const moduleId = nanoid();
        fetch(
          `https://europe-west3-ct-next.cloudfunctions.net/gameServerDev/update-qrcode-leclerc-stats/${
            JSON.parse(JSON.parse(localStorage.getItem("authToken")))?.userId ||
            "undefined"
          }/${qrCode}`,
          {
            method: "POST",
          }
        ).catch((err) => console.log(err));
        emitCommand({
          type: "UnlockQr",
          payload: {
            buildingType: buildingType,
            moduleStep: moduleStep,
            moduleType: moduleType,
            moduleTheme: moduleTheme,
            moduleId: moduleId,
          },
        });
        campaignNavigate(
          `module/${buildingType}/${moduleStep}/${moduleType}/${moduleTheme}/${moduleId}/origin`
        );
      } else {
        setCodeError("qrCode");
      }
    }
  }, [decodedQR]);

  useEffect(() => {
    if (!cameraRef?.current || !_hasPermission) return;
    const takePicture = setInterval(() => {
      (async () => {
        await cameraRef?.current?.takePictureAsync().then(async (photo) => {
          if (!_hasPermission || !photo || photo?.base64 === "data:,") return;
          await fetch(photo.base64)
            .then((e) => e.blob())
            .then(async (blob) => {
              if (!blob) return;
              const imageData = await BlobToImageData(blob, photo);
              if (!imageData) return;
              const decodeQr = jsQR(
                imageData?.data,
                imageData?.width,
                imageData?.height
              );
              setDecodedQR(decodeQr?.data);
            });
        });
      })();
    }, 500);
    return () => clearInterval(takePicture);
  }, [cameraRef?.current, _hasPermission]);
  return (
    <QRModalWrapper ref={ref} onLayout={getScreenDimensions}>
      {codeError?.length ? (
        <QrErrorModal
          codeError={codeError}
          closeError={() => {
            setInput([]);
            setCodeError("");
          }}
        />
      ) : null}
      <QRHeader
        onPress={() => {
          campaignNavigate("");
          setTimeout(() =>
            linkModal(
              {
                type: "construction",
                infos: _backupLastBuildingModalSeen.getValue(),
              },
              200
            )
          );
        }}
      />
      <QRModalView>
        {!keyboardOpened && (
          <Wrapper>
            <QRText color={colors.white}>
              <T path="phase_2.modals.qrModal.aim" />
            </QRText>
            <QRCameraSquare size={50} width={7} />
          </Wrapper>
        )}

        <BackUpQR
          data={data}
          keyboardOpened={keyboardOpened}
          catchError={() => setCodeError("input")}
          input={input}
          setInput={(input) => setInput(input)}
          mobile={mobile}
        />
        <QRContact mobile={mobile} backgroundColor={colors.text} noCgu qr />
      </QRModalView>
      {isIOS() ? (
        _hasPermission && (
          <Camera
            ratio="4:3"
            ref={cameraRef}
            style={{
              width: "100%",
              position: "absolute",
              top: 50,
              bottom: 0,
            }}
            type={type}
          />
        )
      ) : (
        <FirefoxWebCam setDecodedQR={setDecodedQR} />
      )}
    </QRModalWrapper>
  );
};

const BlobToImageData = function (blob, photo) {
  let blobUrl = URL.createObjectURL(blob);
  if (!blobUrl) return;
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = blobUrl;
  }).then((img) => {
    URL.revokeObjectURL(blobUrl);
    let canvas = document.createElement("canvas");
    canvas.width = photo?.width;
    canvas.height = photo?.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    return ctx.getImageData(0, 0, photo?.width, photo?.height);
  });
};

const Wrapper = styled.View({
  height: "60%",
});

export const QRText = styled(Title1)({
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
  fontSize: 18,
  fontWeight: "normal",
  backgroundColor: colors.text,
  opacity: 0.8,
  height: "25%",
});

export const QRModalWrapper = styled.View({
  height: "100%",
  width: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 10,
});

export const QRModalView = styled.View({
  position: "absolute",
  top: 50,
  left: 0,
  bottom: 50,
  right: 0,
  zIndex: 2,
});
