import {useProfile} from '../../../../../Auth/useProfile'
import {View} from 'react-native'
import React, {useEffect, useState} from 'react'
import Collapsible from 'react-native-collapsible'
import styled from '@emotion/native'
import {colors} from '@civitime/library/storybook/configs/colors'
import {DefaultModal} from '@civitime/library/storybook/stories/Modals'
import {MOBILE_HEADER_SIZE} from '@civitime/library/storybook/configs/sizes'
import {useCampaign} from '../../../../../GlobalContexts'
import {SmallBoldText, Title2,} from '@civitime/library/storybook/stories/Texts'
import {ChallengeDisplay, NoChallengesToDisplay,} from '../components/ChallengesDisplay'
import {ModuleResults} from '../../Modules/Components/ModulesResults'
import {useTranslator} from '../../../../../translations/translate'
import {canEndChallenge} from '@civitime/game-server/lib/Domain/Phases/phase1/Challenges/Challenges.helpers'

export const ChallengesHistory = ({
                                    displayedResult,
                                    displayResult,
                                    mobile,
                                    orientation,
                                    height,
                                    phase
                                  }) => {
  const {userSession, emitCommand} = useProfile()
  const [challenges, setChallenges] = useState({})
  const [opened, setOpened] = useState("")
  const {campaignNavigate, campaignSettings} = useCampaign()
  const {t} = useTranslator()
  const seeResult = async (challenge) => {
    const response = await emitCommand({
      type: "SeeChallengeResult",
      payload: {
        module: challenge?.type,
        level: challenge?.level || "lv-1",
        win: true,
        destination: null,
        challengeId: challenge.challengeId,
        result: challenge?.result,
      },
    })
    displayResult({
      open: true,
      result: response,
      challengeId: challenge.challengeId,
      displayPnj: false,
    })
  }

  useEffect(() => {
    if (!userSession?.challenges) return
    setChallenges(filterChallenges(userSession.challenges, campaignSettings))
  }, [userSession])

  return (
    <DefaultModal
      backgroundColor={colors.lightBlue}
      color={colors.text}
      closeButtonColor={colors.secondary}
      title={t('app.challengesHistory.title')}
      onPress={() => {
        displayResult(null)
        return campaignNavigate('') }}
      maxHeight={
        mobile && orientation === "landscape"
          ? height * 0.9
          : (height - MOBILE_HEADER_SIZE) * 0.99
      }
      withShadow
    >
      {displayedResult?.open && (
        <ModuleResults
          displayModulesResult={displayedResult}
          close={() => displayResult(null)}
          ending
          mobile={mobile}
          orientation={orientation}
          height={height}
        />
      )}
      <Wrapper>
        <StateButton
          onPress={() => setOpened("playableChallenges")}
          notification={challenges?.playableChallenges?.length}
          selected={opened === "playableChallenges"}
          title={t('app.challengesHistory.newChallenges.title')}
        />
        <ChallengesWrapper>
          <Challenges collapsed={!(opened === "playableChallenges")}>
            {challenges?.playableChallenges?.length ? (
              challenges?.playableChallenges.map((challenge) => (
                <ChallengeDisplay
                  challenge={challenge}
                  type={"play"}
                  key={`challenge--${challenge.challengeId}`}
                  mobile={mobile}
                  phase={phase}
                />
              ))
            ) : (
              <NoChallengesToDisplay/>
            )}
          </Challenges>
        </ChallengesWrapper>

        <StateButton
          onPress={() => setOpened("challengesResults")}
          selected={opened === "challengesResults"}
          notification={challenges?.seeAbleChallenges?.length}
          actionButton={t('app.challengesHistory.seeResult.actionButton')}
          title={t('app.challengesHistory.seeResult.title')}
        />
        <ChallengesWrapper>
          <Challenges collapsed={!(opened === "challengesResults")}>
            {challenges?.seeAbleChallenges?.length ? (
              challenges?.seeAbleChallenges.map((challenge) => (
                <ChallengeDisplay
                  challenge={challenge}
                  type={"seeResult"}
                  seeResult={async (challenge) => {
                    await seeResult(challenge)
                  }}
                  key={`challenge--${challenge.challengeId}`}
                  mobile={mobile}
                />
              ))
            ) : (
              <NoChallengesToDisplay/>
            )}
          </Challenges>
        </ChallengesWrapper>

        <StateButton
          onPress={() => setOpened("awaitingChallenges")}
          notification={challenges?.awaitingChallenges?.length}
          selected={opened === "awaitingChallenges"}
          actionButton={t('app.challengesHistory.awaitingChallenges.actionButton')}
          title={t('app.challengesHistory.awaitingChallenges.title')}
        />
        <ChallengesWrapper>
          <Challenges collapsed={!(opened === "awaitingChallenges")}>
            {challenges?.awaitingChallenges?.length ? (
              challenges?.awaitingChallenges.map((challenge) => (
                <ChallengeDisplay
                  challenge={challenge}
                  key={`challenge--${challenge.challengeId}`}
                  type={"awaiting"}
                  mobile={mobile}
                />
              ))
            ) : (
              <NoChallengesToDisplay/>
            )}
          </Challenges>
        </ChallengesWrapper>
      </Wrapper>
    </DefaultModal>
  )
}

const StateButton = ({onPress, title, selected, notification}) => {
  return (
    <StateButtonWrapper onPress={onPress} selected={selected}>
      {notification > 0 && <Notification>{notification}</Notification>}
      <View>
        <StateButtonText selected={selected}>{title}</StateButtonText>
      </View>
    </StateButtonWrapper>
  )
}

const filterChallenges = (challenges, campaignSettings) => {
  const challengesEnd = campaignSettings?.challengeEndDate
  const challengesArray = Object.entries(challenges).map(([key, value]) => ({
    ...value,
    challengeId: key,
  }))
  const seeAbleChallenges = challengesArray.filter(
    (challenge) => {
      return (challenge.status === 'ended' &&
        ((challenge?.opponentResult || challenge?.opponentResult === 0)
          || canEndChallenge(challenge.timeStamp, challengesEnd)))
    }
  )
  const playableChallenges = challengesArray.filter(
    (challenge) => challenge.status === "started"
  )
  const awaitingChallenges = challengesArray.filter(
    (challenge) =>
      challenge.status === "ended" &&
      !challenge?.opponentResult &&
      challenge?.opponentResult !== 0
      && !canEndChallenge(challenge.timeStamp, challengesEnd)
  )
  return {
    seeAbleChallenges: seeAbleChallenges,
    playableChallenges: playableChallenges,
    awaitingChallenges: awaitingChallenges,
  }
}

export const Notification = ({children, ...props}) => (
  <NotificationWrapper {...props}>
    <NotificationText>{children}</NotificationText>
  </NotificationWrapper>
)

const NotificationWrapper = styled.View({
  backgroundColor: colors.red,
  borderRadius: "50%",
  width: 20,
  height: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: 10,
})

const NotificationText = styled(SmallBoldText)({
  color: colors.white,
  fontSize: 14,
  marginBottom: 4,
  marginLeft: 2,
})

const Wrapper = styled.View(({}) => ({
  padding: 20,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
}))

const StateButtonWrapper = styled.TouchableOpacity(({selected}) => ({
  backgroundColor: colors.defaultBackground,
  boxShadow: "-1px -1px 4px #FFFFFF, 1px 1px 3px rgba(94, 104, 121, 0.945)",
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 5,
  paddingBottom: 5,
  margin: 5,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  borderRadius: 5,
  position: "relative",
  justifyContent: "center",
  ...(selected && {
    backgroundColor: colors.primary,
  }),
}))

const StateButtonText = styled(Title2)(({selected}) => ({
  color: selected ? colors.white : colors.text,
}))

const Challenges = styled(Collapsible)({
  width: "100%",
  maxHeight: 300,
  overflowY: 'scroll'
})

const ChallengesWrapper = styled.View({
  width: "100%",
})
