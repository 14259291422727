import React, { useEffect, useRef, useState }                           from 'react'
import { Text, TouchableOpacity }                                       from 'react-native'
import styled                                                           from '@emotion/native'
import { T, useTranslator }                                             from '../../translations/translate'
import { useCampaign }                                                  from '../../GlobalContexts'
import { authClient }                                                   from '../authClient'
import { AuthCard, AuthContent, AuthFooter, }                           from '@civitime/library/storybook/stories/Cards'
import {
  Input,
  InputRow
}                                                                       from '@civitime/library/storybook/stories/Inputs'
import {
  ActionButton,
  TextButton
}                                                                       from '@civitime/library/storybook/stories/Button'
import { colors }                                                       from '@civitime/library/storybook/configs/colors'
import { BaseText, BoldText }                                           from '@civitime/library/storybook/stories/Texts'
import { ErrorAlert, ErrorText, }                                       from '@civitime/library/storybook/stories/Error'
import { MailIcon, PasswordIcon, VisibilityOffIcon, VisibilityOnIcon, } from '@civitime/library/storybook/stories/Icons'
import { formatErrorMessage, WrapperBrowsersList }                      from './Signup'
import { brand as isMobileApp }                                         from 'expo-device'
import { AuthLangSelectorButton, GoogleChromeImage, TitleWarn }         from './Auth'
import { Dialog }                                                       from '@civitime/library/storybook/stories/Dialog/index.web'
import { clientId }                                                     from '../../../clientId'
import Loader
                                                                        from '../../../assets/Auth/animations/loader/data.json'
import LottieView                                                       from 'lottie-react-native'

export const Login = ({ mobile, openLangSelector, campaignSettings }) => {
  const { t, lang } = useTranslator()
  const { campaignNavigate } = useCampaign()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [modalTrailsOvered, setModalTrialsOvered] = useState(false)
  const [modalEmailReset, setModalEmailReset] = useState(false)
  const [securedTextEntry, setSecuredTextEntry] = useState(true)
  const [state, setState] = useState({
    submitted: false,
    error: false,
    trials: 0
  })
  const canSubmit = email && password
  const campaignId = location.pathname.split('/')[1]
  const [awaiting, setAwaiting] = useState(false)
  const login = async () => {
    setState((state) => ({
      ...state,
      submitted: true,
    }))
    try {
      setAwaiting(true)
      await authClient.login(email, password, campaignId)
      setAwaiting(false)
    } catch (e) {
      const error = await formatErrorMessage(e)
      setState((state) => ({
        ...state,
        error,
        trials: state.trials + 1
      }))
      setAwaiting(false)
    }
  }
  
  useEffect(() => {
    if (state?.trials === 3) {
      setModalTrialsOvered(true)
      setState((state) => ({
        ...state,
        trials: 0
      }))
    }
  }, [state?.trials])
  
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  
  const askResetPassword = async () => {
    if (!email) return
    setModalEmailReset(true)
    await authClient.askResetPassword(email, clientId, campaignId)
  }
  const ref = useRef(null)
  return (
    <AuthCard mobile={mobile}>
      {campaignSettings?.internationalized && <AuthLangSelectorButton size={40}
                                                                      iconSize={30}
                                                                      onPress={openLangSelector}
                                                                      text={lang}/>}
      <Dialog
        visible={modalTrailsOvered}
        closable={true}
        onRequestClose={() => {
          setModalTrialsOvered(false)
        }}
      >
        <TitleWarn>
          <T path={'app.auth.alerts.loginTrialOveredTitle'} data={{ email }}/>
        </TitleWarn>
        <ModalText style={{ marginTop: 10 }}>
          <T path={'app.auth.alerts.loginTrialOveredText'}/>
        </ModalText>
        <ActionButton style={{ alignSelf: 'center', marginTop: 30 }}
                      onPress={() => {
                        askResetPassword()
                        setModalTrialsOvered(false)
                      }}
        >
          <T path='app.auth.alerts.okButton'/>
        </ActionButton>
      </Dialog>
      <Dialog
        visible={modalEmailReset}
        closable={true}
        onRequestClose={() => {
          setModalEmailReset(false)
        }}
      >
        <ModalText>
          <T path={'app.auth.alerts.resetPasswordEmailSent'} data={{ email }}/>
        </ModalText>
        <ModalText style={{ marginTop: 10 }}>
          <T path={'app.auth.alerts.resetPasswordFollowInstructions'}/>
        </ModalText>
      </Dialog>
      
      <AuthContent>
        <Title>
          <T path={'app.auth.titles.signin'}/>
        </Title>
        
        {state.submitted && state.error && (
          <ErrorAlert>
            <ErrorText>
              {state.error.slice(0, 3) === 'app' ? (
                //Support found error directly in translations : <T path={state.error}/>
                <T path={'app.auth.errors.wrongPassword'}/>
              ) : (
                <T path={'app.auth.errors.wrongPassword'}/>
              )}
            </ErrorText>
          </ErrorAlert>
        )}
        
        <InputRow>
          <Input
            name="email"
            icon={<MailIcon width={17} height={17} color={colors.text}/>}
            placeholder={t('app.auth.fields.email')}
            onChangeText={setEmail}
            value={email}
            keyboardType="email-address"
            autoCompleteType="email"
          />
        </InputRow>
        <InputRow>
          <Input
            name="password"
            icon={<PasswordIcon width={17} height={17} color={colors.text}/>}
            placeholder={t('app.auth.fields.password')}
            secureTextEntry={securedTextEntry}
            onChangeText={setPassword}
            value={password}
            returnKeyType="send"
            onSubmitEditing={() => canSubmit && login()}
            secondIcon={securedTextEntry ? <VisibilityOnIcon width={20} height={20} style={{ cursor: 'pointer' }}
                                                             onClick={() => setSecuredTextEntry(v => !v)}/> :
              <VisibilityOffIcon width={20} height={20} style={{ cursor: 'pointer' }}
                                 onClick={() => setSecuredTextEntry(v => !v)}/>}
          />
        </InputRow>
        
        <ForgotPasswordWrapper
          onPress={() => campaignNavigate('forgot-password')}
        >
          <ForgotPassword>
            <T path={'app.auth.links.forgotPassword'}/>
          </ForgotPassword>
        </ForgotPasswordWrapper>
        
        {!isMobileApp && !isChrome && !mobile && (
          <WrapperWarningNavFix>
            <TitleWarn>
              <T path={'app.auth.warningNav'}/>
            </TitleWarn>
            <WrapperBrowsersList>
              <Text
                accessibilityRole="link"
                href="https://www.google.com/chrome/"
                target="_blank"
              >
                <GoogleChromeImage
                  source={require('../../../assets/Auth/chrome.png')}
                  resizeMode="contain"
                />
              </Text>
              <FirefoxLink
                accessibilityRole="link"
                href="https://www.mozilla.org/fr/firefox/"
                target="_blank"
              >
                <FirefoxImage
                  source={require('../../../assets/Auth/firefox-logo.png')}
                  resizeMode="contain"
                />
              </FirefoxLink>
            </WrapperBrowsersList>
          </WrapperWarningNavFix>
        )}
        
        <ActionsWrapper>
          {awaiting ? <LoaderWrapper>
              <LottieView
                source={Loader}
                width={'100%'}
                height={'100%'}
                ref={ref}
                autoPlay
                loop
              />
            </LoaderWrapper>
            : <TextButton
              onPress={() => canSubmit && login()}
              disabled={!canSubmit}
            >
              <T path={'app.auth.actions.signin'}/>
            </TextButton>
          }
        
        </ActionsWrapper>
      </AuthContent>
      
      <AuthFooter>
        <TouchableOpacity onPress={() => campaignNavigate('signup')}>
          <BoldText>
            <T path={'app.auth.links.signup'}/>
          </BoldText>
        </TouchableOpacity>
      </AuthFooter>
    </AuthCard>
  )
}

const FirefoxImage = styled.Image({
  width: 80,
  height: 22,
  marginTop: 10,
  marginLeft: 5
})

export const FirefoxLink = styled.Text({
  width: 85
})

const ModalText = styled(BaseText)({
  fontSize: 18,
  lineHeight: 21,
})

const ActionsWrapper = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-around',
  paddingTop: 60,
})

const Title = styled(BaseText)({
  fontSize: 21,
  lineHeight: 25,
})

const ForgotPassword = styled(BaseText)({
  fontFamily: 'Raleway_600SemiBold_Italic',
  fontSize: 13,
  lineHeight: 15,
})

const ForgotPasswordWrapper = styled.TouchableOpacity({
  flex: 1,
  alignItems: 'flex-end',
  paddingTop: 12,
})

export const WrapperWarningNavFix = styled.View({
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 40
})

export const LoaderWrapper = styled.View({
  width: 150,
  height: 150
})
