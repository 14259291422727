import React, {useEffect} from "react"
import {Animated} from "react-native"
import styled from "@emotion/native"
import {useProfile} from "../../../../../Auth/useProfile"
import {PlayerInfos} from "../components/PlayerInfos"
import {colors} from "@civitime/library/storybook/configs/colors"
import Left from "@civitime/library/assets/challenges/left.svg"
import Right from "@civitime/library/assets/challenges/right.svg"
import Thunder from "@civitime/library/assets/challenges/thunder.svg"
import {useCampaign} from "../../../../../GlobalContexts"

export const ChallengeLaunch = ({
                                  player,
                                  width,
                                  height,
                                  mobile,
                                  orientation,
                                  launchModuleAfterAnimation,
                                  phase
                                }) => {
  const aspectRatio = 331 / 458
  const LEFT_WIDTH = mobile ? width * 0.45 : 458
  const RIGHT_WIDTH = mobile ? width * 0.45 : 445
  const PLAYER_HEIGHT = mobile ? width * 0.45 * aspectRatio : 331
  const THUNDER_WIDTH = mobile ? width * 0.3 : 245
  const THUNDER_HEIGHT = mobile ? height * 0.95 : 541
  const initTranslationAnimationValue = width / 2

  const {publicProfile} = useProfile()
  const leftTranslation = new Animated.Value(-initTranslationAnimationValue)
  const rightTranslation = new Animated.Value(initTranslationAnimationValue)
  const hideBeforeAnimation = new Animated.Value(0)
  const scale = new Animated.Value(0.2)

  const {campaignNavigate} = useCampaign()

  useEffect(() => {
    Animated.parallel([
      Animated.timing(leftTranslation, {toValue: 0, duration: 700}),
      Animated.timing(rightTranslation, {toValue: 0, duration: 700}),
      Animated.timing(scale, {toValue: 1, duration: 800}),
      Animated.timing(hideBeforeAnimation, {toValue: 1, duration: 200}),
    ]).start(() => {
      setTimeout(() => {
        if (phase === 1) {
          campaignNavigate('wait')
        }
        launchModuleAfterAnimation()
      }, 1000)
    })
  }, [])

  return (
    <WrapperChallengeLaunch {...{width}}>
      <WrapperLeft style={{
        transform: [{translateX: leftTranslation}],
        opacity: hideBeforeAnimation
      }}>
        <PlayerInfos player={publicProfile} {...{mobile, orientation}} top={50} left={0}/>
        <Left width={LEFT_WIDTH} height={PLAYER_HEIGHT}/>
      </WrapperLeft>
      <WrapperPlaceholderThunder>
        <Thunder
          width={THUNDER_WIDTH}
          height={THUNDER_HEIGHT}
          fill={colors.challenges.thunderPlaceholder}
        />
      </WrapperPlaceholderThunder>
      <WrapperThunder style={{transform: [{scale}]}}>
        <Thunder
          width={THUNDER_WIDTH}
          height={THUNDER_HEIGHT}
          fill={colors.secondary}
        />
      </WrapperThunder>
      <WrapperRight style={{
        transform: [{translateX: rightTranslation}],
        opacity: hideBeforeAnimation
      }}>
        <PlayerInfos {...{player, mobile, orientation}} top={50} right={0}/>
        <Right width={RIGHT_WIDTH} height={PLAYER_HEIGHT}/>
      </WrapperRight>
    </WrapperChallengeLaunch>
  )
}

const WrapperChallengeLaunch = styled.View(
  {
    position: "absolute",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    bottom: 0,
    left: '50%',
    transform: [{translateX: '-50%'}]
  }
)

const PlayerShape = styled(Animated.View)({
  position: "absolute",
  justifyContent: "center",
})
const WrapperLeft = styled(PlayerShape)({
  top: 27,
  left: -311,
  zIndex: 1,
})
const WrapperRight = styled(PlayerShape)({
  top: 27,
  right: -311,
  zIndex: 1,
})

const WrapperPlaceholderThunder = styled.View({
  position: "absolute",
  transform: [{translateX: 5}, {translateY: 2}],
  top: -78,
  zIndex: 2
})

const WrapperThunder = styled(Animated.View)({
  zIndex: 10,
})
