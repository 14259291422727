import React, { useEffect, useState } from 'react'
import jsQR                           from 'jsqr'
import styled                         from '@emotion/native'

export const FirefoxWebCam = ({ setDecodedQR }) => {
  const [cameraDimensions, setCameraDimensions] = useState(null)

  useEffect(() => {
    ;(async () => {
      const video = document.querySelector('#video')
      await navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" } } })
        .then(stream => {
          video.srcObject = stream
          video.play()
        })
    })()
  }, [])

  const takePicture = (cameraDimensions) => {
    const video = document.querySelector('#video')
    if (!cameraDimensions) return
    canvas.width = cameraDimensions?.width
    canvas.height = cameraDimensions?.height
    let ctx = canvas.getContext('2d')
    ctx.drawImage(video, 0, 0)
    return ctx.getImageData(0, 0, cameraDimensions?.width, cameraDimensions?.height)
  }

  const getScreenDimensions = (event) => {
    const { width, height } = event.nativeEvent.layout
    setCameraDimensions({ width: width, height: height })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      ;(async () => {
        const data = takePicture(cameraDimensions)
        if (!data) return
        const decodeQr = jsQR(data?.data, cameraDimensions?.width, cameraDimensions?.height)
        setDecodedQR(decodeQr?.data)
      })()
    }, 500)
    return () => clearInterval(interval)

  }, [cameraDimensions])
  return <FirefoxCamWrapper onLayout={getScreenDimensions}>
    <video id="video" style={{
      width: '100%',
      position: 'absolute',
      top: 50,
      bottom: 0
    }}/>
    <canvas id="canvas" style={{
      display: 'none'
    }}/>
  </FirefoxCamWrapper>
}

const FirefoxCamWrapper = styled.View({
  width: '100%',
  position: 'absolute',
  top: 50,
  bottom: 0,
})
