import React, {useEffect, useRef, useState} from 'react'
import {useProfile} from '../../Auth/useProfile'

import {TeamRankingListItem} from './TeamRankingListItem'
import {RankingWrapper} from './Containers'

export const TeamRankingList = ({
                                  select,
                                  data,
                                  selected,
                                  teamMax,
                                  mobile,
                                  orientation,
                                  phase,
                                }) => {
  const scrollRef = useRef(null)
  const {userState} = useProfile()
  const [listHeight, setListHeight] = useState(0)

  const myIndex = data.findIndex((v) => userState.teamId === v.teamId)
  const scrollToRow = (itemIndex) => {
    if (!scrollRef?.current) return
    scrollRef.current.scrollTo({y: (itemIndex * 50), animated: true})
  }

  useEffect(() => {
    if (!scrollRef?.current || !myIndex) return console.log("not ready")
    scrollToRow(myIndex)
  }, [scrollRef?.current, myIndex, listHeight])

  return (
    <RankingWrapper
      ref={scrollRef}
      onLayout={(event) => setListHeight(event?.nativeEvent?.layout?.height)}
      contentContainerStyle={{alignItems: "center"}}
      {...{mobile, orientation}}
    >
      {
        data?.map((team, index) => {
          return < TeamRankingListItem
            phase={phase}
            key={index + "teamKey"}
            isMyTeam={index === myIndex}
            index={index}
            select={select}
            selected={selected}
            teamMax={teamMax}
            mobile={mobile}
            team={team}
          />
        })}
    </RankingWrapper>
  )
}
