import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams }                                   from 'react-router-native'
import { DuelQuiz }                                    from '@civitime/duel-quiz/App'
import { useCampaign }                                 from '../../../../GlobalContexts'
import { fbApp, firebaseAccessor }                     from '../../../../firebase'
import { clientId, PWA }                               from '../../../../../clientId'
import { useProfile }                                  from '../../../../Auth/useProfile'
import { useRealTimeData }                             from '../../../../Utils/dataClient'
import { useScreenDimensions }                         from '@civitime/library/storybook/hooks/useScreenDimensions'
import { _backupLastBuildingModalSeen }                from '../../Map/MapBuildings'
import {
  getGoldenCompanyContent,
  getMemoryContent,
  getPictionaryContent,
  getPreferencesContent,
  getRandomQuestion
}                                                      from './modulesFunctions'
import { Challenges }                                  from '../../../Phase1/Components/Challenges/pages/Challenges'
import { useTranslator, T }                            from '../../../../translations/translate'
import { UseLinkInText }                               from '@civitime/duel-quiz/src/useLinkInText'
import { _focusOnElement }                             from '../../Map/MapPhase2'

const modulesTypes = {
  quiz: DuelQuiz,
}

const tutoKeys = {
  preferences: 'firstPreferences',
  goldenCompany: 'firstGoldenCompany',
  pictionary: 'firstPictionary',
}

export const ModulesManagement = ({ linkModal, phase, updateHeaderDisplayed }) => {
  const { moduleType, moduleTheme, moduleId, moduleStep, buildingType, source } = useParams()
  const { campaignNavigate, campaignId, campaignSettings, campaignEnded } = useCampaign()
  const { userState, emitCommand, publicProfile, userSession } = useProfile()
  const [moduleData, setModuleData] = useState(null)
  const [foundOpponent, setFoundOpponent] = useState(null)
  const [invited, setInvited] = useState(false)
  const [opponentProfile, setOpponentProfile] = useState(null)
  const [botProfile, setBotProfile] = useState(null)
  const { width, height } = useScreenDimensions()
  const { t, lang } = useTranslator()
  const lockEndedModule = useRef(false)
  const knowMoreData = campaignSettings?.modulesSettings?.[buildingType]?.[moduleStep]?.knowMore
  const LaunchModule = useMemo(() => {
    if (!moduleType) return
    return modulesTypes[moduleType]
  }, [moduleType])
  
  const teamData = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}`
  )
  
  const focusOnTownHall = () => {
    setTimeout(() =>
      _focusOnElement.next({
        x: campaignSettings?.townHall?.positions?.x,
        y: campaignSettings?.townHall?.positions?.y
      }), 1000)
  }
  
  const data = (campaignSettings?.lockBuildingsFromOrder || campaignEnded) ? userState?.onBoardingGameState : teamData
  useEffect(() => {
    ;(async () => {
      const moduleContent = await firebaseAccessor
        .get(`clients/${clientId}/campaigns/${campaignId}/content/${moduleType}/${moduleStep}/${moduleTheme}`)
      if (!moduleContent) return
      switch (moduleType) {
        case 'quiz' :
          const invite = await firebaseAccessor
            .get(`clients/${clientId}/campaigns/${campaignId}/sessions/${userState?.id}/challenges/${moduleId}`)
          if (invite) {
            setInvited(true)
            return prepareQuizModule({ id: invite?.opponentUid }, moduleContent)
          }
          return setFoundOpponent(moduleContent)
        case 'memory' :
          const rawContent = moduleContent?.images
          const memoryData = await getMemoryContent(clientId, campaignId, moduleStep, moduleTheme, rawContent)
          return setModuleData({ memory: memoryData, data: moduleContent })
        case 'mastermind' :
          return setModuleData(moduleContent)
        case 'preferences' :
          const preferencesData = await getPreferencesContent(clientId, campaignId, moduleStep, moduleTheme, userState?.teamId, userState?.id)
          return setModuleData({
            ...moduleContent,
            teamMembers: preferencesData?.teamMembers,
            globalUsersStats: preferencesData?.globalUsersStats
          })
        case 'goldenCompany' :
          const goldenCompanyData = await getGoldenCompanyContent(clientId, campaignId, moduleStep, moduleTheme, userState?.teamId, userState?.id)
          return setModuleData({
            ...moduleContent,
            ...goldenCompanyData
          })
        case 'pictionary' :
          const pictionaryData = await getPictionaryContent(clientId, campaignId, moduleStep, moduleTheme, userState?.teamId, userState?.id)
          return setModuleData({
            ...moduleContent,
            ...pictionaryData
          })
        default:
          return setModuleData(null)
      }
    })()
  }, [])
  
  const prepareQuizModule = async (opponentData, inviteContent) => {
    if (opponentData?.id) {
      if (opponentData?.bot) {
        setBotProfile(opponentData)
      } else {
        const opponentPublicProfile = await firebaseAccessor
          .get(`clients/${clientId}/profiles/${opponentData?.id}`)
        setOpponentProfile(opponentPublicProfile)
      }
    }
    const quizData = Object.values(inviteContent || foundOpponent || {})
    const quizQuestions = campaignSettings?.modulesSettings?.quizQuestions || 3
    const questions = getRandomQuestion(quizData, quizQuestions)
    setFoundOpponent(null)
    return setModuleData(questions)
  }
  
  //TODO: Making modal model for every modules (on an other sheet)
  const moduleEnded = async (result) => {
    if (tutoKeys?.[moduleType] && !userSession?.tuto?.[tutoKeys?.[moduleType]]) {
      await emitCommand({
        type: 'Phase2UpdateSeeTutoStep',
        payload: {
          step: tutoKeys?.[moduleType]
        }
      })
    }
    if (moduleType === 'quiz') {
      if (opponentProfile) {
        if (!invited) {
          await emitCommand({
            type: 'ChallengePlayer',
            payload: {
              playerChallenged: opponentProfile?.id,
              level: moduleStep,
              moduleType: moduleType,
              theme: moduleTheme,
              moduleName: buildingType,
              result: result?.goodAnswers || 0
            }
          })
        }
        await emitCommand({
          type: 'UpdateModuleStateUserSession',
          payload: {
            module: moduleType,
            level: moduleStep,
            win: !!result?.win,
            challengeId: moduleId,
            result: result?.goodAnswers || 0,
            challenged: false,
            answers: result?.answers,
            timeStampStart: result?.timeStampStart,
            timeStampEnd: result?.timeStampEnd,
            theme: moduleTheme,
            phase: phase
          }
        })
      }
    }
    if (source === 'notOrigin') {
      setModuleData(null)
      campaignNavigate('history')
      return
    }
    if (moduleType === 'pictionary' && result?.win) {
      const drawImage = result?.draw?.image
      await (async () => {
        await fbApp
          .storage('ct-campaigns.civitimeapp.com')
          .ref(`clients/${clientId}/campaigns/${campaignId}/pictionary/${userState?.id}/${result?.draw?.name}.png`)
          .put(drawImage, {
            public: true,
            contentType: 'image/png',
          })
      })()
    }
    const reward = getReward(moduleType, campaignSettings?.buildingsRules?.[moduleStep]?.reward, result, campaignSettings)
    await emitCommand({
      type: 'Phase2EndModule',
      payload: {
        valuePA: campaignSettings?.PA?.cost || null,
        buildingType: buildingType,
        moduleStep: moduleStep,
        moduleType: moduleType,
        moduleTheme: moduleTheme,
        moduleId: moduleId,
        reward: {
          flouz: reward,
          points: reward
        },
        result: result
      }
    }).then((response) => {
      const previousBuilding = _backupLastBuildingModalSeen.getValue()
      const buildingEvolution = response.some(r => r?.type === 'Phase2BuildingEvolved')
      const buttonPressed = () => {
        if (buildingEvolution && !PWA) {
          linkModal({
            type: 'evolution',
            infos: {
              type: previousBuilding?.type,
              step: ((data?.buildingsState?.[previousBuilding?.type]?.step || 1) + 1),
              title: t('phase_2.modals.buildingEvolution.title', { 0: previousBuilding?.name?.toUpperCase() }),
              text: t('phase_2.modals.buildingEvolution.text'),
              buttonText: t('phase_2.modals.buildingEvolution.invest'),
              asset: `https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/buildings/${previousBuilding?.type}/steps/${previousBuilding?.type}${data?.buildingsState?.[previousBuilding?.type]?.step + 1}.svg`,
              textHomeReturn: t('phase_2.modals.buildingEvolution.seeRanking'),
              homeAction: () => {
                campaignNavigate('leaderboard/team')
              },
              actionButton: () => linkModal({
                type: 'merveille',
                infos: { ...Object.values(campaignSettings?.buildings).filter(v => v?.type === 'merveille')?.[0] }
              }),
              buildingType: true
            }
          })
        } else {
          reward <= 0 ? linkModal({ type: 'mairie' }) :result.win ? linkModal({
              type: 'merveille',
              infos: Object.values(campaignSettings?.buildings).filter(v => v?.type === 'merveille')?.[0]
            })
            : linkModal({ type: 'construction', infos: _backupLastBuildingModalSeen.getValue() })
        }
        _backupLastBuildingModalSeen.next(null)
      }
      const mastermindOutro = moduleType === 'mastermind' && result.outro
      linkModal({
        type: 'module',
        infos: {
          title: t('phase_2.modals.modules.default.title'),
          textContent: reward <= 0 ? 'Malheureusement vous ne gagnez pas de crédits cette fois-ci. Je suis sûre que vous ferez mieux au prochain point de votre visite.'
            : result.win ? (
                mastermindOutro ? <T path={mastermindOutro}/> :
                  result?.outro ? <UseLinkInText text={result?.outro}/>
                    : t(PWA ? 'phase_2.modals.modules.default.PWA.textContent'
                    : 'phase_2.modals.modules.default.textContent')
              )
              : result.quit ? t('phase_2.modals.modules.default.quitText')
                : t('phase_2.modals.modules.default.failedText'),
          buttonText: buildingEvolution ?
            t('phase_2.modals.modules.default.buttonTextEvolution')
            : (result.win && reward > 0) ? t('phase_2.modals.buildingEvolution.invest')
              : t('phase_2.modals.modules.default.buttonText'),
          reward: result?.win ? {
            flouz: reward,
            points: reward
          } : null,
          secondActionButtonText: result.win && !PWA && t('phase_2.modals.buildingEvolution.seeRanking'),
          secondAction: result.win && !PWA ? () => {
            linkModal(null)
            return campaignNavigate('leaderboard/player')
          } : false,
          action: async () => {
            if (reward <= 0 ){
              _focusOnElement.next({
                x: campaignSettings?.townHall?.positions?.x,
                y: campaignSettings?.townHall?.positions?.y
              })
              buttonPressed()
            }
            
            else {
              focusOnTownHall()
              buttonPressed()
            }
          },
          onClose: async () => {
            if (reward <= 0 ){
              _focusOnElement.next({
                x: campaignSettings?.townHall?.positions?.x,
                y: campaignSettings?.townHall?.positions?.y
              })
              buttonPressed()
            }
            else {
              focusOnTownHall()
              buttonPressed()
            }
          },
          asset: t(`phase_2.modals.buildingModal.buildings.${buildingType}.launchModuleModal.asset`)
        }
      })
      setModuleData(null)
      campaignNavigate('')
    })
  }
  
  return <>
    {
      moduleData &&
      <LaunchModule data={moduleData}
                    onClose={async (result) => {
                      if (lockEndedModule?.current) return
                      lockEndedModule.current = true
                      await moduleEnded(result)
                    }}
                    width={width} height={height}
                    onEnd={async (result) => {
                      if (lockEndedModule?.current) return
                      lockEndedModule.current = true
                      await moduleEnded(result)
                    }}
                    moduleContent={moduleData} clientId={clientId} campaignId={campaignId}
                    firstLaunch={tutoKeys?.[moduleType] && !userSession?.progressionSeen?.tuto?.[tutoKeys?.[moduleType]]}
                    lang={lang} phase={2}
                    translateUrl={`https://ct-campaigns.civitimeapp.com/clients/${clientId}/campaigns/${campaignId}/${lang}.json`}
                    knowMoreData={knowMoreData}
        //Challenges props
                    opponentProfile={opponentProfile ?? botProfile}
                    botProfile={botProfile}
                    playerProfile={publicProfile}
                    opponentResult={botProfile ? 2 : userSession?.challenges?.[moduleId]?.opponentResult}/>
    }
    {
      foundOpponent &&
      <Challenges match={{
        params: {
          module: moduleType,
          level: moduleStep,
          theme: moduleTheme
        }
      }}
                  phase={phase}
                  updateHeaderDisplayed={updateHeaderDisplayed}
                  enterModule={prepareQuizModule}/>
    }
  </>
}

const getReward = (moduleType, reward, result, campaignSettings) => {
  if (moduleType === 'quiz') {
    const answersArray = Object.values(result?.answers?.questions || {})
    const quizzesGoodAnswerBonus = campaignSettings?.quizzesGoodAnswerBonus || 50
    const goodAnswers = answersArray?.filter(v => v).length
    const goodAnswersReward = goodAnswers * quizzesGoodAnswerBonus
    const win = goodAnswers > 2
    const equality = goodAnswers === 2
    const winBonus = campaignSettings?.wonChallengeBonus
    const equalityBonus = campaignSettings?.equalityChallengeBonus
    const winGain = win ? (winBonus ?? 100) : equality ? (equalityBonus ?? 50) : 0
    return Math.round(goodAnswersReward + winGain)
  }
  return Math.round(reward)
}
