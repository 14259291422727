import React from "react"
import styled from "@emotion/native"
import {BasicModal} from "../Modals/BasicModal"
import {colors} from "@civitime/library/storybook/configs/colors"

export const TooltipTuto = ({infos, cornerTop, cornerBottom, cornerLeft, cornerRight, minWidth, backgroundColor, color, customPadding, mainMarginPadding, width, ...props}) => {

  return <WrapperGiantTooltip {...props}>
    <Corner cornerTop={cornerTop} cornerBottom={cornerBottom} cornerLeft={cornerLeft} cornerRight={cornerRight} backgroundColor={backgroundColor}/>
    <BasicModal infos={infos} width={width} mainMarginPadding={mainMarginPadding} textLineHeight={24} textFont={20} titleFont={25} noButton={!infos?.buttonText} onClose={infos?.onClose} minWidth={minWidth} onMap backgroundColor={backgroundColor} color={color} customPadding={customPadding} noShadow/>
  </WrapperGiantTooltip>
}

const WrapperGiantTooltip = styled.View({
  position: 'absolute'
})

const Corner = styled.View(({cornerTop, cornerBottom, cornerLeft, cornerRight, backgroundColor}) => ({
  position: 'absolute',
  bottom: cornerBottom || 'unset',
  top: cornerTop ? cornerTop : (cornerLeft || cornerRight) ? 30 : 'unset',
  left: cornerRight ? 'unset' : cornerLeft || '50%',
  right: cornerLeft ? 'unset' : cornerRight || '50%',
  width: 40,
  height: 40,
  transform: [{rotate: '45deg'}, {translateX: (cornerLeft || cornerRight) ? 0 : '-50%'}],
  backgroundColor: backgroundColor || colors.defaultBackground,
}))
