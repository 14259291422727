import React, { useEffect, useRef, useState, useMemo } from 'react'
import { colors }                             from '@civitime/library/storybook/configs/colors'
import { DefaultModal }                       from '@civitime/library/storybook/stories/Modals'
import { useScreenDimensions }                from '@civitime/library/storybook/hooks/useScreenDimensions'
import styled                                 from '@emotion/native'
import { Title1 }                             from '@civitime/library/storybook/stories/Texts'
import { BuildingInProgressCard }             from './BuildingInProgressCard'
import { TeamChat }                           from './TeamChat'
import { Animated }                           from 'react-native'
import { CustomScrollbar }                    from '../CustomScrollbar'
import { useCampaign }                        from '../../../../GlobalContexts'
import { useRealTimeData } from '../../../../Utils/dataClient'
import { clientId, PWA }   from '../../../../../clientId'
import { useProfile }      from '../../../../Auth/useProfile'
import { TooltipTuto }                        from '../Tuto/TooltipTuto'
import { HeaderModal }                        from './HeaderModal'
import { useTranslator, T }                   from '../../../../translations/translate'

export const TownHallModal = ({ _infos, onClose, linkModal }) => {
  const { height, mobile } = useScreenDimensions()
  const [openTeamChat, setOpenTeamChat] = useState(false)
  const {userSession} = useProfile()
  const [scrollbarSizes, setScrollbarSizes] = useState({})
  const [scrollbarPositions, setScrollbarPositions] = useState({})
  const scrollbarRef = useRef(null)
  const scrollbarInit = useRef(true)
  const { campaignSettings, campaignId, campaignEnded } = useCampaign()
  const { userState } = useProfile()
  const { t } = useTranslator()
  const teamData = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}`
  )
  
  const orderedBuildings = useMemo(() => {
    const isFinishedBuilding = []
    const isNotFinishBuilding = []
    Object.values(campaignSettings?.buildings || {})?.forEach(building => {
      const stepsPlayed = Object.values(userSession?.buildings?.[building?.type]?.stepsPlayed || {})?.filter(v => !!v?.win)?.length
      const currentBuilding = Object.values(campaignSettings?.buildings).filter((v => v?.type === building?.type))?.[0]
      const isFinished = stepsPlayed === currentBuilding?.steps
      isFinished ? isFinishedBuilding.push(building)
        : isNotFinishBuilding.push(building)
    })
    isFinishedBuilding.sort((a, b) => a?.order - b?.order)
    isNotFinishBuilding.sort((a, b) => a?.order - b?.order)
    return isNotFinishBuilding.concat(isFinishedBuilding)
  }, [teamData?.wonderState?.step])

  const [displayedModalInfos, setDisplayedModalInfos] = useState(false)
  const lockBuildingsFromOrder = campaignSettings?.lockBuildingsFromOrder
  const buildingProgression = userSession?.buildingProgression


  useEffect(() => {
    if (!scrollbarRef?.current) return
    if (scrollbarInit?.current) {
      scrollbarRef.current.scrollTo({ y: 1, animated: true })
      scrollbarInit.current = false
    }
  }, [scrollbarRef])

  return <>
    <HeaderModal mobile={mobile}/>
    <TownHallDefaultModal title={t(PWA ? 'phase_2.modals.townHall.PWA.name' : 'phase_2.modals.townHall.name')}
                          maxHeight={height * 0.9}
                          onPress={onClose}
                          backgroundColor={colors.lightBlue}
                          color={colors.text}
                          withShadow
                          noScroll={mobile}
                          infoButton={() => setDisplayedModalInfos((e) => !e)}
    >
      {
        displayedModalInfos &&
        <TooltipTuto
          color={colors.white}
          backgroundColor={colors?.text}
          style={{
            position: 'absolute',
            top: 20,
            left: 3,
            zIndex: 2,
            transform: [{ scale: 0.8 }],
            transformOrigin: 'top left'
          }}
          cornerLeft={15}
          cornerTop={-15}
          infos={{
            title: t(PWA ? 'phase_2.modals.townHall.PWA.name'
              : 'phase_2.modals.townHall.name'),
            text: PWA ? t("phase_2.tutorial.tooltips.townHall.PWA")
              : (campaignEnded && !userState?.teamId) ? t("phase_2.tutorial.tooltips.townHall.textUnBoarding")
                : t('phase_2.tutorial.tooltips.townHall.text'),
            onClose: () => setDisplayedModalInfos(false)
          }}/>
      }
      <WrapperTownHallModal mobile={mobile}>
        <WrapperBuildingsAvailable mobile={mobile} noChat={campaignEnded && !userState?.teamId}>
          {
            (mobile && userState?.teamId) &&
            <WrapperTeamChat mobile={mobile} height={height * 0.8}
                             chatStatus={openTeamChat}>
              <TeamChat mobile={mobile} chatInteract={() => setOpenTeamChat((e) => !e)}
                        chatStatus={openTeamChat} height={height}/>
            </WrapperTeamChat>
          }
          {
            !openTeamChat &&
            <>
              <BuildingAvailableTitle>
                <BuildingAvailableTitleText>
                  <T path="phase_2.modals.townHall.currentConstructions"/>
                </BuildingAvailableTitleText>
              </BuildingAvailableTitle>
              <WrapperVerticalScrollbar>
                <CustomScrollbar heightThumb={scrollbarSizes?.height || 0}
                                 scrollbarPositions={scrollbarPositions}
                                 widthThumb={16}
                                 widthTrack={10}
                                 scrollbarRef={scrollbarRef}
                                 customPositionTop={20}
                                 backgroundColor={colors.white}
                                 marginBottom={20}
                                 marginTop={20}
                                 color={colors.defaultBackground}/>
                <WrapperBuildingsInProgress ref={scrollbarRef}
                                            onLayout={(e) => setScrollbarSizes(e?.nativeEvent?.layout)}
                                            vertical
                                            scrollEventThrottle={80}
                                            onScroll={(e) => setScrollbarPositions(e?.nativeEvent)}
                                            mobile={mobile}
                                            maxHeight={height * 0.6}
                                            showsHorizontalScrollIndicator={false}>
                  {
                    orderedBuildings
                      .filter(building => !(building.type === 'merveille'))
                      .map((building, index) => {
                        return <BuildingInProgressCard mobile={mobile}
                                                       key={index + 'town hall buildings'}
                                                       teamData={(campaignEnded && !userState?.teamId || campaignSettings?.lockBuildingsFromOrder) ? userState?.onBoardingGameState : teamData}
                                                       building={building}
                                                       type='construction'
                                                       index={index}
                                                       lockBuildingsFromOrder={lockBuildingsFromOrder}
                                                       buildingProgress={lockBuildingsFromOrder && buildingProgression}
                                                       linkModal={linkModal}/>
                      })
                  }
                </WrapperBuildingsInProgress>
              </WrapperVerticalScrollbar>
            </>
          }
        </WrapperBuildingsAvailable>
        {
          (!mobile && userState?.teamId) &&
          <WrapperTeamChat>
            <TeamChat/>
          </WrapperTeamChat>
        }
      </WrapperTownHallModal>
    </TownHallDefaultModal>
  </>
}

export const WrapperVerticalScrollbar = styled.View({
  position: 'relative',
  paddingRight: 20
})

const TownHallDefaultModal = styled(DefaultModal)(({}) => ({}))

const WrapperTownHallModal = styled.View(({ mobile }) => ({
  flexDirection: mobile ? 'column' : 'row',
  padding: 15,
  overflowY: mobile ? 'hidden' : 'unset'
}))

const WrapperBuildingsAvailable = styled.View(({ mobile, noChat }) => ({
  flexDirection: 'column',
  width: (mobile || noChat) ? '100%' : '60%',
}))

const BuildingAvailableTitle = styled.View({
  width: '100%',
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: colors.text,
  borderRadius: 9
})

const BuildingAvailableTitleText = styled(Title1)({
  color: colors.white,
  width: '100%',
  textAlign: 'center',
  textTransform: 'uppercase'
})

const WrapperTeamChat = styled(Animated.View)(({ mobile, chatStatus, height }) => ({
  width: mobile ? '100%' : '40%',
  position: 'relative',
  left: mobile ? 0 : 'unset',
  height: mobile ? chatStatus ? height : 40 : 'unset',
  marginBottom: mobile && !chatStatus ? 10 : 0,
  maxHeight: mobile ? '99%' : 'unset'
}))

const WrapperBuildingsInProgress = styled.ScrollView(({ mobile, maxHeight }) => ({
  flexDirection: 'column',
  marginTop: 20,
  maxHeight: mobile ? maxHeight : 500,
  marginBottom: mobile ? 40 : 0,
}))
