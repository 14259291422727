import { createMainSagas }    from './Domain/mainSagas'
import { ReplaySubject }      from 'rxjs'
import { takeWhile, toArray } from 'rxjs/operators'
import { SAGA_ENDED }         from '@civitime/game-server/lib/saga-tools/SagaRunner'
import { handleLocalEvent }   from './localEventHandler'


export async function collectEvents(source$) {
  const events = await new Promise((resolve, reject) => {
    source$
      .pipe(toArray())
      .subscribe(resolve, reject)
  })
  
  if (events.some(e => e.isError)) {
    return events.filter(e => e.isError)
  } else {
    return events
  }
}

export const sendLocalCommand = async (command) => {
  const PWACommand = {
    ...command,
    payload: {
      ...command?.payload,
      PWA: true
    }
  }
  const sagaRunner = createMainSagas()

  const source$ = new ReplaySubject()
const events$ = source$
    .pipe(takeWhile(event => event.type !== SAGA_ENDED))
  
  sagaRunner.events$
    .subscribe(v => {
      source$.next(v)
    }, (e) => {
      console.error("error", e)
      source$.error(e)
    })
  sagaRunner.emit(PWACommand)
  const events = await collectEvents(events$)
  
  await Promise.all(
    events.map(e => handleLocalEvent(e))
  )
  sagaRunner.close()
  
  return events
}