import React from "react"
import styled from "@emotion/native"
import { usePreCachedData } from "../../Utils/dataClient"
import { clientId } from "../../../clientId"

import { colors } from "@civitime/library/storybook/configs/colors"
import { BoldText } from "@civitime/library/storybook/stories/Texts"
import { AvatarComponent } from "@civitime/library/storybook/stories/Avatar"

export const MyTeamMemberListItem = ({
  member,
  noBorder,
}) => {
  const memberInfos = usePreCachedData(
    `clients/${clientId}/profiles/${member}`,
    []
  )

  return memberInfos ? (
    <WrapperMemberListItem {...{ noBorder }}>
      <AvatarComponent
        avatar={memberInfos.avatar}
        circleAvatarConfig={{
          size: 50,
          withBorder: true,
          backgroundColor: colors.extraLightBackground,
        }}
      />
      <MemberListItemName>{memberInfos.name}</MemberListItemName>
    </WrapperMemberListItem>
  ) : null
}

const WrapperMemberListItem = styled.View(
  {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 50,
  },
  ({ noBorder }) =>
    !noBorder && {
      borderBottomWidth: 1,
      borderBottomColor: colors.text,
    }
)

const MemberListItemName = styled(BoldText)({
  textTransform: "uppercase",
  marginLeft: 17,
})
