import React from "react"
import styled from "@emotion/native"
import Collapsible from "react-native-collapsible"
import { useTranslator } from "../../translations/translate"
import { useCampaign } from "../../GlobalContexts"
import { useRealTimeData } from "../../Utils/dataClient"
import { clientId } from "../../../clientId"

import {
  RankingLineWrapper,
  TeamNameWrapper,
  WrapperRank,
  Name,
  WrapperPoints,
} from "./Containers"
import { MembersList } from "../../Teams/components/MembersList"

import { colors }                 from "@civitime/library/storybook/configs/colors"
import { LittleTextButton }       from "@civitime/library/storybook/stories/Button"
import { Blazon }                 from "@civitime/library/storybook/stories/Blazon"
import { calculateDisplayedName } from './PlayerRankingListItem'

export const TeamRankingListItem = ({
  team,
  index,
  select,
  selected,
  teamMax,
  mobile,
  isMyTeam,
  phase
}) => {
  const { t } = useTranslator()
  const { campaignId } = useCampaign()
  const teamData = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${team?.teamId}`
  )

  const displayedName = calculateDisplayedName(teamData?.name)

  return (
    <>
      <RankingLineWrapper {...{ me: isMyTeam, mobile }}>
        {teamData && (
          <>
            <WrapperRank {...{ mobile }}>{index + 4}</WrapperRank>
            <TeamNameWrapper {...{ mobile }}>
              <WrapperBlazon
                phase={phase}
                blazon={teamData?.logo?.shape}
                color={teamData?.logo?.color}
                text={teamData?.logo?.text}
                size={30}
              />
              <Name>{mobile ? displayedName : teamData?.name}</Name>
            </TeamNameWrapper>
            <WrapperMemberButton {...{ mobile }}>
              <LittleTextButton
                hoverColor={colors.lightBlue}
                onPress={() => select(team?.teamId)}
              >
                {teamData?.members?.length}{teamMax && `/${teamMax}`}
                {!mobile &&
                  (teamData?.members?.length > 1
                    ? " " + t("app.team.members")
                    : " " + t("app.team.member"))}
              </LittleTextButton>
            </WrapperMemberButton>
            <WrapperPoints {...{ mobile }} count={team.score} phase={phase} type="team"/>
          </>
        )}
      </RankingLineWrapper>
      <WrapperMembersList {...{ mobile }}>
        <Collapsible collapsed={!(team.teamId === selected)}>
          {teamData && <MembersList members={teamData.members} />}
        </Collapsible>
      </WrapperMembersList>
    </>
  )
}

const WrapperBlazon = styled(Blazon)({
  padding: 10,
})

const WrapperMemberButton = styled.View(({ mobile }) => ({
  flex: mobile ? 0.2 : 0.25,
  alignItems: "center",
}))

const WrapperMembersList = styled.View(({ mobile }) => ({
  width: mobile ? "90%" : "70%",
}))
