import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { firebaseAccessor }                                                   from '../firebase'
import { clientId }                                                           from '../../clientId'
import { useCampaign }                                                        from '../GlobalContexts'
import { isDev }                                                              from '../../isDev'
import { useProfile }                                                         from '../Auth/useProfile'
import backupTranslate from "../Utils/fr.json";

const TranslationsContext = createContext({
  content: {},
  appTexts: {},
});

const defaultLang = "fr";
export const TranslationCampaignProvider = ({
  children,
  firestoreInitiated,
}) => {
  const { campaignId } = useCampaign();
  const { emitCommand, userId } = useProfile();
  const { content } = useContext(TranslationsContext);
  const [campaignContent, setContent] = useState(content);
  const [campaignLangs] = useState([{ lang: "Français", shortLang: "fr" }]);
  const [lang, setLang] = useState(defaultLang);
  useEffect(() => {
    if (!userId || !firestoreInitiated) return;
    getContentTranslations();
  }, [userId, firestoreInitiated]);

  const getContentTranslations = async () => {
    const checkIfCampaignHasCustomTranslations = await fetch(
      `https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/texts/custom/fr.json`
    )
      .then(async (r) => {
        if (!r || r.status === 404) {
          return null;
        }
        return r.json();
      })
      .catch((error) =>
        console.error("checkIfCampaignHasCustomTranslations : ", error)
      );

    const appTranslations = !checkIfCampaignHasCustomTranslations
      ? backupTranslate
      : checkIfCampaignHasCustomTranslations;

    const phase2Content =
      clientId && campaignId
        ? await fetch(
            `https://ct-campaigns.civitimeapp.com/clients/cttest/campaigns/leclerc/fr.json`
          ).then(async (r) => {
            if (r.status === 404) {
              return null;
            }
            return r.json();
          })
        : null;

    const content = phase2Content ?? null;
    setContent({ ...appTranslations, ...content });
  };

  useEffect(() => {
    if (emitCommand) {
      emitCommand({
        type: "UpdateLang",
        payload: {
          lang: lang,
        },
      });
    }
  }, [lang]);
  return (
    <TranslationsContext.Provider
      value={{
        content: campaignContent,
        lang: lang,
        langs: campaignLangs,
        setLang: (lang) => setLang(lang),
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};

const splitParams = /([[{].*?[\]}])/
export const useTranslations = () => {
  const { content, lang, langs, setLang } = useContext(TranslationsContext)
  const t = useCallback(
    (key, data) => toText(key, data, content),
    [content],
  )
  return {
    lang,
    langs,
    content,
    setLang,
    t: t,
    text: (...params) => t(...params).join(''),
  }
}
export const TContent = (({ children, path, data }) => {
  const { t } = useTranslations()
  const rawText = t(path, data ?? {}).filter(Boolean)
  const value = rawText
    .map((v, i) => {
      if (typeof v === 'object') {
        return <span key={i}>{v}</span>
      }
      if (typeof v === 'string') {
        return <React.Fragment key={v}>{v
          .split(' ')
          .map(v => {
            if (v === '\\n') {
              return '\n'
            } else return v + ' '
          })
          .join('')
          .replace(/<a[^>]*href=["']?([^"' >]+)['"]?[^>]*>[^<]*<\/a>/gi, '$1')
          .split(/(?=http)| /)
          .map(item => {
            if (item.startsWith('http')) {
              return <a href={item} target="_blank" rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
              >{t('app.challenges.link')}</a>
            } else {
              return item + ' '
            }
          })
        }
        
        </React.Fragment>
      }
      return v
    })
  return <>
    {value || children}
  </>
})
export const T = TContent

export const useTranslator = () => {
  const { content, lang, langs, setLang } = useContext(TranslationsContext)
  const t = useCallback(
    (key, data) => toText(key, data, content),
    [content],
  )
  return {
    lang,
    langs,
    content,
    setLang,
    t: t,
  }
}

function toText (initialKey, data, content) {
  try {
    const key = initialKey.replace(/^text\./i, '')
    const rawValue = getValue(key, content)
    return rawValue
      .split(splitParams)
      .map(s => {
        if (s[0] === '{' || s[0] === '[') {
          return data[s.slice(1, -1)] !== 'undefined' || data[s.slice(1, -1)] !== 'null' ? data[s.slice(1, -1)]
            : ''
        }
        return s
      })
  } catch (e) {
    return []
  }
}

const splitter = /\./

function getValue (key, obj) {
  const fallback = isDev ? key : ''
  if (!key) return fallback
  return key
    .split(splitter)
    .reduce((acc, k) => acc && acc[k], obj)
    ?.toString() ?? fallback
}
