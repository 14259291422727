import React                         from 'react'
import styled                        from '@emotion/native'
import { T }                         from '../../translations/translate'
import { Dialog }                    from '@civitime/library/storybook/stories/Dialog'
import { MediumText, Title1 }        from '@civitime/library/storybook/stories/Texts'
import { ActionButton, TextButton, } from '@civitime/library/storybook/stories/Button'

export const JoinTeamAlert = ({ onRequestClose, visible, navToTeam }) => {
  return (
    <JoinTeamDialog {...{ onRequestClose, visible }}>
      <WarningTitle>
        <T path="app.global.warning" />
      </WarningTitle>
      <MediumText>
        <T path="app.team.warningJoinTeam" />
      </MediumText>
      <ModalActionButtonWrapper>
        <ActionButton onPress={navToTeam}>
          <T path="app.global.next" />
        </ActionButton>
        <TextButton onPress={onRequestClose}>
          <T path="app.global.cancel" />
        </TextButton>
      </ModalActionButtonWrapper>
    </JoinTeamDialog>
  )
}

const JoinTeamDialog = styled(Dialog)({
  display: 'flex'
})

export const WarningTitle = styled(Title1)({
  marginBottom: 13,
  textTransform: "uppercase",
})

const ModalActionButtonWrapper = styled.View({
  marginTop: 30,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
})
