import React                          from 'react'
import styled                         from '@emotion/native'
import { BoldMediumText, MediumText } from '@civitime/library/storybook/stories/Texts'
import ConstructionIcon               from '../../Map/Assets/buildings/construction.svg'
import WonderIcon                     from '../../Map/Assets/buildings/merveille.svg'
import { View }                       from 'react-native'

export const IntroTutoSecondPart = ({ infos }) => {
  
  return <WrapperIntroSecondPart>
    <TextIntro>{infos?.textIntro}</TextIntro>
    <WrapperInvestSteps>
      <InvestWrapper>
        <IconWrapper>
          <ConstructionIcon width={40} height={40}/>
        </IconWrapper>
        <InvestText>
          {infos?.textConstruction}
        </InvestText>
      </InvestWrapper>
      <InvestWrapper>
        <IconWrapper>
          <WonderIcon width={40} height={40}/>
        </IconWrapper>
        <InvestText>
          {infos?.textWonder}
        </InvestText>
      </InvestWrapper>
    </WrapperInvestSteps>
    <TextOutro>{infos?.textOutro}</TextOutro>
  </WrapperIntroSecondPart>
}

const InvestText = styled(BoldMediumText)({
  textAlign: 'left'
})

const WrapperIntroSecondPart = styled.View({})

const TextIntro = styled(MediumText)({
  marginTop: 15,
})

const WrapperInvestSteps = styled.View({})

const InvestWrapper = styled.View({
  flexDirection: 'row',
  marginTop: 15,
  marginBottom: 15
})

const TextOutro = styled(BoldMediumText)({
  fontWeight: 'bold'
})

const IconWrapper = styled.View({
  marginRight: 15,
  width: 40,
  display: 'flex',
  alignItems:'center',
  justifyContent: 'center'
})