import React, { useEffect, useRef, useState }   from 'react'
import styled                                   from '@emotion/native'
import { useScreenDimensions }                  from '@civitime/library/storybook/hooks/useScreenDimensions'
import { colors }                               from '@civitime/library/storybook/configs/colors'
import { MediumText, Title1 }                   from '@civitime/library/storybook/stories/Texts'
import { TouchableWithoutFeedback, View }       from 'react-native'
import { WrapperVerticalScrollbar }             from './TownHallModal'
import { CustomScrollbar }                      from '../CustomScrollbar'
import { ReturnToMapButton }                    from '@civitime/library/storybook/stories/Button'
import { T, useTranslator }                     from '../../../../translations/translate'
import Points                                   from './Assets/score.svg'
import Flouz                                    from './Assets/flouz.svg'
import { useCampaign }                          from '../../../../GlobalContexts'
import WhiteFlouzIcon                           from './Assets/whiteFlouz.svg'
import { EvolutionButton, EvolutionButtonText } from './WonderModal'
import format                                   from 'date-fns/format'
import parseISO                                 from 'date-fns/parseISO'
import BlueTree                                 from '../../Map/Assets/buildings/ended-wonder.svg'
import Construction                             from '../../Map/Assets/buildings/construction.svg'
import Thunder                                  from './Assets/points.svg'
import Invest                                   from './Assets/invest.svg'

export const HelperModal = ({ linkModal }) => {
  const { mobile, width, height } = useScreenDimensions()
  const [tabActive, setTabActive] = useState('rules')
  const { t } = useTranslator()
  
  return <WrapperHelperModal mobile={mobile} height={height} width={width}>
    <WrapperBackButton>
      <ReturnToMapButton
        text={t('phase_2.modals.helperModal.backToCity')}
        onPress={() => linkModal(null)}
      />
    </WrapperBackButton>
    <HelperTabs updateActiveTab={setTabActive} activeTab={tabActive} mobile={mobile}/>
    {
      tabActive === 'rules' ?
        <HelperContentComponent height={height} mobile={mobile}>
          <HelperRulesContent/>
        </HelperContentComponent>
        : <HelperContentComponent height={height} mobile={mobile}>
          <HelperFAQContent mobile={mobile}/>
        </HelperContentComponent>
    }
  </WrapperHelperModal>
}

const WrapperBackButton = styled.View({
  position: 'absolute',
  right: 0,
  top: -50
})

const WrapperRulesContent = styled.View(({ mobile }) => ({
  width: '100%',
  paddingTop: mobile ? 15 : 30,
  paddingBottom: mobile ? 15 : 30,
  paddingLeft: mobile ? 10 : '10%',
  paddingRight: mobile ? 10 : '10%',
}))

const ContentTitle = styled(Title1)(({ mobile }) => ({
  marginBottom: 20,
  borderBottomWidth: 1,
  borderColor: colors.text,
  fontWeight: 'bold',
  paddingBottom: 3,
  textAlign: mobile ? 'center' : 'left',
  marginTop: 15
}))

const ContentText = styled(MediumText)(({ active }) => ({
  color: active ? colors.white : colors.text
}))

const HelperRulesContent = () => {
  const { mobile } = useScreenDimensions()
  
  return <WrapperRulesContent mobile={mobile}>
    <ContentTitle mobile={mobile}>
      <T path="phase_2.modals.helperModal.helperRules.goal"/>
    </ContentTitle>
    <WrapperHelperPart mobile={mobile}>
      <BlueTree width={70} height={70} style={{ marginTop: mobile && 10, marginBottom: mobile && 10 }}/>
      <ContentText style={{ textAlign: mobile ? 'center' : 'left', marginLeft: 15 }}>
        <T path="phase_2.modals.helperModal.helperRules.textGoal"/>
      </ContentText>
    </WrapperHelperPart>
    <ContentTitle mobile={mobile}>
      <T path="phase_2.modals.helperModal.helperRules.how"/>
    </ContentTitle>
    <WrapperHelperPart mobile={mobile}>
      <Construction width={70} height={70} style={{ marginTop: mobile && 10, marginBottom: mobile && 10 }}/>
      <ContentText style={{ textAlign: mobile ? 'center' : 'left', marginLeft: 15 }}>
        <T path="phase_2.modals.helperModal.helperRules.howBuildings"/>
      </ContentText>
    </WrapperHelperPart>
    <WrapperHelperPart mobile={mobile}>
      <Flouz width={60} height={60} style={{ marginTop: mobile && 10, marginBottom: mobile && 10 }}/>
      <ContentText style={{ textAlign: mobile ? 'center' : 'left', marginLeft: 15 }}>
        <T path="phase_2.modals.helperModal.helperRules.howFlouz"/>
      </ContentText>
    </WrapperHelperPart>
    <WrapperHelperPart mobile={mobile}>
      <Points width={70} height={70} style={{ marginTop: mobile && 10, marginBottom: mobile && 10 }}/>
      <ContentText style={{ textAlign: mobile ? 'center' : 'left', marginLeft: 15 }}>
        <T path="phase_2.modals.helperModal.helperRules.howScore"/>
      </ContentText>
    </WrapperHelperPart>
    <ContentTitle mobile={mobile}>
      <T path="phase_2.modals.helperModal.helperRules.warning"/>
    </ContentTitle>
    <WrapperHelperPart mobile={mobile}>
      <Thunder width={50} height={50} style={{ marginTop: mobile && 10, marginBottom: mobile && 10 }}/>
      <ContentText style={{ textAlign: mobile ? 'center' : 'left', marginLeft: 15 }}>
        <T path="phase_2.modals.helperModal.helperRules.warningText"/>
      </ContentText>
    </WrapperHelperPart>
    <ContentTitle mobile={mobile}>
      <T path="phase_2.modals.helperModal.helperRules.invest"/>
    </ContentTitle>
    <ContentText style={{ textAlign: mobile ? 'center' : 'left' }}>
      <T path="phase_2.modals.helperModal.helperRules.investHow"/>
    </ContentText>
    <WrapperInvestLogo mobile={mobile}>
      <Invest width={160} height={30} style={{ marginTop: 15 }}/>
    </WrapperInvestLogo>
  </WrapperRulesContent>
}

const WrapperInvestLogo = styled.View(({ mobile }) => ({
  width: '100%',
  alignItems: mobile ? 'center' : 'flex-start'
}))

const WrapperHelperPart = styled.View(({ mobile }) => ({
  flexDirection: mobile ? 'column' : 'row',
  width: '100%',
  alignItems: 'center',
}))

const WrapperFAQContent = styled.View(({ mobile }) => ({
  width: '100%',
  paddingTop: 30,
  paddingRight: mobile ? 10 : 30,
  paddingBottom: 30,
  paddingLeft: mobile ? 10 : 30,
}))

const HelperFAQContent = ({ mobile }) => {
  const [displayedContent, setDisplayedContent] = useState(null)
  const { content } = useTranslator()
  const data = content?.phase_2?.modals?.helperModal?.FAQ?.sections
  const { campaignSettings } = useCampaign()
  return <WrapperFAQContent mobile={mobile}>
    <Title1 style={{ marginBottom: 10 }}>
      <T path={'phase_2.modals.helperModal.FAQ.title'}/>
    </Title1>
    {
      data.map((content, i) => {
        return <WrapperFaq key={'faq' + i}>
          <WrapperSection>
            {content?.section}
          </WrapperSection>
          {
            content?.questions?.map((question, index) => {
              return <View key={'faq questions' + index}>
                <WrapperFAQQuestion onPress={() => {
                  if (displayedContent === (i + '-' + index)) {
                    setDisplayedContent(null)
                    return
                  }
                  setDisplayedContent((i + '-' + index))
                }}>
                  <FAQQuestion active={displayedContent === (i + '-' + index)}>
                    <ContentText
                      active={displayedContent === (i + '-' + index)}
                      style={{
                        marginBottom: 0,
                        textAlign: 'left',
                        fontWeight: 'bold',
                        fontSize: mobile ? 13 : 15
                      }}
                    >
                      {question?.label}
                    </ContentText>
                  </FAQQuestion>
                </WrapperFAQQuestion>
                {
                  displayedContent === (i + '-' + index) &&
                  <WrapperFAQTextContent>
                    <ContentText
                      style={{
                        marginBottom: 0,
                        textAlign: 'left',
                        fontSize: mobile ? 13 : 15
                      }}
                    >
                      {transformText(question?.answer, campaignSettings)}
                    </ContentText>
                  </WrapperFAQTextContent>
                }
              </View>
            })
          }
        </WrapperFaq>
      })
    }
  </WrapperFAQContent>
}

const WrapperFAQTextContent = styled.View({
  width: '100%',
  padding: 10,
})

const FAQQuestion = styled.View(({ active }) => ({
  width: '100%',
  backgroundColor: active ? colors.primary : colors.lightBlue,
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 20,
  borderRadius: 9,
  alignItems: 'fex-start',
  justifyContent: 'center',
  marginTop: 5,
  marginBottom: 5,
}))

const WrapperFAQQuestion = styled.TouchableWithoutFeedback({})

const WrapperFaq = styled.View({
  flexDirection: 'column'
})

const WrapperSection = styled(MediumText)({
  borderBottomWidth: 1,
  borderBottomColor: colors.primary,
  color: colors.primary,
  fontSize: 18,
  fontWeight: 'bold',
  textAlign: 'left'
})

const HelperContentComponent = ({ mobile, height, children }) => {
  const [scrollbarSizes, setScrollbarSizes] = useState({})
  const [scrollbarPositions, setScrollbarPositions] = useState({})
  const scrollbarRef = useRef(null)
  const scrollbarInit = useRef(true)
  
  useEffect(() => {
    if (!scrollbarRef?.current) return
    if (scrollbarInit?.current) {
      scrollbarRef.current.scrollTo({ y: 1, animated: true })
      scrollbarInit.current = false
    }
  }, [scrollbarRef])
  
  return <WrapperHelperContent>
    <WrapperVerticalScrollbar>
      <CustomScrollbar
        heightThumb={scrollbarSizes?.height || 0}
        scrollbarPositions={scrollbarPositions}
        widthThumb={16}
        widthTrack={10}
        scrollbarRef={scrollbarRef}
        backgroundColor={colors.white}
        marginBottom={20}
        marginRight={10}
        marginTop={20}
        color={colors.defaultBackground}
      />
      <HelperContent ref={scrollbarRef}
                     onLayout={(e) => setScrollbarSizes(e?.nativeEvent?.layout)}
                     vertical
                     scrollEventThrottle={80}
                     onScroll={(e) => setScrollbarPositions(e?.nativeEvent)}
                     mobile={mobile}
                     height={height}
                     showsHorizontalScrollIndicator={false}>
        {children}
      </HelperContent>
    </WrapperVerticalScrollbar>
    <HelpContact mobile={mobile} bottomRadius={9} backgroundColor={colors.cadetBlueL}/>
  </WrapperHelperContent>
}

export const HelpContact = ({ mobile, bottomRadius, backgroundColor, noCgu, ...props }) => {
  return <TechContact mobile={mobile} bottomRadius={bottomRadius} backgroundColor={backgroundColor} {...props}>
    <TechContactText mobile={mobile}>
      <T path="phase_2.modals.helperModal.footer.contentText"/>
      <TechContactText
        style={{ fontWeight: 'bold', paddingRight: 5, paddingLeft: 5, fontSize: mobile ? 13 : 15 }}
      >
        <T path="phase_2.modals.helperModal.footer.email"/>
      </TechContactText>
      {!noCgu && <TechContactText
        style={{
          fontWeight: 'bold',
          textDecoration: 'underline',
          paddingRight: 5,
          paddingLeft: 5,
          fontSize: mobile ? 13 : 15
        }}
      >
        <T path="phase_2.modals.helperModal.footer.links"/>
      </TechContactText>}
    </TechContactText>
  </TechContact>
}

export const QRContact = ({ bottomRadius, backgroundColor, noCgu, mobile, qr, ...props }) => {
  return <QRTechContact mobile={mobile} bottomRadius={bottomRadius} backgroundColor={backgroundColor} {...props}>
    <TechContactText mobile={mobile}>
      <T path="phase_2.modals.helperModal.footer.pwa"/>
      {!noCgu && <TechContactText
        style={{
          fontWeight: 'bold',
          textDecoration: 'underline',
          paddingRight: 5,
          paddingLeft: 5,
          fontSize: mobile ? 13 : 15
        }}
      >
        <T path="phase_2.modals.helperModal.footer.links"/>
      </TechContactText>}
    </TechContactText>
  </QRTechContact>
}

const QRTechContact = styled.View(({ bottomRadius, backgroundColor }) => ({
  width: '100%',
  backgroundColor: backgroundColor,
  borderBottomLeftRadius: bottomRadius,
  borderBottomRightRadius: bottomRadius,
  justifyContent: 'center',
  alignItems: 'center',
  opacity: .8,
  position: 'fixed',
  bottom: 0,
  height: 50
}))

const TechContactText = styled(MediumText)(({ mobile }) => ({
  paddingRight: mobile ? '3%' : '10%',
  paddingLeft: mobile ? '3%' : '10%',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.white,
  fontSize: mobile ? 13 : 15
}))

const HelperContent = styled.ScrollView(({ mobile, height }) => ({
  flexDirection: 'column',
  height: mobile ? (height * 0.7) - 60 : ((height * 0.7) - 90) * 0.97,
  maxHeight: mobile ? (height * 0.7) - 60 : ((height * 0.7) - 90) * 0.97
}))

const WrapperHelperContent = styled.View({
  flexDirection: 'column',
  backgroundColor: colors.defaultBackground,
  borderRadius: 9
})

const TechContact = styled.View(({ mobile, bottomRadius, backgroundColor }) => ({
  width: '100%',
  height: mobile ? 90 : 50,
  backgroundColor: backgroundColor,
  borderBottomLeftRadius: bottomRadius,
  borderBottomRightRadius: bottomRadius,
  justifyContent: 'center',
  alignItems: 'center',
}))

const HelperTabs = ({ updateActiveTab, activeTab, mobile }) => {
  return <WrapperTabs mobile={mobile}>
    <TouchableWithoutFeedback onPress={() => updateActiveTab('rules')}>
      <TabRules active={activeTab === 'rules'} mobile={mobile}>
        <TabText active={activeTab === 'rules'}>
          Les règles
        </TabText>
      </TabRules>
    </TouchableWithoutFeedback>
    <TouchableWithoutFeedback onPress={() => updateActiveTab('faq')}>
      <TabFAQ active={activeTab === 'faq'} mobile={mobile}>
        <TabText active={activeTab === 'faq'}>
          FAQ
        </TabText>
      </TabFAQ>
    </TouchableWithoutFeedback>
  </WrapperTabs>
}

const WrapperHelperModal = styled.View(({ mobile, height, width }) => ({
  width: mobile ? width * 0.9 : width * 0.6,
  height: mobile ? height * 0.8 : height * 0.7,
  maxHeight: mobile ? height * 0.8 : height * 0.7,
  justifyContent: 'space-between'
}))

const WrapperTabs = styled.View(({ mobile }) => ({
  flexDirection: mobile ? 'column' : 'row',
}))

const TabRules = styled.View(({ active, mobile }) => ({
  width: mobile ? '100%' : '50%',
  height: 40,
  backgroundColor: active ? colors.primary : colors.lightBlue,
  justifyContent: 'center',
  alignItems: 'center',
  borderTopLeftRadius: 9,
  borderBottomLeftRadius: 9,
  borderBottomRightRadius: mobile ? 9 : 0,
  borderTopRightRadius: mobile ? 9 : 0,
}))

const TabFAQ = styled(TabRules)(({ mobile }) => ({
  borderTopLeftRadius: mobile ? 9 : 0,
  borderBottomLeftRadius: mobile ? 9 : 0,
  borderTopRightRadius: 9,
  borderBottomRightRadius: 9
}))

const TabText = styled(Title1)(({ active }) => ({
  color: active ? colors.white : colors.text,
  textTransform: 'uppercase'
}))

const transformText = (rawText, campaignSettings) => {
  const startDate = campaignSettings?.startAt
  const endDate = campaignSettings?.endAt
  return rawText.replace(/DATESTART/gm, format(parseISO(startDate), 'dd/MM'))
    .replace(/DATEENDHOUR/gm, format(parseISO(endDate), 'hh:mm'))
    .replace(/DATEEND/gm, format(parseISO(endDate), 'dd/MM'))
    .replace(/TEAMMAX/gm, campaignSettings?.maxTeamMembers)
}
