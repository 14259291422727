export default {
  'v': '5.7.1',
  'fr': 25,
  'ip': 0,
  'op': 300,
  'w': 10635,
  'h': 8745,
  'nm': '4',
  'ddd': 0,
  'assets': [{ 'id': 'image_0', 'w': 1161, 'h': 665, 'u': '', 'p': require('./images/img_0.png'), 'e': 0 }, {
    'id': 'image_1',
    'w': 1049,
    'h': 776,
    'u': '',
    'p': require('./images/img_1.png'),
    'e': 0
  }, { 'id': 'image_2', 'w': 2049, 'h': 1769, 'u': '', 'p': require('./images/img_2.png'), 'e': 0 }, {
    'id': 'image_3',
    'w': 1589,
    'h': 1244,
    'u': '',
    'p': require('./images/img_3.png'),
    'e': 0
  }, { 'id': 'image_4', 'w': 1155, 'h': 539, 'u': '', 'p': require('./images/img_4.png'), 'e': 0 }, {
    'id': 'image_5',
    'w': 495,
    'h': 1000,
    'u': '',
    'p': require('./images/img_5.png'),
    'e': 0
  }, { 'id': 'image_6', 'w': 780, 'h': 1608, 'u': '', 'p': require('./images/img_6.png'), 'e': 0 }, {
    'id': 'image_7',
    'w': 471,
    'h': 820,
    'u': '',
    'p': require('./images/img_7.png'),
    'e': 0
  }, { 'id': 'image_8', 'w': 1207, 'h': 2042, 'u': '', 'p': require('./images/img_8.png'), 'e': 0 }],
  'layers': [{
    'ddd': 0,
    'ind': 1,
    'ty': 2,
    'nm': 'herbe4',
    'refId': 'image_0',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 25,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 50,
          's': [100]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 200,
          's': [100]
        }, { 't': 225, 's': [0] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5007.5, 7318.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [580.5, 612.5, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 25,
          's': [100, 41, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 50,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 200,
          's': [100, 100, 100]
        }, { 't': 225, 's': [100, 41, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 2,
    'ty': 2,
    'nm': 'feuillage3',
    'refId': 'image_1',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5552, 5440, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [448, 476, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 100,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 113,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 125,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 150,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 175,
          's': [100, 100, 100]
        }, { 't': 200, 's': [105, 105, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 100,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 3,
    'ty': 2,
    'nm': 'feuillage1',
    'refId': 'image_2',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4416, 5160, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [951, 1158, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 38,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 50,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 63,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 88,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 113,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 138,
          's': [105, 105, 100]
        }, { 't': 163, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 38,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 4,
    'ty': 2,
    'nm': 'feuillage2',
    'refId': 'image_3',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5336.5, 4921, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [810.5, 870, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 63,
          's': [0, 0, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [110, 110, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 88,
          's': [100, 100, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 113,
          's': [105, 105, 100]
        }, {
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 138,
          's': [100, 100, 100]
        }, { 't': 163, 's': [105, 105, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 63,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 5,
    'ty': 2,
    'nm': 'ombre',
    'refId': 'image_4',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 100,
          's': [0]
        }, { 't': 112, 's': [76.078] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5012.5, 5566.5, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [577.5, 269.5, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 88,
    'op': 300,
    'st': 0,
    'bm': 1
  }, {
    'ddd': 0,
    'ind': 6,
    'ty': 2,
    'nm': 'lum tronc',
    'refId': 'image_5',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 50,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 75,
          's': [50.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 100,
          's': [25]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 125,
          's': [50.196]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 150,
          's': [25]
        }, { 't': 175, 's': [50] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4907.5, 6507, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [247.5, 500, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 7,
    'ty': 2,
    'nm': 'lum tronc',
    'refId': 'image_6',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 88,
          's': [0]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 113,
          's': [54.118]
        }, {
          'i': { 'x': [0.833], 'y': [0.833] },
          'o': { 'x': [0.167], 'y': [0.167] },
          't': 137,
          's': [25]
        }, { 't': 162, 's': [55] }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4989, 6257, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [390, 804, 0], 'ix': 1 },
      's': { 'a': 0, 'k': [100, 100, 100], 'ix': 6 }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 6
  }, {
    'ddd': 0,
    'ind': 8,
    'ty': 2,
    'nm': 'pt branche',
    'refId': 'image_7',
    'sr': 1,
    'ks': {
      'o': { 'a': 0, 'k': 100, 'ix': 11 },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [5154.5, 6157, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [35.5, 714, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 75,
          's': [0, 0, 100]
        }, { 't': 100, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 75,
    'op': 300,
    'st': 0,
    'bm': 0
  }, {
    'ddd': 0,
    'ind': 9,
    'ty': 2,
    'nm': 'tronc',
    'refId': 'image_8',
    'sr': 1,
    'ks': {
      'o': {
        'a': 1,
        'k': [{ 'i': { 'x': [0.833], 'y': [0.833] }, 'o': { 'x': [0.167], 'y': [0.167] }, 't': 0, 's': [0] }, {
          't': 12,
          's': [100]
        }],
        'ix': 11
      },
      'r': { 'a': 0, 'k': 0, 'ix': 10 },
      'p': { 'a': 0, 'k': [4986.5, 7039, 0], 'ix': 2 },
      'a': { 'a': 0, 'k': [603.5, 1877, 0], 'ix': 1 },
      's': {
        'a': 1,
        'k': [{
          'i': { 'x': [0.833, 0.833, 0.833], 'y': [0.833, 0.833, 0.833] },
          'o': { 'x': [0.167, 0.167, 0.167], 'y': [0.167, 0.167, 0.167] },
          't': 0,
          's': [10, 10, 100]
        }, { 't': 37, 's': [100, 100, 100] }],
        'ix': 6
      }
    },
    'ao': 0,
    'ip': 0,
    'op': 300,
    'st': 0,
    'bm': 0
  }],
  'markers': []
}