import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useCampaign }                                 from '../../../GlobalContexts'
import styled                                          from '@emotion/native'
import { BuildingIcon }                                from './BuildingIcon'
import { Animated, View }                              from 'react-native'
import { colors }                                      from '@civitime/library/storybook/configs/colors'
import ChatIcon                                        from './Assets/chat-icon.svg'
import { useRealTimeData }                             from '../../../Utils/dataClient'
import { clientId, PWA }                               from '../../../../clientId'
import { useProfile }                                  from '../../../Auth/useProfile'
import LottieView                                      from 'lottie-react-native'
import { useTutorialStatus }                           from '../Hooks/useTutorialStatus'
import { firebaseAccessor }                            from '../../../firebase'
import { TutoHallTown }                                from '../Components/Tuto/TutoHallTown'
import { TutoBuildings }                               from '../Components/Tuto/TutoBuildings'
import { TutoWonder }                                  from '../Components/Tuto/TutoWonder'
import { BehaviorSubject }                             from 'rxjs'
import { _focusOnElement }                             from './MapPhase2'
import { IntroTutoSecondPart }                         from '../Components/Tuto/IntroTutoSecondPart'
import { T, useTranslator }                            from '../../../translations/translate'
import { Title2 }                                      from '@civitime/library/storybook/stories/Texts'

export const _backupLastBuildingModalSeen = new BehaviorSubject(null)
export const _wonderSimulateOnPress = new BehaviorSubject(false)

export const MapBuildings = ({ linkModal, globalModalOpened }) => {
  const { userState, userId, userSession, emitCommand } = useProfile()
  const { campaignSettings, campaignId, campaignEnded, campaignNavigate } = useCampaign()
  const [buildings, setBuildings] = useState([])
  const iconHoverAnimation = useRef(new Animated.Value(1)).current
  const [buildingHovered, setBuildingHovered] = useState(null)
  const { tutorialIntro, hallTownFirstClicked, buildingFirstClicked, wonderFirstClicked } = useTutorialStatus()
  const [tutorialIntroSecondPart, setTutorialIntroSecondPart] = useState(false)
  const onBoarding = (campaignEnded && !userState?.teamId) || campaignSettings?.PWA

  const { t } = useTranslator()


  useEffect(() => {
    if (!campaignEnded || !userState?.id || PWA) return
    if (userState?.campaignEndedModalSeen) return
    setTimeout(() => linkModal({
      type: 'basic',
      infos: {
        title: t('phase_2.modals.campaignEnded.title'),
        text: t('phase_2.modals.campaignEnded.textContent'),
        buttonText: t('phase_2.modals.campaignEnded.buttonText'),
        action: async () => {
          await emitCommand({
            type: 'Phase2SeeCampaignEndedModal',
            payload: {}
          })
          campaignNavigate('leaderboard/team')
          linkModal(null)
        },
        onClose: async () => {
          await emitCommand({
            type: 'Phase2SeeCampaignEndedModal',
            payload: {}
          })
          linkModal(null)
        }
      }
    }), 300)
  }, [campaignEnded, userState])

  useEffect(() => {
    if (globalModalOpened || tutorialIntro === undefined) return
    if (tutorialIntro) {
      linkModal(null)
      return
    }
    if (!tutorialIntro) {
      if (tutorialIntroSecondPart) {
        ;(async () => {
          linkModal({
            type: 'module',
            infos: {
              customComponent: IntroTutoSecondPart,
              textIntro: (campaignEnded && !userState?.teamId) ? t('phase_2.tutorial.intro.second.textIntroUnBoarding') : t('phase_2.tutorial.intro.second.textIntro'),
              textConstruction: t('phase_2.tutorial.intro.second.textConstruction'),
              textWonder: (campaignEnded && !userState?.teamId) ? t('phase_2.tutorial.intro.second.textWonderUnBoarding') : t('phase_2.tutorial.intro.second.textWonder'),
              textOutro: t(PWA ? 'phase_2.tutorial.intro.second.PWA.textOutro' : 'phase_2.tutorial.intro.second.textOutro'),
              buttonText: t('phase_2.tutorial.intro.second.buttonText'),
              asset: 'mayor',
              noCloseButton: true,
              action: async () => {
                await emitCommand({
                  type: 'Phase2UpdateSeeTutoStep',
                  payload: {
                    step: 'tutorialIntro'
                  }
                })
                _focusOnElement.next({
                  x: campaignSettings?.townHall?.positions?.x,
                  y: campaignSettings?.townHall?.positions?.y
                })
                linkModal(null)
              }
            },
          })
        })()
        return
      }
      ;(async () => {
        const teamName = await firebaseAccessor.get(`clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}/name`)
        linkModal({
          type: 'module',
          infos: {
            title: <>
              <Title2><T
                path={PWA ? 'phase_2.tutorial.intro.first.PWA.title'
                  : (campaignEnded && !userState?.teamId) ? 'phase_2.tutorial.intro.first.titleUnBoarding'
                    : 'phase_2.tutorial.intro.first.title'}/>
              </Title2>
              <Title2>{teamName}</Title2>
            </>,
            textContent: (PWA ? t('phase_2.tutorial.intro.first.PWA.textContent')
              : campaignEnded && !userState?.teamId) ? t('phase_2.tutorial.intro.first.textContentUnBoarding')
              : t('phase_2.tutorial.intro.first.textContent'),
            buttonText: t('phase_2.tutorial.intro.first.buttonText'),
            asset: 'mayor',
            noCloseButton: true,
            action: async () => {
              setTutorialIntroSecondPart(true)
            }
          },
        })
      })()
    }
  }, [tutorialIntro, globalModalOpened, tutorialIntroSecondPart, campaignEnded, userState?.teamId])

  const [newMessages, setNewMessages] = useState(0)

  const messagesData = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}`
  )

  const teamData = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}`
  )

  useEffect(() => {
    if (!messagesData?.chat) return
    const messagesFormat = Object.entries(messagesData?.chat).sort(([, a], [, b]) => {
      return new Date(a?.timeStamp) - new Date(b?.timeStamp)
    })
    const lastMessageId = messagesFormat[messagesFormat?.length - 1]?.[0]
    const lastMessageSeen = userState?.lastMessageSeenChatTeam
    if (lastMessageSeen === lastMessageId || messagesFormat.every(v => v?.[1]?.emitter === userId)) {
      setNewMessages(0)
      return
    }
    const lastMessageSeenIndex = messagesFormat.findIndex(v => v[0] === lastMessageSeen)
    const messageNotSeen = (messagesFormat?.length - 1) - lastMessageSeenIndex
    setNewMessages(messageNotSeen)
  }, [messagesData, userState?.lastMessageSeenChatTeam])

  const iconMouseOnHoverEnter = (id) => {
    setBuildingHovered(id)
  }

  useEffect(() => {
    if (!buildingHovered) return
    Animated.timing(iconHoverAnimation, {
      toValue: 1.1,
      duration: 300,
      useNativeDriver: true
    }).start()
  }, [buildingHovered])

  const iconMouseOnHoverLeave = () => {
    Animated.timing(iconHoverAnimation, {
      toValue: 1,
      duration: 100,
      useNativeDriver: true,
    }).start(() => setBuildingHovered(null))
  }

  useEffect(() => {
    if (!campaignSettings?.buildings) return
    const buildingsData = Object.entries(campaignSettings?.buildings || [])
    ;(async () => {
      await Promise.all(
        buildingsData?.map(async ([_id, building]) => {
          setBuildings((buildings) => [
            ...buildings,
            {
              ...building,
            }
          ])
        })
      )
    })()
  }, [campaignSettings?.buildings])



  const [animationLauncher, setAnimationLauncher] = useState({})
  const [displayedAnimations, setDisplayedAnimation] = useState({})
  const lockBuildingButtonsDuringAnimation = useRef(false)

  useEffect(() => {
    if (!teamData?.buildingsState || !campaignSettings) return
    const buildingsState = Object.entries(teamData?.buildingsState || {})
    buildingsState?.forEach(([buildingType, building], _index) => {
      const getBuildingTimingAnimation = Object.values(campaignSettings?.buildings).filter(v => v?.type === buildingType)?.[0]?.animation
      if (!getBuildingTimingAnimation) return
      if (!building?.step && !userSession?.progressionSeen?.[buildingType]?.step) return
      if (building?.step === userSession?.progressionSeen?.[buildingType]?.step) {
        setAnimationLauncher((buildingsAnimations) => ({
          ...buildingsAnimations,
          [buildingType]: null
        }))
      }
      if (building?.step > userSession?.progressionSeen?.[buildingType]?.step) {
        setAnimationLauncher((buildingsAnimations) => ({
          ...buildingsAnimations,
          [buildingType]: (displayedAnimation) => {
            emitCommand({
              type: 'Phase2UpdateBuildingSeen',
              payload: {
                step: building?.step,
                buildingType: buildingType,
              }
            })
            if (displayedAnimation) displayedAnimation()
          }
        }))
      } else if (!userSession?.progressionSeen?.[buildingType]?.step) {
        setAnimationLauncher((buildingsAnimations) => ({
          ...buildingsAnimations,
          [buildingType]: (displayedAnimation) => {
            emitCommand({
              type: 'Phase2UpdateBuildingSeen',
              payload: {
                step: building?.step,
                buildingType: buildingType,
              }
            })
            if (displayedAnimation) displayedAnimation()
          }
        }))
      }
    })
  }, [teamData?.buildingsState, campaignSettings, userSession?.progressionSeen])

  useMemo(() => {
    _wonderSimulateOnPress.subscribe(value => {
      if (animationLauncher?.['merveille'] && value) {
        lockBuildingButtonsDuringAnimation.current = true
        _wonderSimulateOnPress.next(false)
        return animationLauncher?.['merveille'](() => setDisplayedAnimation((e) => ({
          ...e,
          ['merveille']: true
        })))
      }
    })
    return () => _wonderSimulateOnPress.unsubscribe()
  }, [animationLauncher])

  useEffect(() => {
    if (!teamData?.wonderState || !campaignSettings) return
    const wonderState = teamData?.wonderState || {}
    const getWonderTimingAnimation = Object.values(campaignSettings?.buildings).filter(v => v?.type === 'merveille')?.[0]?.animation
    if (!getWonderTimingAnimation) return
    if (!wonderState?.step && !userSession?.progressionSeen?.wonder?.step) return
    if (wonderState?.step === userSession?.progressionSeen?.wonder?.step) {
      setAnimationLauncher((buildingsAnimations) => ({
        ...buildingsAnimations,
        merveille: null
      }))
    }
    if (wonderState?.step > userSession?.progressionSeen?.wonder?.step) {
      setAnimationLauncher((buildingsAnimations) => ({
        ...buildingsAnimations,
        merveille: (displayedAnimation) => {
          emitCommand({
            type: 'Phase2UpdateBuildingSeen',
            payload: {
              step: wonderState?.step,
              buildingType: 'wonder',
            }
          })
          if (displayedAnimation) displayedAnimation()
        }
      }))
    } else if (!userSession?.progressionSeen?.wonder?.step) {
      setAnimationLauncher((buildingsAnimations) => ({
        ...buildingsAnimations,
        merveille: (displayedAnimation) => {
          emitCommand({
            type: 'Phase2UpdateBuildingSeen',
            payload: {
              step: wonderState?.step,
              buildingType: 'wonder',
            }
          })
          if (displayedAnimation) displayedAnimation()
        }
      }))
    }
  }, [teamData?.wonderState, campaignSettings, userSession?.progressionSeen])

  const [launchTutoModal, setLaunchTutoModal] = useState(false)

  useEffect(() => {
    focusOnNextBuilding({ userSession: userSession, campaignSettings: campaignSettings })

  }, [userSession?.buildingProgression])

  return <>
    {
      buildings?.map(( building, index) => {
        const buildingLevel = building?.type === 'merveille' ?
          teamData?.wonderState?.step
          : teamData?.buildingsState?.[building?.type]?.step
        const levelsTotalNumber = building?.type === 'merveille' ?
          Object.keys(campaignSettings?.wonderRules || {}).length
          : Object.keys(campaignSettings?.modulesSettings?.[building?.name] || {}).length
        const lockBuildingsFromOrder = campaignSettings?.lockBuildingsFromOrder
        const buildingProgress = lockBuildingsFromOrder ? Math.round((Object.values((userSession?.buildings?.[building?.type]?.stepsPlayed || {}))?.filter(v => v?.win)?.length || 0) * 100 / levelsTotalNumber)
          : Math.round(((buildingLevel || (teamData?.buildingsState?.[building?.type] ? 1 : 0)) * 100) / levelsTotalNumber)
        const buildingProgression = userSession?.buildingProgression || 1
        const order = building?.order
        const orderLocked = buildingProgression < order
        return <WrapperBuilding key={index + 'buildingsRendering'}
                                buildingWidth={building?.size?.width}
                                buildingHeight={building?.size?.height}
                                x={building?.positions?.x}
                                y={building?.positions?.y}
                                zIndex={building?.type === 'merveille' ? 1 : building?.positions?.z ?? 2}
        >
          <MainContainerBuilding merveille={building?.type === 'merveille'}
                                 locked={lockBuildingsFromOrder && orderLocked}>
            {
              !buildingFirstClicked &&
              <TutoBuildings onOpen={(launchTutoModal === ('buildings' + index)) && hallTownFirstClicked}
                             linkModal={linkModal}
                             onClose={() => setLaunchTutoModal(null)}
                             building={building}
              />
            }
            <WrapperBuildingIcon
              merveille={building?.type === 'merveille'}
              onMouseEnter={() => iconMouseOnHoverEnter(index + 'buildingsRendering')}
              onMouseLeave={iconMouseOnHoverLeave}
              iconHoverAnimation={iconHoverAnimation}
              canHovered={buildingHovered === (index + 'buildingsRendering')}
            >
              <BuildingIcon buildingType={building?.type === 'merveille' ? 'merveille' : 'construction'}
                            type={building.type}
                            buildingProgress={buildingProgress}
                            displayedMiniIcon
                            buildingProgression={buildingProgression}
                            lockBuildingsFromOrder={lockBuildingsFromOrder}
                            orderLocked={orderLocked}
                            ended={
                              Object.values(userSession?.buildings?.[building?.type]?.stepsPlayed || {})
                                .filter(v => v.win).length === Object.values(campaignSettings?.buildings || {}).filter(v => v?.type === building?.type)?.[0]?.steps
                              ||
                              building?.type === 'merveille' && teamData?.wonderState?.step === Object.values(campaignSettings?.buildings).filter((v => v?.type === 'merveille'))?.[0]?.steps
                            }
                            evolutionReadyToPlay={animationLauncher?.[building?.type]}
                            infos={t('phase_2.modals.buildingModal.global.currentLevel', { 0: buildingLevel || 1 })}
                            infosEvol={t('phase_2.modals.buildingModal.global.evolInfos', { 0: t(`phase_2.modals.buildingModal.buildings.${building.type}.launchModuleModal.title`) })}
                            hover={buildingFirstClicked && wonderFirstClicked && buildingHovered === (index + 'buildingsRendering') && (buildingLevel || lockBuildingsFromOrder || teamData?.buildingsState?.[building?.type])}
                            onPress={() => {
                              _focusOnElement.next({
                                x: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.positions?.x,
                                y: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.positions?.y,
                                size: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.size
                              })
                              if (!hallTownFirstClicked) return
                              if (hallTownFirstClicked && ((building?.type !== 'merveille' && !buildingFirstClicked) || (building?.type === 'merveille' && !wonderFirstClicked))) {
                                setLaunchTutoModal(building?.type === 'merveille' ? 'merveille' + index : 'buildings' + index)
                                return
                              }
                              if (lockBuildingButtonsDuringAnimation.current) return
                              if (animationLauncher?.[building?.type]) {
                                lockBuildingButtonsDuringAnimation.current = true
                                return animationLauncher?.[building?.type](() => setDisplayedAnimation((e) => ({
                                  ...e,
                                  [building?.type]: true
                                })))
                              } else {
                                linkModal({
                                  type: building?.type === 'merveille' ? 'merveille' : 'construction',
                                  infos: building
                                })
                              }
                            }
                            }>
                {
                  !wonderFirstClicked &&
                  <TutoWonder onOpen={(launchTutoModal === ('merveille' + index)) && hallTownFirstClicked}
                              linkModal={linkModal}
                              onClose={() => setLaunchTutoModal(null)}
                              building={building}
                  />
                }
              </BuildingIcon>
            </WrapperBuildingIcon>
            <ContainerBuilding onPress={(e) => {
              if (lockBuildingsFromOrder && orderLocked) return
              _focusOnElement.next({
                x: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.positions?.x,
                y: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.positions?.y,
                size: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === building?.type)?.[0]?.size
              })
              if (!hallTownFirstClicked) return
              if (hallTownFirstClicked && ((building?.type !== 'merveille' && !buildingFirstClicked) || (building?.type === 'merveille' && !wonderFirstClicked))) {
                setLaunchTutoModal(building?.type === 'merveille' ? 'merveille' + index : 'buildings' + index)
                return
              }
              if (lockBuildingButtonsDuringAnimation.current) return
              if (animationLauncher?.[building?.type]) {
                lockBuildingButtonsDuringAnimation.current = true
                return animationLauncher?.[building?.type](() => setDisplayedAnimation((e) => ({
                  ...e,
                  [building?.type]: true
                })))
              } else {
                linkModal({
                  type: building?.type === 'merveille' ? 'merveille' : 'construction',
                  infos: building
                })
              }
            }
            }
                               disabled={building?.type === 'merveille' || (lockBuildingsFromOrder && orderLocked)}>
              <View style={{ width: '100%', height: '100%', position: 'relative' }}>
                <BuildingImage
                  source={getAssetUrl(building, userState)}
                  alt="building" style={{ opacity: !displayedAnimations?.[building?.type] ? 1 : 0 }}/>
                <HackFirefoxDaraggableImages/>
              </View>
            </ContainerBuilding>
          </MainContainerBuilding>
        </WrapperBuilding>
      })
    }
    {
      !hallTownFirstClicked && tutorialIntro &&
      <WrapperOverlayFirstClick/>
    }
    {
      campaignSettings?.townHall &&
      <WrapperTownHallIcons positions={campaignSettings?.townHall?.positions}
                            style={{
                              width: '20%',
                              height: '20%',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                            tuto={!hallTownFirstClicked}
                            onMouseEnter={() => {
                              if (!hallTownFirstClicked) return
                              iconMouseOnHoverEnter('XXbuildingsRendering')
                            }}
                            onMouseLeave={iconMouseOnHoverLeave}
                            iconHoverAnimation={iconHoverAnimation}
                            canHovered={buildingHovered === 'XXbuildingsRendering'}>
        {
          !hallTownFirstClicked &&
          <TutoHallTown onOpen={launchTutoModal}
                        linkModal={linkModal}
                        onClose={() => setLaunchTutoModal(null)}/>
        }
        {
          !!newMessages &&
          <MainWrapperChatIcon>
            <WrapperChatIcon onPress={() => {
              if (!hallTownFirstClicked) {
                setLaunchTutoModal(true)
                return
              }
              linkModal({ type: 'mairie' })
            }}>
              <WrapperChatIconContent>
                <ChatIcon width={35}
                          height={35}
                          style={{ zIndex: 2 }}/>
                <ChatNotification>{newMessages}</ChatNotification>
              </WrapperChatIconContent>
            </WrapperChatIcon>
          </MainWrapperChatIcon>
        }
        <BuildingIcon buildingType="mairie"
                      ended={false}
                      onPress={async () => {
                        _focusOnElement.next({
                          x: campaignSettings?.townHall?.positions?.x,
                          y: campaignSettings?.townHall?.positions?.y
                        })
                        if (!hallTownFirstClicked) {
                          setLaunchTutoModal(true)
                          return
                        }
                        linkModal({ type: 'mairie' })
                      }}/>
      </WrapperTownHallIcons>
    }
  </>
}

const WrapperChatIconContent = styled.View({
  transform: [{ translateX: 40 }, { translateY: 35 }]
})

export const WrapperOverlayFirstClick = styled.View({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: colors.mediumShadow,
  zIndex: 5
})

const BuildingAnimation = styled(LottieView)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})

const WrapperTownHallIcons = styled(Animated.View)(({ positions, iconHoverAnimation, canHovered, tuto }) => ({
  position: 'absolute',
  top: `${positions?.y}%`,
  left: `${positions?.x}%`,
  transform: [{ scale: canHovered && iconHoverAnimation || 1 }],
  transformOrigin: 'center',
  transformStyle: 'preserve-3d',
  willChange: 'transform',
  zIndex: tuto ? 5 : 2,
}))

const ChatNotification = styled.Text({
  width: 20,
  height: 20,
  borderRadius: 50,
  backgroundColor: 'red',
  borderColor: colors.primary,
  borderWidth: 2,
  position: 'absolute',
  top: -5,
  right: -5,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: colors.white,
  fontSize: 12,
  fontWeight: 'bold'
})

const WrapperChatIcon = styled.TouchableWithoutFeedback({
  position: 'relative',
  width: '100%',
  height: '100%'
})

const MainWrapperChatIcon = styled.View({
  position: 'absolute',
  top: '50%',
  left: '50%',
  willChange: 'transform',
  transformStyle: 'preserve-3d',
  transform: [{ translateX: '-50%' }, { translateY: '-50%' }],
  zIndex: 2
})

const WrapperBuilding = styled.View(({ buildingWidth, buildingHeight, x, y, zIndex }) => ({
  position: 'absolute',
  width: `${buildingWidth}%`,
  height: `${buildingHeight}%`,
  left: `${x}%`,
  top: `${y}%`,
  zIndex: zIndex
}))

const ContainerBuilding = styled.TouchableWithoutFeedback({
  position: 'relative',
  width: '100%',
  height: '100%',
})

const MainContainerBuilding = styled.View(({ merveille, locked }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  cursor: (merveille || locked) ? 'normal' : 'pointer',
}))

const BuildingImage = styled.Image({
  width: '100%',
  height: '100%',
  resizeMode: 'contain',
})

const WrapperBuildingIcon = styled(Animated.View)(({ iconHoverAnimation, canHovered, merveille }) => ({
  width: 35,
  height: 35,
  position: 'absolute',
  transformStyle: 'preserve-3d',
  willChange: 'transform',
  top: merveille ? '77%' : '15%',
  left: '50%',
  transform: [{ translateX: '-50%' }, { translateY: '-15%' }, { scale: canHovered && iconHoverAnimation || 1 }],
  borderRadius: 50,
  zIndex: 2
}))

const HackFirefoxDaraggableImages = styled.View({
  zIndex: 2,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute'
})

const getAssetUrl = (building, userState) => {
  if (!building || !userState) return;
  return building?.type === 'merveille' ? `${building?.asset}${userState?.onBoardingGameState?.wonderState?.step || 1}-map.png`
    : `${building?.asset}.png`
}


export const focusOnNextBuilding = ({ userSession, campaignSettings }) => {
  if (userSession?.buildingProgression === 1) return
  const lastBuilding = Object.values(campaignSettings?.buildings || {})
    .map(v => v.type)
    .sort((a, b) => a.order - b.order)?.[userSession?.buildingProgression - 1]
  setTimeout(() => {
    _focusOnElement.next({
      x: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === lastBuilding)?.[0]?.positions?.x,
      y: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === lastBuilding)?.[0]?.positions?.y,
      size: Object.values(campaignSettings?.buildings || {}).filter(v => v.type === lastBuilding)?.[0]?.size
    })
  }, 500)
}
