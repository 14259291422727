import { BehaviorSubject } from 'rxjs'
import storage             from 'react-native-modest-storage'
import { nanoid }          from 'nanoid'

export const offlineAuthState$ = new BehaviorSubject(null)

export const offlineAuthClient = () => {
  storage
    .get('authToken')
    .then((authToken) => {
      if (authToken){
        offlineAuthState$.next(JSON.parse(authToken))
      }
      else {
        const userId = nanoid()
        storage.set('authToken', JSON.stringify({ userId: userId }))
        offlineAuthState$.next({ userId: userId })
      }
    })
    .catch((e) => console.error("failed to load authState from storage :", e))
  
}

