if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    loadSWs()
  })
}

const loadSWs = () => {
  navigator.serviceWorker
    .register('/expo-service-worker.js', { scope: '/' })
    .then(function (info) {
      console.info('Registered expo-service-worker', info)
    })
    .catch(function (error) {
      console.info('Failed to register service-worker', error)
    })
}