import { createContext, useContext, useEffect, useState } from 'react'
import React                                              from 'react'
import { BehaviorSubject }                                from 'rxjs'



const defaultValue = false
const OfflineContext = createContext(defaultValue)
const _isOffline = new BehaviorSubject(false)
export const Offline = ({children}) => {
  const [isOffline, setIsOffline] = useState(false)
  useEffect(() => {
    window.addEventListener('online', () => {
      setIsOffline(false)
      _isOffline.next(false)
    })
    window.addEventListener('offline', () => {
      setIsOffline(true)
      _isOffline.next(true)
    })
    return () => {
      window.removeEventListener('online', () => {
        setIsOffline(false)
        _isOffline.next(false)
      })
      window.removeEventListener('offline', () => {
        setIsOffline(true)
        _isOffline.next(true)
      })
    }
  }, [])
  return <OfflineContext.Provider value={isOffline}>{children}</OfflineContext.Provider>
}

export const useIsOffline = () => {
  const [isOffline, setIsOffline] = useState(true)
  useEffect(() => {
    _isOffline.subscribe((v) => {
      setIsOffline(v)
    })
  })
  return isOffline
}