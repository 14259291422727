export default {
  "v": "5.7.1",
  "fr": 25,
  "ip": 0,
  "op": 188,
  "w": 10635,
  "h": 8745,
  "nm": "3",
  "ddd": 0,
  "assets": [
  {
    "id": "image_0",
    "w": 658,
    "h": 273,
    "p":require('./images/img_0.png'),
    "e": 0
  },
  {
    "id": "image_1",
    "w": 450,
    "h": 337,
    "p":require('./images/img_1.png'),
    "e": 0
  },
  {
    "id": "image_2",
    "w": 725,
    "h": 602,
    "p":require('./images/img_2.png'),
    "e": 0
  },
  {
    "id": "image_3",
    "w": 210,
    "h": 299,
    "p":require('./images/img_3.png'),
    "e": 0
  },
  {
    "id": "image_4",
    "w": 527,
    "h": 424,
    "p":require('./images/img_4.png'),
    "e": 0
  },
  {
    "id": "image_5",
    "w": 730,
    "h": 479,
    "p":require('./images/img_5.png'),
    "e": 0
  },
  {
    "id": "image_6",
    "w": 374,
    "h": 861,
    "p":require('./images/img_6.png'),
    "e": 0
  },
  {
    "id": "image_7",
    "w": 736,
    "h": 1240,
    "p":require('./images/img_7.png'),
    "e": 0
  },
  {
    "id": "image_8",
    "w": 730,
    "h": 1452,
    "p":require('./images/img_8.png'),
    "e": 0
  },
  {
    "id": "image_9",
    "w": 146,
    "h": 342,
    "p":require('./images/img_9.png'),
    "e": 0
  },
  {
    "id": "image_10",
    "w": 208,
    "h": 434,
    "p":require('./images/img_10.png'),
    "e": 0
  }
],
  "layers": [
  {
    "ddd": 0,
    "ind": 1,
    "ty": 2,
    "nm": "herbe3",
    "refId": "image_0",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 0,
            "s": [
              0
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 25,
            "s": [
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 225,
            "s": [
              100
            ]
          },
          {
            "t": 250,
            "s": [
              0
            ]
          }
        ],
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          4987,
          7095.5,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          329,
          212.5,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 0,
            "s": [
              100,
              41,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 25,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 225,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "t": 250,
            "s": [
              100,
              41,
              100
            ]
          }
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 188,
    "st": 0,
    "bm": 0
  },
  {
    "ddd": 0,
    "ind": 2,
    "ty": 2,
    "nm": "feuillage3",
    "refId": "image_1",
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100,
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          5368,
          5883.5,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          225,
          168.5,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 62,
            "s": [
              0,
              0,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 75,
            "s": [
              110,
              110,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 88,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 113,
            "s": [
              105,
              105,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 138,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 175,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 188,
            "s": [
              110,
              110,
              100
            ]
          },
          {
            "t": 200,
            "s": [
              0,
              0,
              100
            ]
          }
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 62,
    "op": 188,
    "st": 0,
    "bm": 0
  },
  {
    "ddd": 0,
    "ind": 3,
    "ty": 2,
    "nm": "feuillage1",
    "refId": "image_2",
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100,
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          4624.5,
          5535,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          362.5,
          213,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 25,
            "s": [
              0,
              0,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 38,
            "s": [
              110,
              110,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 50,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 75,
            "s": [
              105,
              105,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 100,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 125,
            "s": [
              105,
              105,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 200,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 213,
            "s": [
              110,
              110,
              100
            ]
          },
          {
            "t": 225,
            "s": [
              0,
              0,
              100
            ]
          }
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 25,
    "op": 188,
    "st": 0,
    "bm": 0
  },
  {
    "ddd": 0,
    "ind": 4,
    "ty": 2,
    "nm": "ombre feuillage2",
    "refId": "image_3",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 62,
            "s": [
              0
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 75,
            "s": [
              30.196
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 188,
            "s": [
              30.196
            ]
          },
          {
            "t": 200,
            "s": [
              0
            ]
          }
        ],
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          4968,
          5583.5,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          105,
          149.5,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 0,
        "k": [
          100,
          100,
          100
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 188,
    "st": 0,
    "bm": 0
  },
  {
    "ddd": 0,
    "ind": 5,
    "ty": 2,
    "nm": "feuillage2",
    "refId": "image_4",
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100,
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          5092,
          5666,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          229,
          312,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 50,
            "s": [
              0,
              0,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 63,
            "s": [
              110,
              110,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 75,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 100,
            "s": [
              105,
              105,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 125,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 150,
            "s": [
              105,
              105,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 188,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 200,
            "s": [
              110,
              110,
              100
            ]
          },
          {
            "t": 213,
            "s": [
              0,
              0,
              100
            ]
          }
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 50,
    "op": 188,
    "st": 0,
    "bm": 0
  },
  {
    "ddd": 0,
    "ind": 6,
    "ty": 2,
    "nm": "ombre",
    "refId": "image_5",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 62,
            "s": [
              0
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 74,
            "s": [
              65.882
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 176,
            "s": [
              65.882
            ]
          },
          {
            "t": 188,
            "s": [
              0
            ]
          }
        ],
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          5019,
          5904.5,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          365,
          239.5,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 0,
        "k": [
          100,
          100,
          100
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 188,
    "st": 0,
    "bm": 1
  },
  {
    "ddd": 0,
    "ind": 7,
    "ty": 2,
    "nm": "lum tronc",
    "refId": "image_6",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 62,
            "s": [
              0
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 75,
            "s": [
              50.196
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 100,
            "s": [
              25
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 125,
            "s": [
              50.196
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 176,
            "s": [
              50.196
            ]
          },
          {
            "t": 188,
            "s": [
              0
            ]
          }
        ],
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          5138,
          6213.5,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          187,
          430.5,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 0,
        "k": [
          100,
          100,
          100
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 188,
    "st": 0,
    "bm": 6
  },
  {
    "ddd": 0,
    "ind": 8,
    "ty": 2,
    "nm": "lum tronc",
    "refId": "image_7",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 25,
            "s": [
              0
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 50,
            "s": [
              54.118
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 75,
            "s": [
              25
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 100,
            "s": [
              54.118
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 125,
            "s": [
              25
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 150,
            "s": [
              54.118
            ]
          },
          {
            "i": {
              "x": [
                0.833
              ],
              "y": [
                0.833
              ]
            },
            "o": {
              "x": [
                0.167
              ],
              "y": [
                0.167
              ]
            },
            "t": 213,
            "s": [
              25
            ]
          },
          {
            "t": 225,
            "s": [
              0
            ]
          }
        ],
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          5090,
          6375,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          368,
          620,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 0,
        "k": [
          100,
          100,
          100
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 188,
    "st": 0,
    "bm": 6
  },
  {
    "ddd": 0,
    "ind": 9,
    "ty": 2,
    "nm": "tronc",
    "refId": "image_8",
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100,
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          4987,
          6993,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          333,
          1390,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 0,
            "s": [
              17,
              17,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 25,
            "s": [
              110,
              110,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 37,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 213,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 225,
            "s": [
              110,
              110,
              100
            ]
          },
          {
            "t": 250,
            "s": [
              17,
              17,
              100
            ]
          }
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 188,
    "st": 0,
    "bm": 0
  },
  {
    "ddd": 0,
    "ind": 10,
    "ty": 2,
    "nm": "pt branche",
    "refId": "image_9",
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100,
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          5002,
          5964,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          41,
          299,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 37,
            "s": [
              0,
              0,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 50,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 200,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "t": 213,
            "s": [
              0,
              0,
              100
            ]
          }
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 37,
    "op": 188,
    "st": 0,
    "bm": 0
  },
  {
    "ddd": 0,
    "ind": 11,
    "ty": 2,
    "nm": "pt branche",
    "refId": "image_10",
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100,
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [
          5196,
          6218,
          0
        ],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [
          20,
          353,
          0
        ],
        "ix": 1
      },
      "s": {
        "a": 1,
        "k": [
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 50,
            "s": [
              0,
              0,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 63,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "i": {
              "x": [
                0.833,
                0.833,
                0.833
              ],
              "y": [
                0.833,
                0.833,
                0.833
              ]
            },
            "o": {
              "x": [
                0.167,
                0.167,
                0.167
              ],
              "y": [
                0.167,
                0.167,
                0.167
              ]
            },
            "t": 188,
            "s": [
              100,
              100,
              100
            ]
          },
          {
            "t": 200,
            "s": [
              0,
              0,
              100
            ]
          }
        ],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 50,
    "op": 188,
    "st": 0,
    "bm": 0
  }
],
  "markers": []
}