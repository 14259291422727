import React, { useEffect, useRef, useState }                       from 'react'
import { useScreenDimensions }                                      from '@civitime/library/storybook/hooks/useScreenDimensions'
import { colors }                                                   from '@civitime/library/storybook/configs/colors'
import { ModalBuildingCard }                                        from './ModalBuildingCard'
import { Animated, View }                                           from 'react-native'
import FlouzIcon                                                    from './Assets/flouz.svg'
import WhiteFlouzIcon                                               from './Assets/whiteFlouz.svg'
import { ButtonText, MediumText, SmallBoldText, Title1 }            from '@civitime/library/storybook/stories/Texts'
import { DefaultModal }                                             from '@civitime/library/storybook/stories/Modals'
import {
  EvolutionProgressBar,
  TextProgressBar,
  WrapperHorizontalScrollbar,
  WrapperProgressBar
}                                                                   from './BuildingModal'
import styled                                                       from '@emotion/native'
import { PressedInCard }                                            from '@civitime/library/storybook/stories/Cards'
import WonderIcon                                                   from '../../Map/Assets/buildings/merveille.svg'
import { CustomScrollbar }                                          from '../CustomScrollbar'
import { clientId, PWA }                                            from '../../../../../clientId'
import { useCampaign }                                              from '../../../../GlobalContexts'
import { useRealTimeData }                                          from '../../../../Utils/dataClient'
import { useProfile }                                               from '../../../../Auth/useProfile'
import { InfoCorner, InfoIcon, InfoIconContainer, WrapperInfoIcon } from '../../Map/BuildingIcon'
import { TooltipTuto }                                              from '../Tuto/TooltipTuto'
import { HeaderModal }                                              from './HeaderModal'
import { T, useTranslator }                                         from '../../../../translations/translate'
import { TextButton }                                               from '@civitime/library/storybook/stories/Button'
import { _focusOnElement }                                          from '../../Map/MapPhase2'
import { useIsOffline }                                             from '../../../../Hooks/isOffline'
import { focusOnNextBuilding }                                      from '../../Map/MapBuildings'

export const WonderModal = ({ infos, onClose, linkModal }) => {
  const isOffline = useIsOffline()
  const { height, mobile } = useScreenDimensions()
  const progressAnimation = useRef(new Animated.Value(0)).current
  const { campaignId, campaignSettings, campaignEnded, campaignNavigate } = useCampaign()
  const { userState, emitCommand, userSession } = useProfile()
  const [progressHovered, setProgressHovered] = useState(false)
  const [buildingFinished, setBuildingFinished] = useState(false)
  const { t } = useTranslator()
  
  const [lockInvest, setLockInvest] = useState(false)
  const lockInvestManyTrigger = useRef(false)
  const [investCost, setInvestCost] = useState(0)
  const [scrollbarSizes, setScrollbarSizes] = useState({})
  const [scrollbarPositions, setScrollbarPositions] = useState({})
  const scrollbarRef = useRef(null)
  const scrollbarInit = useRef(true)
  
  const [pointsProgression, setPointsProgression] = useState(0)
  
  const [displayedModalInfos, setDisplayedModalInfos] = useState(false)
  const onBoarding = (campaignEnded && !userState?.teamId) || campaignSettings?.PWA
  
  const teamData = onBoarding ?
    userState?.onBoardingGameState
    : useRealTimeData(
      `clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}`
    )
  
  useEffect(() => {
    const onboardingPlayer = campaignEnded && !userState?.teamId
    if (!onboardingPlayer && !teamData) return
    const buildingCurrentPoints = teamData?.wonderState?.points - ((campaignEnded && !userState?.teamId) ?
      campaignSettings?.wonderRules?.[userState?.onBoardingGameState?.wonderState?.step + 1 || 2]?.goalAddedOnBoarding
      : campaignSettings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goalAdded) || 0
    const buildingGoal = onboardingPlayer ?
      campaignSettings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.goalOnBoarding
      : campaignSettings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.goal
    
    const maxInvest = buildingGoal - buildingCurrentPoints
    setInvestCost(Math.min(maxInvest, userState?.flouz))
  }, [teamData?.wonderState?.points, teamData?.wonderState?.step, userState?.flouz])
  
  useEffect(() => {
    if (!scrollbarRef?.current) return
    if (scrollbarInit?.current) {
      scrollbarRef.current.scrollTo({ x: 1, animated: true })
      scrollbarInit.current = false
    }
  }, [scrollbarRef])
  
  useEffect(() => {
    if (!pointsProgression && pointsProgression !== 0) return
    Animated.timing(progressAnimation,
      {
        toValue: pointsProgression,
        duration: 500,
        useNativeDriver: true
      }
    ).start()
  }, [pointsProgression])
  
  useEffect(() => {
    const progressionBarSize = 200
    const wonderPoints = teamData?.wonderState?.points
    const stepGoalAdded = (campaignSettings?.PWA || campaignEnded && !userState?.teamId) ?
      campaignSettings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goalAddedOnBoarding
      : campaignSettings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goalAdded
    const goal = (campaignSettings?.PWA || campaignEnded && !userState?.teamId) ?
      campaignSettings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goalOnBoarding
      : campaignSettings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goal
    const pointsProgressionValue = (wonderPoints - stepGoalAdded) / goal
    if (pointsProgressionValue === 0) {
      setPointsProgression(-progressionBarSize)
      return
    }
    setPointsProgression((progressionBarSize * pointsProgressionValue) > progressionBarSize ? progressionBarSize : progressionBarSize * pointsProgressionValue)
  }, [teamData?.wonderState?.points, teamData?.wonderState?.step])
  
  useEffect(() => {
    if (!campaignSettings?.buildings || !teamData?.wonderState?.step) return
    const buildingIsFinished = teamData?.wonderState?.step === Object.values(campaignSettings?.buildings).filter((v => v?.type === 'merveille'))?.[0]?.steps
    setBuildingFinished(buildingIsFinished)
  }, [teamData?.wonderState?.step, campaignSettings?.buildings])
  
  const wonderInvest = async (type, flouz) => {
    await emitCommand({
      type: 'Phase2InvestInBuilding',
      payload: {
        buildingType: 'merveille',
        teamId: userState?.teamId || null,
        flouz: flouz
      }
    }).then((response) => {
      if (response.some(r => r?.type === 'Phase2BuildingInvested' && r?.payload?.evolve)) {
        const step = teamData?.wonderState?.step + 1 || 2
        linkModal({
          type: 'evolution',
          infos: {
            type: 'merveille',
            step: step,
            title: t('phase_2.modals.buildingModal.buildings.merveille.wonderEvolution.title'),
            text: t('phase_2.modals.buildingModal.buildings.merveille.wonderEvolution.text'),
            buttonText: t('phase_2.modals.buildingModal.buildings.merveille.wonderEvolution.buttonText'),
            textHomeReturn: t('phase_2.modals.buildingModal.buildings.merveille.wonderEvolution.textHomeReturn'),
            homeAction: () => linkModal({ type: 'mairie' }),
            actionButton: () => linkModal({ type: 'merveille', infos: { ...infos } }),
            asset: `https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/buildings/${infos?.type}/steps/${infos?.type}${step}.png`
          }
        })
      }
      setLockInvest(false)
      lockInvestManyTrigger.current = false
    })
  }
  
  const wonderInvestAll = async () => {
    await emitCommand({
      type: 'Phase2InvestAllInBuilding',
      payload: {
        buildingType: 'merveille',
        teamId: userState?.teamId || null,
      }
    }).then((_response) => {
      setLockInvest(false)
      lockInvestManyTrigger.current = false
    })
  }
  
  return <>
    <HeaderModal mobile={mobile}/>
    <DefaultModal title={t(`phase_2.modals.buildingModal.buildings.${infos?.type}.name`)}
                  maxHeight={height * 0.9}
                  onPress={() => {
                    onClose()
                    focusOnNextBuilding({ userSession: userSession, campaignSettings: campaignSettings })
                    displayGameEnd({campaignSettings, userSession, userState, isOffline, campaignNavigate, linkModal, emitCommand})
                  }}
                  backgroundColor={colors.lightBlue}
                  color={colors.text}
                  withShadow
                  infoButton={() => setDisplayedModalInfos((e) => !e)}
    >
      {
        displayedModalInfos &&
        <TooltipTuto
          color={colors.white}
          backgroundColor={colors?.text}
          style={{
            position: 'absolute',
            top: 20,
            left: 3,
            zIndex: 2,
            transform: [{ scale: 0.8 }],
            transformOrigin: 'top left'
          }}
          cornerLeft={15}
          cornerTop={-15}
          infos={{
            title: t('phase_2.tutorial.tooltips.wonder.title'),
            text: onBoarding ? t('phase_2.tutorial.tooltips.wonder.textUnBoarding') : t('phase_2.tutorial.tooltips.wonder.text'),
            onClose: () => setDisplayedModalInfos(false)
          }}/>
      }
      <WrapperWonderModal>
        <WrapperHorizontalScrollbar>
          <CustomScrollbar heightThumb={scrollbarSizes?.width || 0}
                           scrollbarPositions={scrollbarPositions}
                           widthThumb={16}
                           widthTrack={10}
                           customPositionLeft={20}
                           scrollbarRef={scrollbarRef}
                           backgroundColor={colors.white}
                           color={colors.defaultBackground}
                           horizontal
          />
          <WrapperWonderCards horizontal
                              showsHorizontalScrollIndicator={false}
                              ref={scrollbarRef}
                              scrollEventThrottle={80}
                              onLayout={(e) => setScrollbarSizes(e?.nativeEvent?.layout)}
                              onScroll={(e) => setScrollbarPositions(e?.nativeEvent)}>
            {
              infos?.steps &&
              Object.values(campaignSettings?.wonderRules || {}).map((step, index) => {
                return <ModalBuildingCard
                  wonder
                  key={index + 'wonder'}
                  image={`https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/buildings/${infos?.type}/steps/${infos?.type}${index + 1}.png`}
                  lock={index + 1 > (teamData?.wonderState?.step || 1)}
                >
                  <WrapperContentCard>
                    <FirstLine>
                      <LevelText>
                        <T path="phase_2.modals.buildingModal.global.currentLevel" data={{ 0: index + 1 }}/>
                      </LevelText>
                    </FirstLine>
                    {
                      <SecondLine style={{ opacity: ((index + 1) > 1) ? 1 : 0 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <PriceText>
                            <T path="phase_2.modals.buildingModal.global.levelCost"
                               data={{
                                 0: (PWA || onBoarding) ? campaignSettings?.wonderRules?.[index + 1]?.goalOnBoarding
                                   : campaignSettings?.wonderRules?.[index + 1]?.goal
                               }}/>
                          </PriceText>
                          <FlouzIcon width={18} height={18}/>
                        </View>
                      </SecondLine>
                    }
                  </WrapperContentCard>
                </ModalBuildingCard>
              })
            }
          </WrapperWonderCards>
        </WrapperHorizontalScrollbar>
        <WrapperContent mobile={mobile}>
          <WonderDescription mobile={mobile}>
            <WonderDescriptionText>
              <T path={PWA ? `phase_2.modals.buildingModal.buildings.merveille.PWA.text`
                : `phase_2.modals.buildingModal.buildings.merveille.text`}/>
            </WonderDescriptionText>
            <WrapperResumeWonderPoints>
              <WonderResumeText>
                <T path={`phase_2.modals.buildingModal.buildings.merveille.creditsInvestText`}/>
              </WonderResumeText>
              <WonderPointsCounter>
                <WonderIcon width={30} height={30}/>
                <WonderPoints>
                  <T path={`phase_2.modals.buildingModal.buildings.merveille.wonderPoints`}
                     data={{ 0: teamData?.wonderState?.points ?? 0 }}/>
                </WonderPoints>
              </WonderPointsCounter>
            </WrapperResumeWonderPoints>
          </WonderDescription>
          <WrapperWonderEvolution noHover customPadding={PWA ? 10 : 20} mobile={mobile}>
            <WrapperMakingEvolution onMouseEnter={() => {
              if (!userState?.flouz || lockInvest ||
                (userState?.flouz < (onBoarding
                  ? campaignSettings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.costOnBoarding
                  : campaignSettings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.cost))) {
                setProgressHovered(true)
              }
            }}
                                    onMouseLeave={() => {
                                      if (!userState?.flouz || lockInvest || userState?.flouz < (userState?.flouz < (onBoarding
                                        ? campaignSettings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.costOnBoarding
                                        : campaignSettings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.cost))) {
                                        setProgressHovered(false)
                                      }
                                    }}>
              {
                !buildingFinished && !PWA && <TextEvolution>
                  <T path="phase_2.modals.buildingModal.global.evolutionWonderIndicator"
                     data={{ 0: (teamData?.wonderState?.step || 1) + 1 }}/>
                </TextEvolution>
              }
              {
                buildingFinished ? <WrapperEndedEvolution mobile={mobile}>
                    <MediumText style={{ width: '100%', fontWeight: "700" }}>
                      <T path="phase_2.modals.buildingModal.global.evolutionWonderComplete"/>
                    </MediumText>
                    <EvolutionButton
                      disabled={!userState?.flouz || lockInvest || userState?.flouz === 0}
                      onPress={() => {
                        lockInvestManyTrigger.current = true
                        setLockInvest(true)
                        return wonderInvestAll(infos?.type)
                      }}
                      mobile={mobile}>
                      <EvolutionButtonText>
                        <T path="phase_2.modals.buildingModal.global.investAll"/>
                      </EvolutionButtonText>
                      <WhiteFlouzIcon width={25} height={25}/>
                    </EvolutionButton>
                  </WrapperEndedEvolution>
                  : <WrapperEvolutionProgress mobile={mobile}>
                    <WrapperProgressBar>
                      <TextProgressBar>
                        {teamData?.wonderState?.points - (onBoarding ?
                          campaignSettings?.wonderRules?.[userState?.onBoardingGameState?.wonderState?.step + 1 || 2]?.goalAddedOnBoarding
                          : campaignSettings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goalAdded) || 0}
                        /
                        {onBoarding ?
                          campaignSettings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goalOnBoarding
                          : campaignSettings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.goal}</TextProgressBar>
                      <EvolutionProgressBar merveille>
                        <Animated.View style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          left: '-100%',
                          willChange: 'transform',
                          backgroundColor: colors.secondary,
                          transform: [{ translateX: progressAnimation }]
                        }}/>
                      </EvolutionProgressBar>
                    </WrapperProgressBar>
                    <EvolutionButton
                      disabled={lockInvest || (((userState?.flouz || 0) < campaignSettings?.wonderRules?.[(userState?.onBoardingGameState?.wonderState?.step || 1) + 1]?.costOnBoarding))}
                      onPress={() => {
                        lockInvestManyTrigger.current = true
                        setLockInvest(true)
                        return wonderInvest(infos?.type, investCost)
                      }}
                      mobile={mobile}>
                      <EvolutionButtonText>
                        <T
                          path="phase_2.modals.buildingModal.buildings.merveille.invest"/>
                      </EvolutionButtonText>
                      <WhiteFlouzIcon width={25} height={25}/>
                    </EvolutionButton>
                    {
                      lockInvest || (((userState?.flouz || 0) < campaignSettings?.wonderRules?.[(userState?.onBoardingGameState?.wonderState?.step || 1) + 1]?.costOnBoarding))
                      && progressHovered &&
                      <WrapperInfoIcon style={{ left: 'unset', right: 10 }}>
                        <InfoIconContainer style={{ backgroundColor: colors.text }}>
                          <InfoIcon style={{ color: colors.white }}>
                            <T path="phase_2.modals.buildingModal.global.noMoreFlouz"/>
                          </InfoIcon>
                          <InfoCorner style={{ backgroundColor: colors.text }}/>
                        </InfoIconContainer>
                      </WrapperInfoIcon>
                    }
                  </WrapperEvolutionProgress>
              }
            </WrapperMakingEvolution>
          </WrapperWonderEvolution>
        </WrapperContent>
        <ButtonsWrapper>
          <TextButton onPress={() => {
            _focusOnElement.next({
              x: campaignSettings?.townHall?.positions?.x,
              y: campaignSettings?.townHall?.positions?.y
            })
            linkModal({ type: 'mairie' })
          }}>
            <T path="app.global.next"/>
          </TextButton>
        </ButtonsWrapper>
      </WrapperWonderModal>
    </DefaultModal>
  </>
}

const WonderPointsCounter = styled.View({
  flexDirection: 'row',
  alignItems: 'center'
})

const WonderPoints = styled(SmallBoldText)({
  textAlign: 'left',
  fontSize: 15,
  marginLeft: 10
})

const WrapperResumeWonderPoints = styled.View({
  paddingTop: 5,
  fontSize: 15,
  lineHeight: 16
})

const WonderResumeText = styled(MediumText)({
  textAlign: 'left'
})

const WrapperWonderModal = styled.View({
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
  paddingTop: 10,
  paddingRight: 10,
  paddingBottom: 70,
  paddingLeft: 10
})

const WrapperWonderCards = styled.ScrollView({
  width: '100%',
  overflow: 'hidden'
})

const WrapperContent = styled.View(({ mobile }) => ({
  width: '100%',
  flexDirection: mobile ? 'column' : 'row',
}))

const WonderDescription = styled.View(({ mobile }) => ({
  width: mobile ? '100%' : '50%',
  padding: 15,
  paddingTop: 5,
  paddingBottom: 5,
}))

const WonderDescriptionText = styled(MediumText)(({}) => ({
  textAlign: 'left',
  alignSelf: 'center',
  paddingBottom: 5,
  borderBottomWidth: 1,
  borderColor: colors.darkerBackground,
  width: '100%',
  fontSize: 15,
  lineHeight: 16
}))

const WrapperWonderEvolution = styled(PressedInCard)(({ mobile }) => ({
  width: mobile ? '100%' : '50%',
  marginTop: 0,
}))

const WrapperContentCard = styled.View({
  flexDirection: 'column',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-around'
})

const FirstLine = styled.View({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-end',
  paddingRight: 5,
  paddingLeft: 5,
})

const SecondLine = styled(FirstLine)({
  flexDirection: 'column',
  alignItems: 'center',
  paddingRight: 5,
  paddingLeft: 5,
})

const LevelText = styled(MediumText)({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 15,
})

const PriceText = styled(MediumText)({
  fontSize: 15,
  marginRight: 5,
  fontWeight: 'bold'
})

const WrapperMakingEvolution = styled.View({
  flexDirection: 'column',
  height: '100%',
  width: '100%'
})

const TextEvolution = styled(Title1)({
  fontSize: 15
})

const WrapperEvolutionProgress = styled.View(({ mobile }) => ({
  flexDirection: mobile ? 'column' : 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  marginTop: 20,
  width: '100%'
}))

const WrapperEndedEvolution = styled.View({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 0,
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%'
})

export const EvolutionButton = styled(ButtonText)(({ disabled, mobile }) => ({
  backgroundColor: colors.secondary,
  minWidth: 90,
  paddingRight: 10,
  paddingLeft: 10,
  paddingTop: 5,
  paddingBottom: 5,
  borderRadius: 50,
  cursor: disabled ? 'normal' : 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? 'none' : 'auto',
  marginTop: mobile ? 10 : 0,
}))

export const EvolutionButtonText = styled(MediumText)({
  color: colors.white,
  fontSize: 15,
})

const ButtonsWrapper = styled.View({
  position: 'absolute',
  bottom: PWA ? 15 : 25,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  margin: 'auto',
  justifyContent: 'center'
})

const displayGameEnd = ({
  campaignSettings: campaignSettings,
  userSession: userSession,
  userState: userState,
  isOffline: isOffline,
  campaignNavigate: campaignNavigate,
  linkModal: linkModal,
  emitCommand: emitCommand
}) => {
  const lastBuilding = Object.values(campaignSettings?.buildings || {})?.sort((a, b) => b?.order - a?.order)?.[0]?.type
  const lastBuildingsSteps = Object.keys(campaignSettings?.modulesSettings?.[lastBuilding] || {}).map(v => parseInt(v))
  const lastStep = Math.max(...lastBuildingsSteps)
  const gameEnded = !!userSession?.buildings?.[lastBuilding]?.stepsPlayed?.[lastStep]?.win
  if (gameEnded && !userState?.finalLeaderboard) {
    const closeModal = async () => {
      if (!isOffline) {
        await emitCommand({
          type: 'SendFinalLeaderboard',
          payload: { points: userState?.points || 0 }
        })
        campaignNavigate('leaderboard/player')
      }
      linkModal(null)
      return
    }
    setTimeout(() => linkModal({
      type: 'basic',
      infos: {
        text: 'Félicitations, vous êtes désormais un expert EVA ! \n \n' +
          'Vous avez terminé le parcours itinérant, découvrez maintenant votre classement et rendez-vous à l’espace galerie SCA \n',
        buttonText: 'Classement',
        buttonTextOffline: 'Fermer',
        action: closeModal,
        onClose: closeModal,
      }
    }), 300)
  }
}