import { publicProfileSet } from '@civitime/game-server/lib/saga-tools/Data.effects'

export function* offlinePublicProfileInitiated(event) {
  const userId = event.target
  yield publicProfileSet(userId, {id: userId})
}


export function* offlinePublicProfileUpdated(event) {
  const userId = event.target
  yield publicProfileSet(userId, {id: userId, name: event.name, avatar: event.avatar})
}