import React, { useEffect, useState }                                        from 'react'
import styled                                                                from '@emotion/native'
import { useDataLoading }                                                    from '../../../../Utils/dataClient'
import { clientId, PWA }                                                     from '../../../../../clientId'
import { Blazon }                                                            from '@civitime/library/storybook/stories/Blazon'
import { useProfile }                                                        from '../../../../Auth/useProfile'
import { useCampaign }                                                       from '../../../../GlobalContexts'
import { useTutorialStatus }                                                 from '../../Hooks/useTutorialStatus'
import { TooltipTuto }                                                       from '../Tuto/TooltipTuto'
import { WrapperToolip }                                                     from './Phase2WrapperLeftContent'
import { HomeLangSelectorButton, HomeLeaderboardButton, HomeSettingsButton } from '../../../../MainComponents/Header'
import { useScreenDimensions }                                               from '@civitime/library/storybook/hooks/useScreenDimensions'
import { Challenge }                                                         from '../../../Phase1/Components/AppSkeleton/Phase1Header'
import { useTranslator }                                                     from '../../../../translations/translate'

export const Phase2WrapperRightContent = ({
                                            notifications, openLangSelector, isOffline,
                                            lang, ...props
                                          }) => {
  const {campaignNavigate, campaignSettings} = useCampaign()
  const {emitCommand} = useProfile()
  const {rankingFirstClicked, notificationsFirstClicked} = useTutorialStatus()
  const [openTutoTooltip, setOpenTutoTooltip] = useState(false)
  const {mobile} = useScreenDimensions()
  const {t} = useTranslator()
  useEffect(() => {
    if (openTutoTooltip === 'leaderboard' && mobile) {
      props?.linkModal({
        type: 'basic',
        infos: {
          title: t(PWA ? "phase_2.tutorial.tooltips.ranking.rankingHomeButton.PWA.title" : "phase_2.tutorial.tooltips.ranking.rankingHomeButton.title"),
          text: t(PWA ? "phase_2.tutorial.tooltips.ranking.rankingHomeButton.PWA.text" : "phase_2.tutorial.tooltips.ranking.rankingHomeButton.text"),
          buttonText: t("phase_2.tutorial.tooltips.ranking.rankingHomeButton.buttonText"),
          action: async () => {
            await emitCommand({
              type: "Phase2UpdateSeeTutoStep",
              payload: {
                step: "rankingFirstClicked"
              }
            })
            setOpenTutoTooltip(null)
            campaignNavigate("leaderboard/team")
            props?.linkModal(null)
          },
          noCloseButton: true
        }
      })
    }
    if (openTutoTooltip === 'challenges' && mobile) {
      props?.linkModal({
        type: 'basic',
        infos: {
          title: t("phase_2.tutorial.tooltips.notifications.notificationsButton.title"),
          text: t("phase_2.tutorial.tooltips.notifications.notificationsButton.text"),
          buttonText: t("phase_2.tutorial.tooltips.notifications.notificationsButton.buttonText"),
          action: async () => {
            await emitCommand({
              type: "Phase2UpdateSeeTutoStep",
              payload: {
                step: "notificationsFirstClicked"
              }
            })
            setOpenTutoTooltip(null)
            campaignNavigate("history")
            props?.linkModal(null)
          },
          noCloseButton: true
        }
      })
    }
  }, [openTutoTooltip, mobile])

  return (
    <>
      {campaignSettings?.internationalizedContent && (
        <HomeLangSelectorButton
          size={30}
          iconSize={20}
          onPress={openLangSelector}
          text={lang}
        />
      )}
      <HomeSettingsButton
        size={30}
        iconSize={20}
        onPress={() => campaignNavigate("settings")}
      />
      {/* {!isOffline && <HomeLeaderboardButton
      size={30}
      iconSize={20}
      onPress={() => {
        if (!rankingFirstClicked) {
          setOpenTutoTooltip('leaderboard')
          return
        }
        campaignNavigate('leaderboard/player')
      }}
    />} */}
      {openTutoTooltip === "leaderboard" && !mobile && (
        <WrapperToolip style={{ right: "unset" }}>
          <TooltipTuto
            minWidth={450}
            cornerRight={-15}
            style={{
              transformOrigin: "top left",
              transform: [{ scale: 0.7 }],
              top: -17,
              left: -345,
            }}
            infos={{
              title: t(
                PWA
                  ? "phase_2.tutorial.tooltips.ranking.rankingHomeButton.PWA.title"
                  : "phase_2.tutorial.tooltips.ranking.rankingHomeButton.title"
              ),
              text: t(
                PWA
                  ? "phase_2.tutorial.tooltips.ranking.rankingHomeButton.PWA.text"
                  : "phase_2.tutorial.tooltips.ranking.rankingHomeButton.text"
              ),
              buttonText: t(
                "phase_2.tutorial.tooltips.ranking.rankingHomeButton.buttonText"
              ),
              onClose: async () => {
                await emitCommand({
                  type: "Phase2UpdateSeeTutoStep",
                  payload: {
                    step: "rankingFirstClicked",
                  },
                });
                setOpenTutoTooltip(null);
                campaignNavigate("leaderboard/team");
              },
              noCloseButton: true,
            }}
          />
        </WrapperToolip>
      )}
      {openTutoTooltip === "challenges" && !mobile && (
        <WrapperToolip style={{ right: "unset" }}>
          <TooltipTuto
            minWidth={450}
            cornerRight={-15}
            style={{
              transformOrigin: "top left",
              transform: [{ scale: 0.7 }],
              top: 18,
              left: -345,
            }}
            infos={{
              title: t(
                "phase_2.tutorial.tooltips.notifications.notificationsButton.title"
              ),
              text: t(
                "phase_2.tutorial.tooltips.notifications.notificationsButton.text"
              ),
              buttonText: t(
                "phase_2.tutorial.tooltips.notifications.notificationsButton.buttonText"
              ),
              onClose: async () => {
                await emitCommand({
                  type: "Phase2UpdateSeeTutoStep",
                  payload: {
                    step: "notificationsFirstClicked",
                  },
                });
                setOpenTutoTooltip(null);
                campaignNavigate("history");
              },
              noCloseButton: true,
            }}
          />
        </WrapperToolip>
      )}
      {!PWA && (
        <Challenge
          notification={notifications}
          campaignNavigate={campaignNavigate}
          onPress={() => {
            if (!notificationsFirstClicked) {
              setOpenTutoTooltip("challenges");
              return;
            }
            campaignNavigate("history");
          }}
        />
      )}
    </>
  );
}

export const Phase2HomeButtons = ({mobile, width, height, phase, ...props}) => {
  const {userState} = useProfile()
  const {campaignNavigate, campaignId} = useCampaign()

  return <>
    {
      userState?.teamId && (
        <TeamBlazon
          phase={phase}
          teamId={userState?.teamId}
          {...{campaignId, campaignNavigate, mobile}}
          {...props}
        />
      )}
  </>
}


const TeamBlazon = ({teamId, campaignId, campaignNavigate, mobile, phase, ...props}) => {
  if (!teamId) return null
  const {cityFirstClicked} = useTutorialStatus()
  const [openTutoToolTip, setOpenTutoToolTip] = useState(false)
  const {emitCommand} = useProfile()
  const {t} = useTranslator()

  const {data: team, loaded} = useDataLoading(
    `clients/${clientId}/campaigns/${campaignId}/teams/${teamId}`,
    [],
    false
  )

  useEffect(() => {
    if (openTutoToolTip && mobile) {
      props?.linkModal({
        type: 'basic',
        infos: {
          title: t("phase_2.tutorial.tooltips.team.teamHomeButton.title"),
          text: t("phase_2.tutorial.tooltips.team.teamHomeButton.text"),
          buttonText: t("phase_2.tutorial.tooltips.team.teamHomeButton.buttonText"),
          action: async () => {
            await emitCommand({
              type: "Phase2UpdateSeeTutoStep",
              payload: {
                step: "cityFirstClicked"
              }
            })
            setOpenTutoToolTip(null)
            props?.linkModal(null)
            campaignNavigate("team")
          },
          noCloseButton: true
        }
      })
    }
  }, [openTutoToolTip, mobile])

  return loaded && team ? (<>
      {
        (openTutoToolTip && !mobile) &&
        <WrapperTeamTuto>
          <TooltipTuto
            style={{
              top: 50,
              left: '50%',
              transform: [{translateX: '-50%'}, {scale: 0.7}]
            }}
            cornerTop={-5}
            infos={{
              title: t("phase_2.tutorial.tooltips.team.teamHomeButton.title"),
              text: t("phase_2.tutorial.tooltips.team.teamHomeButton.text"),
              buttonText: t("phase_2.tutorial.tooltips.team.teamHomeButton.buttonText"),
              action: async () => {
                await emitCommand({
                  type: "Phase2UpdateSeeTutoStep",
                  payload: {
                    step: "cityFirstClicked"
                  }
                })
                setOpenTutoToolTip(null)
                campaignNavigate("team")
              }
            }}/>
        </WrapperTeamTuto>
      }
      <HomeTeamButton
        phase={phase}
        blazon={team.logo.shape}
        color={team.logo.color}
        text={team.logo.text}
        size={mobile ? 50 : 60}
        name={team.name}
        onPress={() => {
          if (!cityFirstClicked) {
            setOpenTutoToolTip(true)
            return
          }
          campaignNavigate("team")
        }}
      />
    </>
  ) : null
}

const WrapperTeamTuto = styled(WrapperToolip)({
  position: 'fixed',
  width: '100%',
  right: 'unset',
  top: '2.5%',
})

const HomeTeamButton = styled(Blazon)({
  position: 'fixed',
  top: '2.5%',
})

