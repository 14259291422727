import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useCampaign }                                 from '../../../GlobalContexts'
import styled                                          from '@emotion/native'
import { Animated, PanResponder }                      from 'react-native'
import { BehaviorSubject }                             from 'rxjs'
import { AppLoader }                                   from '../../Phase1/Components/AppLoader'
import { useProfile }                                  from '../../../Auth/useProfile'

const zoomScaleValues = {
  2: 1.5,
  1: 1.25,
  0: 1,
}

const mapCompensateWidth = 1.2
const mapCompensateHeight = 0.8
const mapBaseRatio = 1.1
const baseLimitX = 0
const baseLimitY = 0
const panValue = new BehaviorSubject({x: 0, y: 0})
export const _zoomStatusIncrement = new BehaviorSubject(true)
export const _focusOnElement = new BehaviorSubject(null)

export const MapPhase2 = ({screenWidth, screenHeight, zoomScaleValue, overlay, buildings, hide}) => {
  const {campaignSettings} = useCampaign()
  const [mapAsset, setMapAsset] = useState(null)
  const [loading, setLoading] = useState(true)
  const pan = useRef(new Animated.ValueXY({x: -300, y: -300})).current
  const zoomScale = useRef(new Animated.Value(1.5)).current
  useEffect(() => {
    if (!campaignSettings?.map) return
      ;(async () => {
      await fetch(`${campaignSettings?.map}`)
        .then(async res => await res.blob())
        .then(async (blob) => {
          return setMapAsset(URL.createObjectURL(blob))
        })
    })()
  }, [campaignSettings?.map])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  useEffect(() => {
    pan.addListener((value) => {
      panValue.next(value)
    })
  }, [])

  const badScreenRatio = useMemo(() => {
    const ratio = screenWidth / screenHeight
    return ratio >= 1 && ratio <= 1.136
  }, [screenWidth, screenHeight])

  useEffect(() => {
    if (!screenWidth || !screenHeight || zoomScaleValue === undefined) return
    const focus = _focusOnElement.subscribe((values) => {
      makeFocusOnElement(values, screenWidth, screenHeight, zoomScaleValue)
    })
    return () => focus.unsubscribe()
  }, [screenWidth, screenHeight, zoomScaleValue])

  const makeFocusOnElement = (position, screenWidth, screenHeight, zoomScaleValue) => {
    if (!position) return
    const width = screenWidth < screenHeight ? ((screenHeight * mapBaseRatio) * mapCompensateWidth) : screenWidth * mapBaseRatio
    const height = screenWidth < screenHeight ? (screenHeight * mapBaseRatio) : (screenWidth * mapBaseRatio) * mapCompensateHeight
    Animated.timing(pan, {
      toValue: {
        x: -((((position?.x * width) / 100) * zoomScaleValues[zoomScaleValue]) - (screenWidth / 2) + (position?.size ? ((((parseInt(position?.size?.width) * width) / 100) * zoomScaleValues[zoomScaleValue]) / 2) : 0)),
        y: -(((position?.y * height) / 100) * zoomScaleValues[zoomScaleValue] - (screenHeight / 2) + (position?.size ? ((((parseInt(position?.size?.height) * height) / 100) * zoomScaleValues[zoomScaleValue]) / 2) : 0))
      },
      duration: 500,
      useNativeDriver: true
    }).start(() => controlFlattenOffSetPan(panValue, screenWidth, screenHeight, zoomScaleValue, badScreenRatio, pan))
    _focusOnElement.next(null)
  }

  useEffect(() => {
    if (!typeof zoomScaleValue === 'number' || !screenWidth || !screenHeight) return
    const panValues = panValue.getValue()
    const mobile = screenHeight < screenWidth
    const zoomDiffX = mobile ? ((screenHeight * 1.2) / zoomScaleValues[zoomScaleValue]) : screenWidth / zoomScaleValues[zoomScaleValue]
    const zoomDiffY = screenHeight / zoomScaleValues[zoomScaleValue]
    const newPosX = _zoomStatusIncrement.getValue() ?
      (panValues.x + -(zoomDiffX / zoomScaleValues[zoomScaleValue])) :
      (panValues.x + (zoomDiffX / zoomScaleValues[zoomScaleValue]))
    const newPosY = _zoomStatusIncrement.getValue() ?
      (panValues.y + -(zoomDiffY / zoomScaleValues[zoomScaleValue])) :
      (panValues.y + (zoomDiffY / zoomScaleValues[zoomScaleValue]))
    Animated.parallel([
      Animated.timing(zoomScale, {
        toValue: zoomScaleValues[zoomScaleValue],
        duration: 500,
        useNativeDriver: true
      }),
      Animated.timing(pan, {
        toValue: {
          x: newPosX,
          y: newPosY,
        },
        duration: 500,
        useNativeDriver: true
      }),
    ]).start(() => controlFlattenOffSetPan(panValue, screenWidth, screenHeight, zoomScaleValue, badScreenRatio, pan))
  }, [zoomScaleValue, screenWidth, screenHeight])

  const panResponder = useMemo(
    () => PanResponder.create({
      onStartShouldSetPanResponderCapture: () => {
      },
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        pan.setOffset({
          x: pan.x._value,
          y: pan.y._value
        })
      },
      onPanResponderMove: (event, gestureState) => {
        return Animated.event(
          [
            null,
            {
              dx: pan.x, dy: pan.y
            }
          ],
          {
            listener: (_value) => {
            },
            useNativeDriver: true
          }
        )(event, gestureState)
      },
      onPanResponderRelease: () => {
        pan.flattenOffset()
        controlFlattenOffSetPan(panValue, screenWidth, screenHeight, zoomScaleValue, badScreenRatio, pan)
      },
      onPanResponderEnd: () => {
      }
    }),
    [screenWidth, screenHeight, zoomScaleValue, badScreenRatio]
  )
  


  return <>
    <WrapperMapPhase2 screenWidth={screenWidth} screenHeight={screenHeight}
                      {...panResponder.panHandlers} hide={hide}>
      <WrapperMapImage pan={pan}
                       width={(screenWidth < screenHeight || badScreenRatio) ? ((screenHeight * mapBaseRatio) * mapCompensateWidth) : screenWidth * mapBaseRatio}
                       height={(screenWidth < screenHeight || badScreenRatio) ? (screenHeight * mapBaseRatio) : (screenWidth * mapBaseRatio) * mapCompensateHeight}
                       zoom={zoomScale}
                       lockXTranslation={getLockXTranslation(screenWidth, screenHeight, zoomScaleValue)}
                       lockYTranslation={getLockYTranslation(screenWidth, screenHeight, zoomScaleValue)}
      >
        {
          mapAsset &&
          <MapImage source={mapAsset} alt="map"/>
        }
        <WrapperContentMap>
          {buildings}
        </WrapperContentMap>
      </WrapperMapImage>
      {overlay}
    </WrapperMapPhase2>
    {
      loading &&
      <AppLoader/>
    }
  </>
}

const controlFlattenOffSetPan = (panValue, screenWidth, screenHeight, zoomScaleValue, badScreenRatio, pan) => {
  const screenPosition = panValue.getValue()
  const posX = screenPosition.x
  const posY = screenPosition.y
  const mapWidth = (screenWidth < screenHeight || badScreenRatio) ?
    ((screenHeight * mapBaseRatio) * mapCompensateWidth) * zoomScaleValues[zoomScaleValue]
    : (screenWidth * mapBaseRatio) * zoomScaleValues[zoomScaleValue]
  const mapHeight = (screenWidth < screenHeight || badScreenRatio) ?
    ((screenHeight * mapBaseRatio) * zoomScaleValues[zoomScaleValue])
    : ((screenWidth * mapBaseRatio) * mapCompensateHeight) * zoomScaleValues[zoomScaleValue]
  const maxLimitX = (mapWidth - screenWidth)
  const maxLimitY = (mapHeight - screenHeight)

  if (posX > baseLimitX && posY > baseLimitY) {
    pan.setValue({x: baseLimitX, y: baseLimitY})
  } else if (posX < -maxLimitX && posY < -maxLimitY) {
    pan.setValue({x: -maxLimitX, y: -maxLimitY})
  } else if (posX < -maxLimitX && posY > baseLimitY) {
    pan.setValue({x: -maxLimitX, y: baseLimitY})
  } else if (posX > baseLimitX && posY < -maxLimitY) {
    pan.setValue({x: baseLimitX, y: -maxLimitY})
  } else if (posX < -maxLimitX) {
    pan.setValue({x: -maxLimitX, y: posY})
  } else if (posY < -maxLimitY) {
    pan.setValue({x: posX, y: -maxLimitY})
  } else if (posX > baseLimitX) {
    pan.setValue({x: baseLimitX, y: posY})
  } else if (posY > baseLimitY) {
    pan.setValue({x: posX, y: baseLimitY})
  }
}

const getLockXTranslation = (screenWidth, screenHeight, zoomScaleValue) => {
  return screenWidth < screenHeight ?
    -(Math.abs(((screenHeight * mapBaseRatio) * mapCompensateWidth) * zoomScaleValues[zoomScaleValue]) - screenWidth) || 0
    : -(Math.abs(((screenWidth * mapBaseRatio) * zoomScaleValues[zoomScaleValue]) - screenWidth)) || 0
}

const getLockYTranslation = (screenWidth, screenHeight, zoomScaleValue) => {
  return screenWidth < screenHeight ?
    -(Math.abs(((screenHeight * mapBaseRatio) * zoomScaleValues[zoomScaleValue]) - screenHeight)) || 0
    : -(Math.abs((((screenWidth * mapBaseRatio) * mapCompensateHeight) * zoomScaleValues[zoomScaleValue]) - screenHeight)) || 0
}

const WrapperMapPhase2 = styled.View(({screenWidth, screenHeight, hide}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: screenWidth,
  height: screenHeight,
  overflow: 'hidden',
  opacity: hide ? 0 : 1,
}))

const WrapperMapImage = styled(Animated.View)(({pan, zoom, width, height, lockXTranslation, lockYTranslation}) => ({
  position: 'absolute',
  width: width,
  height: height,
  transformStyle: 'preserve-3d',
  willChange: 'transform',
  touchAction: 'none',
  transformOrigin: 'top left',
  transform: [{
    translateX: pan.x.interpolate({
      inputRange: [lockXTranslation, 0],
      outputRange: [lockXTranslation, 0],
      extrapolate: 'clamp'
    })
  }, {
    translateY: pan.y.interpolate({
      inputRange: [lockYTranslation, 0],
      outputRange: [lockYTranslation, 0],
      extrapolate: 'clamp'
    })
  }, {scale: zoom}],
  cursor: 'grab',
}))

const MapImage = styled.Image({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})

const WrapperContentMap = styled.View({
  position: 'relative',
  width: '100%',
  height: '100%'
})
