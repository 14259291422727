import React from "react"
import ScoreIcon from "../Modals/Assets/score.svg"
import {ScoreValue, WrapperScoreIcon} from "./Phase2WrapperLeftContent"
import {useProfile} from "../../../../Auth/useProfile"

export const Phase2MobileHeader = ({}) => {
  const {userState} = useProfile()

  return <WrapperScoreIcon>
    <ScoreIcon width={30} height={30} style={{position: 'absolute', left: 0, top: 0}}/>
    <ScoreValue>
      {userState?.points || 0}
    </ScoreValue>
  </WrapperScoreIcon>
}
