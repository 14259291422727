import React, { useRef, useState } from "react"
import styled from "@emotion/native"
import { T, useTranslator } from "../translations/translate"
import { useProfile } from "../Auth/useProfile"
import { useCampaign } from "../GlobalContexts"
import { usePreCachedData } from "../Utils/dataClient"
import { clientId } from "../../clientId"
import { colors } from "@civitime/library/storybook/configs/colors"
import { useScreenDimensions } from "@civitime/library/storybook/hooks/useScreenDimensions"
import { DefaultModal } from "@civitime/library/storybook/stories/Modals"
import { AvatarComponent } from "@civitime/library/storybook/stories/Avatar"
import { PressedInCard } from "@civitime/library/storybook/stories/Cards"
import { Input } from "@civitime/library/storybook/stories/Inputs"
import { MediumText, Title2 } from "@civitime/library/storybook/stories/Texts"
import { PlayerIcon } from "@civitime/library/storybook/stories/Icons"
import { Blazon } from "@civitime/library/storybook/stories/Blazon"
import {
  ActionButton,
  TextButton,
} from "@civitime/library/storybook/stories/Button"

export const AvatarProfile = ({phase, hub}) => {
  const root = useRef(null)
  const { t } = useTranslator()
  const { mobile, orientation, width } = useScreenDimensions(root)
  const { campaignNavigate, campaignId } = useCampaign()
  const { emitCommand } = useProfile()
  const { publicProfile, userState } = useProfile()
  const [name, setName] = useState(publicProfile?.name)
  const team = usePreCachedData(
    `clients/${clientId}/campaigns/${campaignId}/teams/${userState?.teamId}`,
    []
  )

  const updateName = () => {
    emitCommand({
      type: "UpdateNameProfile",
      payload: {
        name: name,
      },
    })
  }

  return publicProfile ? (
    <DefaultModal
      title={t("app.profile.modalTitle")}
      onPress={() => campaignNavigate("")}
    >
      <WrapperAvatarProfileTop ref={root}>
        <AvatarComponent
          circleAvatarConfig={{
            size: 80,
            withBorder: true,
            backgroundColor: colors.lightText,
          }}
          avatar={publicProfile?.avatar}
        />
        <ActionButton onPress={() => campaignNavigate("avatar/update")}>
          <T path="app.profile.customize" />
        </ActionButton>
      </WrapperAvatarProfileTop>

      <WrapperProfileContent>
        <InfosProfileCard noHover={true} {...{ mobile, width }}>
          <WrapperInput {...{ mobile }}>
            <WrapperPlayerName {...{ mobile, orientation }}>
              <PlayerIcon height={30} width={30} color={colors.linkWater} />
              <PlayerNameInput
                defaultValue={publicProfile?.name}
                onChangeText={(e) => setName(e)}
              />
            </WrapperPlayerName>
            <TextButton
              disabled={publicProfile.name === name}
              onPress={updateName}
            >
              <T path="app.profile.edit" />
            </TextButton>
          </WrapperInput>
          <WrapperTeam {...{ mobile, orientation }}>
            {team?.logo && (
              <Blazon
                phase={phase}
                size={30}
                blazon={team?.logo?.shape}
                color={team?.logo?.color}
                text={team?.logo?.text}
              />
            )}
            {team ? (
              <TextTeam>
                <T path="app.profile.teamBelonging" />
                <TextBoldTeam>{team?.name}</TextBoldTeam>
              </TextTeam>
            ) : (
              <TextTeam>
                <T path="app.profile.notInTeam" />
              </TextTeam>
            )}
          </WrapperTeam>
        </InfosProfileCard>
      </WrapperProfileContent>
    </DefaultModal>
  ) : null
}

const WrapperAvatarProfileTop = styled.View({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: 180,
  justifyContent: "space-around",
})

const WrapperProfileContent = styled.View({
  alignItems: "center",
})

const InfosProfileCard = styled(PressedInCard)(
  {
    minHeight: 150,
  },
  ({ mobile, width }) => ({
    maxWidth: "90%",
    alignItems: mobile ? "center" : "initial",
    paddingLeft: mobile ? 5 : 60,
    paddingRight: mobile ? 5 : 60,
    paddingTop: mobile ? 20 : 30,
    paddingBottom: mobile ? 20 : 30,
    marginVertical: mobile ? 20 : 50,
    minWidth: mobile ? width * 0.9 : 630,
  })
)

const WrapperInput = styled.View(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ({ mobile }) => ({
    flexDirection: mobile ? "column" : "row",
    ...(mobile && {
      marginBottom: 10,
    }),
  })
)

const WrapperTeam = styled.View(({ mobile, orientation }) => ({
  display: "flex",
  flexDirection: mobile && orientation === "portrait" ? "column" : "row",
  alignItems: "center",
}))

const TextTeam = styled(MediumText)({
  marginLeft: 15,
})

const TextBoldTeam = styled(Title2)({
  textTransform: "uppercase",
})

const PlayerNameInput = styled(Input)({
  width: 300,
})

const WrapperPlayerName = styled.View(({ mobile, orientation }) => ({
  flexDirection: mobile && orientation === "portrait" ? "column" : "row",
  alignItems: "center",
}))
