import React, { useEffect, useRef, useState } from 'react'
import styled                                 from '@emotion/native'
import { T, useTranslator }                   from '../translations/translate'
import { useProfile }                         from '../Auth/useProfile'
import { useCampaign }                        from '../GlobalContexts'

import { colors }                         from '@civitime/library/storybook/configs/colors'
import { useScreenDimensions }            from '@civitime/library/storybook/hooks/useScreenDimensions'
import { DefaultModal }                   from '@civitime/library/storybook/stories/Modals'
import { BoldText }                       from '@civitime/library/storybook/stories/Texts'
import { AvatarComponent }                from '@civitime/library/storybook/stories/Avatar'
import { ColorBall, ColorsSelection }     from '@civitime/library/storybook/stories/SelectionBar'
import { ActionButton }                   from '@civitime/library/storybook/stories/Button'
import FaceIcon                           from './Assets/face.svg'
import TShirtIcon                         from './Assets/t-shirt.svg'
import TShirtStrip                         from './Assets/stripTShirt.svg'
import { Image, TouchableWithoutFeedback } from 'react-native'
import { PWA }                             from '../../clientId'

export const AvatarHomeSelection = ({ openHelper, hub }) => {
  const root = useRef(null)
  const { t } = useTranslator()
  const { campaignNavigate, campaignSettings, campaignId } = useCampaign()
  const { emitCommand, publicProfile, emitClientCommand } = useProfile()
  const { mobile, orientation } = useScreenDimensions(root)
  const dateNow = new Date(Date.now()).toISOString()
  const [avatarSelection, setAvatarSelection] = useState({
    type: null,
    color: colors.initAvatar.skin,
    tShirt: colors.initAvatar.tShirt
  })
  const [pseudo, setPseudo] = useState('')
  const defaultAvatar = ['man', 'woman']
  const canSubmit =
    avatarSelection.type !== null &&
    avatarSelection.color !== null &&
    avatarSelection.color !== colors.initAvatar.skin &&
    avatarSelection.tShirt !== null &&
    avatarSelection.tShirt !== colors.initAvatar.tShirt && (PWA || hub ? pseudo.length : true)

  const updateAvatar = () => {
    canSubmit && campaignId &&
    emitCommand({
      type: 'UpdateAvatar',
      payload: { avatar: avatarSelection },
    }).then(
      (value) => {
        value?.[0]?.type === 'AvatarUpdated' && campaignNavigate('')
        if (campaignSettings?.endAt < dateNow && !PWA) {
          openHelper()
        }
      }
    )
    canSubmit && !campaignId &&
    emitClientCommand({
      type: 'UpdateAvatar',
      payload: { avatar: avatarSelection },
    }).then(
      (value) => {
        value?.[0]?.type === 'AvatarUpdated' && campaignNavigate('')
        if (campaignSettings?.endAt < dateNow) {
          openHelper()
        }
      }
    )
  }
  
  const initiateOfflineProfile = () => {
    emitCommand({
      type: 'SetOfflinePublicProfile',
      payload: {
        avatar: avatarSelection,
        pseudo: pseudo
      }
    }).then(
      (value) => {
        value?.[0]?.type === 'OfflinePublicProfileUpdated' && campaignNavigate('')
      }
    )
  }
  
  useEffect(() => {
    if (!publicProfile || !publicProfile.avatar) return
    setAvatarSelection({
      type: publicProfile.avatar.type,
      color: publicProfile.avatar.color,
      tShirt: publicProfile.avatar?.tShirt,
    })
  }, [publicProfile])
  return publicProfile ? (
    <DefaultModal
      title={t('app.profile.modalTitle')}
      onPress={!publicProfile.avatar ? false : () => campaignNavigate('')}
      noHeader={!!PWA}
    >
      <WrapperAvatarHomeSelectionContent ref={root}>
        <TextAvatar>
          <T path="app.profile.avatarChoice"/>
        </TextAvatar>
        <WrapperChooseYourDefaultAvatar>
          {defaultAvatar.map((avatar, index) => {
            const isActive = avatarSelection?.type === avatar
            return (
              <WrapperAvatar
                key={index + 'default avatars'}
                {...{ index, mobile, orientation }}
              >
                <AvatarComponent
                  avatar={{ color: avatarSelection?.color, type: avatar, tShirt: avatarSelection?.tShirt }}
                  circleAvatarConfig={{
                    size: 135,
                    withBorder: false,
                    backgroundColor: colors.avatarBackground,
                  }}
                  onPress={() => {
                    setAvatarSelection((selection) => ({
                      ...selection,
                      type: avatar,
                    }))
                  }}
                  isActiveOverride={isActive}
                />
                {isActive ? <ActiveBar/> : null}
              </WrapperAvatar>
            )
          })}
        </WrapperChooseYourDefaultAvatar>
        <WrapperColorPalette>
          <WrapperFaceColor>
            <FaceIcon width={50} height={50} style={{ marginRight: 10 }}/>
            <ColorsSelection
              size={40}
              ballsColors={[
                colors.skin.peachPuff,
                colors.skin.rose,
                colors.skin.cherokee,
                colors.skin.brownBramble,
              ]}
              activeColor={
                publicProfile && publicProfile.avatar
                  ? publicProfile.avatar.color
                  : null
              }
              onPress={(color) => {
                setAvatarSelection((selection) => ({
                  ...selection,
                  color: color,
                }))
              }}
            />
          </WrapperFaceColor>
          <WrapperTShirtColor>
            <TShirtIcon width={50} height={50} style={{ marginRight: 10 }}/>
            <TouchableWithoutFeedback
              onPress={() => {
                setAvatarSelection((selection) => ({
                  ...selection,
                  tShirt: '#000000',
                }))
              }}
            >
              <WrapperStripColor style={{ width: 40, marginRight: 5 }}>
                <TShirtLogoWrapper>
                  <ColorBall color={'#000000'} size={40} isActive={avatarSelection?.tShirt === '#000000'}
                             style={{ position: 'absolute' }}/>
                  <LogoWrapper>
                    <Image style={{width: "100%", height: "100%"}} source={require('./Assets/leclerc-logo2.png')}/>
                  </LogoWrapper>
                </TShirtLogoWrapper>
                {
                  avatarSelection?.tShirt === '#000000' &&
                  <BorderSelection/>
                }
              </WrapperStripColor>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback
              onPress={() => {
                setAvatarSelection((selection) => ({
                  ...selection,
                  tShirt: '#2673B6',
                }))
              }}
            >
              <WrapperStripColor style={{ width: 40, marginRight: 5 }}>
                <TShirtLogoWrapper>
                  <ColorBall color={'#2673B6'} size={40} isActive={avatarSelection?.tShirt === '#2673B6'}
                             style={{ position: 'absolute' }}/>
                  <LogoWrapper>
                    <Image style={{width: "100%", height: "100%"}} source={require('./Assets/leclerc-logo2.png')}/>
                  </LogoWrapper>
                </TShirtLogoWrapper>
                {
                  avatarSelection?.tShirt === '#2673B6' &&
                  <BorderSelection/>
                }
              </WrapperStripColor>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback
              onPress={() => {
                setAvatarSelection((selection) => ({
                  ...selection,
                  tShirt: '#E89048',
                }))
              }}
            >
              <WrapperStripColor style={{ width: 40, marginRight: 5 }}>
                <TShirtLogoWrapper>
                  <ColorBall color={'#E89048'} size={40} isActive={avatarSelection?.tShirt === '#E89048'}
                             style={{ position: 'absolute' }}/>
                  <LogoWrapper>
                    <Image style={{width: "100%", height: "100%"}} source={require('./Assets/leclerc-logo2.png')}/>
                  </LogoWrapper>
                </TShirtLogoWrapper>
                {
                  avatarSelection?.tShirt === '#E89048' &&
                  <BorderSelection/>
                }
              </WrapperStripColor>
            </TouchableWithoutFeedback>
            
            <TouchableWithoutFeedback
              onPress={() => {
                setAvatarSelection((selection) => ({
                  ...selection,
                  tShirt: '#F2F2F2',
                }))
              }}
            >
              <WrapperStripColor>
                <TShirtStrip width={40} height={40}/>
                {
                  avatarSelection?.tShirt === '#F2F2F2' &&
                  <BorderSelection/>
                }
              </WrapperStripColor>
            </TouchableWithoutFeedback>
          </WrapperTShirtColor>
        </WrapperColorPalette>
        {(PWA && !publicProfile?.name?.length) && <PseudoWrapper>
          <PseudoText><T path="app.profile.pseudoChoice"/> </PseudoText>
          <PseudoInput onChangeText={setPseudo} value={pseudo}/>
        </PseudoWrapper>}
        <WrapperValidateButton>
          <ActionButton disabled={!canSubmit} onPress={PWA ? publicProfile?.avatar ? updateAvatar
            : initiateOfflineProfile
            : updateAvatar}>
            <T path="app.profile.validate"/>
          </ActionButton>
        </WrapperValidateButton>
      </WrapperAvatarHomeSelectionContent>
    </DefaultModal>
  ) : null
}

const PseudoInput = styled.TextInput({
  backgroundColor: colors.extraLightBackground,
  borderRadius: 15,
  boxShadow: 'inset 3px 3px 5px rgba(94, 104, 121, 0.692), inset -3px -3px 7px #FFFFFF',
  marginLeft: 15,
  marginRight: 15,
  height: 30,
  paddingLeft: 20,
  paddingRight: 20,
  textTransform: 'uppercase'
})

const PseudoWrapper = styled.View({
  borderTopColor: colors.border,
  borderTopWidth: 1,
  width: '80%',
  margin: 'auto',
  marginBottom: 25
})

const BorderSelection = styled.View({
  position: 'absolute',
  width: '120%',
  left: '-10%',
  borderBottomWidth: 3,
  borderBottomColor: colors.secondary,
  bottom: -10,
  borderRadius: 100
})

const WrapperStripColor = styled.View({
  position: 'relative',
  justifyContent: 'center',
  marginLeft: 5,
})

const WrapperFaceColor = styled.View({
  marginTop: 30,
  marginBottom: 15,
  flexDirection: 'row'
})

const WrapperTShirtColor = styled(WrapperFaceColor)({
  marginTop: 15,
})

const WrapperAvatarHomeSelectionContent = styled.View({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  minHeight: 350,
})

const TextAvatar = styled(BoldText)({
  fontSize: 15,
  paddingLeft: 15,
  paddingRight: 15,
  marginTop: 25,
  marginBottom: 25,
})

const PseudoText = styled(TextAvatar)({
  marginTop: 15,
  marginBottom: 15
})

const WrapperChooseYourDefaultAvatar = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

const WrapperColorPalette = styled.View({
  display: 'flex',
  alignItems: 'center',
})

const WrapperValidateButton = styled.View({
  alignItems: 'center',
  marginBottom: 25
})

const WrapperAvatar = styled.View(({ index, mobile, orientation }) =>
  index === 0 && {
    marginRight: mobile && orientation === 'portrait' ? 10 : 40,
  }
)

const ActiveBar = styled.View({
  position: 'absolute',
  bottom: -15,
  left: 0,
  width: '100%',
  height: 3,
  borderRadius: 3 / 2,
  backgroundColor: colors.secondary,
})

const LogoWrapper = styled.View({
  position: 'absolute',
  width: 28,
  height: 28,
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)'
})

const TShirtLogoWrapper = styled.View({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center'
})