import React, {useEffect, useState} from 'react'
import styled from '@emotion/native'
import {Animated} from "react-native"
import {colors} from "@civitime/library/storybook/configs/colors"

export const AppLoader = () => {
  const [point1Anim,] = useState(new Animated.Value(0))
  const [point2Anim,] = useState(new Animated.Value(0))
  const [point3Anim,] = useState(new Animated.Value(0))

  const launchAnimation1 = () => {
    Animated.sequence([
      Animated.timing(point1Anim, {toValue: -3, duration: 250, useNativeDriver: true}),
      Animated.timing(point1Anim, {toValue: 3, duration: 250, useNativeDriver: true}),
      Animated.timing(point1Anim, {toValue: 0, duration: 250, useNativeDriver: true}),
    ]).start(() => launchAnimation1())
  }

  const launchAnimation2 = () => {
    Animated.sequence([
      Animated.timing(point2Anim, {toValue: -3, duration: 250, useNativeDriver: true}),
      Animated.timing(point2Anim, {toValue: 3, duration: 250, useNativeDriver: true}),
      Animated.timing(point2Anim, {toValue: 0, duration: 250, useNativeDriver: true}),
    ]).start(() => launchAnimation2())
  }

  const launchAnimation3 = () => {
    Animated.sequence([
      Animated.timing(point3Anim, {toValue: -3, duration: 250, useNativeDriver: true}),
      Animated.timing(point3Anim, {toValue: 3, duration: 250, useNativeDriver: true}),
      Animated.timing(point3Anim, {toValue: 0, duration: 250, useNativeDriver: true}),
    ]).start(() => launchAnimation3())
  }

  useEffect(() => {
    launchAnimation1()
    setTimeout(() => {
      launchAnimation2()
      setTimeout(() => launchAnimation3(), 250)
    }, 150)
  }, [])

  return <WrapperAppLoader>
    <WrapperAnimationLoader>
      <AppLogo source={require('../../../../assets/civi-logo.png')} alt='App_logo'/>
      <WrapperPoints>
        <PointLoader translateAnimation={point1Anim}/>
        <PointLoader middle translateAnimation={point2Anim}/>
        <PointLoader translateAnimation={point3Anim}/>
      </WrapperPoints>
    </WrapperAnimationLoader>
  </WrapperAppLoader>
}

const WrapperAppLoader = styled.View({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'white',
  zIndex: 100
})

const AppLogo = styled.Image({
  width: 200,
  height: 200,
})

const WrapperAnimationLoader = styled.View({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: [{translateX: '-50%'}, {translateY: '-50%'}]
})

const PointLoader = styled(Animated.View)(({middle, translateAnimation}) => ({
  width: 25,
  height: 25,
  backgroundColor: colors.logoColor,
  borderRadius: 25,
  marginRight: middle ? 15 : 0,
  marginLeft: middle ? 15 : 0,
  transform: [{translateY: translateAnimation}]
}))

const WrapperPoints = styled.View({
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: 30
})
