import { colors }      from '@civitime/library/storybook/configs/colors'
import React           from 'react'
import styled          from '@emotion/native'
import { QRLogoIcon }  from '@civitime/library/storybook/stories/Icons'
import { CloseButton } from '@civitime/library/storybook/stories/Button'

export const QRHeader = ({onPress}) => {
  
  return <HeaderWrapper>
    <QRLogo color={colors.text} width={30} height={30}/>
    <QRClose
      color={colors.secondary}
      iconColor="white"
      iconSize={18}
      size={30}
      crossColor={colors.white}
      mobile={true}
      {...{ onPress }}
    />
  </HeaderWrapper>
}

const HeaderWrapper = styled.View({
  width: '100%',
  height: 50,
  backgroundColor: colors.lightBlue,
  position: 'relative',
  zIndex: 4
})

const QRLogo = styled(QRLogoIcon)({
  position: "absolute",
  left: '50%',
  top: '50%',
  transform: [{translateY: '-50%'}, {translateX: '-50%'}]
})

const QRClose = styled(CloseButton)({
  position: 'absolute',
  top: '50%',
  transform: [{translateY: '-50%'}],
  right: 25
})
