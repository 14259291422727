import React, {useMemo} from "react"
import {BuildingModal} from "./BuildingModal"
import {TownHallModal} from "./TownHallModal"
import {WonderModal} from "./WonderModal"
import {PNJModal} from "./PNJModal"
import {EvolutionModal} from "./EvolutionModal"
import {BasicModal} from "./BasicModal"
import styled from "@emotion/native"
import {HelperModal} from "./HelperModal"

const modals = {
  merveille: WonderModal,
  mairie: TownHallModal,
  construction: BuildingModal,
  module: PNJModal,
  evolution: EvolutionModal,
  basic: BasicModal,
  helper: HelperModal
}

export const ModalSelector = ({type, infosOrigin, onClose, linkModal}) => {
  const ModalToRender = useMemo(() => {
    return modals?.[type] || null
  }, [type])

  return <>
    <ModalOverlay/>
    {
      ModalToRender && <ModalToRender infos={infosOrigin} onClose={onClose} linkModal={linkModal}/>
    }
  </>
}

export const ModalOverlay = styled.View({
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})
