export default {
  "v": "5.7.5",
  "fr": 25,
  "ip": 0,
  "op": 25,
  "w": 200,
  "h": 200,
  "nm": "Composition 2",
  "ddd": 0,
  "assets": [{
    "id": "comp_0", "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Calque de forme 3",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [101.035, 128.5, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [1, 22, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
              "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
              "v": [[30.5, -66.5], [0.5, -61.5], [-30, -66.5], [-20, 22.5], [20.5, 22.5]],
              "c": true
            },
            "ix": 2
          },
          "nm": "Tracé 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 10, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "Contour 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "Fond 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "Transformer "
        }],
        "nm": "Forme 1",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }, {
        "ty": "rd",
        "nm": "Sommets arrondis 1",
        "r": {"a": 0, "k": 1, "ix": 1},
        "ix": 2,
        "mn": "ADBE Vector Filter - RC",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "Calque de forme 2",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 45, "ix": 10},
        "p": {"a": 0, "k": [101.965, 161.5, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [6.364, 78.489, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [128.811, 128.811, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0, 0], [0, 0], [0, 0]],
              "o": [[0, 0], [0, 0], [0, 0]],
              "v": [[6, 31.5], [-43.5, 80], [6, 80]],
              "c": true
            },
            "ix": 2
          },
          "nm": "Tracé 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "rd",
          "nm": "Sommets arrondis 1",
          "r": {"a": 0, "k": 1, "ix": 1},
          "ix": 2,
          "mn": "ADBE Vector Filter - RC",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 10, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "Contour 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "Fond 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "Transformer "
        }],
        "nm": "Forme 1",
        "np": 4,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": -1,
      "op": 124,
      "st": -1,
      "bm": 0
    }]
  }],
  "layers": [{
    "ddd": 0,
    "ind": 1,
    "ty": 0,
    "nm": "fleche& 2",
    "refId": "comp_0",
    "sr": 1,
    "ks": {
      "o": {"a": 0, "k": 100, "ix": 11},
      "r": {"a": 0, "k": -40, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.167, "y": 0.167},
          "t": 0,
          "s": [100, 100, 0],
          "to": [3.667, 3.833, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.167, "y": 0.167},
          "t": 12,
          "s": [122, 123, 0],
          "to": [0, 0, 0],
          "ti": [3.667, 3.833, 0]
        }, {"t": 25, "s": [100, 100, 0]}],
        "ix": 2,
        "l": 2
      },
      "a": {"a": 0, "k": [100, 100, 0], "ix": 1, "l": 2},
      "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
    },
    "ao": 0,
    "w": 200,
    "h": 200,
    "ip": 0,
    "op": 125,
    "st": 0,
    "bm": 0
  }],
  "markers": []
}
