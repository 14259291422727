import React, { useEffect, useState } from 'react'
import { colors }                     from '@civitime/library/storybook/configs/colors'
import styled                         from '@emotion/native'
import { Title1, Title2 }             from '@civitime/library/storybook/stories/Texts'

export const PWALoader = ({ externalProgress, PWAProgress, finishedLoading }) => {
  const [calculatedProgress, setCalculatedProgress] = useState({})
  const getProgress = () => {

    if (PWAProgress.app === 'done') return finishedLoading()
    if (PWAProgress.temp < 100 && PWAProgress.temp !== 'done') {
      return setCalculatedProgress({
        text: `Mise en cache de l'application ${Math.round(PWAProgress.temp)}%`,
        progress: Math.round(PWAProgress.temp)
      })
    } else if (PWAProgress.app < 100 && PWAProgress.app !== 'done' && PWAProgress.app !== 0) {
      return setCalculatedProgress({
        text: `Mise en cache espace disque ${Math.round(PWAProgress.app)}%`,
        progress: Math.round(PWAProgress.app)
      })
    } else if (externalProgress < 100 && externalProgress !== 'done') {
      return setCalculatedProgress({
        text: `Mise en cache des URLs externes ${Math.round(externalProgress)}%`,
        progress: Math.round(externalProgress)
      })
    } else {
      finishedLoading()
    }
  }
  useEffect(() => {
    getProgress()
  }, [calculatedProgress, externalProgress, PWAProgress.temp, PWAProgress.app])

  return <PWALoaderWrapper>
    <LoadingTextWrapper>
      <LoadingText>Les données sont en cours de téléchargement</LoadingText>
    </LoadingTextWrapper>
    <LoaderWrapper>
      <Loader>
        <LoaderText color={colors.white}>{calculatedProgress?.text}</LoaderText>
      </Loader>
      <ProgressBar progress={calculatedProgress.progress}/>
    </LoaderWrapper>

  </PWALoaderWrapper>
}

const LoadingText = styled(Title1)({})

const PWALoaderWrapper = styled.View({})

const Loader = styled.View({
  backgroundColor: 'rgba(118,0,213, .5)',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
  paddingLeft: 5,
  paddingRight: 5
})

const LoaderText = styled(Title2)({})

const LoaderWrapper = styled.View({
  position: 'relative',
  overflow: 'hidden',
  width: 280,
  height: 30,
  margin: 'auto',
  borderRadius: 29,
  marginTop: 50
})

const ProgressBar = styled.View(({ progress }) => ({
  position: 'absolute',
  transform: `translateX(${progress}%)`,
  left: -280,
  top: 0,
  backgroundColor: 'rgb(118,0,213)',
  width: 280,
  height: 30,
  paddingLeft: 5,
  paddingRight: 5
}))

const LoadingTextWrapper = styled.View({
  marginTop: 80,
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})
