import DeviceDetector from "device-detector-js"

export const getBrowserInfos = () => {
  const deviceDetector = new DeviceDetector()
  const userAgent = navigator.userAgent
  const device = deviceDetector.parse(userAgent)

  const os = `${device.os.name} ${device.os.version}`
  let browserName = device.client.name
  let browserVersion = device.client.version

  return {
    browser: browserName,
    version: browserVersion,
    os     : os
  }
}
