import React, {useEffect, useState} from 'react'
import styled               from '@emotion/native'
import { useLocation }      from 'react-router-native'
import { T, useTranslator } from '../translations/translate'
import { useProfile }       from '../Auth/useProfile'
import { useCampaign }      from '../GlobalContexts'

import { colors }             from '@civitime/library/storybook/configs/colors'
import { MOBILE_HEADER_SIZE } from '@civitime/library/storybook/configs/sizes'
import { TextButton }         from '@civitime/library/storybook/stories/Button'
import { DefaultModal }       from '@civitime/library/storybook/stories/Modals'
import { Blazon }             from '@civitime/library/storybook/stories/Blazon'
import {
  BoldText,
  MediumText,
  Title1,
  Title2,
}                             from '@civitime/library/storybook/stories/Texts'
import {
  ColorsSelection,
  SelectionBlazonButton,
  UseMultiTextesBarSelection,
}                             from '@civitime/library/storybook/stories/SelectionBar'
import {
  InputCard,
  InputCardWrapper,
}                             from '@civitime/library/storybook/stories/Inputs'
import {
  Building1Icon, Building2Icon, Building3Icon,
  RoundIcon,
  SquareIcon,
  TriangleIcon,
} from '@civitime/library/storybook/stories/Icons'

const teamIcons = {
  1 : [
    SquareIcon,
    TriangleIcon,
    RoundIcon,
  ],
  1.3 : [
    SquareIcon,
    TriangleIcon,
    RoundIcon,
  ],
  2 : [
    Building1Icon,
    Building2Icon,
    Building3Icon
  ]
}

const teamsColorIcons = {
  defaultColor: {
    1: colors.extraLightBackground,
    1.3: colors.extraLightBackground,
    2: colors.text
  }
}

export const NewTeam = ({ height, mobile, openHelper, phase}) => {
  const { t } = useTranslator()
  const { emitCommand } = useProfile()
  const { campaignNavigate } = useCampaign()
  const [teamName, setTeamName] = useState(null)
  const [activeBlazon, setActiveBlazon] = useState(null)
  const [activeColor, setActiveColor] = useState(null)
  const [teamCreationError, setTeamCreationError] = useState(null)
  const { state } = useLocation()

  const ballsColors = [
    colors.blazon.salmon,
    colors.blazon.yellow,
    colors.blazon.cyan,
    colors.blazon.purple,
    colors.blazon.orchid,
  ]

  const createNewTeam = () => {
    if (!teamName || !activeColor || !activeBlazon) return
    switch (phase) {
      case 1:
        emitCommand({
          type: "CreateTeam",
          payload: {
            name: teamName,
            color: activeColor,
            logoShape: activeBlazon,
          },
        })
          .then(
            (value) => {
              if(value[0].type === "TeamCreated"){
                campaignNavigate("team")
              }else if (value[0].type === "TeamErrors_TeamAlreadyExists"){
                setTeamCreationError(true)
              }
            }
          )
          .then(openHelper())
        break
      case 1.3:
        emitCommand({
          type: "CreateTeam",
          payload: {
            name: teamName,
            color: activeColor,
            logoShape: activeBlazon,
          },
        })
          .then(
            (value) => {
              if(value[0].type === "TeamCreated"){
                campaignNavigate("team")
              }else if (value[0].type === "TeamErrors_TeamAlreadyExists"){
                setTeamCreationError(true)
              }
            }
          )
        break
      case 2:
        emitCommand({
          type: "CreateTeam",
          payload: {
            name: teamName,
            color: activeColor,
            logoShape: activeBlazon,
          },
        })
          .then(
            (value) => {
              if(value[0].type === "TeamCreated"){
                campaignNavigate("team")
              }else if (value[0].type === "TeamErrors_TeamAlreadyExists"){
                setTeamCreationError(true)
              }
            }
          )
        break

      default:
        return
    }
  }

  useEffect(() => {
    if(teamCreationError){
      setTimeout(() => setTeamCreationError(null), 3000)
    }
  }, [teamCreationError])

  return (
    <DefaultModal
      backgroundColor={colors.lightBackground}
      title={t("app.team.createYourTeam")}
      color={colors.text}
      maxHeight={(height - MOBILE_HEADER_SIZE) * 0.99}
      onPress={() => campaignNavigate("team")}
    >
      <WrapperNewTeam mobile={mobile} minHeight={440}>
        {state === "allTeamsFull" && (
          <AllTeamsFullText>
            <T path="app.team.allTeamsFull" />
          </AllTeamsFullText>
        )}
        <WrapperPreview>
          <Blazon
            blazon={activeBlazon ? activeBlazon : "square"}
            color={activeColor ? activeColor : teamsColorIcons.defaultColor?.[phase]}
            text={teamName ? teamName.charAt(0).toUpperCase() : " "}
            name={teamName ? teamName : t("app.team.teamName")}
            phase={phase}
            size={70}
          />
          <Preview>
            <T path="app.team.preview" />
          </Preview>
        </WrapperPreview>
        <WrapperRow mobile={mobile}>
          <WrapperName>
            <Header index={1} text={t("app.team.giveTeamName")} />
            <InputCardWrapper>
              <InputCard
                placeholder="Nom"
                onChangeText={setTeamName}
                maxLength={20}
              />
              {
                teamCreationError &&
                  <TeamNameError>
                    <T path='app.team.teamNameAlreadyUsed'/>
                  </TeamNameError>
              }
            </InputCardWrapper>
          </WrapperName>
          <WrapperBlazon>
            <Header index={2} text={t("app.team.chooseBlazon")} />
            <SelectionBlazonButton
              color={teamsColorIcons.defaultColor?.[phase]}
              blazons={[
                { id: "square", icon: teamIcons?.[phase]?.[0]},
                { id: "triangle", icon: teamIcons?.[phase]?.[1] },
                { id: "round", icon: teamIcons?.[phase]?.[2] },
              ]}
              size={65}
              onPress={setActiveBlazon}
            />
          </WrapperBlazon>
          <WrapperColor>
            <Header index={3} text={t("app.team.chooseColor")} />
            <ColorsSelection
              size={40}
              {...{ ballsColors }}
              onPress={setActiveColor}
            />
          </WrapperColor>
        </WrapperRow>

        <CreateButton onPress={createNewTeam} disabled={!teamName || !activeColor || !activeBlazon}>
          <T path="app.team.create" />
        </CreateButton>
        <BackButton
          texts={[t("app.team.backTeamChoice")]}
          onPressArray={[() => campaignNavigate("team")]}
          withoutActive
        />
      </WrapperNewTeam>
    </DefaultModal>
  )
}

const TeamNameError = styled.Text({
  alignSelf: 'center',
  backgroundColor: colors.highlightDanger,
  color: colors.white,
  padding: 5
})

const Header = ({ index, text }) => {
  return (
    <WrapperHeader>
      <HeaderNumber>
        <HeaderNumberText>{index}</HeaderNumberText>
      </HeaderNumber>
      <HeaderLabel>{text}</HeaderLabel>
    </WrapperHeader>
  )
}

const WrapperHeader = styled.View({
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  borderBottomColor: colors.text,
  borderBottomWidth: 1,
  marginBottom: 20,
})

const HeaderNumber = styled.View({
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: colors.darkerBackground,
  width: 30,
  height: 30,
  borderRadius: 15,
})

const HeaderNumberText = styled(Title1)({
  color: colors.lightText,
})

const HeaderLabel = styled(Title2)({
  marginVertical: 10,
})

const WrapperNewTeam = styled.View(({mobile, minHeight}) => ({
  padding: 20,
  minHeight: mobile ? 'unset' : minHeight
}))

const AllTeamsFullText = styled(MediumText)({
  fontSize: 18,
  lineHeight: 21,
  alignSelf: "center",
  width: "70%",
  marginBottom: 35,
})

const WrapperPreview = styled.View({
  alignItems: "center",
  marginBottom: 35,
})

const Preview = styled(BoldText)({
  fontSize: 16,
  lineHeight: 19,
  marginTop: 10,
})

const WrapperRow = styled.View(({ mobile }) => ({
  flexDirection: mobile ? "column" : "row",
  flex: 1,
  justifyContent: "space-between",
  marginBottom: 40,
}))

const WrapperName = styled.View({
  flex: 1,
  flexDirection: "column",
})
const WrapperBlazon = styled.View({
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  marginHorizontal: 20,
})
const WrapperColor = styled.View({
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
})

const CreateButton = styled(TextButton)({
  alignSelf: "center",
})

const BackButton = styled(UseMultiTextesBarSelection)({})
