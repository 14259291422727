import React, {useEffect, useMemo, useRef, useState} from "react"
import styled from "@emotion/native"
import {Animated, PanResponder, TouchableWithoutFeedback, View} from "react-native"
import ScrollbarArrow from '../Components/Modals/Assets/scrollbar-arrow.svg'
import {BehaviorSubject} from "rxjs"

export const CustomScrollbar = ({
                                  heightThumb,
                                  customPositionTop,
                                  scrollbarPositions,
                                  widthThumb,
                                  widthTrack,
                                  color,
                                  backgroundColor,
                                  scrollbarRef,
                                  marginTop,
                                  marginBottom,
                                  marginRight,
                                  right,
                                  horizontal,
                                  customPositionLeft
                                }) => {
  const scrollTrackAnimation = useRef(new Animated.Value(0)).current
  const localScrollbarValue = useRef(null)
  const [_scrollbarMoves] = useState(new BehaviorSubject({}))

  //Todo: Slow scrollbar//

  const goToScroll = (pos) => {
    const a = requestAnimationFrame(() => {
      cancelAnimationFrame(a)
      return horizontal ?
        scrollbarRef?.current?.scrollTo({x: pos, animated: true})
        : scrollbarRef?.current?.scrollTo({y: pos, animated: true})
    })
  }

  useEffect(() => {
    _scrollbarMoves
      .subscribe((gestureState) => {
        goToScroll(
          horizontal ?
            localScrollbarValue?.current?.contentOffset?.x + (gestureState.dx)
            : localScrollbarValue?.current?.contentOffset?.y + (gestureState.dy)
        )
      })
  }, [])

  const panResponder = useMemo(
    () => {
      return PanResponder.create({
        onMoveShouldSetPanResponder: (_event, _gestureState) => true,
        onPanResponderMove: (event, gestureState) => {
          return _scrollbarMoves.next(gestureState)
        }
      })
    },
    [horizontal])

  useEffect(() => {
    if (typeof scrollbarPositions?.contentOffset?.y !== 'number') return
    localScrollbarValue.current = scrollbarPositions
    const trackSize = horizontal ?
      (((scrollbarPositions?.layoutMeasurement?.width - 40) * 100) / (scrollbarPositions?.contentSize?.width - 40))
      : (((scrollbarPositions?.layoutMeasurement?.height - 40) * 100) / (scrollbarPositions?.contentSize?.height - 40))
    Animated.timing(scrollTrackAnimation, {
      toValue: horizontal ?
        (scrollbarPositions?.contentOffset?.x * trackSize) / 100
        : (scrollbarPositions?.contentOffset?.y * trackSize) / 100,
      duration: 0,
      useNativeDriver: true
    }).start()
  }, [scrollbarPositions?.contentOffset])

  return <WrapperCustomScrollbar widthThumb={widthThumb}
                                 heightThumb={heightThumb}
                                 horizontal={horizontal}
                                 customPositionLeft={customPositionLeft}
                                 backgroundColor={backgroundColor}
                                 customPositionTop={customPositionTop}
                                 marginRight={marginRight || 0}
                                 marginTop={marginTop || 0} marginBottom={marginBottom || 0}
                                 right={right || 0}>
    <WrapperScrollButton top horizontal={horizontal}>
      <TouchableWithoutFeedback onPress={() => goToScroll(horizontal ?
        scrollbarPositions?.contentOffset?.x - 100
        : scrollbarPositions?.contentOffset?.y - 100
      )}>
        <View>
          <ScrollbarArrow height={18} width={10}/>
        </View>
      </TouchableWithoutFeedback>
    </WrapperScrollButton>
    <WrapperTrack {...panResponder.panHandlers}>
      <Track horizontal={horizontal}
             widthTrack={horizontal ?
               (((scrollbarPositions?.layoutMeasurement?.width - 40) * 100) / (scrollbarPositions?.contentSize?.width - 40)) || 0
               : widthTrack} color={color}
             translate={scrollTrackAnimation}
             trackHeight={horizontal ?
               widthTrack
               : (((scrollbarPositions?.layoutMeasurement?.height - 40) * 100) / (scrollbarPositions?.contentSize?.height - 40)) || 0}/>
    </WrapperTrack>
    <WrapperScrollButton bottom horizontal={horizontal}>
      <TouchableWithoutFeedback onPress={() => goToScroll(horizontal ?
        scrollbarPositions?.contentOffset?.x + 100
        : scrollbarPositions?.contentOffset?.y + 100
      )}>
        <View>
          <ScrollbarArrow height={18} width={10}/>
        </View>
      </TouchableWithoutFeedback>
    </WrapperScrollButton>
  </WrapperCustomScrollbar>
}

const WrapperScrollButton = styled.View(({top, bottom, horizontal}) => ({
  position: 'absolute',
  width: horizontal ? 20 : 'unset',
  top: top && !horizontal ? '-20px' : horizontal && bottom ? '25%' : 'unset',
  bottom: bottom && !horizontal ? '-20px' : 'unset',
  left: horizontal && top ? -15 : horizontal && bottom ? 'unset' : '50%',
  right: horizontal && bottom ? -20 : 'unset',
  transform: [{rotate: top && !horizontal ? '90deg' : top && horizontal ? 0 : bottom && !horizontal ? '-90deg' : bottom && horizontal ? '180deg' : '0deg'},
    {translateX: ((top || bottom) && !horizontal) ? '-25%' : '25%'},
    {translateY: top && !horizontal ? '25%' : bottom && !horizontal ? '-25%' : top && horizontal ? '-25%' : bottom && horizontal ? '25%' : 0}]
}))

const WrapperCustomScrollbar = styled.View(({
                                              widthThumb,
                                              customPositionTop,
                                              heightThumb,
                                              backgroundColor,
                                              marginTop,
                                              marginBottom,
                                              marginRight,
                                              right,
                                              horizontal,
                                              customPositionLeft
                                            }) => ({
  position: 'absolute',
  top: horizontal ? 'unset' : customPositionTop,
  left: horizontal ? customPositionLeft : 'unset',
  bottom: horizontal ? 0 : 'unset',
  right: right,
  width: horizontal ? heightThumb - 40 : widthThumb,
  backgroundColor: backgroundColor,
  borderRadius: 12,
  height: horizontal ? widthThumb : heightThumb - 40,
  marginTop: marginTop,
  marginBottom: marginBottom,
  marginRight: marginRight,
}))

const WrapperTrack = styled.View({
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: 3
})

const Track = styled(Animated.View)(({widthTrack, color, trackHeight, translate, horizontal}) => ({
  width: horizontal ? `${widthTrack}%` : widthTrack,
  backgroundColor: color,
  height: horizontal ? trackHeight : `${trackHeight}%`,
  transform: [{translateY: horizontal ? 0 : translate}, {translateX: horizontal ? translate : 0}],
  borderRadius: 12,
  cursor: 'pointer'
}))
