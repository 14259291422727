import React, { useEffect, useRef, useState } from "react";
import { Keyboard } from "react-native";
import { colors } from "@civitime/library/storybook/configs/colors";
import { QRText } from "./QRCameraSquare";
import styled from "@emotion/native";
import { nanoid } from "nanoid";
import { useCampaign } from "../../../../../GlobalContexts";
import { T } from "../../../../../translations/translate";
import { useProfile, userState } from "../../../../../Auth/useProfile";

export const BackUpQR = ({
  data,
  keyboardOpened,
  catchError,
  setInput,
  input,
  mobile,
}) => {
  const { campaignNavigate } = useCampaign();
  const { emitCommand } = useProfile();
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const selectRef = (value, index) => {
    switch (index) {
      case 0:
        value && input2Ref?.current?.focus();
        return;
      case 1:
        !value?.length
          ? input1Ref?.current?.focus()
          : input3Ref?.current?.focus();
        return;
      case 2:
        !value?.length
          ? input2Ref?.current?.focus()
          : input4Ref?.current?.focus();
        return;
      case 3:
        !value?.length && input3Ref?.current?.focus();
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    if (input.join("").length === 4) {
      handleSubmit();
    }
  }, [input]);

  const handleInput = (value, index) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    if (value && !numericValue) return;
    setInput((state) => {
      const copiedState = [...state];
      copiedState[index] = numericValue;
      return copiedState;
    });
    selectRef(numericValue, index);
  };

  const handleSubmit = () => {
    if (data?.qrCode === input.join("")) {
      const moduleId = nanoid();
      console.log("send data");
      fetch(
        `https://europe-west3-ct-next.cloudfunctions.net/gameServerDev/update-qrcode-leclerc-stats/${
          JSON.parse(JSON.parse(localStorage.getItem("authToken")))?.userId ||
          "undefined"
        }/${input.join("")}`,
        {
          method: "POST",
        }
      ).catch((err) => console.log(err));
      emitCommand({
        type: "UnlockQr",
        payload: {
          buildingType: data?.buildingType,
          moduleStep: data?.moduleStep,
          moduleType: data?.moduleType,
          moduleTheme: data?.moduleTheme,
          moduleId: moduleId,
        },
      });
      campaignNavigate(
        `module/${data?.buildingType}/${data?.moduleStep}/${data?.moduleType}/${data?.moduleTheme}/${moduleId}/origin`
      );
    } else {
      catchError();
    }
  };

  return (
    <BackUpQRWrapper keyboardOpened={keyboardOpened}>
      <MarginWrapper>
        <QRText color={colors.white}>
          <T path="phase_2.modals.qrModal.backup" />
        </QRText>
        <QrInputsWrapper>
          <Input
            ref={input1Ref}
            handleInput={handleInput}
            input={input}
            index={0}
            mobile={mobile}
          />
          <Input
            ref={input2Ref}
            handleInput={handleInput}
            input={input}
            index={1}
            mobile={mobile}
          />
          <Input
            ref={input3Ref}
            handleInput={handleInput}
            input={input}
            index={2}
            mobile={mobile}
          />
          <Input
            ref={input4Ref}
            handleInput={handleInput}
            input={input}
            index={3}
            mobile={mobile}
          />
        </QrInputsWrapper>
      </MarginWrapper>
    </BackUpQRWrapper>
  );
};

const Input = React.forwardRef(({ handleInput, input, index, mobile }, ref) => {
  return (
    <QRInputWrapper>
      <QRInput
        onChangeText={(e) => handleInput(e, index)}
        value={input?.[index] ?? ""}
        maxLength={1}
        ref={ref}
        keyboardType={mobile ? "numeric" : "default"}
        onSubmitEditing={Keyboard?.dismiss}
      />
      <QrSubline />
    </QRInputWrapper>
  );
});

const QRInputWrapper = styled.View({
  width: 50,
  height: 50,
  position: "relative",
});

const MarginWrapper = styled.View({
  marginRight: "10%",
  marginLeft: "10%",
  marginTop: "auto",
  marginBottom: "auto",
});

const BackUpQRWrapper = styled.View(({ keyboardOpened }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: colors.text,
  opacity: 0.8,
  height: keyboardOpened ? "100%" : "40%",
}));

const QRInput = styled.TextInput({
  margin: "auto",
  paddingLeft: 20,
  paddingRight: 20,
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  fontFamily: "Raleway_700Bold",
  fontWeight: "bold",
  fontSize: 18,
  lineHeight: 21,
  color: colors.text,
  width: "100%",
  height: "100%",
});

const QrSubline = styled.View({
  height: 1,
  backgroundColor: colors.text,
  width: "100%",
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 15,
});

const QrInputsWrapper = styled.View({
  borderRadius: 9,
  backgroundColor: colors.white,
  height: 50,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  opacity: 1,
});
