import { CloseButtonDefaultModal, DefaultModal } from '@civitime/library/storybook/stories/Modals'
import React                                     from 'react'
import styled                                    from '@emotion/native'
import { Title2 }                                from '@civitime/library/storybook/stories/Texts'
import { colors }                                from '@civitime/library/storybook/configs/colors'
import { ActionButton }     from '@civitime/library/storybook/stories/Button'
import { T, useTranslator } from '../../../../../translations/translate'


export const QrErrorModal = ({ closeError, codeError}) => {
  const {t} = useTranslator()
  return <ErrorModal noHeader noBorder withShadow borderRadius={15}>
    <CloseButtonDefaultModal color={colors.secondary} size={30} crossColor={colors.white} iconColor={colors.white} top={9} right={7}
                             iconSize={15} onPress={closeError}/>
    <ContentWrapper>
    <ErrorTitle>
      <T path="phase_2.modals.qrModal.errorModal.title"/>
    </ErrorTitle>
    <TextError>
      {codeError === "qrCode" ? t("phase_2.modals.qrModal.errorModal.qr")
        :  t("phase_2.modals.qrModal.errorModal.input")}
    </TextError>
    </ContentWrapper>
    <ActionButtonWrapper>
      <ActionButton onPress={closeError}>
        <T path="phase_2.modals.qrModal.errorModal.button"/>
      </ActionButton>
    </ActionButtonWrapper>
  </ErrorModal>
}

const ErrorTitle = styled(Title2)({
  textTransform: 'uppercase',
  marginBottom: 10
})

const TextError = styled(Title2)({})

const ErrorModal = styled(DefaultModal)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  zIndex: 1000,
  right: 0,
})

const ContentWrapper = styled.View({
  marginTop: 45,
  marginLeft: 20,
  marginRight: 20
})

const ActionButtonWrapper = styled.View({
  margin: "auto",
  marginTop: 25
})