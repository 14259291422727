import React, {useEffect} from "react"
import {useProfile}       from "../../../../Auth/useProfile"
import {useTranslator}    from "../../../../translations/translate"
import { clientId }       from '../../../../../clientId'
import { useCampaign }    from '../../../../GlobalContexts'

export const TutoWonder = ({onOpen, onClose, linkModal, building}) => {
  const {emitCommand, userState} = useProfile()
  const {t} = useTranslator()
  const {campaignId, campaignEnded} = useCampaign()
  useEffect(() => {
    if (onOpen) {
      linkModal({
        type: 'basic',
        infos: {
          title: t(`phase_2.modals.buildingModal.buildings.${building?.type}.name`),
          text: (campaignEnded && !userState?.teamId) ? t("phase_2.tutorial.wonder.textUnBoarding") : t("phase_2.tutorial.wonder.text"),
          buttonText: t("phase_2.tutorial.wonder.buttonText"),
          asset: `https://ct-campaigns.civitimeapp.com/phase2/${clientId}/campaigns/${campaignId}/buildings/merveille/bonus/merveille.svg`,
          action: async () => {
            await emitCommand({
              type: "Phase2UpdateSeeTutoStep",
              payload: {
                step: "wonderFirstClicked"
              }
            })
            onClose()
            linkModal({type: "merveille", infos: building})
          },
          noCloseButton: true,
          tuto: true
        }
      })
    }
  }, [onOpen])

  return <></>
}
