import styled                                 from '@emotion/native'
import { colors }                             from '@civitime/library/storybook/configs/colors'
import React, { useEffect, useRef, useState } from 'react'
import { Title1 }                             from '@civitime/library/storybook/stories/Texts'


export const QRCameraSquare = ({ size, width }) => {
  
  const ref = useRef(null)
  const [photoDimensions, setPhotoDimensions] = useState({})
  const [calculatedSize, setCalculatedSize] = useState(0)
  
  const getPhotoDimensions = (event) => {
    let {width, height} = event.nativeEvent.layout
    setPhotoDimensions({width: width, height: height})
  }
  useEffect(() => {
    photoDimensions?.width > photoDimensions?.height ? setCalculatedSize(photoDimensions?.height)
      : setCalculatedSize(photoDimensions?.width)
  }, [photoDimensions?.width, photoDimensions?.height])
  
  return <CornersWrapper ref={ref} onLayout={getPhotoDimensions}>
    <BackgroundView size={calculatedSize} width={photoDimensions?.width}/>
    <Wrapper size={calculatedSize}>
      <Corner topLeft size={size} width={width}/>
      <Corner topRight size={size} width={width}/>
      <Corner bottomLeft size={size} width={width}/>
      <Corner bottomRight size={size} width={width}/>
    </Wrapper>
    <BackgroundView size={calculatedSize} width={photoDimensions?.width}/>
  </CornersWrapper>
}

const Corner = ({ topLeft, topRight, bottomLeft, bottomRight, size, width }) => {
  return <CornerWrapper topLeft={topLeft} topRight={topRight} bottomLeft={bottomLeft} bottomRight={bottomRight}
                        size={size} width={width}>
    <FirstLign topLeft={topLeft} topRight={topRight} bottomLeft={bottomLeft} bottomRight={bottomRight} size={size}
               width={width}/>
    <SecondLign topLeft={topLeft} topRight={topRight} bottomLeft={bottomLeft} bottomRight={bottomRight} size={size}
                width={width}/>
  </CornerWrapper>
}



const CornersWrapper = styled.View({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  height: '75%',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: 'none',
  zIndex: 3
})

const Wrapper = styled.View(({size}) => ({
  height: size,
  width: size
}))

const CornerWrapper = styled.View(({ topLeft, topRight, bottomLeft, bottomRight, size, width }) => ({
  width: size,
  height: size,
  position: 'absolute',
  left: (topLeft || bottomLeft) && 0,
  right: (topRight || bottomRight) && 0,
  top: (topLeft || topRight) && 0,
  bottom: (bottomRight || bottomLeft) && 0,
}))


const FirstLign = styled.View(({ topLeft, topRight, bottomLeft, bottomRight, size, width }) => ({
  backgroundColor: colors.white,
  ...(topLeft || topRight) && {
    height: width,
    width: size,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: topLeft && 4,
    borderBottomLeftRadius: topRight && 4
  },
  ...(bottomLeft || bottomRight) && {
    height: size - width,
    width: width,
    transform: bottomRight && [{ translateX: size - width }],
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  }
}))

const BackgroundView = styled.View(({width, size}) => ({
  backgroundColor: colors.text,
  opacity: .8,
  width: (width - size) / 2,
}))
const SecondLign = styled.View(({ topLeft, topRight, bottomLeft, bottomRight, size, width }) => ({
  backgroundColor: colors.white,
  ...(topLeft || topRight) && {
    height: size - width,
    width: width,
    transform: topRight && [{ translateX: size - width }],
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  ...(bottomLeft || bottomRight) && {
    width: size,
    height: width,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopRightRadius: bottomLeft && 4,
    borderTopLeftRadius: bottomRight && 4
  }
}))

export const QRText = styled(Title1)({
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
  fontSize: 18,
  fontWeight: 'normal',
  backgroundColor: colors.text,
  opacity: 1
})
