import { firebaseAccessor }         from '../../../firebase'
import { createReducerRunner }      from '@civitime/game-server/lib/saga-tools/sagas-tools'
import {
  langUpdated,
  nameProfileUpdated,
  playerJoined,
  userInfosDeleted
}                                   from '@civitime/game-server/lib/Domain/Profile/Profile.reducers'
import { avatarUpdated }            from '@civitime/game-server/lib/Domain/Avatar/Avatar.reducers'
import {
  fogMapUpdated,
  playerPositionUpdated
}                                   from '@civitime/game-server/lib/Domain/Phases/phase1/Map/Map.reducers'
import { phase2UpdateTutoStepSeen } from '@civitime/game-server/lib/Domain/Phases/phase2/Tuto/Tuto.reducers'
import {
  phase2BuildingLocked,
  phase2BuildingSeenUpdated, phase2BuildingUnlocked,
  phase2CampaignEndedModalSeen,
  phase2ModuleEnded,
  phase2WonderNotificationSeen
} from '@civitime/game-server/lib/Domain/Phases/phase2/Session/Session.reducers'
import {
  phase2ModuleGoldenCompanyEnded,
  phase2ModulePictionaryEnded,
  phase2ModulePreferencesEnded
}                                   from '@civitime/game-server/lib/Domain/Phases/phase2/Modules/Modules.reducers'
import {
  bossModuleFailed,
  challengeModuleEnded,
  challengeModuleSeen,
  moduleEnded,
  moduleEndedV2,
  moduleFailed,
  moduleFailedV2,
  opponentChallengeModuleSeen
}                                   from '@civitime/game-server/lib/Domain/Phases/phase1/Session/Session.reducers'
import { playerChallenged }         from '@civitime/game-server/lib/Domain/Phases/phase1/Challenges/Challenges.reducers'
import {
  teamPointsUpdated,
  userPointsUpdated
}                                   from '@civitime/game-server/lib/Domain/Phases/phase1/Points/Points.reducers'
import { inviteTeamCreated }        from '@civitime/game-server/lib/Domain/Invite/Invite.reducers'
import { actionPointsSet }          from '@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.reducers'
import { phase2BuildingEvolved }    from '@civitime/game-server/lib/Domain/Phases/phase2/Buildings/Buildings.reducers'
import {
  leaderboardSynchronized,
  phase2BuildingInvested,
  phase2FlouzWin,
  phase2PointsWin,
} from '@civitime/game-server/lib/Domain/Phases/phase2/Points/Points.reducers'
import {
  lastMessageChatTeamSeen,
  teamMessageSent
}                                   from '@civitime/game-server/lib/Domain/Team/Team.reducers'
import {
  campaignEndedModalSeen,
  gameAccountReInitialized
}                     from '@civitime/game-server/lib/Domain/Phases/phase1/Campaign/Campaign.reducers'
import {
  finalDestinationDiscovered,
  finalDestinationModalSeen,
  playerFragmentsUpdated,
  playerFragmentThemeUpdated
}                          from '@civitime/game-server/lib/Domain/Phases/phase1/Fragments/Fragments.reducers'
import {
  offlinePublicProfileInitiated,
  offlinePublicProfileUpdated
}                          from './LocalProfile/LocalProfile.reducers'
import { qrUnlocked }                            from './LocalQr/LocalQr.reducers'
import { finalLeaderboardSent, leaderboardSent } from './LocalLeaderboard/LocalLeaderboard.reducers'

export const reducers = {
  NameProfileUpdated: [nameProfileUpdated],
  PlayerJoined: [playerJoined],
  
  OfflinePublicProfileInitiated: [offlinePublicProfileInitiated],
  OfflinePublicProfileUpdated: [offlinePublicProfileUpdated],
  
  AvatarUpdated: [avatarUpdated],
  PlayerPositionUpdated: [playerPositionUpdated],
  FogMapUpdated: [fogMapUpdated],
  InviteTeamCreated: [inviteTeamCreated],
  
  ActionPointsSet: [actionPointsSet],
  QrUnlocked: [qrUnlocked],
  
  ModuleEnded: [moduleEnded],
  ModuleEndedV2: [moduleEndedV2],
  ModuleFailed: [moduleFailed],
  ModuleFailedV2: [moduleFailedV2],
  BossModuleFailed: [bossModuleFailed],
  PlayerChallenged: [playerChallenged],
  UserPointsUpdated: [userPointsUpdated],
  TeamPointsUpdated: [teamPointsUpdated],
  
  PlayerFragmentsUpdated: [playerFragmentsUpdated],
  PlayerFragmentThemeUpdated: [playerFragmentThemeUpdated],
  FinalDestinationDiscovered: [finalDestinationDiscovered],
  FinalDestinationModalSeen: [finalDestinationModalSeen],
  ChallengeModuleEnded: [challengeModuleEnded],
  ChallengeModuleSeen: [challengeModuleSeen],
  OpponentChallengeModuleSeen: [opponentChallengeModuleSeen],
  
  UserInfosDeleted: [userInfosDeleted],
  CampaignEndedModalSeen: [campaignEndedModalSeen],
  
  GameAccountReInitialized: [gameAccountReInitialized],
  LangUpdated: [langUpdated],
  
  TeamMessageSent: [teamMessageSent],
  LastMessageChatTeamSeen: [lastMessageChatTeamSeen],
  
  // //Phase 2
  Phase2ModuleEnded: [phase2ModuleEnded],
  Phase2PointsWin: [phase2PointsWin],
  Phase2BuildingUnlocked: [phase2BuildingUnlocked],
  Phase2FlouzWin: [phase2FlouzWin],
  Phase2BuildingInvested: [phase2BuildingInvested],
  Phase2BuildingEvolved: [phase2BuildingEvolved],
  Phase2BuildingSeenUpdated: [phase2BuildingSeenUpdated],
  Phase2WonderNotificationSeen: [phase2WonderNotificationSeen],
  Phase2UpdateTutoStepSeen: [phase2UpdateTutoStepSeen],
  Phase2BuildingLocked: [phase2BuildingLocked],
  Phase2ModulePreferencesEnded: [phase2ModulePreferencesEnded],
  Phase2ModulePictionaryEnded: [phase2ModulePictionaryEnded],
  Phase2ModuleGoldenCompanyEnded: [phase2ModuleGoldenCompanyEnded],
  Phase2CampaignEndedModalSeen: [phase2CampaignEndedModalSeen],
  LeaderboardSent: [leaderboardSent],
  FinalLeaderboardSent: [finalLeaderboardSent],
  LeaderboardSynchronized: [leaderboardSynchronized]
  
}

export const createMainReducers = () => {
  return createReducerRunner(reducers, firebaseAccessor)
}

