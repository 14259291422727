import React, {useEffect} from "react"
import {ArrowFirstClick}  from "./ArrowFirstClick"
import {useProfile}       from "../../../../Auth/useProfile"
import {useTranslator}    from "../../../../translations/translate"
import {T}                from '../../../../translations/translate'
import HallTown           from '../../Map/Assets/buildings/mairie.svg'
import { ModalText }      from '../Modals/BasicModal'
import styled             from '@emotion/native'
import { PWA }            from '../../../../../clientId'
export const TutoHallTown = ({onOpen, onClose, linkModal}) => {
  const {emitCommand} = useProfile()
  const {t} = useTranslator()

  useEffect(() => {
    if (onOpen) {
      linkModal({
        type: 'basic',
        infos: {
          title: t(PWA? "phase_2.tutorial.hallTown.PWA.title" : "phase_2.tutorial.hallTown.title"),
          text: <Wrapper>
            <HallTown style={{verticalAlign: 'bottom', width: 40, height: 40, marginLeft: 'auto', marginRight: 'auto'}}/>
            <ModalText>
            <T path={PWA ? "phase_2.tutorial.hallTown.PWA.text" : "phase_2.tutorial.hallTown.text"}/>
          </ModalText>
          </Wrapper>,
          buttonText: t("phase_2.tutorial.hallTown.buttonText"),
          action: async () => {
            await emitCommand({
              type: "Phase2UpdateSeeTutoStep",
              payload: {
                step: "hallTownFirstClicked"
              }
            })
            onClose()
            linkModal({type: "mairie"})
          },
          noCloseButton: true
        }
      })
    }
  }, [onOpen])

  return <>
    <ArrowFirstClick/>
  </>
}

const Wrapper = styled(ModalText)({
  display: 'flex',
  flexDirection: 'column'
})
